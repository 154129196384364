import { deleteRequest, getRequest, IApiResponse, postRequest, putRequest } from "../../service/Basevices";
import { IResBody } from "../interface";
import {
    IGetDetailProAPI,
    IGetListOrderAPI,
    IGetListProAPI,
    IPostProductAPI,
    IPutProductAPI,
    IResGetListCustomer,
} from "./Interface";
import { Config } from "../../commons/validation/ValidationPattern";

export const getListProAPI = (
    page: number,
    search: string | null,
    status: number | null,
    categoryId: any,
    type: number,
    limit?: number,
    price_order?: number,
    sold_order?: number
) => {
    let url = `/product/list?page=${page}&limit=${limit ? limit : Config.limit}`;
    if (search) url = url + `&search=${search}`;
    if (status === 0 || status === 1) url = url + `&status=${status}`;
    if (categoryId) url = url + `&category_id=${categoryId}`;
    if (type >= 0) url = url + `&type=${type}`;
    if (price_order === 1 || price_order === 0) url = url + `&price_order=${price_order}`;
    if (sold_order === 0 || sold_order === 1) url = url + `&sold_order=${sold_order}`;
    return getRequest(url, true);
};

export const getProductDetailInfoAPI = (id: any): Promise<IApiResponse<IGetDetailProAPI>> => {
    let url: string = `/product/${id}/detail`;
    return getRequest(url, false);
};

export const getListCustomerAPI = (id: any, page: number): Promise<IApiResponse<IResGetListCustomer>> => {
    const path: string = `/product/${id}/customer_product?page=${page}&limit=${Config.limit}`;
    return getRequest(path, true);
};

export const getListOrderAPI = (id: number, page: number): Promise<IApiResponse<IGetListOrderAPI>> => {
    return getRequest(`/product/${id}/order_product?page=${page}&limit=${Config.limit}`);
};

export const postProductAPI = (data: IPostProductAPI): Promise<IApiResponse<IResBody>> => {
    const path: string = `/product/create`;
    return postRequest(path, true, data);
};
export const putStatusProductAPI = (id: number, status: number): Promise<IApiResponse<IResBody>> => {
    let url: string = `/product/${id}/change-status`;
    return putRequest(url, true, { status: status });
};

export const putProductAPI = (data: IPutProductAPI, id: any): Promise<IApiResponse<IResBody>> => {
    let url: string = `/product/${id}/update`;
    return putRequest(url, true, data);
};

export const deleteProductAPI = (id: any): Promise<IApiResponse<IResBody>> => {
    const url = `/product/${id}/delete`;
    return deleteRequest(url, true);
};

export const deleteListProductAPI = (listId: number[]): Promise<IApiResponse<IResBody>> => {
    let path: string = `/product/delete`;
    return deleteRequest(path, true, { list_id: listId });
};
