import { InputNumber } from "antd";
import { changeWeight } from "app/slices/add-update-product/AddAndUpdateProduct";
import { useDispatch } from "react-redux";

interface IWeightInput {
    value: any;
}

const WeightInput = (props: IWeightInput) => {
    const { value } = props;
    console.log("🚀 ~ WeightInput ~ value:", value);
    const dispatch = useDispatch();

    return (
        <InputNumber
            placeholder={"Trọng lượng"}
            value={!value?.value ? "" : value?.value}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(text) => {
                dispatch(
                    changeWeight({
                        key: value.id,
                        weight: text,
                    })
                );
            }}
            style={{ width: 150 }}
            onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
        />
    );
};

export default WeightInput;
