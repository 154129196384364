import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import AppServices from "service/AppServices";
import { Notification } from "utils";
import { v4 as uuidv4 } from "uuid";
import { getProductDetailInfoAPI, postProductAPI, putProductAPI } from "../../../api/product/Product";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { ValidationPattern } from "../../../commons/validation/ValidationPattern";
import { ITypePro } from "../../../modules/product/Interfaces";
import { defaultColumns } from "../../../modules/product/page/add-update/component/InfoSaleComponent";
import { IFile } from "../../../modules/product/page/add-update/component/UploadListFileComponent";
import { IColumn } from "../../../service/Interfaces";
import { convertDataSourceToPost, getColumnPrice, getDataSourcePrice, getProductCustomAttribute } from "./Function";

interface ICate {
    id: any;
    name: string;
}

export interface IInitState {
    code: string;
    description: string;
    cate: ICate;
    cateChild: ICate;
    proName: string;
    price: number;
    statusStock: number;
    status: number;
    statusProduct: number;

    listImg: { image_url: string; display_order: number }[];
    defaultListImage: { image_url: string; fileName: string; type: number; is_default: any }[];
    video: string;
    defaultVideo: IFile[];
    listTypeImg: { image_url: string; index: number }[];
    defaultListTypeImg: { image_url: string; index: number }[];
    defaultSelectClassifyImg: { id: number; value: string; index: number };

    columns: IColumn[];
    dataSource: any[];
    typeProdCache: ITypePro;
    typePro: ITypePro[];
    listClassifyName: { key: any; value: string }[];
    defaultDataSource: any[];

    validation: {
        code: boolean;
        namePro: boolean;
        description: boolean;
        cate: boolean;
        price: boolean;
        imagePro: boolean;
        nameGroupClassify: boolean;
        nameClassify: boolean;
    };
    loadingGetDetailProduct: boolean;
    loadingUpdatePro: boolean;
    loadingPostPro: boolean;
    default_media_url: string;
    default_media_type: number; // 1 - ảnh, 2 - video
    sold_count: number;
}

const initialState: any = {
    code: "",
    description: "",
    cate: { id: 0, name: "" },
    cateChild: { id: 0, name: "" },
    proName: "",
    statusStock: 0,
    status: 1,
    statusProduct: 0,

    listImg: [],
    defaultListImage: [],
    video: "",
    defaultVideo: [],
    listTypeImg: [],
    defaultSelectClassifyImg: { id: 0, value: "", index: 0 },
    defaultListTypeImg: [],

    columns: defaultColumns,
    dataSource: [],
    defaultDataSource: [],
    typeProdCache: {
        key: "",
        typeItem: [{ key: uuidv4(), value: "", url: "", fileName: "" }],
    },
    typePro: [],
    price: 0,
    listClassifyName: [],

    validation: {
        cate: true,
        code: true,
        description: true,
        imagePro: true,
        namePro: true,
        price: true,
        nameGroupClassify: true,
        nameClassify: true,

        // update
        weight: true,
        stock: true,
        wholesale_price: true,
        quantity_wholesale_price: true,
    },
    loadingUpdatePro: false,
    loadingGetDetailProduct: false,
    loadingPostPro: false,
    stock: 0,
    weight: 0,
    wholesale_price: 0,
    quantity_wholesale_price: 0,
    allow_order_when_out_stock: 0,
    allow_sale: 1,
    default_media_url: "",
    default_media_type: 1, // 1 - ảnh, 2 - video
    sold_count: 0,
};

export const postProductAction = createAsyncThunk("addAndUpdatePro/createProductAction", async (arg, thunkAPI) => {
    const store: any = thunkAPI.getState();
    let {
        code,
        description,
        cate,
        proName,
        price,
        listImg,
        video,
        listTypeImg,
        columns,
        dataSource,
        typePro,
        validation,
        cateChild,
        statusProduct,
        stock,
        weight,
        wholesale_price,
        quantity_wholesale_price,
        allow_order_when_out_stock,
        allow_sale,
        default_media_url,
        default_media_type,
        sold_count,
    } = store.addAndUpdateProduct;

    switch (false) {
        case validation.code: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.weight: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.stock: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.wholesale_price: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.wholesale_quantity: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.description: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.price: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.imagePro: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.cate: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.nameClassify: {
            return new Promise((resolve, reject) => reject("0"));
        }
        case validation.nameGroupClassify: {
            return new Promise((resolve, reject) => reject("0"));
        }
        default: {
            const checkValidation = () => {
                const listData: any[] =
                    typePro?.length > 0
                        ? convertDataSourceToPost(dataSource)
                        : [
                              {
                                  wholesale_price,
                                  wholesale_quantity: quantity_wholesale_price,
                                  price,
                                  stock,
                                  weight,
                                  sold_count,
                              },
                          ];
                const invalidWholesaleItem = listData?.find(
                    (item: any) =>
                        (item?.wholesale_price > 0 && !item?.wholesale_quantity && item?.wholesale_quantity !== 0) ||
                        (!item?.wholesale_price && item?.wholesale_price !== 0 && item?.wholesale_quantity > 0)
                );

                const zeroWholesaleQuantity = listData?.find(
                    (item: any) => item?.wholesale_quantity === 0 && item?.wholesale_price
                );
                // thiếu giá bán, khối lượng, tồn kho
                const invalidItem = listData?.find(
                    (item: any) => !item?.price || !item?.weight || item?.stock === undefined
                );
                if (invalidItem) {
                    Notification("error", "Vui lòng nhập đầy đủ giá bán, khối lượng và số lượng tồn kho!");
                    return false;
                }
                if (invalidWholesaleItem) {
                    Notification("error", "Vui lòng nhập đầy đủ số lượng sỉ và giá sỉ cho sản phâm!");
                    return false;
                }
                if (zeroWholesaleQuantity) {
                    Notification("error", "Vui lòng nhập số lượng sỉ lớn hơn 0!");
                    return false;
                }

                if (description.trim() === "<p></p>" || description?.length === 0) {
                    Notification("error", "Vui lòng nhập mô tả sản phẩm!");
                    return false;
                }

                return true;
            };

            if (checkValidation()) {
                let data: any = {
                    code: code,
                    name: proName,
                    type_tag: parseInt(statusProduct),
                    category_id: cateChild.id ? parseInt(cateChild.id) : parseInt(cate.id),
                    description: description,
                    product_media: {
                        image_url: listImg,
                        video_url: video,
                        image_attribute_url: listTypeImg,
                        default_media_url,
                        default_media_type: 1,
                    },
                    product_price:
                        typePro.length > 0
                            ? convertDataSourceToPost(dataSource)
                            : [
                                  {
                                      tier_index: [0],
                                      price,
                                      status: 1,
                                      stock,
                                      weight,
                                      allow_sale,
                                      allow_order_when_out_stock,
                                      wholesale_price,
                                      sold_count,
                                      wholesale_quantity: quantity_wholesale_price,
                                  },
                              ],
                    product_custom_attribute: typePro.length > 0 ? getProductCustomAttribute(typePro, columns) : [],
                };

                const rs = await postProductAPI(data);
                if (rs.body.status === 1) {
                    Notification("success", "Thêm sản phẩm thành công!");
                    AppServices.history!.push(`/home/products?page=1&search=&status=&categoryId`);
                    resetForm();
                    return rs.body.message;
                } else {
                    return new Promise((resolve, reject) => reject(rs.body.message));
                }
            }
        }
    }
});

export const putProductAction = createAsyncThunk(
    "addAndUpdatePro/putProductAction",
    async (arg: { id: any }, thunkAPI) => {
        const store: any = thunkAPI.getState();
        let {
            description,
            cate,
            proName,
            price,
            listImg,
            video,
            listTypeImg,
            columns,
            dataSource,
            typePro,
            validation,
            cateChild,
            statusStock,
            status,
            statusProduct,
            stock,
            weight,
            wholesale_price,
            quantity_wholesale_price,
            allow_order_when_out_stock,
            allow_sale,
            defaultDataSource,
            default_media_url,
            sold_count,
        } = store.addAndUpdateProduct;
        switch (false) {
            case validation.code: {
                return new Promise((resolve, reject) => reject("Định dạng mã sản phẩm không hợp lệ."));
            }
            case validation.namePro: {
                return new Promise((resolve, reject) => reject("Định dạng tên sản phẩm không hợp lệ."));
            }
            case validation.description: {
                return new Promise((resolve, reject) => reject("Mô tả sản phẩm không được bỏ trống."));
            }
            // case validation.price: {
            //     return new Promise((resolve, reject) => reject("Giá sản phẩm không được bỏ trống."));
            // }
            case validation.imagePro: {
                return new Promise((resolve, reject) => reject("Không được bỏ trống ảnh sản phẩm."));
            }
            // case validation.cate: {
            //     return new Promise((resolve, reject) => reject("Không được bỏ trống danh mục sản phẩm."));
            // }
            case validation.nameClassify: {
                return new Promise((resolve, reject) => reject("0"));
            }
            case validation.nameGroupClassify: {
                return new Promise((resolve, reject) => reject("0"));
            }
            default: {
                const checkValidation = () => {
                    const listData: any[] =
                        typePro?.length > 0
                            ? convertDataSourceToPost(dataSource)
                            : [
                                  {
                                      wholesale_price,
                                      wholesale_quantity: quantity_wholesale_price,
                                      price,
                                      stock,
                                      weight,
                                      sold_count,
                                  },
                              ];
                    const invalidWholesaleItem = listData?.find(
                        (item: any) =>
                            (item?.wholesale_price > 0 &&
                                !item?.wholesale_quantity &&
                                item?.wholesale_quantity !== 0) ||
                            (!item?.wholesale_price && item?.wholesale_price !== 0 && item?.wholesale_quantity > 0)
                    );

                    const zeroWholesaleQuantity = listData?.find(
                        (item: any) => item?.wholesale_quantity === 0 && item?.wholesale_price
                    );
                    // thiếu giá bán, khối lượng, tồn kho
                    const invalidItem = listData?.find(
                        (item: any) => !item?.price || !item?.weight || item?.stock === undefined
                    );

                    if (invalidWholesaleItem) {
                        Notification("error", "Vui lòng nhập đầy đủ số lượng sỉ và giá sỉ cho sản phâm!");
                        return false;
                    }
                    if (zeroWholesaleQuantity) {
                        Notification("error", "Vui lòng nhập số lượng sỉ lớn hơn 0!");
                        return false;
                    }
                    if (invalidItem) {
                        Notification("error", "Vui lòng nhập đầy đủ giá bán, khối lượng và số lượng tồn kho!");
                        return false;
                    }

                    if (description === "<p></p>" || description === "<p></p>\n" || description?.length === 0) {
                        Notification("error", "Vui lòng nhập mô tả sản phẩm!");
                        return false;
                    }

                    return true;
                };
                if (checkValidation()) {
                    let data: any = {
                        status: status,
                        status_stock: statusStock,
                        name: proName,
                        type_tag: parseInt(statusProduct),
                        category_id: cateChild.id ? parseInt(cateChild.id) : parseInt(cate.id),
                        description,
                        product_media: {
                            image_url: listImg,
                            video_url: video,
                            image_attribute_url: listTypeImg,
                            default_media_url,
                            default_media_type: 1,
                        },
                        product_price:
                            typePro.length > 0
                                ? convertDataSourceToPost(dataSource)
                                : [
                                      {
                                          id: defaultDataSource?.[0]?.key,
                                          tier_index: [0],
                                          price,
                                          status: allow_sale,
                                          stock,
                                          weight,
                                          allow_sale,
                                          allow_order_when_out_stock,
                                          wholesale_price,
                                          sold_count,
                                          wholesale_quantity: quantity_wholesale_price,
                                      },
                                  ],
                        product_custom_attribute: typePro.length > 0 ? getProductCustomAttribute(typePro, columns) : [],
                    };

                    const rs = await putProductAPI(data, arg.id);
                    if (rs.body.status === 1) {
                        Notification("success", "Cập nhật sản phẩm thành công!");
                        AppServices.history!.push(`/home/products?page=1&search=&status=&categoryId`);
                        resetForm();
                        return rs.body.message;
                    } else {
                        return new Promise((resolve, reject) => reject(rs.body.message));
                    }
                }
            }
        }
    }
);

export const getDetailProductAction = createAsyncThunk("addAndUpdatePro", async (arg: { id: number }, thunkAPI) => {
    const rs = await getProductDetailInfoAPI(arg.id);
    if (rs.body.status === 1) {
        return rs.body;
    } else {
        return new Promise((resolve, reject) => reject(rs.body.message));
    }
});

const addAndUpdateProductSlice = createSlice({
    name: "addAndUpdatePro",
    initialState,
    reducers: {
        changeDataInfoPublicProAction: (state, action) => {
            let {
                code,
                description,
                cate,
                cateChild,
                proName,
                price,
                video,
                status,
                statusStock,
                videoFile,
                statusProduct,
                weight,
                stock,
                wholesale_price,
                quantity_wholesale_price,
                allow_sale,
                allow_order_when_out_stock,
                default_media_url,
                default_media_type,
                sold_count,
            } = action.payload;

            if (sold_count !== undefined) state.sold_count = sold_count;

            if (code !== undefined) state.code = code;
            if (description !== undefined) state.description = description;
            if (weight !== undefined) state.weight = weight;
            if (stock !== undefined) state.stock = stock;
            if (wholesale_price !== undefined) state.wholesale_price = wholesale_price;
            if (quantity_wholesale_price !== undefined) state.quantity_wholesale_price = quantity_wholesale_price;
            if (allow_sale !== undefined) state.allow_sale = allow_sale;
            if (allow_sale !== undefined) state.allow_sale = allow_sale;
            if (allow_order_when_out_stock !== undefined) state.allow_order_when_out_stock = allow_order_when_out_stock;
            if (cate !== undefined) state.cate = cate;
            if (cateChild !== undefined) state.cateChild = cate;
            if (cateChild !== undefined) state.cateChild = cateChild;
            if (proName !== undefined) state.proName = proName;

            if (status !== undefined) state.status = status;
            if (statusStock !== undefined) state.statusStock = statusStock;
            if (statusProduct !== undefined) state.statusProduct = statusProduct;

            if (price !== undefined) state.price = price;
            if (video !== undefined) state.video = video;
            if (videoFile !== undefined) state.defaultVideo = videoFile;
            if (default_media_url?.length >= 0) {
                if (default_media_url?.length === 0) {
                    state.listImg = state.defaultListImage
                        ?.filter((item: any) => item?.is_default !== 1 || item?.isDefault !== 1)
                        ?.map((item: any, index: number) => ({
                            image_url: item?.image_url,
                            display_order: index,
                        }));
                }
                state.default_media_url = default_media_url;
            }
            if (default_media_type) state.default_media_type = default_media_type;
        },
        changeImage: (state, action) => {
            state.listImg = [...action.payload];
        },
        changeDefaultImage: (state, action) => {
            state.defaultListImage = [...action.payload];
        },
        changeImageType: (state, action) => {
            state.listTypeImg = [...action.payload];
            state.defaultListTypeImg = [...action.payload];
        },
        validationFormAction: (state, action) => {
            if (state.code) {
                if (ValidationPattern.codePro.test(state.code as string)) {
                    state.validation.code = true;
                } else {
                    state.validation.code = false;
                    openNotification("ERROR", "Mã sản phẩm không hợp lệ");
                    return;
                }
            } else {
                state.validation.code = false;
                openNotification("ERROR", "Vui lòng nhập mã sản phẩm.");
                return;
            }

            if (state.proName?.trim()?.length > 0) {
                state.validation.namePro = true;

                // if (ValidationPattern.namePro.test(state.proName as string)) {

                //     if (state.proName.trim()) {
                //         state.validation.namePro = true;
                //     } else {
                //         state.validation.namePro = false;
                //     }
                // } else {
                //     console.log("2");

                //     state.validation.namePro = false;
                //     openNotification("ERROR", "Tên sản phẩm không hợp lệ.");
                //     return;
                // }
            } else {
                state.validation.namePro = false;
                openNotification("ERROR", "Vui lòng nhập tên sản phẩm.");
                return;
            }

            // if (state.description) {
            //     state.validation.description = true;
            // } else {
            //     state.validation.description = false;
            //     openNotification("ERROR", "Vui lòng nhập mô tả sản phẩm.");
            //     return;
            // }

            // if (state.cate.id) {
            //     state.validation.cate = true;
            // } else {
            //     state.validation.cate = false;
            //     openNotification("ERROR", "Vui lòng chọn danh mục sản phẩm.");
            //     return;
            // }

            // ---- UPDATE -----
            if (state.weight >= 0) {
                state.validation.weight = true;
            } else {
                state.validation.weight = false;
                openNotification("ERROR", "Vui lòng nhập khối lượng!");
                return;
            }
            if (state.stock >= 0) {
                state.validation.stock = true;
            } else {
                state.validation.stock = false;
                openNotification("ERROR", "Vui lòng nhập số lượng tồn kho!");
                return;
            }

            // for (let i = 0; i < state.dataSource.length; i++) {
            //     if (!state.dataSource[i].col3.value) {
            //         state.validation.price = false;
            //         openNotification("ERROR", "Vui lòng nhập giá sản phẩm.");
            //         break;
            //     }
            // }

            // if (state.price || state.dataSource.length > 0) {
            //     state.validation.price = true;
            // } else {
            //     state.validation.price = false;
            //     openNotification("ERROR", "Vui lòng nhập giá sản phẩm.");
            //     return;
            // }

            // -----------

            if (state.listImg.length === 0) {
                state.validation.imagePro = false;
                openNotification("ERROR", "Vui lòng chọn ảnh sản phẩm.");
                return;
            } else {
                state.validation.imagePro = true;
            }

            if (!state.columns[0].title || !state.columns[1].title) {
                state.validation.nameGroupClassify = false;
                openNotification("ERROR", "Tên nhóm phân loại không được bỏ trống.");
                return;
            } else {
                state.validation.nameGroupClassify = true;
            }
            if (state.typePro.length === 2) {
                if (state.columns[0].title === state.columns[1].title) {
                    state.validation.nameGroupClassify = false;
                    openNotification("ERROR", "Tên nhóm phân loại không được trùng nhau.");
                    return;
                } else {
                    state.validation.nameGroupClassify = true;
                }
            }

            if (state.typePro.length > 0) {
                state.typePro[0].typeItem.forEach((value: any) => {
                    if (!value?.value) {
                        state.validation.nameClassify = false;
                        openNotification("ERROR", "Tên phân loại không được bỏ trống.");
                        return;
                    } else {
                        state.validation.nameClassify = true;
                    }
                });
            }
            if (state.typePro.length === 2) {
                state.typePro[1].typeItem.forEach((value: any) => {
                    if (!value?.value) {
                        state.validation.nameClassify = false;
                        openNotification("ERROR", "Tên phân loại không được bỏ trống.");
                        return;
                    } else {
                        state.validation.nameClassify = true;
                    }
                });
            }

            if (state.typePro.length > 0) {
                for (let i = 0; i < state.typePro[0].typeItem.length; i++) {
                    for (let j = i + 1; j < state.typePro[0].typeItem.length; j++) {
                        if (state.typePro[0].typeItem[i].value === state.typePro[0].typeItem[j].value) {
                            state.validation.nameClassify = false;
                            openNotification("ERROR", "Tên phân loại không được trùng nhau.");
                            break;
                        } else {
                            state.validation.nameClassify = true;
                        }
                    }
                }
            }

            if (state.typePro.length === 2) {
                for (let i = 0; i < state.typePro[1].typeItem.length; i++) {
                    for (let j = i + 1; j < state.typePro[1].typeItem.length; j++) {
                        if (state.typePro[1].typeItem[i].value === state.typePro[1].typeItem[j].value) {
                            openNotification("ERROR", "Tên phân loại không được trùng nhau.");
                            state.validation.nameClassify = false;
                            break;
                        } else {
                            state.validation.nameClassify = true;
                        }
                    }
                }
            }

            if (state.typePro.length === 2) {
                for (let i = 0; i < state.typePro[0].typeItem.length; i++) {
                    for (let j = 0; j < state.typePro[1].typeItem.length; j++) {
                        if (state.typePro[0].typeItem[i].value === state.typePro[1].typeItem[j].value) {
                            state.validation.nameClassify = false;
                            openNotification("ERROR", "Tên phân loại không được trùng nhau.");
                            break;
                        } else {
                            state.validation.nameClassify = true;
                        }
                    }
                }
            }
        },
        resetForm: (state) => {
            state.code = "";
            state.sold_count = 0;
            state.description = "";
            state.cate = { id: 0, name: "" };
            state.cateChild = { id: 0, name: "" };
            state.proName = "";
            state.price = 0;
            state.status = 1;
            state.statusStock = 0;
            state.statusProduct = 0;
            state.weight = 0;
            state.stock = 0;
            state.wholesale_price = 0;
            state.allow_order_when_out_stock = 0;
            state.allow_sale = 1;
            state.wholesale_quantity = 0;

            state.listImg = [];
            state.defaultListImage = [];
            state.video = "";
            state.defaultVideo = [];
            state.listTypeImg = [];
            state.defaultSelectClassifyImg = { id: 0, value: "", index: 0 };

            state.columns = defaultColumns;
            state.dataSource = [];
            // state.typeProdCache = { key: 0, typeItem: [{ key: uuidv4(), value: "", url: "", fileName: "" }] };
            state.typePro = [];
            state.listClassifyName = [];

            state.validation = {
                cate: true,
                code: true,
                description: true,
                imagePro: true,
                namePro: true,
                price: true,
                nameClassify: true,
                nameGroupClassify: true,
            };
        },

        // Thêm nhóm phân loại
        createTypeProAction: (state, action) => {
            // Thêm nhóm phân loại đầu tiên
            if (state.typePro.length === 0) {
                // state.typeProdCache = {
                //     key: uuidv4(),
                //     typeItem: [{ key: uuidv4(), value: null, fileName: "", url: "" }],
                // };

                state.typePro.push({
                    key: uuidv4(),
                    typeItem: [{ key: uuidv4(), value: null, fileName: "", url: "" }],
                });

                state.columns.unshift({
                    title: "",
                    key: "col1",
                    dataIndex: "col1",
                    render: (value: any, row: any, index: any) => value,
                });
            } else {
                // state.typePro.push(state.typeProdCache as ITypePro);
                state.typePro.push({
                    key: uuidv4(),
                    typeItem: [{ key: uuidv4(), value: null, fileName: "", url: "" }],
                });

                state.columns.splice(1, 0, {
                    title: "",
                    key: "col2",
                    dataIndex: "col2",
                    render: (value: any) => value,
                });
            }
        },
        genDataTableAction: (state) => {
            state.dataSource = [];

            if (state.typePro.length === 1) {
                console.log("state.typePro[0].typeItem", current(state.typePro[0].typeItem));
                state.typePro[0].typeItem.forEach((item: any, index: any) => {
                    let id = item.key;
                    state.dataSource.push({
                        key: id,
                        col1: item?.value,
                        col3: { value: 0, id },
                        col4: { value: 1, id },
                        stock: { id },
                        weight: { id },
                        wholesale_price: { id },
                        sold_count: { id },
                        quantity_wholesale_price: { id },
                        allow_order_when_out_stock: { value: 0, id },
                        index: [index],
                    });
                });

                state.dataSource = state.dataSource.map((item: any) => {
                    const targetItem = state.defaultDataSource?.find((defaultItem: any) => {
                        return defaultItem.key === item?.key;
                    });
                    if (targetItem) {
                        return {
                            ...item,
                            col3: { ...item?.col3, value: targetItem.col3.value },
                            col4: { ...item?.col4, value: targetItem.col4.value },
                            weight: { ...item?.weight, value: targetItem.weight.value },
                            stock: { ...item?.stock, value: targetItem.stock.value },
                            sold_count: { ...item?.sold_count, value: targetItem.sold_count.value },
                            wholesale_price: { ...item?.wholesale_price, value: targetItem.wholesale_price.value },
                            quantity_wholesale_price: {
                                ...item?.quantity_wholesale_price,
                                value: targetItem.quantity_wholesale_price.value,
                            },
                            allow_order_when_out_stock: {
                                ...item?.allow_order_when_out_stock,
                                value: targetItem.allow_order_when_out_stock.value,
                            },
                        };
                    } else {
                        return item;
                    }
                });

                state.defaultDataSource = state.dataSource;
            } else if (state.typePro.length === 2) {
                const lengthCol1 = state.typePro[0].typeItem.length; // Số lượng attr ở nhớm 1
                const lengthCol2 = state.typePro[1].typeItem.length; // Số lượng attr ở nhóm 2
                for (let i = 0; i < lengthCol1; i++) {
                    for (let j = 0; j < lengthCol2; j++) {
                        let id =
                            state.typePro[0].typeItem[i].key.toString() + state.typePro[1].typeItem[j].key.toString();
                        state.dataSource.push({
                            key: id,
                            col1: state.typePro[0].typeItem[i].value, // Phân loại 1
                            col2: state.typePro[1].typeItem[j].value, // Phân loại 2
                            stock: { id },
                            weight: { id },
                            sold_count: { id },
                            wholesale_price: { id },
                            quantity_wholesale_price: { id },
                            allow_order_when_out_stock: { value: 0, id },
                            col3: { value: 0, id: id },
                            col4: { value: 1, id: id },
                            index: [i, j],
                        });
                    }
                }

                let lengthDefaultDataSource: number = state.defaultDataSource.length;

                if (lengthDefaultDataSource > 0) {
                    state.dataSource = state.dataSource?.map((item: any) => {
                        const targetItem = state.defaultDataSource?.find(
                            (defaultItem: any) => defaultItem.key === item?.key
                        );
                        if (targetItem) {
                            return {
                                ...item,
                                id: targetItem?.product_price_id,
                                col3: { ...item?.col3, value: targetItem.col3.value },
                                col4: { ...item?.col4, value: targetItem.col4.value },
                                weight: { ...item?.weight, value: targetItem.weight.value },
                                stock: { ...item?.stock, value: targetItem.stock.value },
                                sold_count: { ...item?.sold_count, value: targetItem.sold_count.value },
                                wholesale_price: { ...item?.wholesale_price, value: targetItem.wholesale_price.value },
                                quantity_wholesale_price: {
                                    ...item?.quantity_wholesale_price,
                                    value: targetItem.quantity_wholesale_price.value,
                                },
                                allow_order_when_out_stock: {
                                    ...item?.allow_order_when_out_stock,
                                    value: targetItem.allow_order_when_out_stock.value,
                                },
                            };
                        } else {
                            return item;
                        }
                    });
                }

                state.defaultDataSource = state.dataSource;
            }
        },

        genIndexTableAction: (state) => {
            let newDataSource: any[] = [];
            state.dataSource.forEach((value: any) => newDataSource.push({ ...value }));

            if (state.typePro.length === 1) {
                state.typePro[0].typeItem.forEach((value: any, index: any) => {
                    newDataSource[index] = { ...newDataSource[index], index: [index] };
                });
            } else if (state.typePro.length === 2) {
                const lengthCol1 = state.typePro[0].typeItem.length;
                const lengthCol2 = state.typePro[1].typeItem.length;
                let index: number = 0;
                for (let i = 0; i < lengthCol1; i++) {
                    for (let j = 0; j < lengthCol2; j++) {
                        newDataSource[index] = { ...newDataSource[index], index: [i, j] };
                        index = index + 1;
                    }
                }
            }
            state.dataSource = newDataSource;
            state.defaultDataSource = newDataSource;
            state.constDefaultDataSource = newDataSource;
        },
        destroyTypeProAction: (state, action) => {
            const { key } = action.payload;
            let index = state.typePro.findIndex((value: any) => value.key === key);
            if (index === 0) {
                if (state.typePro.length === 2) {
                    state.columns[1].key = "col1";
                    state.columns[1].dataIndex = "col1";
                }
                state.columns.shift();
            } else {
                state.columns.splice(1, 1);
            }
            state.typePro = state.typePro.filter((value: any) => value.key !== key);
            // state.typeProdCache!.key = uuidv4();
            state.listClassifyName.splice(index, 1);
            if (index === state.defaultSelectClassifyImg.index) {
                state.listTypeImg = [];
            }
            state.defaultSelectClassifyImg.value = "";
        },
        // Xoá thuộc tính concreateTypeProAction
        destroyTypeProChildAction: (state, action) => {
            let { keyTypePro, keyTypeProChild } = action.payload;
            let index = state.typePro.findIndex((value: any) => value.key === keyTypePro);

            // Xoá ảnh của thuộc tính này
            let imgDetele = state.typePro[index].typeItem.filter((value: any) => value.key === keyTypeProChild);

            if (index === state.defaultSelectClassifyImg.index) {
                let newListTypeImg = state.listTypeImg.filter(
                    (value: any) => value.image_url !== imgDetele[0].fileName
                );
                state.listTypeImg = newListTypeImg;
            }

            let newTypeProChild = state.typePro[index].typeItem.filter((value: any) => value.key !== keyTypeProChild);
            state.typePro[index].typeItem = newTypeProChild;
        },
        // Thêm thuộc tính con
        createTypeProChildAction: (state, action) => {
            let { keyTypePro, value } = action.payload;
            let index = state.typePro.findIndex((value: any) => value.key === keyTypePro);
            state.typePro[index].typeItem.push({
                key: uuidv4(),
                value: value,
                url: "",
                fileName: "",
            });
        },
        changeValueTypeProChildAction: (state, action) => {
            let { keyTypePro, keyTypeProChild, value, isTitle } = action.payload;
            let index = state.typePro.findIndex((value: any) => value.key === keyTypePro);
            if (isTitle) {
                state.columns[index].title = value;

                // state.listClassifyName
            } else {
                state.typePro[index].typeItem.forEach((valueItem: any) => {
                    if (valueItem.key === keyTypeProChild) {
                        valueItem.value = value;
                    }
                });
            }
        },
        RmDefaultImageClassifyUrlAction: (state, action) => {
            let { keyTypePro, keyTypeProChild, url, fileName } = action.payload;
            console.log(url, fileName);
            let index = state.typePro.findIndex((value: any) => value.key === keyTypePro);
            state.typePro[index].typeItem.forEach((valueItem: any) => {
                if (valueItem.key === keyTypeProChild) {
                    valueItem.url = url;
                    valueItem.fileName = fileName;
                }
            });
        },
        changePriceAndStatusProAction: (state, action) => {
            let { key, price, status } = action.payload;
            state.dataSource.forEach((value: any) => {
                if (value.key === key) {
                    if (price !== undefined) {
                        value.col3 = { value: price, id: value.col3.id };
                    } else if (status !== undefined) {
                        value.col4 = { value: status, id: value.col4.id };
                    }
                }
            });
            state.defaultDataSource = state.dataSource;
        },
        changeWeight: (state, action) => {
            const { key, weight } = action.payload;
            state.dataSource.forEach((value: any) => {
                if (value.key === key) {
                    value.weight = { value: weight, id: value.weight.id };
                }
            });
            state.defaultDataSource = state.dataSource;
        },
        changeStock: (state, action) => {
            const { key, stock } = action.payload;
            state.dataSource.forEach((value: any) => {
                if (value.key === key) {
                    value.stock = { value: stock, id: value.stock.id };
                }
            });
            state.defaultDataSource = state.dataSource;
        },
        changeWholesalePrice: (state, action) => {
            const { key, price } = action.payload;
            state.dataSource.forEach((value: any) => {
                if (value.key === key) {
                    value.wholesale_price = { value: price, id: value.wholesale_price.id };
                }
            });
            state.defaultDataSource = state.dataSource;
        },
        changeWholesaleQuantity: (state, action) => {
            const { key, quantity } = action.payload;
            state.dataSource.forEach((value: any) => {
                if (value.key === key) {
                    value.quantity_wholesale_price = { value: quantity, id: value.quantity_wholesale_price.id };
                }
            });
            state.defaultDataSource = state.dataSource;
        },
        changeSoldCountApp: (state, action) => {
            const { key, quantity } = action.payload;
            state.dataSource.forEach((value: any) => {
                if (value.key === key) {
                    value.sold_count = { value: quantity, id: value.sold_count.id };
                }
            });
            state.defaultDataSource = state.dataSource;
        },
        changeBuyNegative: (state, action) => {
            const { key, allow_order_when_out_stock } = action.payload;
            state.dataSource.forEach((value: any) => {
                if (value.key === key) {
                    value.allow_order_when_out_stock = {
                        value: allow_order_when_out_stock,
                        id: value.allow_order_when_out_stock.id,
                    };
                }
            });
            state.defaultDataSource = state.dataSource;
        },
        setAllPriceAction: (state, action) => {
            state.dataSource.forEach((value: any) => {
                value.col3 = {
                    value: action.payload.price,
                    id: value.col3.id,
                };
                value.stock = {
                    value: action.payload.stock,
                    id: value.stock.id,
                };
                value.wholesale_price = {
                    value: action.payload.wholesale_price,
                    id: value.wholesale_price.id,
                };
                value.quantity_wholesale_price = {
                    value: action.payload.quantity_wholesale_price,
                    id: value.quantity_wholesale_price.id,
                };
                value.weight = {
                    value: action.payload.weight,
                    id: value.weight.id,
                };
                value.sold_count = {
                    value: action.payload.sold_count,
                    id: value.sold_count.id,
                };
                value.allow_order_when_out_stock = {
                    value: action.payload.allow_order_when_out_stock,
                    id: value.allow_order_when_out_stock.id,
                };
            });
            state.defaultDataSource = state.dataSource;
        },
        setAllWholesaleStat: (state, action: any) => {
            state.dataSource.forEach((value: any) => {
                value.wholesale_price = {
                    value: action.payload.price,
                    // id: value.wholesale_price.id,
                };
                value.quantity_wholesale_price = {
                    value: action.payload.quantity,
                    // id: value.quantity_wholesale_price.id,
                };
            });
            state.defaultDataSource = state.dataSource;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postProductAction.pending, (state) => {
                state.loadingPostPro = true;
            })
            .addCase(postProductAction.rejected, (state, action) => {
                state.loadingPostPro = false;
                if (action.error.message !== "0") {
                    openNotification("ERROR", action.error.message as string);
                }
            })
            .addCase(postProductAction.fulfilled, (state, action) => {
                state.loadingPostPro = false;
                // openNotification("SUCCESS", action.payload as string);
            })

            .addCase(getDetailProductAction.pending, (state) => {
                state.loadingGetDetailProduct = true;
            })
            .addCase(getDetailProductAction.rejected, (state, action) => {
                state.loadingGetDetailProduct = false;
                if (action.error.message !== "") {
                    openNotification("ERROR", action.payload as string);
                }
            })
            .addCase(getDetailProductAction.fulfilled, (state, action: any) => {
                const {
                    code,
                    description,
                    statusStock,
                    status,
                    productCustomAttributes,
                    productMedia,
                    productPrices,
                    name,
                    categoryName,
                    categoryParentName,
                    categoryId,
                    categoryParentId,
                    typeTag,
                } = action.payload.data;

                const firstItem = action.payload.data?.productPrices?.[0];

                state.loadingGetDetailProduct = false;
                state.weight = firstItem?.weight;
                state.stock = firstItem?.stock;
                state.wholesale_price = firstItem?.wholesalePrice === 0 ? undefined : firstItem?.wholesalePrice;
                state.quantity_wholesale_price =
                    firstItem?.wholesaleQuantity === 0 ? undefined : firstItem?.wholesaleQuantity;
                state.allow_sale = firstItem?.allowSale;
                state.allow_order_when_out_stock = firstItem?.allowOrderWhenOutStock;
                state.code = code;
                state.description = description;
                state.cate = categoryParentId
                    ? { id: categoryParentId, name: categoryParentName }
                    : { id: categoryId, name: categoryName };
                state.cateChild = categoryParentId
                    ? { id: categoryId, name: categoryName }
                    : { id: undefined, name: "" };

                state.proName = name;
                state.price = productPrices.length > 0 ? productPrices[0].price : 0;
                state.statusStock = statusStock;
                state.status = status;
                state.sold_count = firstItem?.soldCount;
                state.statusProduct = typeTag || 0;

                state.dataSource = getDataSourcePrice(productPrices);
                state.defaultDataSource = getDataSourcePrice(productPrices);

                let columnsInsert: IColumn[] = getColumnPrice(action.payload.data.productCustomAttributes);
                state.columns = columnsInsert.concat(defaultColumns);

                productCustomAttributes.forEach((value: any) => {
                    let list: any = [];

                    // Có 1 hoặc không thuộc tính
                    if (productCustomAttributes?.length <= 1) {
                        value.productCustomAttributeOptions.forEach((data: any) => {
                            const targetItem = productPrices?.find(
                                (item: any) =>
                                    item?.customAttributeOptionId1 === data?.id ||
                                    item?.customAttributeOptionId2 === data?.id
                            );
                            list.push({
                                key: targetItem?.id,
                                value: data?.name,
                                url: "",
                                fileName: "",
                            });
                        });
                        state.typePro.push({
                            key: value.id,
                            typeItem: list,
                        });
                    } else {
                        value.productCustomAttributeOptions.forEach((data: any) => {
                            list.push({
                                key: data.id,
                                value: data.name,
                                url: "",
                                fileName: "",
                            });
                        });
                        state.typePro.push({
                            key: value.id,
                            typeItem: list,
                        });
                    }

                    state.listClassifyName.push({ key: value.id, value: value.name });
                    if (value.displayImage === 1) {
                        if (state.columns[0].title === value.name) {
                            state.defaultSelectClassifyImg = { id: value.id, value: value.name, index: 0 };
                        } else {
                            state.defaultSelectClassifyImg = { id: value.id, value: value.name, index: 1 };
                        }
                    }
                });

                // productCustomAttributes.forEach((value: any) => {
                //     let list: { key: any; value: any; url: string; fileName: string }[] = [];
                //     value.productCustomAttributeOptions.forEach((data: any) =>
                //         list.push({
                //             key: data.id,
                //             value: data.name,
                //             url: "",
                //             fileName: "",
                //         })
                //     );
                //     state.typePro.push({
                //         key: value.id,
                //         typeItem: list,
                //     });
                //     state.listClassifyName.push({ key: value.id, value: value.name });
                //     if (value.displayImage === 1) {
                //         if (state.columns[0].title === value.name) {
                //             state.defaultSelectClassifyImg = { id: value.id, value: value.name, index: 0 };
                //         } else {
                //             state.defaultSelectClassifyImg = { id: value.id, value: value.name, index: 1 };
                //         }
                //     }
                // });

                let defaultImg: any[] = [];
                productMedia.forEach((value: any) => {
                    if (value.type === 1 && value.productCustomAttributeOptionId === null && value.isDefault !== 1) {
                        defaultImg.push({
                            image_url: value.url,
                            fileName: value.fileName,
                            type: value.type,
                            is_default: 0,
                        });
                    }
                });
                state.defaultListImage = defaultImg;

                const defaultMedia = productMedia?.find((item: any) => item?.isDefault === 1);
                state.default_media_url = defaultMedia?.url;
                state.default_media_type = defaultMedia?.type;

                let index = state.typePro.findIndex((value: any) => value.key === state.defaultSelectClassifyImg.id);
                if (index !== -1) {
                    state.typePro[index].typeItem.forEach((value: any, i: any) => {
                        productMedia.forEach((value1: any) => {
                            if (value?.value === value1.customAttributeOptionName) {
                                state.typePro[index].typeItem[i].url = value1.url;
                                state.typePro[index].typeItem[i].fileName = value1.fileName;
                            }
                        });
                    });
                }
                // state.typeProdCache = {
                //     key: uuidv4(),
                //     typeItem: [{ key: uuidv4(), value: "", url: "", fileName: "" }],
                // };

                let listImg: { image_url: string; display_order: number }[] = [];
                let listTypeImg: { image_url: string; index: number }[] = [];

                state.listImg = listImg;
                state.listTypeImg = listTypeImg;
                state.defaultListTypeImg = listTypeImg;

                let indexTypeImg: number = 0;
                productMedia.forEach((value: any, i: any) => {
                    if (value.type === 1 && value.productCustomAttributeOptionId === null && value.isDefault !== 1) {
                        listImg.push({ image_url: value.fileName, display_order: value.displayOrder });
                    }
                    if (value.type === 1 && value.productCustomAttributeOptionId !== null) {
                        if (index === 0) {
                            listTypeImg.push({ image_url: value.fileName, index: value.index });
                        }
                        if (index === 1) {
                            listTypeImg.push({ image_url: value.fileName, index: value.index });
                        }
                        indexTypeImg = indexTypeImg + 1;
                    }
                    if (value.type === 2) {
                        state.video = value.fileName;
                    }
                });

                state.defaultVideo = productMedia
                    ?.filter((item: any) => item?.type === 2 && item?.isDefault !== 1)
                    ?.map((item: any) => ({ name: item.fileName, status: "done", uid: 1, url: item.url }));
            })

            .addCase(putProductAction.pending, (state) => {
                state.loadingUpdatePro = true;
            })
            .addCase(putProductAction.rejected, (state, action) => {
                state.loadingUpdatePro = false;
                if ((action.error.message as string) !== "0") {
                    openNotification("ERROR", action.error.message as string);
                }
            })
            .addCase(putProductAction.fulfilled, (state, action) => {
                state.loadingUpdatePro = false;
                // openNotification("SUCCESS", action.payload as string);
            });
    },
});

export const {
    changeDataInfoPublicProAction,
    createTypeProAction,
    destroyTypeProAction,
    createTypeProChildAction,
    genDataTableAction,
    destroyTypeProChildAction,
    changeValueTypeProChildAction,
    changePriceAndStatusProAction,
    setAllPriceAction,
    validationFormAction,
    changeImage,
    changeImageType,
    resetForm,
    changeDefaultImage,
    RmDefaultImageClassifyUrlAction,
    genIndexTableAction,
    setAllWholesaleStat,
    changeWeight,
    changeStock,
    changeWholesalePrice,
    changeWholesaleQuantity,
    changeBuyNegative,
    changeSoldCountApp,
} = addAndUpdateProductSlice.actions;

export default addAndUpdateProductSlice.reducer;
