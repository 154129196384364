import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createAccAction, getListAccAction, updateAccAction } from "../../../app/slices/account/Account";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { Config, ValidationMes, ValidationPattern } from "../../../commons/validation/ValidationPattern";
import { IDataTableAccount } from "../interfaces";
import UploadListFileComponent from "../../product/page/add-update/component/UploadListFileComponent";
import AppServices from "../../../service/AppServices";

interface IFormAddAcc {
  eventCancel: () => any
}

interface IFormUpdate extends IFormAddAcc {
  account: IDataTableAccount | null;
}

export const FormAddAcc: React.FC<IFormAddAcc> = ({ eventCancel }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.account.loadingPostAccount);
  const [url, setUrl] = useState<string>("");

  const onFinish = async (value: any) => {
    const { name, phoneNumber, email, confirmPass, role } = value.user;
    if (value.user.pass === value.user.confirmPass && !loading) {
      if (!ValidationPattern.email.test(email)) {
        let rs = await dispatch(
          createAccAction({
            name: name,
            profilePictureUrl: url,
            phone: phoneNumber,
            email: email ? email : null,
            role: role ? role : 2,
            password: confirmPass,
          }),
        );
        if (rs.type === "account/createAccAction/fulfilled") {
          eventCancel();
          AppServices.history!.push("/home/account?page=1&phone=ALL&status=2");
          dispatch(getListAccAction({ page: 1, status: 2, search: "", role: 0 }));
        }
      } else {
        openNotification("ERROR", "Định dạng email chưa hợp lệ.");
      }
    } else {
      openNotification("ERROR", "Mật khẩu xác nhận không khớp.");
    }
  };

  return (
    <Form {...Config.layoutForm} onFinish={onFinish}>

      <Form.Item
        name={["user", "name"]}
        label={"Họ tên"}
        rules={[
          { pattern: ValidationPattern.username, message: ValidationMes.username },
          { required: true, message: "Vui lòng nhập họ tên" },
        ]}
      >
        <Input prefix={<i className="far fa-user-alt" />} />
      </Form.Item>

      <Form.Item
        name={["user", "phoneNumber"]}
        label={"Số điện thoại"}
        rules={[
          { pattern: ValidationPattern.phone, message: ValidationMes.phone },
          { required: true, message: "Vui lòng nhập số điện thoại." },
        ]}
      >
        <Input prefix={<i className="far fa-phone-alt" />} />
      </Form.Item>

      <Form.Item
        name={["user", "email"]}
        label={"Email"}
        rules={[
          { type: "email", message: ValidationMes.email },
          // { pattern: ValidationPattern.gmail, message: ValidationMes.email },
        ]}
      >
        <Input prefix={<i className="fal fa-envelope" />} />
      </Form.Item>

      <Form.Item
        name={["user", "role"]}
        label={"Loại tài khoản"}
      >
        <Select defaultValue={"2"}
        >
          <Select.Option value={"2"}>Quản trị viên</Select.Option>
          <Select.Option value={"4"}>Nhân viên</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name={["user", "pass"]}
        label={"Mật Khẩu"}
        rules={[
          { pattern: ValidationPattern.pass, message: ValidationMes.pass },
          { required: true, message: "Vui lòng nhập mật khẩu" },
        ]}
      >
        <Input.Password prefix={<i className="far fa-unlock-alt" />} />
      </Form.Item>

      <Form.Item
        name={["user", "confirmPass"]}
        label={"Xác nhận mật khẩu"}
        rules={[
          { pattern: ValidationPattern.pass, message: ValidationMes.pass },
          { required: true, message: "Vui lòng nhập xác nhận mật khẩu" },
        ]}
      >
        <Input.Password prefix={<i className="fal fa-unlock" />} />
      </Form.Item>

      <Form.Item name={["user", "avatar"]} label={"Ảnh đại diện"}>
        <UploadListFileComponent type={"picture-card"} limit={1} name={"image"}
                                 logData={data => {
                                   if (data[0] && data[0].status === "done") {
                                     setUrl(data[0].response.data.filename);
                                   } else if (data.length === 0) {
                                     setUrl("");
                                   }
                                 }}
                                 path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                 size={2}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ ...Config.layoutForm.wrapperCol, offset: 4 }}>
        <Row gutter={16}>
          <Col lg={12}>
            <Button className={"btn--red"} onClick={() => eventCancel()}>
              Hủy
            </Button>
          </Col>
          <Col lg={12}>
            <Button htmlType="submit" loading={loading}
                    className={"btn--green"}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export const FormUpdateAcc: React.FC<IFormUpdate> = ({ eventCancel, account }) => {

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [url, setUrl] = useState<string>(account!.avatarName as string);

  const onFinish = async (data: any) => {
    if (!ValidationPattern.email.test(data.email)) {
      let rs = await dispatch(
        updateAccAction({
          id: account!.key,
          status: data.status,
          name: data.name,
          profilePictureUrl: url,
          email: data.email ? data.email : null,
          phone: account!.phone,
          role: parseInt(data.role) ? parseInt(data.role) : 4,
        }),
      );
      if (rs.type === "account/updateAccAction/fulfilled") {
        eventCancel();
        dispatch(getListAccAction({ page: 1, status: 2, search: "", role: 0 }));
      }
    } else {
      openNotification("ERROR", "Định dạng email chưa hợp lệ.");
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: account!.name,
      email: account!.email,
      status: account!.status,
      role: account!.role === 4 ? "Nhân viên" : "Quản trị viên",
    });
  }, []);

  return (
    <Form {...Config.layoutForm} form={form} onFinish={onFinish}>

      <Form.Item label={"Số điện thoại"} style={{ marginBottom: 12 }}>{account!.phone}</Form.Item>

      <Form.Item
        label={"Họ tên"}
        name={"name"}
        rules={[
          { required: true, message: "Vui lòng nhập họ tên." },
          { pattern: ValidationPattern.username, message: ValidationMes.username },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={"Email"}
        name={"email"}
        rules={[
          { type: "email", message: ValidationMes.email },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["role"]}
        label={"Loại tài khoản"}
      >
        <Select>
          <Select.Option value={"2"}>Quản trị viên</Select.Option>
          <Select.Option value={"4"}>Nhân viên</Select.Option>
        </Select>
      </Form.Item>


      <Form.Item name={"status"} label={"Trạng thái"}>
        <Select defaultValue={account!.status}>
          <Select.Option value={1}>Đang hoạt động</Select.Option>
          <Select.Option value={0}>Ngưng hoạt động.</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name={["user", "avatar"]} label={"Ảnh đại diên"}>
        <UploadListFileComponent type={"picture-card"} limit={1} name={"image"}
                                 logData={data => {
                                   if (data[0] && data[0].status === "done") {
                                     setUrl(data[0].response.data.filename);
                                   } else if (data.length === 0) {
                                     setUrl("");
                                   }
                                 }}
                                 path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                 size={2}
                                 defaultData={url ? [{
                                   uid: 0,
                                   name: account!.avatarName as string,
                                   url: account!.avatar as string,
                                   status: "done",
                                 }] : []}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ ...Config.layoutForm.wrapperCol, offset: 4 }}>
        <Row gutter={16}>
          <Col lg={12}>
            <Button className={"btn--red"} onClick={() => eventCancel()}>
              Hủy
            </Button>
          </Col>
          <Col lg={12}>
            <Button
              htmlType={"submit"}
              loading={useAppSelector((state) => state.account.loadingUpdateAcc)}
              className={"btn--green"}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
