import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Form, Image, Input, Modal, Pagination, Row } from "antd";
import TitleComponent from "../../../commons/components/title-component/TitleComponent";
import "./DetailLivestream.scss";
import { IResLivestream } from "../../../api/dashboard/Interface";
import {
    deleteLivestreamAPI,
    getDetailLivestreamAPI,
    getListCommentAPI,
    putChangeLinkYoutubeAPI,
    putEndLiveAPI,
} from "../../../api/livestream/Livestream";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Config, Navigation } from "../../../commons/validation/ValidationPattern";
import { numberWithCommas } from "../../../service/Format";
import { IResComment, IResProductLivestream } from "../../../api/livestream/Interfaces";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import ExportCsv from "../../../commons/components/export-excel/ExportCSV";
import LoadingComponent from "../../../commons/components/loading/LoadingComponent";
import { EmptyComponent } from "../../../commons/components/AppComponent";
import { Moment } from "../../../service/Moment";
import AppServices from "../../../service/AppServices";

interface IFormChangeLinkYoutube {
    broadcastId: string;
}

const DetailLivestream: React.FC<any> = () => {
    const [state, setState] = useState<IResLivestream | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [listComment, setListComment] = useState<IResComment[]>([]);
    const [listCommentExport, setListCommentExport] = useState<IResComment[]>([]);
    const [loadingComment, setLoadingComment] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [loadingEndLive, setLoadingEndLive] = useState<boolean>(false);
    const [loadingExport, setLoadingExport] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const params: any = useParams();
    const limit: number = 10;
    const [total, setTotal] = useState<number>(0);
    const [listProduct, setListProduct] = useState<IResProductLivestream[]>([]);
    const [visibleModalChangeLinkYoutube, setVisibleModalChangeLinkYoutube] = useState<boolean>(false);
    const [loadingChangeLink, setLoadingChangeLink] = useState<boolean>(false);

    const getListComment = async (id: number, search: string, page: number) => {
        setLoadingComment(true);
        const rs = await getListCommentAPI(page, id, search.trim(), limit);
        if (rs.body.status === 1) {
            setListComment(rs.body.data);
            setTotal(rs.body.paging.totalItemCount);
        }
        setLoadingComment(false);
    };

    const getData = async (id: number) => {
        setLoading(true);
        const rs = await getDetailLivestreamAPI(id);
        if (rs.body.status === 1) {
            setState(rs.body.data.foundLivestream);
            setListProduct(rs.body.data.listProductLivestream);
        }
        setLoading(false);
    };

    const deleteStream = async (id: number) => {
        setLoadingDelete(true);
        const rs = await deleteLivestreamAPI(id);
        if (rs.body.status === 1) {
            AppServices.history!.push(Navigation().listLivestream);
            openNotification("SUCCESS", "Xóa livestream thành công.");
        } else {
            openNotification("ERROR", rs.body.message);
        }
        setLoadingDelete(false);
    };

    const endLive = async (id: number, broadcastId: string) => {
        try {
            setLoadingEndLive(true);
            const res = await putEndLiveAPI(id, broadcastId);
            if (res.body.status === 1) {
                openNotification("SUCCESS", "Kết thúc livestream thành công.");
                getData(params.id);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingEndLive(false);
        }
    };

    const onChangeLinkYoutube = async (valueForm: IFormChangeLinkYoutube) => {
        try {
            setLoadingChangeLink(true);
            const res = await putChangeLinkYoutubeAPI(state!.id, { broadcast_id: valueForm.broadcastId });
            if (res.body.status === 1) {
                getData(params.id);
                openNotification("SUCCESS", "Thay đổi link Youtube thành công");
                setVisibleModalChangeLinkYoutube(false);
            }
        } catch (e) {
            console.error(e);
            openNotification("ERROR", "Đã có lỗi xảy ra vui lòng thử lại");
        } finally {
            setLoadingChangeLink(false);
        }
    };

    const getDataExport = (
        data: IResComment[]
    ): { "Họ tên": string; "Số điện thoại": string; "Bình luận": string; "Thời gian": string }[] => {
        let rs: { "Họ tên": string; "Số điện thoại": string; "Bình luận": string; "Thời gian": string }[] = [];
        data.forEach((value) =>
            rs.push({
                "Họ tên": value.customerName,
                "Số điện thoại": value.customerPhone,
                "Bình luận": value.content,
                "Thời gian": moment(value.createAt).format(Config.formatTime),
            })
        );
        return rs;
    };

    const getDataProductExport = (
        data: IResProductLivestream[]
    ): { STT: number; "Tên sản phầm": string; "Giá sàn": number; "Giá trần": number }[] => {
        let rs: { STT: number; "Tên sản phầm": string; "Giá sàn": number; "Giá trần": number }[] = [];
        data.forEach((value, index) =>
            rs.push({
                STT: index + 1,
                "Tên sản phầm": value.productName,
                "Giá sàn": value.productPriceMin,
                "Giá trần": value.productPriceMax,
            })
        );
        return rs;
    };

    const onExport = async (fn: Function) => {
        setLoadingExport(true);
        let res = await getListCommentAPI(1, params.id, search.trim(), 1000000);
        if (res.body.status === 1) {
            setListCommentExport(res.body.data);
            fn();
        } else {
            openNotification("ERROR", res.body.message);
        }
        setLoadingExport(false);
    };

    useEffect(() => {
        getData(params.id);
    }, []);

    useEffect(() => {
        getListComment(params.id, search, page);
    }, [page, search]);

    if (state && !loading) {
        // @ts-ignore
        return (
            <div className={"detail-livestream"}>
                <div className={"detail-livestream__header"}>
                    <TitleComponent
                        title={"Chi tiết livestream"}
                        component={
                            <div>
                                <Button
                                    danger
                                    type={"primary"}
                                    loading={loadingDelete}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: "Bạn có muốn xóa livestream này.",
                                            onOk: () => deleteStream(params.id),
                                        });
                                    }}
                                >
                                    Xóa
                                </Button>
                                {state.isLive ? (
                                    <Button
                                        danger
                                        type={"primary"}
                                        loading={loadingEndLive}
                                        className={"ml-16"}
                                        onClick={() => {
                                            Modal.confirm({
                                                title: "Bạn có muốn kết thúc livestream này.",
                                                onOk: () => endLive(params.id, state!.broadcastId),
                                            });
                                        }}
                                    >
                                        Kết thúc livestream
                                    </Button>
                                ) : null}
                                {state.isLive ? (
                                    <Button
                                        type={"primary"}
                                        loading={loadingEndLive}
                                        className={"ml-16"}
                                        onClick={() => {
                                            setVisibleModalChangeLinkYoutube(true);
                                        }}
                                    >
                                        Sửa link Youtube
                                    </Button>
                                ) : null}
                                {state.isLive ? (
                                    <Button type={"primary"} className={"ml-16"}>
                                        <a
                                            target={"_blank"}
                                            rel={"noreferrer"}
                                            href={`https://www.youtube.com/watch?v=${state.broadcastId}`}
                                        >
                                            Xem trên Youtube
                                        </a>
                                    </Button>
                                ) : null}
                                <div style={{ display: "inline-block", marginLeft: 16 }}>
                                    <ExportCsv
                                        onClick={(fn) => onExport(fn)}
                                        fileName={`Binh-luan-livestream_${moment().format("DD-MM-YYYY")}`}
                                        sheets={{
                                            "Binh-luan": ExportCsv.getSheets(getDataExport(listCommentExport)),
                                            "Danh sach san pham livestream": ExportCsv.getSheets(
                                                getDataProductExport(listProduct)
                                            ),
                                        }}
                                        sheetName={["Binh-luan", "Danh sach san pham livestream"]}
                                        loading={loadingExport}
                                    >
                                        Export
                                    </ExportCsv>
                                </div>
                            </div>
                        }
                        isGoBack={true}
                    />
                </div>

                <div className={"detail-livestream__info style-box mt-16"}>
                    <div className={"title"}>Thông tin livestream</div>
                    <Row gutter={16}>
                        <Col md={18}>
                            <div className={"item"}>
                                <span className={"label"}>Mô tả</span>
                                <span>{state.title}</span>
                            </div>
                            <div className={"item"}>
                                <span className={"label"}>Ngày livestream</span>
                                <span>{moment(state.startTime).format(Config.formatTime)}</span>
                            </div>
                            <div className={"item"}>
                                <span className={"label"}>Kết thúc</span>
                                <span>{state.endTime && moment(state.endTime).format(Config.formatTime)}</span>
                            </div>
                            <div className={"item"}>
                                <span className={"label"}>Thời lượng</span>
                                <span>{Moment.msToHMS(state.totalTime)}</span>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={"item"}>
                                <span className={"label"}>Lượt xem</span>
                                <span style={{ color: "orange", fontWeight: "bold" }}>
                                    {numberWithCommas(state.countViewed)}
                                </span>
                            </div>
                            <div className={"item"}>
                                <span className={"label"}>Lượt thích</span>
                                <span style={{ color: "#1890ff", fontWeight: "bold" }}>
                                    {numberWithCommas(state.countLiked)}
                                </span>
                            </div>
                            <div className={"item"}>
                                <span className={"label"}>Bình luận</span>
                                <span style={{ color: "#ff253a", fontWeight: "bold" }}>
                                    {numberWithCommas(state.countComment)}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className={"detail-livestream__comment style-box mt-16"}>
                    <Row gutter={16}>
                        <Col md={6}>
                            <div className={"wrapper-img"}>
                                <Image src={state.thumbnailUrl} />
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className={"title-comment"}>Bình luận</div>
                            <div className={"filter"}>
                                <Input
                                    placeholder={"Tìm kiếm"}
                                    style={{ width: 300 }}
                                    onChange={(event) => {
                                        setSearch(event.target.value);
                                        setPage(1);
                                    }}
                                />
                            </div>
                            <div className={"list-comment"}>
                                {!loadingComment ? (
                                    <div>
                                        {listComment.length > 0 ? (
                                            listComment.map((value) => (
                                                <div className={"comment mt-16"} key={value.id}>
                                                    {value.profilePictureUrl ? (
                                                        <Avatar size={40} src={value.profilePictureUrl} />
                                                    ) : (
                                                        <Avatar size={40}>
                                                            {value.customerName && value.customerName[0].toUpperCase()}
                                                        </Avatar>
                                                    )}
                                                    <div className={"text"}>
                                                        <div>
                                                            {value.customerName} _ {value.customerPhone}
                                                        </div>
                                                        <div>{value.content}</div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className={"flex-center mt-16"} style={{ width: 600 }}>
                                                <EmptyComponent />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={"flex-center"} style={{ height: 500 }}>
                                        <LoadingComponent />
                                    </div>
                                )}
                            </div>
                            <div className={"flex-end mt-16"}>
                                <Pagination
                                    current={page}
                                    pageSize={Config.limit}
                                    total={total}
                                    showSizeChanger={false}
                                    onChange={(page1) => setPage(page1)}
                                />
                            </div>
                        </Col>

                        <Col md={9}>
                            <div className={"title-comment"} style={{ marginBottom: 42 }}>
                                Danh sách sản phẩm livestream
                            </div>
                            <div className={"list-comment"}>
                                <div>
                                    {listComment.length > 0 ? (
                                        listProduct.map((value) => (
                                            <div
                                                className={"product-live color-gray"}
                                                key={value.id}
                                                style={{ display: "flex" }}
                                            >
                                                <Image src={value.productMediaUrl} style={{ height: 80, width: 80 }} />
                                                <div style={{ flexGrow: 1, marginLeft: 8 }}>
                                                    <div className={"font-bold"}>
                                                        {value.productName}
                                                        {value.productCustomCode && "-" + value.productCustomCode}
                                                    </div>
                                                    {value.productPriceMin === value.productPriceMax ? (
                                                        <div>{numberWithCommas(value.productPriceMin, "đ")}</div>
                                                    ) : (
                                                        <div>
                                                            {numberWithCommas(value.productPriceMin, "đ")} -{" "}
                                                            {numberWithCommas(value.productPriceMax, "đ")}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className={"flex-center mt-16"} style={{ width: 600 }}>
                                            <EmptyComponent />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Modal
                    visible={visibleModalChangeLinkYoutube}
                    title={"Sửa link Youtube"}
                    footer={false}
                    onCancel={() => setVisibleModalChangeLinkYoutube(false)}
                >
                    <Form validateTrigger={"onBlur"} onFinish={onChangeLinkYoutube}>
                        <Form.Item
                            name={"broadcastId"}
                            label={"Link Youtube"}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "Vui lòng nhập link Youtube",
                                },
                                { type: "string", max: 11, min: 11, message: "Link Youtube có độ dài 11 ký tự" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <div className={"flex-center"}>
                            <Form.Item>
                                <Button danger type={"primary"} onClick={() => setVisibleModalChangeLinkYoutube(false)}>
                                    Hủy
                                </Button>
                                <Button
                                    type={"primary"}
                                    htmlType={"submit"}
                                    className={"ml-16"}
                                    loading={loadingChangeLink}
                                >
                                    Xác nhận
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>
            </div>
        );
    } else {
        return <LoadingComponent />;
    }
};

export default DetailLivestream;
