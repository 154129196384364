import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getListProAPI, putStatusProductAPI } from "../../../api/product/Product";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { IPro } from "../../../api/product/Interface";
import { Config } from "../../../commons/validation/ValidationPattern";

export interface IDataSourcePro {
    key: any;
    STT: number;
    code: string | null;
    name: string | null;
    cate: { cateChild: string; cateParent: string };
    statusPro: any;
    status: any;
    statusProduct: number;
    sold: number | null;
    turnover: any;
    feature: any;
    isFlashSale: any;
}

export const getDataSourcePro = (data: any[], page?: number) => {
    let rs: any[] = [];
    let startIndex: number = page ? page * Config.limit - Config.limit : 0;
    data.forEach((value, index) => {
        rs.push({
            key: value.id,
            STT: index + 1 + startIndex,
            code: value.code,
            name: value.name,
            cate: {
                cateChild: value.categoryName,
                cateParent: value.categoryParentName,
            },
            statusPro: value.statusStock,
            status: value.status,
            statusProduct: value.typeTag,
            sold: value.soldCount,
            turnover: value.revenue,
            feature: value.id,
            quantity: value.quantity,
            isFlashSale: value.hasFlashSale,
            min_price: value.minPrice,
            max_price: value.maxPrice,
        });
    });
    return rs;
};

export interface IInitState {
    loadingGetListPro: boolean;
    loadingPutStatusProduct: boolean;
    dataSource: IDataSourcePro[];
    total: number;
    dataSourceExport: IDataSourcePro[];
    loadingExport: boolean;
}

export const getListProAction = createAsyncThunk(
    "product/getListProAction",
    async (
        params: {
            page: number;
            search: string | null;
            status: any;
            categoryId: any;
            type: number;
            price_order: number;
            sold_order: number;
        },
        thunkAPI
    ) => {
        const rs = await getListProAPI(
            params.page,
            params.search,
            params.status,
            params.categoryId,
            params.type,
            20,
            params?.price_order,
            params?.sold_order
        );
        if (rs.body.status === 1) {
            return rs.body;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

export const getDataExportAction = createAsyncThunk(
    "product/getDataExportAction",
    async (arg: { page: number; search: string | null; status: any; categoryId: any; type: number }, thunkAPI) => {
        const rs = await getListProAPI(1, arg.search, arg.status, arg.categoryId, arg.type, 1000);
        if (rs.body.status === 1) {
            return rs.body;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

const initialState: IInitState = {
    dataSource: [],
    loadingGetListPro: false,
    total: 0,
    loadingPutStatusProduct: false,
    dataSourceExport: [],
    loadingExport: false,
};

export const putStatusProductAction = createAsyncThunk(
    "product/changeStatusProductAction",
    async (arg: { id: number; status: number }, thunkAPI) => {
        const rs = await putStatusProductAPI(arg.id, arg.status);
        if (rs.body.status === 1) {
            return rs.body.message;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListProAction.pending, (state) => {
                state.loadingGetListPro = true;
            })
            .addCase(getListProAction.rejected, (state, action) => {
                state.loadingGetListPro = false;
                openNotification("ERROR", action.error.message as string);
            })
            .addCase(getListProAction.fulfilled, (state, action) => {
                state.loadingGetListPro = false;
                state.dataSource = getDataSourcePro(action.payload.data, action.meta.arg.page);
                state.total = action.payload.paging.totalItemCount;
            })

            .addCase(getDataExportAction.pending, (state) => {
                state.loadingExport = true;
            })
            .addCase(getDataExportAction.rejected, (state, action) => {
                state.loadingExport = false;
                openNotification("ERROR", "Xảy ra lỗi khi xuất file vui lòng thử lại.");
            })
            .addCase(getDataExportAction.fulfilled, (state, action) => {
                state.loadingExport = false;
                state.dataSourceExport = getDataSourcePro(action.payload.data);
            })

            .addCase(putStatusProductAction.pending, (state, action) => {
                state.loadingPutStatusProduct = true;
            })
            .addCase(putStatusProductAction.rejected, (state, action) => {
                state.loadingPutStatusProduct = false;
                openNotification("ERROR", action.error.message as string);
            })
            .addCase(putStatusProductAction.fulfilled, (state, action) => {
                state.loadingPutStatusProduct = false;
                openNotification("SUCCESS", action.payload as string);
            });
    },
});

export default productSlice.reducer;
