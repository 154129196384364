import React from "react";
import { Card, Image } from "antd";
import styled from "styled-components";
import AppTitle from "../../../commons/components/title-component/AppTitle";
import { IResLivestream } from "../../../api/dashboard/Interface";
import moment from "moment";
import { Config } from "../../../commons/validation/ValidationPattern";
import { EmptyComponent } from "../../../commons/components/AppComponent";
import { formatString, numberWithCommas } from "../../../service/Format";
import AppServices from "../../../service/AppServices";

interface ILiveStreamComponent extends IResLivestream {
}

const ItemLive = (
  id: number,
  img: string,
  name: string,
  like: number,
  view: number,
  message: number,
  time: string,
  timeLive: string,
) => {
  const item = (icon: React.ReactNode, index: string | number, color: string, font: "bold" | "normal" = "bold") => {
    return (
      <div style={{ fontSize: 13, color: `${color}`, fontWeight: "bold" }}
           key={id}
           onClick={() => AppServices.history!.push(`/home/live-stream/${id}`)}
      >
        <span style={{ marginRight: 5 }}>{icon}</span>
        <span style={{ fontWeight: font }}>{index}</span>
      </div>
    );
  };
  return (
    <StyleItem>
      <div className={"wrapper-item-live"}>
        <Card hoverable>
          <div className={"item-live"}>
            <Image src={img} width={80} />
            <div className={"item-live__info"}>
              <div className={"item-live__info__name"}>{name}</div>
              <div className={"item-live__info__index flex-between"}>
                <div>{item(<i className="fas fa-thumbs-up" />, numberWithCommas(like), "blue")}</div>
                <div className={"ml-24"}>{item(<i className="fal fa-eye" />, numberWithCommas(view), "orange")}</div>
                <div className={"ml-24"}>{item(<i className="far fa-comment-dots" />, numberWithCommas(message), "red")}</div>
              </div>
              <div className={"item-live__info__time display-flex"}>
                <div className={"mr-24"}>
                  {item(<i className="fal fa-clock" />, time, "grey", "normal")}
                </div>
                <div>{item(<i className="fal fa-clock" />, timeLive, "grey", "normal")}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </StyleItem>
  );
};

const LiveStreamComponent: React.FC<{ data: ILiveStreamComponent [] }> =
  ({ data }) => {
    return (
      <Style>
        <AppTitle title={"Live stream"} />
        {
          data.length > 0 ?
            <div className={"mt-16 list-item"}>
              {
                data.map(value =>
                  ItemLive(
                    value.id,
                    value.thumbnailUrl,
                    value.title,
                    value.countLiked,
                    value.countViewed,
                    value.countComment,
                    moment(value.startTime).format(Config.formatTime),
                    formatString(value.totalTime ? value.totalTime.toString() : "0"),
                  ),
                )
              }
            </div> : <EmptyComponent />

        }
      </Style>
    );
  };

const StyleItem = styled.div`
  margin-bottom: 8px;

  .wrapper-item-live {
    .ant-card-body {
      padding: 8px;
    }

    .ant-card-bordered {
      border: none;
      border-radius: 4px;
      border-bottom: 1px solid rgb(240, 242, 245) !important;
    }

    .item-live {
      display: flex;

      &__info {
        margin-left: 16px;

        &__name {
          font-weight: bold;
          font-size: 15px;
        }

        &__index {
          margin-top: 5px;
        }
      }
    }
  }
`;

const Style = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 4px;

  .list-item {
    height: 500px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
`;

export default LiveStreamComponent;
