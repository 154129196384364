import { Table, Tag } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ExpandRowTable from "./ExpandRowTable";
import { IColumn } from "../../../service/Interfaces";
import { IDateTableProductCate } from "../interfaces";
import { getListCateChildAction } from "../../../app/slices/product-category/ProductCategory";
import moment from "moment";
import { Config } from "../../../commons/validation/ValidationPattern";
import { EmptyComponent } from "../../../commons/components/AppComponent";

interface ITableProductCate {
  eventChangeStatus: (id: any, status: number, parentId?: any) => any;
  eventUpdate: (data: IDateTableProductCate) => any;
  eventDelete: (id: any) => any;
}

const columnsCateParents: IColumn[] = [
  {
    title: "STT",
    key: "STT",
    dataIndex: "STT",
    width: 100,
    align: "center",
    render: value => value,
  },
  {
    title: "Tên danh mục",
    key: "nameCate",
    dataIndex: "nameCate",
    align: "center",
    render: (value) => <div>{value}</div>,
  },
  {
    title: "Danh mục con",
    key: "cateChild",
    dataIndex: "cateChild",
    align: "center",
    render: (value) => <div>{value}</div>,
  },
  {
    title: "Trạng thái",
    key: "status",
    dataIndex: "status",
    align: "center",
    render: (value) => <div>{value === 0 ? <Tag color={"gray"}>Ngưng hoạt động</Tag> :
      <Tag color={"green"}>Đang hoạt động</Tag>}</div>,
  },
  {
    title: "Thứ tự hiển thị",
    key: "index",
    dataIndex: "index",
    width: 150,
    align: "center",
    render: (value) => <div>{value}</div>,
  },
  {
    title: "Ngày tạo",
    key: "date",
    dataIndex: "date",
    align: "center",
    render: (value) => <div>{moment(value).format(Config.formatDate)}</div>,
  },
];

const TableProductCate: React.FC<ITableProductCate> =
  ({
     eventChangeStatus,
     eventDelete,
     eventUpdate,
   }) => {

    const dispatch = useAppDispatch();
    const [expandRowKeys, setExpandRowKeys] = useState<any[]>([]);
    const listCate = useAppSelector((state) => state.productCategory.listCate);

    return (
      <div className={"product-category__wrapper-table-cate"}>
        <Table
          columns={columnsCateParents}
          dataSource={listCate}
          pagination={false}
          bordered={true}
          locale={{
            emptyText: <EmptyComponent/>
          }}
          loading={useAppSelector(state => state.productCategory.loadingListCate)}
          expandable={{
            expandedRowRender: (data: IDateTableProductCate) => {
              return (
                <ExpandRowTable data={data}
                                eventChangeStatus={eventChangeStatus}
                                eventUpdateCate={eventUpdate}
                                eventDeleteCate={eventDelete}
                />
              );
            },
            expandRowByClick: true,
            expandedRowKeys: expandRowKeys,
            onExpandedRowsChange: (value) => {
              setExpandRowKeys(value.filter((index) => index !== expandRowKeys[0]));
            },
            onExpand: (expanded, record) => {
              dispatch(getListCateChildAction({
                page: 1,
                status: null,
                search: null,
                parentId: record.key,
                fromDate: null,
                toDate: null,
              }));
            },
          }}
        />
      </div>
    );
  };

export default TableProductCate;
