import { IITemGetListOrderAPI } from "../../../api/product/Interface";
import { IDataSourceOrder } from "./ProductDetail";

export const getDataSourceOrder = (data: IITemGetListOrderAPI []): IDataSourceOrder [] => {
  let rs: IDataSourceOrder [] = [];
  data.forEach((value, index) => {
    rs.push({
      key: value.id,
      STT: index + 1,
      code: value.code,
      name: value.customerName,
      quantity: value.quantity,
      total: value.revenue,
    });
  });
  return rs;
};