import React, { useEffect, useState } from "react";
import { Col, Collapse, Radio, Row, Select } from "antd";
import { CollapseExpandIcon } from "../../../../../commons/components/AppComponent";
import UploadListFileComponent, { IFile } from "./UploadListFileComponent";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    changeDataInfoPublicProAction,
    changeDefaultImage,
    changeImage,
    changeImageType,
    IInitState,
    RmDefaultImageClassifyUrlAction,
} from "../../../../../app/slices/add-update-product/AddAndUpdateProduct";
import { v4 as uuidv4 } from "uuid";
import { Config } from "../../../../../commons/validation/ValidationPattern";

const getDefaultImage = (data: { image_url: string; fileName: string }[]): IFile[] => {
    let rs: IFile[] = [];
    data.forEach((value) => {
        if (value.image_url?.length > 0) {
            rs.push({
                uid: uuidv4(),
                status: "done",
                url: value.image_url,
                name: value.fileName,
            });
        }
    });
    return rs;
};

const InfoImageComponent = () => {
    const dispatch = useAppDispatch();
    const state: IInitState = useAppSelector((state) => {
        return state.addAndUpdateProduct;
    });

    const lengthListImg = state.listImg.length;
    const { typePro } = state;
    const selectOption = state.columns;
    const [valueSelect, setValueSelect] = useState<number>(state.defaultSelectClassifyImg.index);
    const [defaultSelect, setDefaultSelect] = useState<string>(state.defaultSelectClassifyImg.value);
    const isAdd: boolean = window.location.pathname === "/home/products/add-product";

    const getDataUpload = (list: any[]): { image_url: string; index: number }[] => {
        let rs: any[] = [];

        list.forEach((value, index) => {
            if (value.status === "done") {
                if (value.response !== undefined) {
                    rs.push({
                        image_url: value.response.data.filename,
                        display_order: index,
                    });
                } else {
                    rs.push({
                        image_url: value.name,
                        display_order: index,
                    });
                }
            }
        });
        return rs;
    };
    const setDefaultImage = (list: any[]): { image_url: string; fileName: string }[] => {
        let rs: any[] = [];

        list.forEach((value, index) => {
            if (value.status === "done") {
                if (value.response !== undefined) {
                    rs.push({
                        image_url: value.response.data.url,
                        display_order: index,
                    });
                } else {
                    rs.push({
                        image_url: value.url,
                        display_order: index,
                    });
                }
            }
        });
        return rs;
    };
    const onUploadImagePro = (data: any) => {
        if (lengthListImg !== getDataUpload(data).length) {
            dispatch(changeImage(getDataUpload(data)));
            dispatch(changeDefaultImage(setDefaultImage(data)));
        }
    };
    const onUploadVideoPro = (data: any) => {
        if (data.length === 0) {
            dispatch(changeDataInfoPublicProAction({ video: "", videoFile: [] }));
        } else {
            if (data[0].status === "done") {
                dispatch(
                    changeDataInfoPublicProAction({
                        video: data[0].response.data.filename,
                        videoFile: [
                            {
                                name: data[0].response.data.filename,
                                status: "done",
                                uid: uuidv4(),
                                url: data[0].response.data.url,
                            },
                        ],
                    })
                );
            }
        }
    };
    const onUploadImageTypePro = (data: any, index: number, idTypePro: number, idChildTypePro: number) => {
        let rs: { image_url: string; index: number }[] = [...state.listTypeImg];
        let i = rs.findIndex((value) => value.index === index);
        if (data.length > 0 && data[0].status === "done") {
            let file: { image_url: string; index: number } = {
                image_url: data[0].response.data.filename,
                index: index,
            };
            if (i === -1) {
                rs.push(file);
            } else {
                rs.splice(i, 1, file);
            }
            dispatch(changeImageType(rs));
            dispatch(
                RmDefaultImageClassifyUrlAction({
                    keyTypePro: idTypePro,
                    keyTypeProChild: idChildTypePro,
                    url: data[0].response.data.url,
                    fileName: data[0].response.data.filename,
                })
            );
        } else if (data.length === 0) {
            rs.splice(i, 1);
            dispatch(changeImageType(rs));
            dispatch(
                RmDefaultImageClassifyUrlAction({
                    keyTypePro: idTypePro,
                    keyTypeProChild: idChildTypePro,
                    url: "",
                    fileName: "",
                })
            );
        }
    };

    const onUploadDefaultMedia = (data: any) => {
        if (data?.length === 0) {
            dispatch(changeDataInfoPublicProAction({ default_media_url: "" }));
        } else {
            dispatch(
                changeDataInfoPublicProAction({
                    default_media_url: getDataUpload(data)?.[0]?.image_url,
                    default_media_type: 1,
                })
            );
        }
    };

    useEffect(() => {
        setDefaultSelect(state.defaultSelectClassifyImg.value);
    }, [state.defaultSelectClassifyImg]);

    return (
        <div className={"add-product__info-img wrapper-collapse style-box"}>
            <Collapse
                bordered={false}
                expandIconPosition={"right"}
                defaultActiveKey={["1"]}
                expandIcon={(panelProps) => (
                    <div>
                        <CollapseExpandIcon isActive={panelProps.isActive as boolean} />
                    </div>
                )}
            >
                <Collapse.Panel key={"1"} header={"Thông tin hình ảnh"}>
                    <div className={"add-product__info-img__img-pro mt-16"}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <span style={{ color: "red" }}>*</span> Hình ảnh sản phẩm:
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16}>
                                <UploadListFileComponent
                                    type={"picture-card"}
                                    limit={15}
                                    isMultiple
                                    name={"image"}
                                    size={Config.sizeImg}
                                    logData={(data) => onUploadImagePro(data)}
                                    path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                    defaultData={isAdd ? undefined : getDefaultImage(state.defaultListImage)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className={"add-product__info-img__video-pro mt-16 mb-16"}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <div>Video sản phẩm</div>
                                <div style={{ color: "gray" }}>
                                    {/* <div>
                                        <small>- Dung lương tối đa: 60MB</small>
                                    </div> */}
                                    <div>
                                        <small>- Thời gian tối đa: 10 phút</small>
                                    </div>

                                    <div>
                                        <small>- Định dạng: .mp4</small>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16}>
                                <div className={"wrapper-video"} style={{ maxWidth: 300 }}>
                                    <UploadListFileComponent
                                        type={undefined}
                                        logData={(data) => onUploadVideoPro(data)}
                                        limit={1}
                                        name={"video"}
                                        size={Config.sizeVideo}
                                        path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/2`}
                                        defaultData={!state.video ? undefined : state.defaultVideo}
                                        accept={".mp4"}
                                    />
                                    {state.video && (
                                        <video width="320" height="240" controls>
                                            <source
                                                src={state.defaultVideo.length > 0 ? state.defaultVideo[0].url : ""}
                                                type="video/mp4"
                                            />
                                        </video>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={"add-product__info-img__video-pro mt-16 mb-16"}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={6}>
                                <div>Hình ảnh mặc định</div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16}>
                                <UploadListFileComponent
                                    type={"picture-card"}
                                    limit={1}
                                    name={"image"}
                                    size={Config.sizeImg}
                                    logData={(data) => {
                                        onUploadDefaultMedia(data);
                                    }}
                                    path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                    defaultData={
                                        isAdd
                                            ? undefined
                                            : state.default_media_url
                                            ? [
                                                  {
                                                      uid: uuidv4(),
                                                      status: "done",
                                                      url: state.default_media_url,
                                                      name: "Default Image",
                                                  },
                                              ]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className={"add-product__info-img__type-pro"}>
                        <Row style={{ display: typePro.length > 0 ? "flex" : "none" }}>
                            <Col xs={24} sm={24} md={24} lg={6}>{`Phân loại`}</Col>
                            <Col xs={24} sm={24} md={24} lg={16}>
                                <div>
                                    <Select
                                        value={defaultSelect ? defaultSelect : "0"}
                                        style={{ width: 200 }}
                                        onChange={(value) => {
                                            setValueSelect(parseInt(value));
                                            if (value === "0") {
                                                setDefaultSelect(selectOption[0].title as string);
                                            } else {
                                                setDefaultSelect(selectOption[1].title as string);
                                            }

                                            if (!isAdd) {
                                                if (state.defaultSelectClassifyImg.index.toString() === value) {
                                                    dispatch(changeImageType(state.defaultListTypeImg));
                                                } else {
                                                    dispatch(changeImageType([]));
                                                }
                                            } else {
                                                dispatch(changeImageType([]));
                                            }
                                        }}
                                    >
                                        {typePro.length > 0 && (
                                            <Select.Option value={"0"}>
                                                {typePro.length > 0 && selectOption[0].title}
                                            </Select.Option>
                                        )}
                                        {typePro.length === 2 && selectOption[1].title && (
                                            <Select.Option value={"1"}>
                                                {typePro.length === 2 ? selectOption[1].title : ""}
                                            </Select.Option>
                                        )}
                                    </Select>
                                </div>
                                {(valueSelect === 0 || typePro.length === 1) && (
                                    <div className={"flex-start"}>
                                        {typePro.length > 0 &&
                                            typePro[0].typeItem.map((value, index) => {
                                                return (
                                                    <div className={"mt-16"}>
                                                        <UploadListFileComponent
                                                            type={"picture-card"}
                                                            limit={1}
                                                            name={"image"}
                                                            logData={(data) =>
                                                                onUploadImageTypePro(
                                                                    data,
                                                                    index,
                                                                    typePro[0].key,
                                                                    value.key
                                                                )
                                                            }
                                                            path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                                            size={Config.sizeImg}
                                                            defaultData={
                                                                value.url
                                                                    ? [
                                                                          {
                                                                              url: value.url,
                                                                              uid: value.key,
                                                                              name: value.fileName,
                                                                              status: "done",
                                                                          },
                                                                      ]
                                                                    : []
                                                            }
                                                        />
                                                        <div className={"flex-center"}>{value?.value}</div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                                {valueSelect === 1 && typePro.length === 2 && (
                                    <div className={"flex-start"}>
                                        {typePro.length === 2 &&
                                            typePro[1].typeItem.map((value, index) => {
                                                return (
                                                    <div className={"mt-16"}>
                                                        <UploadListFileComponent
                                                            type={"picture-card"}
                                                            limit={1}
                                                            name={"image"}
                                                            logData={(data) =>
                                                                onUploadImageTypePro(
                                                                    data,
                                                                    state.typePro[0].typeItem.length + index,
                                                                    typePro[1].key,
                                                                    value.key
                                                                )
                                                            }
                                                            path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                                            size={Config.sizeImg}
                                                            defaultData={
                                                                value.url
                                                                    ? [
                                                                          {
                                                                              url: value.url,
                                                                              uid: value.key,
                                                                              name: value.fileName,
                                                                              status: "done",
                                                                          },
                                                                      ]
                                                                    : []
                                                            }
                                                        />
                                                        <div className={"flex-center"}>{value?.value}</div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default InfoImageComponent;
