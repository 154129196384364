import { InputNumber } from "antd";
import { changeWeight, changeWholesaleQuantity } from "app/slices/add-update-product/AddAndUpdateProduct";
import { useDispatch } from "react-redux";

interface IWholesaleQuantityInput {
    value: any;
}

const WholesaleQuantityInput = (props: IWholesaleQuantityInput) => {
    const { value } = props;
    const dispatch = useDispatch();

    return (
        <InputNumber
            placeholder={"Số lượng sỉ"}
            value={!value?.value ? "" : value?.value}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(text) => {
                dispatch(
                    changeWholesaleQuantity({
                        key: value.id,
                        quantity: text,
                    })
                );
            }}
            style={{ width: 150 }}
            onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
        />
    );
};

export default WholesaleQuantityInput;
