import { Button, Col, DatePicker, Form, Input, PageHeader, Row, Select, Spin, Switch, Tabs } from "antd";
import { Btn } from "commons/components/btn/Button";
import Container from "commons/components/container";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import AppServices from "service/AppServices";
import styled from "styled-components";
import { saleService } from "../service";
import {
    EMAIL_REGEX,
    EMAIL_REGEX_2,
    FIVE,
    MOBI,
    Notification,
    SEVEN,
    STAFF_CODE,
    VIETTEL,
    VINA,
    toNonAccentVietnamese,
} from "utils";
import CustomerList from "../component/CustomerList";
import SaleWallet from "../component/SaleWallet";

const AddEditSaleStaff = () => {
    const location: any = useLocation();
    const [form] = Form.useForm();
    const saleId = location?.state?.saleId;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [provinces, setProvinces] = React.useState<any[]>([]);
    const [districts, setDistricts] = React.useState<any[]>([]);
    const [wards, setWards] = React.useState<any[]>([]);
    const [provinceId, setProvinceId] = React.useState<any>();
    const [districtId, setDistrictId] = React.useState<any>();
    const [banks, setBanks] = React.useState<any[]>([]);

    const items = [
        {
            key: "1",
            label: "Danh sách khách hàng",
            children: <CustomerList saleId={saleId} />,
        },
        {
            key: "2",
            label: "Ví thu nhập",
            children: <SaleWallet saleId={saleId} />,
        },
    ];

    const disabledDate = (current: any) => {
        return current && current > moment().endOf("day");
    };

    const onGetBanks = async () => {
        try {
            const payload = {
                page: 1,
                limit: 100,
            };
            const res: any = await saleService.getBanks(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({
                    ...item,
                    value: item?.id,
                    label: `${item?.code} - ${item?.name}}`,
                    // label: (
                    //     <div style={{ display: "flex", alignItems: "center" }}>
                    //         <img
                    //             src={item?.logo}
                    //             style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10 }}
                    //         />
                    //         <p style={{ margin: 0 }}>
                    //             <b>{item?.code}</b> - {item?.name}
                    //         </p>
                    //     </div>
                    // ),
                }));
                setBanks(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetProvinces = async () => {
        try {
            const res: any = await saleService.getProvinces();
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }));
                setProvinces(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetDistricts = async () => {
        try {
            const payload = {
                province_id: form.getFieldValue("province"),
            };
            const res: any = await saleService.getDistricts(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }));
                setDistricts(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetWards = async () => {
        try {
            const payload = {
                district_id: form.getFieldValue("district"),
            };
            const res: any = await saleService.getWards(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }));
                setWards(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetSaleDetail = async () => {
        try {
            setLoading(true);
            const res: any = await saleService.getSaleId(saleId);
            if (res?.status) {
                const result = res?.data?.data;

                form.setFieldsValue({
                    name: result.name,
                    phone: result.phone,
                    email: result.email,
                    bank: res?.data?.data.user_bank?.[0]?.df_bank_id,
                    bank_number: result.user_bank?.[0]?.account_number,
                    bank_holder: result.user_bank?.[0]?.cardholder_name,
                    province: result.province_id,
                    district: result.district_id,
                    ward: result.ward_id,
                    address: result.address,
                    dob: result.date_of_birth ? moment(res?.data?.data.date_of_birth) : undefined,
                    gender: result.gender,
                });
                setProvinceId(result?.province_id);
                setDistrictId(result?.district_id);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            const payload = {
                name: values.name,
                phone: values.phone,
                email: values?.email,
                password: values.password,
                bank_id: values.bank,
                account_number: values.bank_number,
                cardholder_name: values.bank_holder,
                province_id: values.province,
                district_id: values.district,
                ward_id: values.ward,
                address: values.address,
                date_of_birth: values.dob,
                gender: values.gender,
            };
            let res: any;
            if (saleId) {
                res = await saleService.updateSale(saleId, payload);
            } else {
                res = await saleService.addSale(payload);
            }

            if (res?.status) {
                Notification(
                    "success",
                    saleId ? "Cập nhật thông tin sale thành công!" : "Thêm mới nhân viên sale thành công!"
                );
                AppServices.history!.goBack();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        onGetProvinces();
        onGetBanks();
    }, []);

    React.useEffect(() => {
        if (saleId) onGetSaleDetail();
    }, [saleId]);

    React.useEffect(() => {
        if (provinceId) {
            onGetDistricts();
        }
    }, [provinceId]);

    React.useEffect(() => {
        if (districtId) {
            onGetWards();
        }
    }, [districtId]);

    return (
        <Spin spinning={loading}>
            <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} onFinish={onFinish} autoComplete="off">
                <Container
                    header={
                        <PageHeader
                            style={{ borderRadius: 8 }}
                            title={saleId ? "Chỉnh sửa nhân viên sale" : "Thêm mới nhân viên sale"}
                            onBack={() => AppServices.history?.goBack()}
                            extra={
                                saleId
                                    ? [
                                          <Form.Item noStyle>
                                              <Button
                                                  htmlType="submit"
                                                  type="primary"
                                                  style={{ backgroundColor: "#f6c217", borderColor: "#f6c217" }}
                                              >
                                                  Reset mật khẩu
                                              </Button>
                                          </Form.Item>,
                                          <Form.Item noStyle>
                                              <Button
                                                  htmlType="submit"
                                                  type="primary"
                                                  style={{ backgroundColor: "#109703" }}
                                              >
                                                  Lưu
                                              </Button>
                                          </Form.Item>,
                                      ]
                                    : [
                                          <Form.Item noStyle>
                                              <Button
                                                  htmlType="submit"
                                                  type="primary"
                                                  style={{ backgroundColor: "#109703" }}
                                              >
                                                  Lưu
                                              </Button>
                                          </Form.Item>,
                                      ]
                            }
                        ></PageHeader>
                    }
                    contentComponent={
                        saleId
                            ? [
                                  <>
                                      {/* <CustomRow justify={"end"}>
                                <Form.Item name={"active"} label="">
                                    <Switch
                                        style={{ width: 140, marginRight: 10 }}
                                        checkedChildren="Đang hoạt động"
                                        unCheckedChildren="Ngừng hoạt động"
                                        defaultChecked
                                    ></Switch>
                                </Form.Item>
                            </CustomRow> */}
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"name"}
                                                  label="Họ và tên"
                                                  rules={[
                                                      { required: true, message: "Vui lòng nhập họ tên sale!" },
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập họ tên sale"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"province"} label="Tỉnh/thành phố">
                                                  <Select
                                                      options={provinces}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn tỉnh/thành phố"
                                                      showSearch
                                                      onChange={(value: any) => {
                                                          setProvinceId(value);
                                                          setDistrictId(undefined);
                                                          form.setFieldsValue({
                                                              district: undefined,
                                                              ward: undefined,
                                                          });
                                                      }}
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"phone"}
                                                  label="Số điện thoại"
                                                  rules={[
                                                      { required: true, message: "Vui lòng nhập số điện thoại!" },
                                                      {
                                                          message: "Số điện thoại không hợp lệ!",
                                                          validator: (_, value) => {
                                                              if (!value?.toString()?.trim()) return Promise.resolve();
                                                              if (
                                                                  (!value?.toString()?.match(SEVEN) &&
                                                                      !value?.toString()?.match(MOBI) &&
                                                                      !value?.toString()?.match(VIETTEL) &&
                                                                      !value?.toString()?.match(VINA) &&
                                                                      !value?.toString()?.match(FIVE)) ||
                                                                  isNaN(Number(value))
                                                              ) {
                                                                  return Promise.reject();
                                                              }
                                                              return Promise.resolve();
                                                          },
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập số điện thoại"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"district"} label="Quận/huyện">
                                                  <Select
                                                      options={districts}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn quận/huyện"
                                                      showSearch
                                                      disabled={!provinceId}
                                                      onChange={(value: any) => {
                                                          setDistrictId(value);
                                                          form.setFieldsValue({
                                                              ward: undefined,
                                                          });
                                                      }}
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"email"}
                                                  label="Email"
                                                  rules={[
                                                      //   { required: true, message: "Vui lòng nhập email!" },
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                      {
                                                          message: "Email không đúng định dạng!",
                                                          validator: (_, value) => {
                                                              if (
                                                                  (EMAIL_REGEX.test(value) &&
                                                                      EMAIL_REGEX_2.test(value)) ||
                                                                  !value ||
                                                                  value.length > 100
                                                              ) {
                                                                  return Promise.resolve();
                                                              }
                                                              return Promise.reject();
                                                          },
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập email"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"ward"} label="Phường/xã">
                                                  <Select
                                                      options={wards}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn phường/xã"
                                                      showSearch
                                                      disabled={!districtId}
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"bank_holder"}
                                                  label="Chủ tài khoản"
                                                  rules={[
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập tên chủ tài khoản"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"address"}
                                                  label="Địa chỉ chi tiết"
                                                  rules={[
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập địa chỉ chi tiết"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item name={"bank"} label="Ngân hàng">
                                                  <Select
                                                      options={banks}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn ngân hàng"
                                                      showSearch
                                                      labelInValue
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"dob"} label="Ngày sinh">
                                                  <DatePicker
                                                      disabledDate={disabledDate}
                                                      style={{ width: "100%" }}
                                                      placeholder="Chọn ngày sinh"
                                                      allowClear
                                                      format={"DD-MM-YYYY"}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"bank_number"}
                                                  label="STK"
                                                  rules={[
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                      {
                                                          validator: (_, value) => {
                                                              if (value && !/^\d+$/.test(value)) {
                                                                  return Promise.reject("Vui lòng chỉ nhập chữ số!");
                                                              }
                                                              return Promise.resolve();
                                                          },
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {
                                                          const newValue = e.target.value.replace(/[^0-9]/g, "");
                                                          e.target.value = newValue;
                                                      }}
                                                      placeholder="Nhập số tài khoản "
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"gender"} label="Giới tính">
                                                  <Select allowClear showArrow placeholder="Chọn giới tính" showSearch>
                                                      <Select.Option value={1}>Nam</Select.Option>
                                                      <Select.Option value={2}>Nữ</Select.Option>
                                                  </Select>
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow>
                                          {!saleId && (
                                              <Col span={12}>
                                                  <Form.Item
                                                      name={"password"}
                                                      label="Mật khẩu"
                                                      rules={[
                                                          { required: true, message: "Vui lòng nhập mật khẩu!" },
                                                          {
                                                              max: 255,
                                                              type: "string",
                                                              message: "Vui lòng không nhập quá 255 ký tự!",
                                                          },
                                                          { min: 6, message: "Mật khẩu cần chứa ít nhất 6 ký tự!" },
                                                          {
                                                              message: "Vui lòng không nhập khoảng cách!",
                                                              validator: (_, value) => {
                                                                  if (!value) return Promise.resolve();
                                                                  if (
                                                                      value.includes(" ") &&
                                                                      STAFF_CODE.test(value?.trim())
                                                                  ) {
                                                                      return Promise.reject();
                                                                  }
                                                                  return Promise.resolve();
                                                              },
                                                          },
                                                      ]}
                                                  >
                                                      <Input.Password
                                                          placeholder="Nhập mật khẩu"
                                                          allowClear
                                                          autoComplete="off"
                                                      />
                                                  </Form.Item>
                                              </Col>
                                          )}
                                      </CustomRow>
                                  </>,
                                  <>
                                      <Tabs defaultActiveKey="1" items={items} />
                                  </>,
                              ]
                            : [
                                  <>
                                      {/* <CustomRow justify={"end"}>
                            <Form.Item name={"active"} label="">
                                <Switch
                                    style={{ width: 140, marginRight: 10 }}
                                    checkedChildren="Đang hoạt động"
                                    unCheckedChildren="Ngừng hoạt động"
                                    defaultChecked
                                ></Switch>
                            </Form.Item>
                        </CustomRow> */}
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"name"}
                                                  label="Họ và tên"
                                                  rules={[
                                                      { required: true, message: "Vui lòng nhập họ tên sale!" },
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập họ tên sale"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"province"} label="Tỉnh/thành phố">
                                                  <Select
                                                      options={provinces}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn tỉnh/thành phố"
                                                      showSearch
                                                      onChange={(value: any) => {
                                                          setProvinceId(value);
                                                          setDistrictId(undefined);
                                                          form.setFieldsValue({
                                                              district: undefined,
                                                              ward: undefined,
                                                          });
                                                      }}
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"phone"}
                                                  label="Số điện thoại"
                                                  rules={[
                                                      { required: true, message: "Vui lòng nhập số điện thoại!" },
                                                      {
                                                          message: "Số điện thoại không hợp lệ!",
                                                          validator: (_, value) => {
                                                              if (!value?.toString()?.trim()) return Promise.resolve();
                                                              if (
                                                                  (!value?.toString()?.match(SEVEN) &&
                                                                      !value?.toString()?.match(MOBI) &&
                                                                      !value?.toString()?.match(VIETTEL) &&
                                                                      !value?.toString()?.match(VINA) &&
                                                                      !value?.toString()?.match(FIVE)) ||
                                                                  isNaN(Number(value))
                                                              ) {
                                                                  return Promise.reject();
                                                              }
                                                              return Promise.resolve();
                                                          },
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập số điện thoại"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"district"} label="Quận/huyện">
                                                  <Select
                                                      options={districts}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn quận/huyện"
                                                      showSearch
                                                      disabled={!provinceId}
                                                      onChange={(value: any) => {
                                                          setDistrictId(value);
                                                          form.setFieldsValue({
                                                              ward: undefined,
                                                          });
                                                      }}
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"email"}
                                                  label="Email"
                                                  rules={[
                                                      //   { required: true, message: "Vui lòng nhập email!" },
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                      {
                                                          message: "Email không đúng định dạng!",
                                                          validator: (_, value) => {
                                                              if (
                                                                  (EMAIL_REGEX.test(value) &&
                                                                      EMAIL_REGEX_2.test(value)) ||
                                                                  !value ||
                                                                  value.length > 100
                                                              ) {
                                                                  return Promise.resolve();
                                                              }
                                                              return Promise.reject();
                                                          },
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập email"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"ward"} label="Phường/xã">
                                                  <Select
                                                      options={wards}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn phường/xã"
                                                      showSearch
                                                      disabled={!districtId}
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"bank_holder"}
                                                  label="Chủ tài khoản"
                                                  rules={[
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập tên chủ tài khoản"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"address"}
                                                  label="Địa chỉ chi tiết"
                                                  rules={[
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {}}
                                                      placeholder="Nhập địa chỉ chi tiết"
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow gutter={[16, 16]}>
                                          <Col span={12}>
                                              <Form.Item name={"bank"} label="Ngân hàng">
                                                  <Select
                                                      options={banks}
                                                      allowClear
                                                      showArrow
                                                      placeholder="Chọn ngân hàng"
                                                      showSearch
                                                      filterOption={(input, option: any) => {
                                                          const nonAccent = toNonAccentVietnamese(
                                                              option?.label
                                                          ).toLowerCase();
                                                          const nonAccentInput =
                                                              toNonAccentVietnamese(input).toLowerCase();
                                                          return nonAccent.includes(nonAccentInput);
                                                      }}
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"dob"} label="Ngày sinh">
                                                  <DatePicker
                                                      disabledDate={disabledDate}
                                                      style={{ width: "100%" }}
                                                      placeholder="Chọn ngày sinh"
                                                      allowClear
                                                      format={"DD-MM-YYYY"}
                                                  />
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow>
                                          <Col span={12}>
                                              <Form.Item
                                                  name={"bank_number"}
                                                  label="STK"
                                                  rules={[
                                                      {
                                                          max: 255,
                                                          type: "string",
                                                          message: "Vui lòng không nhập quá 255 ký tự!",
                                                      },
                                                      {
                                                          validator: (_, value) => {
                                                              if (value && !/^\d+$/.test(value)) {
                                                                  return Promise.reject("Vui lòng chỉ nhập chữ số!");
                                                              }
                                                              return Promise.resolve();
                                                          },
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={(e: any) => {
                                                          const newValue = e.target.value.replace(/[^0-9]/g, "");
                                                          e.target.value = newValue;
                                                      }}
                                                      placeholder="Nhập số tài khoản "
                                                      allowClear
                                                      autoComplete="off"
                                                  />
                                              </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                              <Form.Item name={"gender"} label="Giới tính">
                                                  <Select allowClear showArrow placeholder="Chọn giới tính" showSearch>
                                                      <Select.Option value={1}>Nam</Select.Option>
                                                      <Select.Option value={2}>Nữ</Select.Option>
                                                  </Select>
                                              </Form.Item>
                                          </Col>
                                      </CustomRow>
                                      <CustomRow>
                                          {!saleId && (
                                              <Col span={12}>
                                                  <Form.Item
                                                      name={"password"}
                                                      label="Mật khẩu"
                                                      rules={[
                                                          { required: true, message: "Vui lòng nhập mật khẩu!" },
                                                          {
                                                              max: 255,
                                                              type: "string",
                                                              message: "Vui lòng không nhập quá 255 ký tự!",
                                                          },
                                                          { min: 6, message: "Mật khẩu cần chứa ít nhất 6 ký tự!" },
                                                          {
                                                              message: "Vui lòng không nhập khoảng cách!",
                                                              validator: (_, value) => {
                                                                  if (!value) return Promise.resolve();
                                                                  if (
                                                                      value.includes(" ") &&
                                                                      STAFF_CODE.test(value?.trim())
                                                                  ) {
                                                                      return Promise.reject();
                                                                  }
                                                                  return Promise.resolve();
                                                              },
                                                          },
                                                      ]}
                                                  >
                                                      <Input.Password
                                                          placeholder="Nhập mật khẩu"
                                                          allowClear
                                                          autoComplete="off"
                                                      />
                                                  </Form.Item>
                                              </Col>
                                          )}
                                      </CustomRow>
                                  </>,
                              ]
                    }
                />
            </Form>
        </Spin>
    );
};
const CustomRow = styled(Row)`
    margin-bottom: 10px;
`;

export default AddEditSaleStaff;
