import AxiosClient from "api/axios/AxiosClient";

export const rewardPointService = {
    getData: (id: number, payload: any) => {
        return AxiosClient.get(`customer/${id}/wallet_history`, { params: payload });
    },
    getCartData: (id: number, payload: any) => {
        return AxiosClient.get(`customer/${id}/cart`, { params: payload });
    },
};
