import AxiosClient from "api/axios/AxiosClient";

export const voucherService = {
    getVouchers: (payload: any) => {
        return AxiosClient.get("voucher/list-web", { params: payload });
    },
    getVoucherDetail: (id: number) => {
        return AxiosClient.get(`voucher/${id}`);
    },
    deleteVoucher: (id: number) => {
        return AxiosClient.delete(`voucher/${id}`);
    },
    updateVoucher: (id: number, payload: any) => {
        return AxiosClient.put(`voucher/${id}`, payload);
    },
    addVoucher: (payload: any) => {
        return AxiosClient.post(`voucher`, payload);
    },
    changeVoucherStatus: (id: number) => {
        return AxiosClient.put(`voucher/${id}/toggle`);
    },
};
