import "./Container.css";
import styled from "styled-components";

type Props = {
    children?: any;
    filterComponent?: any;
    contentComponent?: any;
    header?: any;
    footer?: any;
    contentStyle?: any;
};

const Container = ({
    // children,
    filterComponent,
    contentStyle,
    contentComponent,
    header = () => {},
}: Props) => {
    return (
        <StyledContainer>
            <div className="header_container">
                <p>{header}</p>
            </div>
            {filterComponent && <div className="content_filter_container">{filterComponent}</div>}
            {contentComponent?.map((item: any, index: number) => (
                <div key={index} className={"content_container"} style={contentStyle}>
                    {item}
                </div>
            ))}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    margin: 0;
`;

export default Container;
