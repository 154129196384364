import { IApiResponse, putRequest } from "../../service/Basevices";
import { IResBody } from "../interface";

export interface IResLogin extends IResBody {
  data: {
    id: number;
    token: string;
    email: string;
    name: string;
    profilePictureUrl: string | null;
    role: number;
  };
}

export const LoginAPI = (pass: string, userName: string): Promise<IApiResponse<IResLogin>> => {
  return putRequest("/users/web/login", false, { password: pass.trim(), user_name: userName.trim() });
};

export const LogoutAPI = (): Promise<IApiResponse<IResBody>> => {
  return putRequest("/users/web/logout", true);
};

export const putChangePassAccAPI = (newPassword: string): Promise<IApiResponse<IResBody>> => {
  return putRequest("/users/change-password", true, { new_password: newPassword });
};
