import React, { useEffect, useRef, useState } from "react";
import { Avatar, Divider, Input } from "antd";
import "./Chat.scss";
import { firebaseConnect } from "../../FirebaseConnect";
import { formatString, slug } from "../../service/Format";
import moment from "moment";
import ListChatComponent from "./components/ListChatComponent";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setRoomAction } from "../../app/slices/chat/Chat";
import { v4 as uuidv4 } from "uuid";
import { openNotification } from "../../commons/components/notifycation/Notification";
import { Config } from "../../commons/validation/ValidationPattern";
import LoadingComponent from "../../commons/components/loading/LoadingComponent";
import { checkTokenAPI } from "../../api/account/Account";
import { postRequest } from "../../service/Basevices";
import AppServices from "../../service/AppServices";

export interface IRoom {
    id: number;
    name: string;
    avatar: string;
    isOnline: 0 | 1;
    isReadMsg: 0 | 1;
    phoneNumber: string;
    lastMsg: {
        msg: string;
        isAdmin?: number;
        createdAt: string;
    };
    deviceId: string;
    isFocus: number;
    timeStamp: number;
}

export const isReadMsg = (roomId: number, isReadOnly: boolean = false) => {
    if (isReadOnly) {
        const refAdmin = firebaseConnect.database().ref(`roomTable/${roomId}/admin/isReadMsg`);
        refAdmin.set(1);
    } else {
        const refUser = firebaseConnect.database().ref(`roomTable/${roomId}/user/isReadMsg`);
        refUser.set(0);
        const refAdmin = firebaseConnect.database().ref(`roomTable/${roomId}/admin/isReadMsg`);
        refAdmin.set(1);
    }
};

const pushNotificationMsg = async (deviceId: string, msg: string) => {
    let path: string = `/notification/push-message`;
    return postRequest(path, true, { device_id: deviceId, content: msg });
};

const Chat: React.FC<any> = () => {
    const dispatch = useAppDispatch();
    const [listRoom, setListRoom] = useState<IRoom[]>([]);
    let room: IRoom = useAppSelector((state) => state.chat.room);

    const [pageListChat, setPageListChat] = useState<number>(1);
    const [loadingListMsg, setLoadingMsg] = useState<boolean>(false);
    const [isOutOfPage, setIsOutOfPage] = useState<boolean>(false);
    const [loadingUploadImg, setLoadingUploadImg] = useState<boolean>(false);

    const [url, setUrl] = useState<string>("");
    const [fileName, setFilename] = useState<string>("");

    const winMsgChatRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const fileUploadRef = useRef<HTMLInputElement>(null);

    const [permissionAccess, setPermissionAccess] = useState<boolean>(false);

    const scrollChatToBottom = (num: number = 10000000000) => {
        if (winMsgChatRef.current) {
            if (num) {
                winMsgChatRef.current.scrollTo({
                    top: num,
                    behavior: "smooth",
                });
            } else {
                winMsgChatRef.current.scrollTo({
                    top: winMsgChatRef.current.scrollHeight - winMsgChatRef.current.clientHeight,
                    behavior: "smooth",
                });
            }
        }
    };

    const getListRoom = () => {
        const refRoomTable = firebaseConnect.database().ref("roomTable/");

        const filterListRoom = (listRoom: IRoom[]): IRoom[] => {
            let rs: IRoom[] = [];
            let listRoomNotRead: IRoom[] = listRoom.filter((value) => value.isReadMsg === 0);
            let listRoomRead: IRoom[] = listRoom.filter((value) => value.isReadMsg === 1);
            listRoomNotRead.sort(function (a, b) {
                return b.timeStamp - a.timeStamp;
            });
            listRoomRead.sort(function (a, b) {
                return b.timeStamp - a.timeStamp;
            });

            rs = listRoomNotRead.concat(listRoomRead);
            return rs;
        };

        refRoomTable.orderByChild("/admin/isReadMsg").on("value", (childSnapshot, prevChildKey) => {
            let newListRoom: IRoom[] = [];
            childSnapshot.forEach((value) => {
                newListRoom.push({
                    id: parseInt(value.key as string),
                    phoneNumber: value?.val()?.user?.phoneNumber ? value.val().user?.phoneNumber : "___",
                    name: value?.val()?.user?.name,
                    avatar: value?.val()?.user?.avatar,
                    isReadMsg: value?.val()?.admin?.isReadMsg,
                    lastMsg: value?.val()?.lastMsg || { msg: "", createdAt: "" },
                    isOnline: value?.val()?.user?.isOnline,
                    deviceId: value?.val()?.user?.deviceID,
                    isFocus: value?.val()?.user?.isFocus,
                    timeStamp: moment(value?.val()?.lastMsg?.createdAt).unix(),
                });
            });

            // const list = filterListRoom(newListRoom);
            setListRoom(filterListRoom(newListRoom));
        });
    };

    const searchListRoom = (search: string, limit: number = 10) => {
        let roomSearch: IRoom[] = [];
        listRoom.forEach((value) => {
            if (
                (value.name && slug(value.name.toLocaleLowerCase()).indexOf(slug(search.toLocaleLowerCase())) !== -1) ||
                (value.phoneNumber && value.phoneNumber.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1)
            ) {
                roomSearch.push(value);
            }
        });
        setListRoom(roomSearch);
    };

    const getRoom = (room: IRoom) => {
        dispatch(setRoomAction({ room: room }));
    };

    const onChangeLastMsg = (roomId: number, msg: string) => {
        const ref = firebaseConnect.database().ref(`roomTable/${roomId}/lastMsg`);
        ref.set({
            msg: msg,
            isAdmin: 1,
            createdAt: new Date().toISOString(),
        });
    };

    const clearChatImage = () => {
        setUrl("");
        setFilename("");
    };

    const sendMsg = async (msg: string, role: 0 | 1 = 1) => {
        try {
            let newRoom: IRoom[] = listRoom.filter((value) => value.id === room.id);
            newRoom.length > 0 && dispatch(setRoomAction({ room: newRoom[0] }));

            if (msg.trim()) {
                isReadMsg(room.id);
                let deviceId: string = "";
                let isFocus: number = 0;
                await firebaseConnect
                    .database()
                    .ref(`listDeviceIdUser/${room.id}/deviceID`)
                    .on("value", (a) => {
                        deviceId = a.val();
                    });
                await firebaseConnect
                    .database()
                    .ref(`listDeviceIdUser/${room.id}/isFocus`)
                    .on("value", (a) => {
                        isFocus = a.val();
                    });
                setTimeout(() => {
                    if (isFocus === 0) {
                        pushNotificationMsg(deviceId, msg);
                    }
                }, 2000);
                const refChatTable = firebaseConnect.database().ref("chatTable/");
                await refChatTable.child(`/${room.id}`).push({
                    _id: uuidv4(),
                    createdAt: new Date().toISOString(),
                    text: msg,
                    user: {
                        _id: 0,
                        avatar: "http://dev.tpmart.winds.vn/static/media/Logo%20TP_mart.01bff5d6.png",
                        name: "Quản trị viên",
                    },
                });
                onChangeLastMsg(room.id, msg.trim());
            }
        } catch (e) {
            console.error(e);
            openNotification("ERROR", "Đã có lỗi xảy ra vui lòng thử lại");
        }

        try {
            if (url) {
                isReadMsg(room.id);
                let deviceId: string = "";
                let isFocus: number = 0;
                await firebaseConnect
                    .database()
                    .ref(`listDeviceIdUser/${room.id}/deviceID`)
                    .on("value", (a) => {
                        deviceId = a.val();
                    });
                await firebaseConnect
                    .database()
                    .ref(`listDeviceIdUser/${room.id}/isFocus`)
                    .on("value", (a) => {
                        isFocus = a.val();
                    });
                setTimeout(() => {
                    if (isFocus === 0) {
                        pushNotificationMsg(deviceId, "Hình ảnh");
                    }
                }, 2000);
                const refChatTable = firebaseConnect.database().ref("chatTable/");
                await refChatTable.child(`/${room.id}`).push({
                    _id: uuidv4(),
                    createdAt: new Date().toISOString(),
                    text: msg,
                    image: url,
                    user: {
                        _id: 0,
                        avatar: "http://dev.tpmart.winds.vn/static/media/Logo%20TP_mart.01bff5d6.png",
                        name: "Quản trị viên",
                    },
                });
                onChangeLastMsg(room.id, "Hình ảnh");
            }
            clearChatImage();
        } catch (e) {
            console.error(e);
            openNotification("ERROR", "Đã có lỗi xảy ra vui lòng thử lại");
        }
    };

    const onClickRoom = async (room: IRoom) => {
        isReadMsg(room.id, true);
        getRoom(room);
        setPageListChat(1);
        setIsOutOfPage(false);
        scrollChatToBottom();
        AppServices.history?.push(`/home/chat?room=${room.id}`);
    };

    const sendImage = (e: any) => {
        let image: any = null;

        let timeStamp = new Date();
        if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg") {
            if (e.target.files[0].size > 3000000) {
                openNotification("ERROR", "Dung lương ảnh quá 3MB");
            } else {
                image = e.target.files[0];
            }
        } else {
            openNotification("ERROR", "Sai định dạng ảnh");
        }

        if (image) {
            setLoadingUploadImg(true);

            firebaseConnect
                .storage()
                .ref(`images/${timeStamp + (image.name ? image.name : "")}`)
                .put(image)
                .on(
                    "state_changed",
                    (snapshot) => {},
                    (error) => {
                        openNotification("ERROR", "Xảy ra lỗi trong quá trình gửi ảnh");
                        console.error(error);
                    },
                    () => {
                        firebaseConnect
                            .storage()
                            .ref("images")
                            .child(timeStamp + (image.name ? image.name : ""))
                            .getDownloadURL()
                            .then((value) => {
                                setUrl(value);
                                setFilename(image.name);
                                setLoadingUploadImg(false);
                            });

                        inputRef.current!.focus();
                        fileUploadRef.current && (fileUploadRef.current.value = "");
                    }
                );
        }
    };

    const checkToken = async () => {
        const res = await checkTokenAPI();
        if (res.body.status === 1) {
            setPermissionAccess(true);
        } else {
            openNotification("ERROR", res.body.message);
        }
    };

    useEffect(() => {
        getListRoom();
        checkToken();
    }, []);

    return (
        <div>
            {permissionAccess ? (
                <div className={"chat"}>
                    <div className={"chat__list"}>
                        <div className={"chat__list__header"}>
                            <div className={"chat__list__header__title"}>Danh sách cuộc hội thoại</div>

                            <div className={"chat__list__header__search"}>
                                <Input
                                    placeholder={"Tìm kiếm theo tên."}
                                    onChange={(event) => {
                                        if (event.target.value.trim()) {
                                            searchListRoom(event.target.value.trim());
                                        } else {
                                            getListRoom();
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <Divider orientation={"left"} style={{ fontSize: 14, color: "gray" }}>
                            Danh sách
                        </Divider>

                        <div className={"chat__list__room"}>
                            <div>
                                {listRoom.map((value) => {
                                    return (
                                        <div
                                            className={`chat__list__room--item ${room.id === value.id && "active"}`}
                                            key={value.id}
                                            id={value.id.toString()}
                                            onClick={() => onClickRoom(value)}
                                        >
                                            {value.avatar ? (
                                                <Avatar
                                                    shape={"circle"}
                                                    style={{ marginRight: 4 }}
                                                    size={40}
                                                    src={value.avatar}
                                                />
                                            ) : (
                                                <Avatar
                                                    shape={"circle"}
                                                    style={{
                                                        marginRight: 4,
                                                        color: "rgb(114, 105, 239)",
                                                        backgroundColor: "rgba(114, 105, 239, 0.25)",
                                                    }}
                                                    size={40}
                                                >
                                                    {value.name ? value.name[0].toUpperCase() : "___"}
                                                </Avatar>
                                            )}
                                            <div className={"chat__list__room--content"}>
                                                <div className={"chat__list__room--wrapper-name"}>
                                                    <div className={"chat__list__room--name"}>
                                                        {formatString(value.name)}_{formatString(value.phoneNumber)}
                                                    </div>
                                                    <div className={"chat__list__room--time"}>
                                                        {value.lastMsg &&
                                                            value.lastMsg.createdAt &&
                                                            moment(value.lastMsg.createdAt).format(
                                                                Config.formatDate
                                                            ) === moment().format(Config.formatDate) &&
                                                            moment(value.lastMsg.createdAt).format("HH:mm")}
                                                        {value.lastMsg &&
                                                            value.lastMsg.createdAt &&
                                                            moment(value.lastMsg.createdAt).format(
                                                                Config.formatDate
                                                            ) !== moment().format(Config.formatDate) &&
                                                            moment(value.lastMsg.createdAt).format("DD/MM")}
                                                    </div>
                                                </div>
                                                <div className={"chat__list__room--msg"}>
                                                    <div className={"msg"}>{value.lastMsg && value.lastMsg.msg}</div>
                                                    {value.lastMsg.isAdmin === 1 && (
                                                        <small style={{ color: "blue" }}>admin</small>
                                                    )}
                                                    {!value.isReadMsg && (
                                                        <i
                                                            className="fas fa-circle"
                                                            style={{ color: "rgb(114, 105, 239)" }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {room.id ? (
                        <div className={"chat__windows"}>
                            <div className={"chat__windows__header"}>
                                {room.avatar ? (
                                    <Avatar shape={"circle"} style={{ marginRight: 8 }} size={40} src={room.avatar} />
                                ) : (
                                    <div className={"chat__windows__header--avatar"}>{room.name[0]}</div>
                                )}
                                <div className={"chat__windows__header--name"}>
                                    {room.name} - {room.phoneNumber}
                                </div>
                            </div>
                            <div
                                className={"chat__windows__win-msg"}
                                ref={winMsgChatRef}
                                onScroll={() => {
                                    if (winMsgChatRef.current!.scrollTop === 0) {
                                        if (!isOutOfPage) {
                                            setPageListChat(pageListChat + 1);
                                            setLoadingMsg(true);
                                        }
                                    }
                                }}
                            >
                                <div className={"flex-center"}>
                                    {loadingListMsg && (
                                        <i
                                            className="fas fa-spinner fa-pulse"
                                            style={{ color: "rgb(114, 105, 239)" }}
                                        />
                                    )}
                                </div>

                                <ListChatComponent
                                    room={room}
                                    page={pageListChat}
                                    onGetNewListMsg={(isLoadMore, isOutOfPage, keyMsgScroll) => {
                                        const getScrollTopMsg = (): number => {
                                            if (winMsgChatRef.current) {
                                                let element: HTMLElement | null =
                                                    winMsgChatRef.current.ownerDocument.getElementById(keyMsgScroll);
                                                if (element) {
                                                    return element.offsetTop - winMsgChatRef.current.offsetTop;
                                                }
                                            }
                                            return 100;
                                        };

                                        if (isLoadMore) {
                                            setLoadingMsg(false);

                                            setTimeout(() => {
                                                scrollChatToBottom(getScrollTopMsg());
                                            }, 500);
                                        } else {
                                            setTimeout(() => {
                                                scrollChatToBottom();
                                            }, 0);
                                        }
                                        setIsOutOfPage(isOutOfPage);
                                    }}
                                />
                            </div>
                            <div className={"chat__windows__send-msg"}>
                                <input
                                    placeholder={"Nhập tin nhắn."}
                                    ref={inputRef}
                                    style={{ width: "100%", outline: "none" }}
                                    onClick={() => {
                                        scrollChatToBottom();
                                        isReadMsg(room.id, true);
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            sendMsg(inputRef.current?.value as string);
                                            inputRef.current && (inputRef.current.value = "");
                                        }
                                    }}
                                />

                                {fileName && (
                                    <div className={"file-name"}>
                                        {fileName}
                                        <i className="far fa-trash ml-16" onClick={() => clearChatImage()} />
                                    </div>
                                )}

                                {loadingUploadImg && (
                                    <div className={"file-name"}>
                                        <i className="fas fa-spinner fa-pulse" />
                                        <span style={{ marginLeft: 4 }}>Đang tải ảnh...</span>
                                    </div>
                                )}

                                <i
                                    className="fas fa-paper-plane"
                                    onClick={() => {
                                        sendMsg(inputRef.current?.value as string);
                                        inputRef.current && (inputRef.current.value = "");
                                    }}
                                />

                                <div className={"wrapper-upload"}>
                                    <input
                                        type={"file"}
                                        onChange={sendImage}
                                        ref={fileUploadRef}
                                        className={"upload"}
                                    />
                                    <i
                                        className="fal fa-paperclip"
                                        style={{
                                            marginRight: 25,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className={"loading"}>
                    <LoadingComponent />
                </div>
            )}
        </div>
    );
};
export default Chat;
