import avatar from "../assets/image/avatar.png";
import logo from "../assets/image/Logo TP_mart.png";
import backLogin from "../assets/image/livestream_optimized.png";

const Images = {
  avatar: avatar,
  logo: logo,
  backLogin: backLogin,
};

export default Images;
