import { Table } from "antd";
import CustomPopConfirm from "commons/components/pop-confirm";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import AttributieSelect from "./AttributieSelect";
import ChildCategorySelect from "./ChildCategorySelect";
import ParentCategorySelect from "./ParentCategorySelect";
import ProductQuantityInput from "./ProductQuantityInput";
import ProductSelect from "./ProductSelect";
import { Notification, formatNumber } from "utils";

interface IAddProductToOrder {
    onDeleteProduct: (productId: string) => void;
}

export const findDuplicateObjects = (arr: any) => {
    const duplicates = new Set();

    for (let i = 0; i < arr.length; i++) {
        const obj1 = arr[i];
        if (duplicates.has(obj1)) continue; // Skip already marked duplicates

        let isDuplicate = false;

        for (let j = i + 1; j < arr.length; j++) {
            const obj2 = arr[j];

            if (
                (obj1.isHasChildCate === true &&
                    obj2.isHasChildCate === true &&
                    obj1.product_price_id === obj2.product_price_id &&
                    obj1.attribute === obj2.attribute) ||
                (obj1.isHasChildCate === false &&
                    obj2.isHasChildCate === false &&
                    obj1.product_price_id === obj2.product_price_id)
            ) {
                duplicates.add(obj2);
                isDuplicate = true;
            }
        }
    }

    return Array.from(duplicates);
};

const AddProductToOrder = (props: IAddProductToOrder) => {
    const { onDeleteProduct } = props;

    const createOrderData = useSelector((state: any) => state.createOrder.data);

    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        {
            title: <b>STT</b>,
            width: 50,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Danh mục cha</b>,
            width: 150,
            dataIndex: "parent_category",
            render: (_: any, record: any) => <ParentCategorySelect record={record} />,
        },
        {
            title: <b>Danh mục con</b>,
            width: 170,
            dataIndex: "child_category",
            render: (_: any, record: any) => <ChildCategorySelect record={record} />,
        },
        {
            title: <b>Sản phẩm</b>,
            width: 170,
            dataIndex: "product",
            render: (_: any, record: any) => <ProductSelect record={record} />,
        },
        {
            title: <b>Thuộc tính</b>,
            width: 170,
            dataIndex: "attribute",
            render: (_: any, record: any) => <AttributieSelect record={record} />,
        },
        {
            title: <b>Số lượng</b>,
            width: 150,
            dataIndex: "quantity",
            render: (_: any, record: any) => <ProductQuantityInput record={record} />,
        },
        {
            title: <b>Giá (VND)</b>,
            dataIndex: "price",
            render: (value: any, record: any) => {
                if (record?.wholesale_quantity <= record?.quantity && record?.wholesale_quantity > 0)
                    return formatNumber(record?.wholesale_price);
                return formatNumber(value);
            },
        },
        {
            title: <b>Giá khuyến mãi (VND)</b>,
            width: 150,
            dataIndex: "sale_price",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Thành tiền (VND)</b>,
            dataIndex: "total_price",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Khối lượng (g)</b>,
            dataIndex: "weight",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Thao tác</b>,
            width: 100,
            dataIndex: "",
            render: (_: any, record: any) => {
                return (
                    <CustomPopConfirm
                        title="Bạn có chắc chắn muốn xoá sản phẩm này?"
                        onConfirm={() => onDeleteProduct(record?.id)}
                    />
                );
            },
        },
    ];

    React.useEffect(() => {
        if (createOrderData?.length > 1) {
            const duplicateItems = findDuplicateObjects(createOrderData);
            if (duplicateItems?.length > 0) {
                Notification("error", "Hiện tại, danh sách sản phẩm đang chứa các sản phẩm trùng nhau!");
            }
        }
    }, [createOrderData]);

    return (
        <Table
            id="top-table"
            style={{ width: "100%" }}
            bordered
            columns={columns}
            dataSource={createOrderData}
            scroll={{
                x: 1500,
                scrollToFirstRowOnChange: true,
            }}
            locale={{
                emptyText: "Chưa có bản ghi nào!",
            }}
            pagination={false}
        />
    );
};

export default memo(AddProductToOrder);
