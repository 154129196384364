import { DatePicker, Row, Table, Tag } from "antd";
import React from "react";
import styled from "styled-components";
import { formatNumber, momentToStringDate } from "utils";
import { rewardPointService } from "../service";

interface ICart {
    customerId: number;
}

const Cart = (props: ICart) => {
    const { customerId } = props;
    const [data, setData] = React.useState<any[]>([]);
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });
    const columns = [
        {
            title: "STT",
            dataIndex: "col1",
            width: 80,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: "Tên sản phẩm",
            dataIndex: "name",
        },
        {
            title: "Giá gốc (đ)",
            dataIndex: "price",
            render: (_: any, record: any) => formatNumber(record?.ProductPrice?.price),
        },
        {
            title: "Giá sale (đ)",
            dataIndex: "price",
            render: (_: any, record: any) => formatNumber(record?.flash_sale_price),
        },
        {
            title: "Số lượng sale)",
            dataIndex: "price",
            render: (_: any, record: any) => formatNumber(record?.flash_sale_item_remain),
        },
        {
            title: "Trạng thái sale",
            dataIndex: "sale_status",
            render: (_: any, record: any) => {
                if (record?.flash_sale_item_remain) return <Tag color="green">Đang sale</Tag>;
                return <Tag color="red">Không sale</Tag>;
            },
        },
        {
            title: "Tồn kho",
            dataIndex: "stock",
            render: (_: any, record: any) => formatNumber(record?.ProductPrice?.stock),
        },
    ];

    const onGetData = async () => {
        try {
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                from_date: dateRange.fromDate,
                to_date: dateRange.toDate,
            };
            const res: any = await rewardPointService.getCartData(customerId, payload);
            if (res?.status) {
                setData(res?.data);
                setPaging({
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetData();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [dateRange]);

    React.useEffect(() => {
        onGetData();
    }, [paging.current]);

    return (
        <Container>
            {/* <Row justify={"space-between"} style={{ marginBottom: 20 }}>
                <p></p>
                <DatePicker.RangePicker
                    placeholder={["Từ ngày", "Đến ngày"]}
                    format={"DD-MM-YYYY"}
                    onChange={(date: any, dateStrings: string[]) => {
                        setDateRange({
                            fromDate: dateStrings[0]?.split("-").reverse().join("-"),
                            toDate: dateStrings[1]?.split("-").reverse().join("-"),
                        });
                    }}
                />
            </Row> */}
            <Table
                id="top-table"
                style={{ width: "100%" }}
                bordered
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: "Chưa có bản ghi nào!",
                }}
                pagination={{
                    ...paging,
                    showSizeChanger: false,
                    onChange: (page) => {
                        setPaging({ ...paging, current: page });
                    },
                }}
            />
        </Container>
    );
};

const Container = styled.div``;

export default Cart;
