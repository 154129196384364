import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IOrderHistories, IOrderItems } from "../../../api/order-product/Interfaces";
import { changeStatusOrderAPI, getOrderDetailAPI } from "../../../api/order-product/OrderProduct";
import { openNotification } from "../../../commons/components/notifycation/Notification";

export interface IInitState {
    code: string;
    status: number;
    countItem: number;
    totalMoney: number;
    createAt: string;
    receiveAddress: { [key: string]: string };
    customerName: string;
    customerPhone: string;
    customerAddress: string;
    customerProvinceName: string;
    note: string;
    orderHistories: IOrderHistories[];
    orderItems: IOrderItems[];
    loadingGetDetailOrder: boolean;
    loadingStatusCancel: boolean;
    loadingStatusConfirm: boolean;
    loadingStatusNotContact: boolean;
    loadingDelivering: boolean;
    loadingComplete: boolean;
    userId: number;
    avatar: string;
    point: number;
    cancelReason: string;
}

const initialState: any = {
    code: "",
    status: 4,
    countItem: 0,
    totalMoney: 0,
    createAt: "",
    receiveAddress: {},
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    customerProvinceName: "",
    orderHistories: [],
    orderItems: [],
    note: "",
    userId: 0,
    avatar: "",
    loadingGetDetailOrder: false,
    loadingStatusCancel: false,
    loadingStatusConfirm: false,
    loadingDelivering: false,
    loadingComplete: false,
    loadingStatusNotContact: false,
    deliveryFee: 0,
    discount: 0,
    discountShip: 0,
    point: 0,
    cancelReason: "",
};

export const getOrderDetailAction = createAsyncThunk(
    "orderProductDetail/getOrderDetailAction",
    async (arg: { id: number }, thunkAPI) => {
        const rs = await getOrderDetailAPI(arg.id);
        if (rs.body.status === 1) {
            console.log("🚀 ~ file: OrderProductDetail.ts:67 ~ rs.body.data:", rs.body.data);
            return rs.body.data;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

export const changeStatusOrderAction = createAsyncThunk(
    "orderProductDetail/changeStatusOrderAction",
    async (arg: { id: number; status: number; note?: string }, thunkAPI) => {
        const rs = await changeStatusOrderAPI([arg.id], arg.status, arg.note);
        if (rs.body.status === 1) {
            return rs.body.message;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

const orderProductDetailSlice = createSlice({
    name: "orderProductDetail",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOrderDetailAction.pending, (state) => {
                state.loadingGetDetailOrder = true;
            })
            .addCase(getOrderDetailAction.rejected, (state, action) => {
                state.loadingGetDetailOrder = false;
                openNotification("ERROR", action.error.message as string);
            })
            .addCase(getOrderDetailAction.fulfilled, (state, action) => {
                state.loadingGetDetailOrder = false;
                const {
                    orderItems,
                    code,
                    countItem,
                    status,
                    orderHistories,
                    totalPrice,
                    receiveAddress,
                    customerAddress,
                    customerPhone,
                    customerName,
                    note,
                    customerProvinceName,
                    createAt,
                    userId,
                    deliveryFee,
                    customerProfileUrl,
                    discount,
                    discountShip,
                    totalMoney,
                    totalWeight,
                    point,
                    cancelReason,
                } = action.payload;
                state.code = code;
                state.cancelReason = cancelReason;
                state.point = point;
                state.status = status;
                state.countItem = countItem;
                state.totalPrice = totalPrice;
                state.totalMoney = totalMoney;
                state.createAt = createAt;
                state.receiveAddress = receiveAddress;
                state.customerName = customerName;
                state.customerPhone = customerPhone;
                state.orderItems = orderItems;
                state.orderHistories = orderHistories;
                state.customerAddress = customerAddress;
                state.customerProvinceName = customerProvinceName;
                state.note = note;
                state.userId = userId;
                state.avatar = customerProfileUrl;
                state.deliveryFee = deliveryFee;
                state.discount = discount;
                state.totalWeight = totalWeight;
                state.discountShip = discountShip;
            })

            .addCase(changeStatusOrderAction.pending, (state, action) => {
                if (action.meta.arg.status === -1) {
                    state.loadingStatusCancel = true;
                } else if (action.meta.arg.status === 2) {
                    state.loadingDelivering = true;
                } else if (action.meta.arg.status === 3) {
                    state.loadingComplete = true;
                } else if (action.meta.arg.status === 4) {
                    state.loadingStatusNotContact = true;
                } else {
                    state.loadingStatusConfirm = true;
                }
            })
            .addCase(changeStatusOrderAction.rejected, (state, action) => {
                if (action.meta.arg.status === -1) {
                    state.loadingStatusCancel = false;
                } else if (action.meta.arg.status === 2) {
                    state.loadingDelivering = false;
                } else if (action.meta.arg.status === 3) {
                    state.loadingComplete = false;
                } else if (action.meta.arg.status === 4) {
                    state.loadingStatusNotContact = false;
                } else {
                    state.loadingStatusConfirm = false;
                }
                openNotification("ERROR", action.error.message as string);
            })
            .addCase(changeStatusOrderAction.fulfilled, (state, action) => {
                if (action.meta.arg.status === -1) {
                    state.loadingStatusCancel = false;
                } else if (action.meta.arg.status === 2) {
                    state.loadingDelivering = false;
                } else if (action.meta.arg.status === 3) {
                    state.loadingComplete = false;
                } else if (action.meta.arg.status === 4) {
                    state.loadingStatusNotContact = false;
                } else {
                    state.loadingStatusConfirm = false;
                }
                openNotification("SUCCESS", action.payload);
            });
    },
});

export default orderProductDetailSlice.reducer;
