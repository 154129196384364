import AxiosClient from "api/axios/AxiosClient";

export const walletService = {
    exportExcel: (payload: any) => {
        return AxiosClient.get("withdrawal-request/export-excel", { params: payload });
    },
    getData: (payload: any) => {
        return AxiosClient.get("withdrawal-request", { params: payload });
    },
    getDetail: (id: number, payload: any) => {
        return AxiosClient.get(`withdrawal-request/${id}/history`, { params: payload });
    },
    requestWithdraw: (payload: any) => {
        return AxiosClient.post(`withdrawal-request`, payload);
    },
};
