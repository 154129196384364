import React, { useEffect, useState } from "react";
import { getRequest, IApiResponse, postRequest, putRequest } from "../../service/Basevices";
import io, { Socket } from "socket.io-client";
import { openNotification } from "../../commons/components/notifycation/Notification";

const getRandomString = (length: number): string => {
  var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result: string = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
};


const register = (userName: string): Promise<IApiResponse<any>> => {
  const reqData = {
    name: getRandomString(10),
    phone: userName,
    password: userName,
    role: 3,
  };
  return postRequest(`/users/register`, false, reqData);
};

const loginApp = (userName: string) => {
  const reqData = {
    user_name: userName,
    password: userName,
    device_id: "string",
  };
  return putRequest(`/users/login`, false, reqData);
};

const checkAccount = (userName: string): Promise<IApiResponse<any>> => {
  return postRequest(`/users/check-account`, false, { user_name: userName });
};

const getLive = (): Promise<IApiResponse<any>> => {
  return getRequest(`/livestream/join_room`);
};

const SpamChild: React.FC<{ index: number, isSpamReaction?: boolean }> = ({ index, isSpamReaction }) => {
  const socketRef = React.useRef<Socket>();
  const [isConnected, setConnected] = React.useState(false);
  const [status, setStatus] = React.useState({ name: "", color: "black" });

  const [userInfo, setUserInfo] = React.useState<{ liveStreamId?: string; userId?: number }>({});
  const searchParams = new URLSearchParams(window.location.search);
  const timeInterval: number = Number(searchParams.get("time-interval") as string) || 1000;
  const phoneNumberURL: number = Number(searchParams.get("phone-number") as string) || 1234567899;
  const linkSocket: string | null = searchParams.get("link-socket");

  const viewCountRef = React.useRef(0);
  const [userName, setUserName] = useState<string>("");

  const setupSocket = () => {
    socketRef.current = io(linkSocket ? linkSocket : (process.env.REACT_APP_SOCKET_URL as string), {
      withCredentials: true,
    });
    const socket = socketRef.current;

    socket.on("server-send-reaction", () => {
      console.log("server-send-reaction");
    });

    socket.on("server-send-comment", (data) => {
      console.log("server-send-comment", data);
    });

    socket.on("server-send-view", () => {
      console.log("server-send-view");
    });
    // socket?.onAny(v => {
    //   console.log('on any', v)
    // })
    socket.on("connect", () => {
      setConnected(true);
      setStatus({ name: `connected`, color: "green" });
    });
    socket.on("error", (err) => {
      setStatus({ name: `error - ${err.message}`, color: "red" });
    });
    socket.on("reconnect", () => {
    });
    socket.on("reconnect_error", (err) => {
      setStatus({ name: `reconnect_error - ${err.message}`, color: "red" });
    });
    socket.on("reconnect_failed", () => {
      setStatus({ name: `reconnect_failed`, color: "red" });
    });
    socket.on("reconnect_attempt", () => {
    });
    socket.on("disconnect", (reason) => {
      setStatus({ name: `disconnect - ${reason}`, color: "orange" });
    });
    socket?.connect();
  };

  const spamSocket = (liveStreamId: string, userId: number, timeInterval: number = 500) => {
    console.log(liveStreamId, userId, timeInterval);
    console.log("socket", socketRef.current);
    const rndInt = randomIntFromInterval(1, 3);
    setInterval(() => {
      viewCountRef.current += 1;
      socketRef.current?.emit("client-send-comment", {
        live_stream_id: liveStreamId,
        user_id: userId,
        name: `${phoneNumberURL + index}`,
        content: `Hello ${userId} ${viewCountRef.current}`,
      });

      // const commentData = {
      //   content: `Hello ${liveStreamId}`,
      //   livestream_id: Number(liveStreamId),
      // };
      // sendCommentAPI(commentData);

      isSpamReaction && socketRef.current?.emit("client-send-reaction", {
        live_stream_id: liveStreamId,
        value: rndInt,
      });
    }, timeInterval);

    // setInterval(() => {

    //   socketRef.current?.emit("client-send-comment", {
    //     live_stream_id: liveStreamId,
    //     user_id: userId,
    //     content: `Hello ${userId}`,
    //   });

    // }, timeInterval);

    socketRef.current?.emit("client-join-live", {
      live_stream_id: liveStreamId,
    });
  };

  const checkRegister = async (): Promise<boolean> => {
    try {
      const resCheckAccount = await checkAccount(String(phoneNumberURL + index));
      if (resCheckAccount.body.status === 1 && resCheckAccount.body.type === 1) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const onSpam = async () => {
    const resCheckRegister = await checkRegister();
    if (resCheckRegister) {
      const resLogin = await loginApp(String(phoneNumberURL + index));
      if (resLogin && resLogin.body.status === 1) {
        // log
        console.log("Login success", resLogin);
        openNotification("SUCCESS", "Login success");

        localStorage.setItem("token", resLogin.body.data.token);
        const userId: number = resLogin.body.data.id;

        setUserName(resLogin.body.data.userName);

        const resLive = await getLive();
        if (resLive && resLive.body.data) {
          const liveStreamId: string = resLive.body.data.liveStreamId;
          setUserInfo({ liveStreamId, userId });
          setupSocket();
        } else {
          console.log("Has not live");
          openNotification("INFO", "Has not live");
        }
      }
    } else {
      const resRegister = await register(String(phoneNumberURL + index));
      if (resRegister && resRegister.body.status === 1) {
        // log
        console.log("register success", resRegister);
        openNotification("SUCCESS", "Register success");

        localStorage.setItem("token", resRegister.body.data.token);
        const userId: number = resRegister.body.data.id;

        setUserName(resRegister.body.data.userName);

        const resLive = await getLive();
        if (resLive && resLive.body.data) {
          const liveStreamId: string = resLive.body.data.liveStreamId;
          setUserInfo({ liveStreamId, userId });
          setupSocket();
        } else {
          console.log("Has not live");
          openNotification("INFO", "Has not live");
        }
      }
    }
  };

  React.useEffect(() => {
    if (isConnected) {
      const { liveStreamId = "", userId = 0 } = userInfo;
      setTimeout(() => {
        spamSocket(liveStreamId, userId, timeInterval);
      }, 1000);
    }
  }, [isConnected, userInfo]);

  useEffect(() => {
    onSpam();
    return () => {
      if (socketRef.current) {
        socketRef.current?.emit("client-leave-live", { live_stream_id: "" });
      }
    };
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <div>
        {index} - User <span style={{ fontWeight: "bold" }}>{userName}</span> {" - "}
        <span style={{ fontWeight: "bold", color: status.color }}>{status.name}</span>
      </div>
    </div>
  );
};

const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const Spam = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const countUser: number = Number(searchParams.get("count-user") as string) || 1;
  const countSpamReaction: number = Number(searchParams.get("count-spam-reaction")) || 10

  const convertNumberToArr = (): any[] => {
    let rs: any[] = [];
    for (let i = 0; i < countUser; i++) {
      rs.push(i);
    }
    return rs;
  };

  return (
    <div>
      {convertNumberToArr().map((value: any, index) => (
        <SpamChild index={index} key={`${index}`} isSpamReaction={index < countSpamReaction ? true : false} />
      ))}
    </div>
  );
};

export default Spam;
