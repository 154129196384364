import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { openNotification } from "commons/components/notifycation/Notification";
import LocalStorage from "./LocalStorage";

const AxiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL as string,
    headers: {
        "content-type": "application/json",
    },
});

// const navigate = useNavigate();

// handle request to convert all api requests to snake_case
AxiosClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = LocalStorage.getToken();

    const newConfig = { ...config };
    if (token && newConfig.headers) {
        newConfig.headers.token = `${token}`;
    }

    if (newConfig.headers && newConfig.headers["Content-Type"] === "multipart/form-data") return newConfig;

    // // convert request to snake_case
    // if (config.params) {
    //     newConfig.params = config.params;
    // }
    // if (config.data) {
    //     newConfig.data = config.data;
    // }

    return newConfig;
});

// handle response to convert all api responses to camelCase
AxiosClient.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response && response.data) {
            if (
                !response.data.status ||
                response.data.code === 400 ||
                response.data.code === 403 ||
                response.data.code === 10 ||
                response.data.code === 2
            ) {
                switch (response.data.code) {
                    // case 2:
                    // case 10:
                    //     openNotification("ERROR", response?.data?.message);
                    //     setTimeout(() => {
                    //         LocalStorage.removeToken();
                    //         window.location.reload();
                    //     }, 500);
                    case 400:
                        // handle ERROR
                        break;
                    case 403:
                        // handle ERROR
                        break;
                    default:
                        openNotification("ERROR", response?.data?.message);
                        break;
                }
            }
            // cover response to camelCase
            return response.data;
        }

        return response;
    },
    (error: any) => {
        // Handle ERRORs
        error?.response?.data?.message && openNotification("ERROR", error?.response?.data?.message);
        return error;
    }
);

export default AxiosClient;
