import { Checkbox } from "antd";
import { changeBuyNegative } from "app/slices/add-update-product/AddAndUpdateProduct";
import React from "react";
import { useDispatch } from "react-redux";

interface IBuyNegativeCheckbox {
    value: any;
}

const BuyNegativeCheckbox = (props: IBuyNegativeCheckbox) => {
    const { value } = props;
    const dispatch = useDispatch();
    return (
        <Checkbox
            checked={value?.value === 1 ? true : false}
            onChange={(event) =>
                dispatch(
                    changeBuyNegative({
                        key: value.id,
                        allow_order_when_out_stock: event.target.checked ? 1 : 0,
                    })
                )
            }
        />
    );
};

export default BuyNegativeCheckbox;
