import { IDataSourceCustomer, IDataSourcePrice } from "../../app/slices/product-detail/ProductDetail";
import { IColumn } from "../../service/Interfaces";
import { IItemProductCustomAttributes, IProductPrices, IResGetListCustomerItem } from "../../api/product/Interface";
import AxiosClient from "api/axios/AxiosClient";

export const convertResListCustomerToDataSource = (data: IResGetListCustomerItem[]): IDataSourceCustomer[] => {
    let rs: IDataSourceCustomer[] = [];
    data.forEach((value, index) => {
        rs.push({
            key: value.id,
            STT: index + 1,
            customerName: value.name,
            phoneNumber: value.phone,
            quantity: value.quantity,
            revenue: value.quantity,
        });
    });
    return rs;
};

export const getDataSourcePrice = (data: IProductPrices[]): IDataSourcePrice[] => {
    let rs: IDataSourcePrice[] = [];
    data.forEach((value, index) => {
        rs.push({
            key: value.id,
            STT: index + 1,
            col2: value.customAttributeOptionId1Name,
            col3: value.customAttributeOptionId2Name,
            col4: value.price,
            col5: { id: value.id, isCheck: value.status },
        });
    });
    return rs;
};

export const getColumnPrice = (data: IItemProductCustomAttributes[]): IColumn[] => {
    let rs: IColumn[] = [];
    if (data[0]) {
        rs.push({
            title: data[0].name,
            key: "col2",
            dataIndex: "col2",
            render: (value) => value,
        });
    }
    if (data[1]) {
        rs.push({
            title: data[1].name,
            key: "col3",
            dataIndex: "col3",
            render: (value) => value,
        });
    }
    return rs;
};

export const exportExcel = (payload: any) => {
    return AxiosClient.get("product/excel", { params: payload });
};
