import AxiosClient from "api/axios/AxiosClient";

export const flashsaleService = {
    getFlashsales: (payload: any) => {
        return AxiosClient.get("flash-sale/list-web", { params: payload });
    },
    getDetailFlashsale: (id: number) => {
        return AxiosClient.get(`flash-sale/${id}`);
    },
    getParentCategories: (payload: any) => {
        return AxiosClient.get(`category/list`, { params: payload });
    },
    deleteFlashSale: (id: number) => {
        return AxiosClient.delete(`flash-sale/${id}`);
    },
    addFlashSale: (payload: any) => {
        return AxiosClient.post(`flash-sale`, payload);
    },
    updateFlashSale: (id: number, payload: any) => {
        return AxiosClient.put(`flash-sale/${id}`, payload);
    },
    getFlashSaleDetail: (id: number) => {
        return AxiosClient.get(`flash-sale/${id}`);
    },
};
