import { getRequest, IApiResponse } from "../../service/Basevices";
import { IResGetListReportCustomerAPI, IResGetListReportLivestream, IResGetListReportProductAPI } from "./Interface";
import { Config } from "../../commons/validation/ValidationPattern";

export const getListReportProductAPI = (page: number, search: string, startDate: string, endDate: string, isExport?: boolean): Promise<IApiResponse<IResGetListReportProductAPI>> => {
  let path: string = `/report/list-product?page=${page}&limit=${isExport ? 1000 : Config.limit}`;
  if (search.trim()) path = path + `&search=${search}`;
  if (startDate) path = path + `&start_date=${startDate}`;
  if (endDate) path = path + `&end_date=${endDate}`;

  return getRequest(path, true);
};

export const getListReportCustomerAPI = (page: number, search: string, startDate: string, endDate: string, isExport?: boolean): Promise<IApiResponse<IResGetListReportCustomerAPI>> => {
  let path: string = `/report/list-customer?page=${page}&limit=${isExport ? 1000 : Config.limit}`;
  if (search.trim()) path = path + `&search=${search}`;
  if (startDate) path = path + `&start_date=${startDate}`;
  if (endDate) path = path + `&end_date=${endDate}`;

  return getRequest(path, true);
};

export const getListReportLivestreamAPI = (page: number, search: string, startDate: string, endDate: string, limit?: number): Promise<IApiResponse<IResGetListReportLivestream>> => {
  let path: string = `/report/list-livestream?page=${page}&limit=${limit ? limit : Config.limit}`;
  search && (path = path + `&search=${search}`);
  startDate && (path = path + `&start_date=${startDate}`);
  endDate && (path = path + `&end_date=${endDate}`);
  return getRequest(path, true);
};