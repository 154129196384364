import { IResCate } from "../../../api/product-category/Interface";
import { IDateTableCateChild, IDateTableProductCate } from "../../../modules/product-category/interfaces";
import { Config } from "../../../commons/validation/ValidationPattern";

export const convertDataToListCate = (data: IResCate[], page: number): IDateTableProductCate[] => {
  let rs: IDateTableProductCate[] = [];
  data.forEach((value, index) => {
    rs.push({
      key: value.id,
      STT: (index + 1) + (page * Config.limit) - Config.limit,
      index: value.displayOrder,
      date: value.createAt,
      status: value.status,
      nameCate: value.name,
      icon: value.icon,
      cateChild: value.countChild,
      iconName: value.iconName,
    });
  });
  return rs;
};

export const convertDataToListCateChild = (data: IResCate []): IDateTableCateChild [] => {
  let rs: IDateTableCateChild [] = [];
  data.forEach((value, index) => {
    rs.push({
      key: value.id,
      STT: index + 1,
      index: value.displayOrder,
      date: value.createAt,
      status: value.status,
      nameCate: value.name,
      icon: value.icon,
      iconName: value.iconName,
      feature: {
        key: value.id,
        STT: index + 1,
        index: value.displayOrder,
        date: value.createAt,
        status: value.status,
        nameCate: value.name,
        icon: value.icon,
        iconName: value.iconName,
      },
    });
  });
  return rs;
};

export const deleteCate = (id: any, listCate: IDateTableProductCate []): IDateTableProductCate [] => {
  const rs = listCate.filter(value => value.key !== id);
  return rs;
};

export const enableOrDisAbleCate = (id: any, listCate: any [], isCateChild?: boolean) => {
  listCate.forEach(value => {
    if (value.key === id) {
      if (value.status === 0) {
        value.status = 1;
      } else {
        value.status = 0;
      }
      if (isCateChild) {
        if (value.feature.status === 0) {
          value.feature.status = 1;
        } else {
          value.feature.status = 0;
        }
      }
    }
  });
};

export const updateCateChild = (data: IResCate, listCateChild: IDateTableCateChild []) => {
  listCateChild.forEach((value, index) => {
    if (value.key === data.id) {
      value.STT = index + 1;
      value.status = data.status;
      value.icon = data.icon;
      value.nameCate = data.name;
      value.date = data.createAt;
      value.index = data.displayOrder;
      value.iconName = data.iconName;
      value.feature = {
        STT: index + 1,
        key: data.id,
        index: data.displayOrder,
        date: data.createAt,
        status: data.status,
        nameCate: data.name,
        icon: data.icon,
        iconName: data.iconName,
      };
    }
    return false;
  });
};
