import { Button, Checkbox, Col, Collapse, Divider, Form, Input, InputNumber, Row, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    changeDataInfoPublicProAction,
    changePriceAndStatusProAction,
    changeValueTypeProChildAction,
    createTypeProAction,
    createTypeProChildAction,
    destroyTypeProAction,
    destroyTypeProChildAction,
    genDataTableAction,
    setAllPriceAction,
} from "../../../../../app/slices/add-update-product/AddAndUpdateProduct";
import { CollapseExpandIcon, EmptyComponent } from "../../../../../commons/components/AppComponent";
import { ValidationPattern } from "../../../../../commons/validation/ValidationPattern";
import { IColumn } from "../../../../../service/Interfaces";
import { ITypePro } from "../../../Interfaces";
import BuyNegativeCheckbox from "./BuyNegativeCheckbox";
import WeightInput from "./WeightInput";
import StockInput from "./StockInput";
import WholesalePriceInput from "./WholesalePriceInput";
import WholesaleQuantityInput from "./WholesaleQuantityInput";
import SoldCountAppInput from "./SoldCountAppInput";

const RenderPrice: React.FC<{ value: any }> = ({ value }) => {
    const dispatch = useAppDispatch();
    return (
        <div>
            <InputNumber
                placeholder={"Giá bán"}
                className={"action input"}
                value={!value?.value ? "" : value?.value}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value1) => {
                    dispatch(
                        changePriceAndStatusProAction({
                            key: value.id,
                            price: value1,
                        })
                    );
                }}
                style={{ width: 150 }}
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
            />
        </div>
    );
};

const RenderCheckBox: React.FC<{ value: any }> = ({ value }) => {
    const dispatch = useAppDispatch();
    return (
        <Checkbox
            defaultChecked={value?.value === 0 ? true : false}
            onChange={(event) => {
                dispatch(
                    changePriceAndStatusProAction({
                        key: value.id,
                        price: undefined,
                        status: event.target.checked ? 0 : 1,
                    })
                );
            }}
        />
    );
};

export const defaultColumns: IColumn[] = [
    {
        title: "Giá",
        key: "col3",
        dataIndex: "col3",
        width: 100,
        render: (value) => <RenderPrice value={value} />,
    },
    {
        title: "Số lượng tồn",
        key: "stock",
        dataIndex: "stock",
        width: 100,
        render: (value) => <StockInput value={value} />,
    },
    {
        title: "Giá sỉ",
        key: "wholesale_price",
        dataIndex: "wholesale_price",
        width: 100,
        render: (value) => <WholesalePriceInput value={value} />,
    },
    {
        title: "Số lượng bán sỉ",
        key: "quantity_wholesale_price",
        dataIndex: "quantity_wholesale_price",
        width: 100,
        render: (value) => <WholesaleQuantityInput value={value} />,
    },
    {
        title: "Số lượng bán trên App",
        key: "sold_count",
        dataIndex: "sold_count",
        width: 100,
        render: (value) => <SoldCountAppInput value={value} />,
    },
    {
        title: "Trọng lượng",
        key: "weight",
        dataIndex: "weight",
        width: 100,
        render: (value) => <WeightInput value={value} />,
    },
    {
        title: "Ngừng bán",
        key: "col4",
        dataIndex: "col4",
        width: 100,
        render: (value) => <RenderCheckBox value={value} />,
    },
    {
        title: "Mua khi hết hàng",
        key: "allow_order_when_out_stock",
        dataIndex: "allow_order_when_out_stock",
        width: 300,
        render: (value) => <BuyNegativeCheckbox value={value} />,
    },
];

const InfoSaleComponent = () => {
    const [isBuyNegative, setIsBuyNegative] = React.useState<number>();

    const [formPrice] = useForm();
    const [formApplyAll] = Form.useForm();
    const [attr1Form] = Form.useForm();
    const [attr2Form] = Form.useForm();

    const dispatch = useAppDispatch();
    const isAdd: boolean = window.location.pathname === "/home/products/add-product";
    const {
        typePro,
        price,
        dataSource,
        listClassifyName,
        weight,
        stock,
        wholesale_price,
        quantity_wholesale_price,
        allow_sale,
        allow_order_when_out_stock,
        sold_count,
    } = useAppSelector((state) => state.addAndUpdateProduct);

    const onCreateTypePro = async () => {
        dispatch(createTypeProAction(null));
        dispatch(genDataTableAction());
    };
    const destroyTypePro = async (key: any, index: number) => {
        dispatch(destroyTypeProAction({ key: key }));
        dispatch(genDataTableAction());
    };
    const destroyTypeProChild = async (keyTypePro: any, keyTypeProChild: any) => {
        dispatch(destroyTypeProChildAction({ keyTypePro: keyTypePro, keyTypeProChild: keyTypeProChild }));
        dispatch(genDataTableAction());
    };
    const createTypeProChild = (keyTypePro: any, value: string | null) => {
        dispatch(createTypeProChildAction({ keyTypePro: keyTypePro, value: value }));
        dispatch(genDataTableAction());
    };
    const changeValueTypeProChild = async (keyTypePro: any, keyTypeProChild: any, value: string, isTitle?: boolean) => {
        dispatch(
            changeValueTypeProChildAction({
                keyTypePro: keyTypePro,
                keyTypeProChild: keyTypeProChild,
                value: value,
                isTitle: isTitle,
            })
        );
        dispatch(genDataTableAction());
    };

    const setAllPrice = () => {
        dispatch(
            setAllPriceAction({
                stock: formApplyAll.getFieldValue("stock"),
                weight: formApplyAll.getFieldValue("weight"),
                wholesale_price: formApplyAll.getFieldValue("wholesale_price"),
                quantity_wholesale_price: formApplyAll.getFieldValue("quantity_wholesale_price"),
                sold_count: formApplyAll.getFieldValue("sold_count"),
                allow_order_when_out_stock: isBuyNegative ? 1 : 0,
                price: formApplyAll.getFieldValue("retail_price"),
            })
        );
        // formApplyAll.resetFields();
    };

    const renderTypeGroup = (listType: ITypePro[]) => {
        const getTypeProByKey = (key: any) => {
            return listType.filter((value) => value.key === key);
        };

        return (
            <div>
                {listType.map((value, index) => {
                    return (
                        <div style={{ position: "relative" }} className={"type-group"} key={value.key}>
                            <b>Nhóm phân loại {index + 1}</b>
                            <Form
                                form={index === 0 ? attr1Form : attr2Form}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                style={{ marginTop: 30 }}
                            >
                                <Form.Item
                                    label={"Tên nhóm"}
                                    rules={[
                                        {
                                            pattern: ValidationPattern.classifyName,
                                            message: "Tên nhóm không quá 50 kí tự không bao gồm kí tự đặc biệt!",
                                        },
                                        {
                                            required: true,
                                            message: "Vui lòng nhập tên nhóm!",
                                        },
                                    ]}
                                >
                                    <Input
                                        defaultValue={
                                            !isAdd && listClassifyName.length > 0 && listClassifyName[index]
                                                ? listClassifyName[index].value
                                                : ""
                                        }
                                        placeholder="Nhập tên nhóm"
                                        onChange={(event) =>
                                            changeValueTypeProChild(value.key, null, event.target.value.trim(), true)
                                        }
                                    />
                                </Form.Item>
                                {getTypeProByKey(value.key)[0].typeItem.map((valueItem: any, index: number) => {
                                    return (
                                        <Form.Item
                                            name={`${valueItem.key}`}
                                            key={valueItem.key}
                                            label={`Thuộc tính ${index + 1}`}
                                            rules={[
                                                {
                                                    pattern: ValidationPattern.classifyName,
                                                    message: "Tên thuộc tính không quá 50 kí tự",
                                                },
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập tên thuộc tính.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                value={valueItem.value}
                                                defaultValue={valueItem.value}
                                                onChange={(event) =>
                                                    changeValueTypeProChild(
                                                        value.key,
                                                        valueItem.key,
                                                        event.target.value.trim()
                                                    )
                                                }
                                                placeholder="Nhập tên thuộc tính"
                                                suffix={
                                                    getTypeProByKey(value.key)[0].typeItem?.length > 1 ? (
                                                        <i
                                                            style={{ cursor: "pointer" }}
                                                            className="fal fa-trash-alt"
                                                            onClick={() =>
                                                                destroyTypeProChild(value.key, valueItem.key)
                                                            }
                                                        />
                                                    ) : undefined
                                                }
                                            />
                                        </Form.Item>
                                    );
                                })}
                                <Row justify={"end"}>
                                    <Form.Item>
                                        {getTypeProByKey(value.key)[0].typeItem.length < 10 ? (
                                            <Button
                                                onClick={() => createTypeProChild(value.key, null)}
                                                icon={<i className="fal fa-plus-circle" style={{ marginRight: 8 }} />}
                                            >
                                                Thêm thuộc tính
                                            </Button>
                                        ) : null}
                                    </Form.Item>
                                </Row>
                                <div
                                    style={{ position: "absolute", top: 20, right: 20, cursor: "pointer" }}
                                    onClick={() => destroyTypePro(value.key, index)}
                                >
                                    <i className="fal fa-times" />
                                </div>
                            </Form>
                        </div>
                    );
                })}
            </div>
        );
    };

    useEffect(() => {
        if (!isAdd) {
            formPrice.setFieldsValue({ price, weight, stock, quantity_wholesale_price, wholesale_price, sold_count });
        }

        if (dataSource?.length === 0) {
            formApplyAll.resetFields();
        }
    }, []);

    return (
        <div className={"add-product__info-sale style-box wrapper-collapse"}>
            <Collapse
                bordered={false}
                expandIconPosition={"right"}
                defaultActiveKey={["1"]}
                expandIcon={(panelProps) => (
                    <div>
                        <CollapseExpandIcon isActive={panelProps.isActive as boolean} />
                    </div>
                )}
            >
                <Collapse.Panel key={"1"} header={"Thông tin bán hàng"}>
                    <div className={"add-product__info-sale__child style-box"}>
                        <div>
                            {renderTypeGroup(typePro)}
                            <div className={"make-type-product"}>
                                {typePro.length === 0 && (
                                    <div>
                                        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={formPrice}>
                                            <Row justify={"space-between"} gutter={[16, 16]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={"Giá bán"}
                                                        name={"price"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "",
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            placeholder={"Nhập giá bán"}
                                                            className={"action input"}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            value={price === null ? "" : price}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                            onChange={(value) =>
                                                                dispatch(
                                                                    changeDataInfoPublicProAction({ price: value })
                                                                )
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={"weight"}
                                                        label="Khối lượng"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Vui lòng nhập khối lượng sản phẩm!",
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            placeholder={"Nhập khối lượng sản phẩm"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            min={0}
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                            onChange={(value) => {
                                                                dispatch(
                                                                    changeDataInfoPublicProAction({ weight: value })
                                                                );
                                                            }}
                                                            addonAfter="gram"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify={"space-between"} gutter={[16, 16]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={"stock"}
                                                        label="Số lượng tồn kho"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Vui lòng nhập số lượng tồn kho!",
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            placeholder={"Nhập số lượng tồn kho"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            min={0}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                            onChange={(value) => {
                                                                dispatch(
                                                                    changeDataInfoPublicProAction({
                                                                        stock: value,
                                                                    })
                                                                );
                                                            }}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label={"Giá sỉ"} name={"wholesale_price"}>
                                                        <InputNumber
                                                            placeholder={"Nhập giá sỉ"}
                                                            value={price === null ? "" : price}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                            onChange={(value) => {
                                                                dispatch(
                                                                    changeDataInfoPublicProAction({
                                                                        wholesale_price: value,
                                                                    })
                                                                );
                                                            }}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item name={"quantity_wholesale_price"} label="Số lượng sỉ">
                                                        <InputNumber
                                                            placeholder={"Nhập số lượng sỉ"}
                                                            // formatter={(value) =>
                                                            //     `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            // }
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            min={0}
                                                            parser={(value: any) => value.replace(/\$\s?|(,.*)/g, "")}
                                                            onChange={(value) => {
                                                                dispatch(
                                                                    changeDataInfoPublicProAction({
                                                                        quantity_wholesale_price: value,
                                                                    })
                                                                );
                                                            }}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name={"sold_count"} label="SL đã bán trên app">
                                                        <InputNumber
                                                            value={sold_count === null ? "" : sold_count}
                                                            placeholder={"Nhập số lượng đã bán trên app"}
                                                            // formatter={(value) =>
                                                            //     `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            // }
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            parser={(value: any) => value.replace(/\$\s?|(,.*)/g, "")}
                                                            min={0}
                                                            onChange={(value) => {
                                                                console.log("🚀 ~ InfoSaleComponent ~ value:", value);
                                                                dispatch(
                                                                    changeDataInfoPublicProAction({
                                                                        sold_count: value,
                                                                    })
                                                                );
                                                            }}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={7}>
                                                    <Row justify={"space-between"}>
                                                        <Form.Item name={"allow_sale"} label="" noStyle>
                                                            <Checkbox
                                                                onChange={(e: any) =>
                                                                    dispatch(
                                                                        changeDataInfoPublicProAction({
                                                                            allow_sale: e.target.checked ? 0 : 1,
                                                                        })
                                                                    )
                                                                }
                                                                defaultChecked={allow_sale === 0}
                                                            >
                                                                Ngừng bán
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item name={"allow_order_when_out_stock"} label="" noStyle>
                                                            <Checkbox
                                                                onChange={(e: any) =>
                                                                    dispatch(
                                                                        changeDataInfoPublicProAction({
                                                                            allow_order_when_out_stock: e.target.checked
                                                                                ? 1
                                                                                : 0,
                                                                        })
                                                                    )
                                                                }
                                                                defaultChecked={allow_order_when_out_stock === 1}
                                                            >
                                                                Mua khi hết hàng
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                )}
                                {typePro.length <= 1 ? (
                                    <div className={"item"}>
                                        <span className={"label"}>Phân loại hàng</span>
                                        <Button className={"action btn"} onClick={onCreateTypePro}>
                                            Thêm phân loại hàng
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div
                            className={"wrapper-table style-box"}
                            style={{
                                display: typePro.length > 0 ? "block" : "none",
                            }}
                        >
                            <Divider>
                                <div className={"title"}>Thông tin bán hàng</div>
                            </Divider>
                            <Row style={{ width: "100%", marginBottom: 40 }}>
                                <Col span={24}>
                                    <Row justify={"end"} style={{ width: "100%", marginBottom: 30 }}>
                                        <Button
                                            type="primary"
                                            onClick={setAllPrice}
                                            disabled={dataSource?.length === 0}
                                        >
                                            Áp dụng cho tất cả các phân loại
                                        </Button>
                                    </Row>
                                </Col>
                                {dataSource.length > 0 && (
                                    <Col span={24}>
                                        <Form form={formApplyAll} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                                            <Row style={{ width: "100%" }}>
                                                <Col span={8}>
                                                    <Form.Item label={"Giá"} name={"retail_price"}>
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            placeholder={"Giá"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={"Giá sỉ"}
                                                        name={"wholesale_price"}
                                                        rules={[
                                                            {
                                                                type: "number",
                                                                min: 0,
                                                                max: 50000000000,
                                                                message: "Giá bán không vượt quá 50.000.000.000",
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            placeholder={"Giá sỉ"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={"SL sỉ"}
                                                        name={"quantity_wholesale_price"}
                                                        rules={[
                                                            {
                                                                type: "number",
                                                                min: 0,
                                                                max: 50000000000,
                                                                message: "Giá bán không vượt quá 50.000.000.000",
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            placeholder={"Số lượng sỉ"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{ width: "100%" }}>
                                                <Col span={8}>
                                                    <Form.Item label={"Khối lượng"} name={"weight"}>
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            placeholder={"Khối lượng"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                            addonAfter="gam"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={"Tồn kho"} name={"stock"}>
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            placeholder={"Tồn kho"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={"SL đã bán trên app"} name={"sold_count"}>
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            placeholder={"Nhập số lượng đã bán trên app"}
                                                            formatter={(value) =>
                                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={8}>
                                                    <Checkbox
                                                        style={{ marginLeft: 30 }}
                                                        onChange={(e: any) => {
                                                            setIsBuyNegative(e.target.checked);
                                                        }}
                                                    >
                                                        Mua khi hết hàng
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                )}
                            </Row>
                            <Table
                                bordered
                                locale={{
                                    emptyText: <EmptyComponent />,
                                }}
                                scroll={{
                                    x: 1300,
                                    scrollToFirstRowOnChange: true,
                                }}
                                columns={useAppSelector((state) => state.addAndUpdateProduct.columns)}
                                dataSource={dataSource}
                            />
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default InfoSaleComponent;
