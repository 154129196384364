import React from "react";

const AppTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div style={{ display: "inline-block" }}>
      <div style={{ fontSize: 16, fontWeight: "bold" }}>{title}</div>
      <div style={{ backgroundColor: "red", height: 3 }} />
    </div>
  );
};

export default AppTitle;
