import { Col, DatePicker, Input, Row, Table } from "antd";
import React from "react";
import styled from "styled-components";
import Icon from "@ant-design/icons";
import useDebounce from "utils/useDebounce";
import { formatNumber, momentToStringDate, scrollTo } from "utils";
import { saleService } from "../service";

interface ICustomerList {
    saleId: number;
}

const CustomerList = (props: ICustomerList) => {
    const { saleId } = props;
    const [search, setSearch] = React.useState<string>("");
    const [data, setData] = React.useState<any[]>([]);
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: "",
        toDate: "",
    });
    const searchDebounce = useDebounce(search, 300);
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Tên khách hàng</b>,
            dataIndex: "name",
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: "phone",
        },
        {
            title: <b>Hoa hồng</b>,
            dataIndex: "commision",
            render: (value: number) => formatNumber(value || 0),
        },
        {
            title: <b>Đơn hàng</b>,
            dataIndex: "count_orders",
            render: (value: number) => formatNumber(value || 0),
        },
        {
            title: <b>Ngày giới thiệu</b>,
            dataIndex: "create_at",
            render: (value: string) => momentToStringDate(value),
        },
    ];

    const onGetCustomersOfSale = async () => {
        try {
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce,
                from_date: dateRange.fromDate,
                to_date: dateRange.toDate,
            };
            const res: any = await saleService.getCustomersOfSale(saleId, payload);
            if (res?.status) {
                setData(res?.data);
                setPaging({ ...paging, current: res?.paging?.page, total: res?.paging?.totalItemCount });
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetCustomersOfSale();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [searchDebounce, dateRange]);

    React.useEffect(() => {
        if (saleId) {
            onGetCustomersOfSale();
        }
    }, [paging.current]);

    return (
        <Container>
            <Row justify={"end"} gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5} span={5}>
                    <Input.Search
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Tên hoặc số điện thoại sale"
                        addonAfter={<Icon type="close-circle-o" rev={undefined} />}
                        value={search}
                        onChange={(e: any) => {
                            setSearch(e.target.value);
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5}>
                    <DatePicker.RangePicker
                        style={{ width: "100%" }}
                        onChange={(date: any, dateStrings: string[]) => {
                            setDateRange({
                                fromDate: dateStrings[0]?.split("-").reverse().join("-"),
                                toDate: dateStrings[1]?.split("-").reverse().join("-"),
                            });
                        }}
                        placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                        format={"DD-MM-YYYY"}
                    />
                </Col>
            </Row>
            <br />
            <Table
                id="top-table"
                style={{ width: "100%" }}
                bordered
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: "Chưa có bản ghi nào!",
                }}
                pagination={{
                    ...paging,
                    showSizeChanger: false,
                    onChange: (page) => {
                        setPaging({ ...paging, current: page });
                        setTimeout(() => {
                            scrollTo("start");
                        }, 500);
                    },
                }}
            />
        </Container>
    );
};

const Container = styled.div``;

export default CustomerList;
