import { createSlice } from "@reduxjs/toolkit";
import { IRoom } from "../../../modules/chat/Chat";

export interface IInitState {
    room: IRoom;
}

const initialState: IInitState = {
    room: {
        id: 0,
        name: "",
        phoneNumber: "",
        avatar: "",
        isReadMsg: 0,
        isOnline: 0,
        lastMsg: { msg: "", createdAt: "" },
        deviceId: "",
        isFocus: 0,
        timeStamp: 0,
    },
};

const chatSlice = createSlice({
    name: "chatLice",
    initialState,
    reducers: {
        setRoomAction: (state, action) => {
            state.room = action.payload.room;
        },
    },
});

export const { setRoomAction } = chatSlice.actions;

export default chatSlice.reducer;
