import Icon from "@ant-design/icons";
import { Col, DatePicker, Input, Row, Select } from "antd";
import moment from "moment";
import React, { memo } from "react";

interface IFilter {
    search: string;
    setNewsType: React.Dispatch<React.SetStateAction<number | undefined>>;
    setsSaveStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
    setDateRange: React.Dispatch<any>;
    setStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Filter = (props: IFilter) => {
    const { search, setSearch, setDateRange, setStatus, setsSaveStatus, setNewsType } = props;

    const disabledDate = (current: any) => {
        return current && current > moment().endOf("day");
    };
    return (
        <Row gutter={[18, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={6} span={6}>
                <Input.Search
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Tiêu đề bài viết"
                    addonAfter={<Icon type="close-circle-o" rev={undefined} />}
                    value={search}
                    onChange={(e: any) => {
                        setSearch(e.target.value);
                    }}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={4} span={4}>
                <Select
                    style={{ width: "100%" }}
                    allowClear
                    showArrow
                    showSearch
                    placeholder="Loại tin tức"
                    onChange={(value: any) => setNewsType(value)}
                >
                    <Select.Option value={1}>Banner</Select.Option>
                    <Select.Option value={2}>Chính sách</Select.Option>
                    <Select.Option value={3}>Tin tức</Select.Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} span={4}>
                <Select
                    style={{ width: "100%" }}
                    allowClear
                    showArrow
                    showSearch
                    placeholder="Trạng thái"
                    onChange={(value: any) => setsSaveStatus(value)}
                >
                    <Select.Option value={0}>Lưu nháp</Select.Option>
                    <Select.Option value={1}>Đăng bài</Select.Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} span={4}>
                <Select
                    style={{ width: "100%" }}
                    allowClear
                    showArrow
                    showSearch
                    placeholder="Trạng thái hoạt động"
                    onChange={(value: any) => setStatus(value)}
                >
                    <Select.Option value={1}>Đangg hoạt động</Select.Option>
                    <Select.Option value={0}>Ngừng hoạt động</Select.Option>
                </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5}>
                <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    onChange={(date: any, dateStrings: string[]) => {
                        setDateRange({
                            fromDate: dateStrings[0]?.split("-").reverse().join("-"),
                            toDate: dateStrings[1]?.split("-").reverse().join("-"),
                        });
                    }}
                    placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                    format={"DD-MM-YYYY"}
                    disabledDate={disabledDate}
                />
            </Col>
        </Row>
    );
};

export default memo(Filter);
