import React from "react";
import styled from "styled-components";

const LoadingComponent = () => {
  return (
    <Style>
      <i className="fas fa-spinner fa-pulse"/>
    </Style>
  );
};

export default LoadingComponent;

const Style = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #1890ff;
`