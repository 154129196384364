import { InputNumber } from "antd";
import { setFlashSaleProducts } from "app/slices/flash-sale/FlashSaleSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

interface IFlashSalePriceInput {
    decreaseType: number | undefined;
    record: any;
    productId: any;
    discount: number | undefined;
}

const FlashSalePriceInput = (props: IFlashSalePriceInput) => {
    const { decreaseType, record, discount, productId } = props;
    const dispatch = useDispatch();
    const flashSaleProducts = useSelector((state: any) => state?.flashSale?.data);

    const [value, setValue] = React.useState<number>();

    const onChange = (value: any) => {
        setValue(value);
        const newList = flashSaleProducts?.map((item: any) => {
            if (item?.id === record?.id) {
                return {
                    ...item,
                    price_sale: decreaseType === 2 && discount ? item?.price - discount * 0.01 * item?.price : value,
                };
            }
            return item;
        });
        dispatch(setFlashSaleProducts(newList));
    };

    React.useEffect(() => {
        // Khi thêm mới
        if (!productId) {
            if (decreaseType === 2 && discount) {
                setValue(record?.price - record?.price * 0.01 * discount);
            } else {
                setValue(0);
            }
        } else {
            if (decreaseType === 2 && discount) {
                setValue(record?.price - record?.price * 0.01 * discount);
            } else {
                setValue(record?.sale_price || 0);
            }
        }
    }, [decreaseType, discount]);

    return (
        <InputNumber
            disabled={decreaseType === 2 || !decreaseType}
            style={{ width: "100%" }}
            value={value}
            placeholder="Nhập giá khuyến mãi"
            min={0}
            onChange={onChange}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
    );
};

export default FlashSalePriceInput;
