import React, { useEffect, useState } from "react";
import { Empty, Select } from "antd";
import { getListCateAPI } from "../../api/product-category/ProductCategory";
import { getRequest } from "../../service/Basevices";
import { IResCate } from "../../api/product-category/Interface";
import { getListProAPI } from "../../api/product/Product";
import { IPro } from "../../api/product/Interface";

interface ISelectCateComponent {
    parentId: any;
    defaultValue?: string | null;
    logData: (cate: { id: any; name: string }) => any;
    placeholder?: string;
    style?: React.CSSProperties;
    isDisplayAll?: boolean;
    countCate?: (countCate: number) => any;
    isAllowClear?: boolean;
}

interface ISelectProvince {
    placeholder?: string;
    logger?: (data: { id: number; name: string }) => any;
}

const CollapseExpandIcon: React.FC<{ isActive: boolean }> = ({ isActive }) => {
    if (isActive) {
        return <i className="fas fa-sort-up" style={{ fontSize: 26, paddingTop: 13 }} />;
    } else return <i className="fas fa-sort-down" style={{ fontSize: 26, paddingBottom: 13 }} />;
};

const SelectCateComponent: React.FC<ISelectCateComponent> = ({
    parentId,
    defaultValue,
    logData,
    style,
    placeholder,
    isDisplayAll,
    countCate,
    isAllowClear,
}) => {
    const [list, setList] = useState<IResCate[]>([]);

    const getData = async () => {
        let rs = await getListCateAPI(1, null, 1, null, null, parentId);
        if (rs.body.status === 1) {
            setList(rs.body.data);
            countCate && countCate(rs.body.data.length);
        }
    };

    useEffect(() => {
        getData();
    }, [parentId, defaultValue]);

    return (
        <Select
            style={{ ...style, display: list.length === 0 ? "none" : "block" }}
            placeholder={placeholder ? placeholder : "DM sản phẩm"}
            onChange={(value) => {
                if (value) {
                    let rs: string = value as string;
                    let splitRs = rs.split("!-");
                    logData({ id: splitRs[0], name: splitRs[1] });
                } else {
                    logData({ id: undefined, name: "" });
                }
            }}
            value={defaultValue ? defaultValue : undefined}
            allowClear={isAllowClear}
        >
            {isDisplayAll && (
                <Select.Option value={"!-"} key={"-1"}>
                    Tất cả
                </Select.Option>
            )}
            {list.map((value) => {
                return (
                    <Select.Option value={value.id.toString() + "!-" + value.name} key={value.id}>
                        {value.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

const SelectProvince: React.FC<ISelectProvince> = ({ placeholder, logger }) => {
    const [listProvince, setListProvince] = useState<{ id: number; name: string }[]>([]);

    const getDate = async () => {
        let rs = await getRequest("/address/province", true);
        if (rs.body.status === 1) {
            setListProvince(rs.body.data);
        }
    };

    useEffect(() => {
        getDate();
    }, []);

    return (
        <div>
            <Select
                style={{ width: "100%" }}
                placeholder={placeholder}
                onChange={(value, option) => logger && logger({ id: parseInt(value as string), name: option.values })}
            >
                <Select.Option value={"all"}>Tất cả</Select.Option>
                {listProvince.map((value) => {
                    return (
                        <Select.Option key={value.id} value={value.id}>
                            {value.name}
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
};

const EmptyComponent: React.FC<{ description?: string }> = ({ description }) => {
    return <Empty description={<span>{description ? description : "Không có dữ liệu"}</span>} />;
};

const SelectProductComponent: React.FC<{
    cateParentId: number;
    cateChildId: number;
    defaultValue: string;
    logger: (product: { id: number; name: string }) => any;
}> = ({ cateParentId, cateChildId, defaultValue, logger }) => {
    const [listProduct, setListProduct] = useState<IPro[]>([]);

    const getListProduct = async (productCateId: number) => {
        const res = await getListProAPI(1, null, null, productCateId, 0, 1000);
        if (res.body.status === 1) {
            setListProduct(res.body.data);
        }
    };

    useEffect(() => {
        getListProduct(cateChildId !== -1 ? cateChildId : cateParentId);
    }, [cateParentId, cateChildId, defaultValue]);

    return (
        <div>
            <Select
                placeholder={"Chọn sản phẩm."}
                style={{ width: "100%" }}
                notFoundContent={<EmptyComponent />}
                onChange={(value: string, option: any) => {
                    logger &&
                        logger({
                            id: parseInt(value as string),
                            name: option.children,
                        });
                }}
                value={defaultValue ? defaultValue : undefined}
            >
                {listProduct.map((value) => (
                    <Select.Option key={value.id} value={value.id.toString()}>
                        {value.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export { CollapseExpandIcon, SelectCateComponent, SelectProvince, EmptyComponent, SelectProductComponent };
