import { InputNumber } from "antd";
import {
    changeSoldCountApp,
    changeWeight,
    changeWholesaleQuantity,
} from "app/slices/add-update-product/AddAndUpdateProduct";
import { useDispatch } from "react-redux";

interface ISoldCountAppInput {
    value: any;
}

const SoldCountAppInput = (props: ISoldCountAppInput) => {
    const { value } = props;
    const dispatch = useDispatch();

    return (
        <InputNumber
            placeholder={"Số lượng đã bán trên App"}
            value={!value?.value ? "" : value?.value}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(text) => {
                dispatch(
                    changeSoldCountApp({
                        key: value?.id,
                        quantity: text,
                    })
                );
            }}
            style={{ width: 150 }}
            onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
        />
    );
};

export default SoldCountAppInput;
