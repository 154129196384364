import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteAccAPI,
  getListAccAPI,
  postAccAPI,
  putChangeStatusAcc,
  putResetPassAccAPI,
  putUpdateAcc,
} from "../../../api/account/Account";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { IDataTableAccount } from "../../../modules/account/interfaces";
import { convertToListAcc, deleteAcc, enableOrDisableAcc, updateAcc } from "./Function";

interface IInitState {
  loadingListAcc: boolean;
  loadingPostAccount: boolean;
  loadingPutChangeStatus: boolean;
  loadingDeleteAccount: boolean;
  loadingResetPass: boolean;
  loadingUpdateAcc: boolean;
  listAcc: IDataTableAccount[];
  total: number;
}

const initialState: IInitState = {
  loadingListAcc: false,
  loadingPostAccount: false,
  loadingPutChangeStatus: false,
  loadingDeleteAccount: false,
  loadingResetPass: false,
  loadingUpdateAcc: false,
  listAcc: [],
  total: 0,
};

export const getListAccAction = createAsyncThunk(
  "account/getListAccAction",
  async (params: { page: number; status: number | null; search: string, role: number }, thunkAPI) => {
    const rs = await getListAccAPI(params.page, undefined, params.status, params.search, params.role);
    if (rs.body.status === 1) {
      return rs.body;
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

export const createAccAction = createAsyncThunk(
  "account/createAccAction",
  async (
    params: {
      name: string;
      email: string;
      role: number;
      password: string;
      phone: string;
      profilePictureUrl: string | null;
    },
    thunkAPI,
  ) => {
    const rs = await postAccAPI(
      params.name,
      params.email,
      params.password,
      params.phone,
      params.profilePictureUrl,
      params.role,
    );
    if (rs.body.status === 1) {
      return rs.body;
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

export const changeStatusAccAction = createAsyncThunk(
  "account/changeStatusAccAction",
  async (params: { id: any; status: number }, thunkAPI) => {
    const rs = await putChangeStatusAcc(params.id, params.status);
    if (rs.body.status === 1) {
      return { message: rs.body.message, id: params.id };
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

export const resetPassAccAction = createAsyncThunk(
  "account/putResetPassAction",
  async (params: { id: string }, thunkAPI) => {
    const rs = await putResetPassAccAPI(params.id);
    if (rs.body.status === 1) {
      return rs.body.message;
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

export const deleteAccAction = createAsyncThunk("account/deleteAccount", async (params: { id: any }, thunkAPI) => {
  const rs = await deleteAccAPI(params.id);
  if (rs.body.status === 1) {
    return { message: rs.body.message, id: params.id };
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const updateAccAction = createAsyncThunk(
  "account/updateAccAction",
  async (params: { id: any; email: string; name: string; status: number; phone: string; profilePictureUrl: string | null, role: number }, thunkAPI) => {
    const rs = await putUpdateAcc(params.id, params.email, params.name, params.status, params.phone, params.profilePictureUrl, params.role);
    if (rs.body.status === 1) {
      return { message: rs.body.message, params: params };
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListAccAction.pending, (state) => {
        state.loadingListAcc = true;
      })
      .addCase(getListAccAction.rejected, (state, action) => {
        state.loadingListAcc = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(getListAccAction.fulfilled, (state, action) => {
        state.loadingListAcc = false;
        state.listAcc = convertToListAcc(action.payload, action.meta.arg.page);
        state.total = action.payload.paging.totalItemCount;
      })

      .addCase(createAccAction.pending, (state) => {
        state.loadingPostAccount = true;
      })
      .addCase(createAccAction.rejected, (state, action) => {
        state.loadingPostAccount = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(createAccAction.fulfilled, (state, action) => {
        state.loadingPostAccount = false;
        openNotification("SUCCESS", action.payload.message as string);
      })

      .addCase(changeStatusAccAction.pending, (state) => {
        state.loadingPutChangeStatus = true;
      })
      .addCase(changeStatusAccAction.rejected, (state, action) => {
        state.loadingPutChangeStatus = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(changeStatusAccAction.fulfilled, (state, action) => {
        state.loadingPutChangeStatus = false;
        enableOrDisableAcc(action.payload.id, state.listAcc);
        openNotification("SUCCESS", action.payload.message);
      })

      .addCase(resetPassAccAction.pending, (state) => {
        state.loadingResetPass = true;
      })
      .addCase(resetPassAccAction.rejected, (state, action) => {
        state.loadingResetPass = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(resetPassAccAction.fulfilled, (state, action) => {
        state.loadingResetPass = false;
        openNotification("SUCCESS", action.payload);
      })

      .addCase(deleteAccAction.pending, (state) => {
        state.loadingDeleteAccount = true;
      })
      .addCase(deleteAccAction.rejected, (state, action) => {
        state.loadingDeleteAccount = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(deleteAccAction.fulfilled, (state, action) => {
        state.listAcc = deleteAcc(action.payload.id, state.listAcc);
        state.loadingDeleteAccount = false;
        openNotification("SUCCESS", action.payload.message);
      })

      .addCase(updateAccAction.pending, (state) => {
        state.loadingUpdateAcc = true;
      })
      .addCase(updateAccAction.rejected, (state, action) => {
        state.loadingUpdateAcc = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(updateAccAction.fulfilled, (state, action) => {
        state.loadingUpdateAcc = false;
        openNotification("SUCCESS", action.payload.message);
        const { id, email, status, name, profilePictureUrl } = action.payload.params;
        updateAcc(id, email, name, status, profilePictureUrl, state.listAcc);
      });
  },
});

export default accountSlice.reducer;
