import Container from "commons/components/container";
import React from "react";
import Filter from "../component/Filter";
import styled from "styled-components";
import { PageHeader, Popconfirm, Row, Spin, Switch, Table, Tag } from "antd";
import useDebounce from "utils/useDebounce";
import { Notification, formatNumber, momentToStringDate, scrollTo } from "utils";
import IconAntd from "commons/components/icon/IconAntd";
import { Btn } from "commons/components/btn/Button";
import AppServices from "service/AppServices";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import CustomPopConfirm from "commons/components/pop-confirm";
import { voucherService } from "../service";

const VoucherPage = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);
    const [search, setSearch] = React.useState<string>("");
    const [status, setStatus] = React.useState<number>();
    const [decreaseType, setDecreaseType] = React.useState<number>(); // Giảm theo tiền hoặc %
    const [voucherType, setVoucherType] = React.useState<number>(); // Loại voucher
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });
    const searchDebounce = useDebounce(search, 300);
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Mã voucher</b>,
            dataIndex: "code",
        },
        {
            title: <b>Tên voucher</b>,
            dataIndex: "name",
        },
        {
            title: <b>Loại voucher</b>,
            dataIndex: "type",
            render: (value: number) => {
                if (value === 1) return <Tag color={"#108d33"}>Mã giảm giá</Tag>;
                return <Tag color={"#e89c02"}>Ưu đãi vận chuyển</Tag>;
            },
        },
        {
            title: <b>Mức giảm</b>,
            dataIndex: "discount",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Số lượng</b>,
            dataIndex: "quantity",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Số lượng còn lại</b>,
            dataIndex: "quantity",
            render: (value: any, record: any) => formatNumber(value - record?.num_of_used),
        },
        {
            title: <b>Ngày bắt đầu</b>,
            dataIndex: "from_time",
            render: (value: any) => (value ? momentToStringDate(value) : "---"),
        },
        {
            title: <b>Ngày kết thúc</b>,
            dataIndex: "to_time",
            render: (value: any) => (value ? momentToStringDate(value) : "---"),
        },
        {
            title: <b>Trạng thái</b>,
            dataIndex: "status",
            render: (value: number, record: any) => (
                <Switch checked={value === 1} onChange={() => onChangeSaleStatus(record?.id)} />
            ),
        },
        {
            title: <b>Thao tác</b>,
            width: 100,
            dataIndex: "",
            render: (_: any, record: any) => {
                return (
                    <>
                        <a
                            onClick={() =>
                                AppServices.history!.push(pathMenu.add_update_voucher, { voucherId: record?.id })
                            }
                        >
                            <IconAntd style={{ margin: "0 10px" }} icon="EditOutlined" size={"18px"} />
                        </a>
                        <CustomPopConfirm
                            title="Bạn có chắc chắn muốn xoá voucher này?"
                            onConfirm={() => onDeleteVoucher(record?.id)}
                        />
                    </>
                );
            },
        },
    ];

    const onGetVouchers = async () => {
        try {
            setLoading(true);
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce,
                status,
                type: voucherType,
                type_discount: decreaseType,
                from_date: dateRange.fromDate,
                to_date: dateRange.toDate,
            };
            const res: any = await voucherService.getVouchers(payload);
            if (res?.status) {
                setData(res?.data);
                setPaging({
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onDeleteVoucher = async (id: number) => {
        try {
            setLoading(true);
            const res = await voucherService.deleteVoucher(id);
            if (res?.status) {
                Notification("success", "Xoá voucher thành công!");
                onGetVouchers();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onChangeSaleStatus = async (id: number) => {
        try {
            setLoading(true);
            const res = await voucherService.changeVoucherStatus(id);
            if (res?.status) {
                Notification("success", "Thay đổi trạng thái voucher thành công!");
                onGetVouchers();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetVouchers();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [searchDebounce, status, dateRange, voucherType, decreaseType]);

    React.useEffect(() => {
        onGetVouchers();
    }, [paging.current]);

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        title="Voucher"
                        extra={[
                            <Btn
                                value={"Thêm mới"}
                                onClick={() => AppServices.history!.push(pathMenu.add_update_voucher)}
                                backGroundColor={"#109703"}
                            />,
                        ]}
                    />
                }
                filterComponent={
                    <Filter
                        setVoucherType={setVoucherType}
                        setDecreaseType={setDecreaseType}
                        search={search}
                        setSearch={setSearch}
                        setStatus={setStatus}
                        setDateRange={setDateRange}
                    />
                }
                contentComponent={[
                    <>
                        <Row>
                            <p>
                                Kết quả lọc: <b>{paging.total}</b>
                            </p>
                        </Row>
                        <Table
                            id="top-table"
                            style={{ width: "100%" }}
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: "Chưa có bản ghi nào!",
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: (page) => {
                                    setPaging({ ...paging, current: page });
                                    setTimeout(() => {
                                        scrollTo("start");
                                    }, 500);
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    );
};

export default VoucherPage;
