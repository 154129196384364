import Icon from "@ant-design/icons";
import { Col, DatePicker, Input, Row, Select } from "antd";
import moment from "moment";
import React, { memo } from "react";

interface IFilter {
    search: string;
    setVoucherType: React.Dispatch<React.SetStateAction<number | undefined>>;
    setDecreaseType: React.Dispatch<React.SetStateAction<number | undefined>>;
    setDateRange: React.Dispatch<any>;
    setStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Filter = (props: IFilter) => {
    const { search, setSearch, setDateRange, setStatus, setVoucherType, setDecreaseType } = props;

    const disabledDate = (current: any) => {
        return current && current > moment().endOf("day");
    };
    return (
        <Row gutter={[18, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5} span={5}>
                <Input.Search
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Tên hoặc mã voucher"
                    addonAfter={<Icon type="close-circle-o" rev={undefined} />}
                    value={search}
                    onChange={(e: any) => {
                        setSearch(e.target.value);
                    }}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={5} span={5}>
                <Select
                    style={{ width: "100%" }}
                    allowClear
                    showArrow
                    showSearch
                    placeholder="Loại giảm"
                    onChange={(value: any) => setDecreaseType(value)}
                >
                    <Select.Option value={1}>Giảm theo tiền</Select.Option>
                    <Select.Option value={2}>Giảm theo %</Select.Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5} span={5}>
                <Select
                    style={{ width: "100%" }}
                    allowClear
                    showArrow
                    showSearch
                    placeholder="Loại voucher"
                    onChange={(value: any) => setVoucherType(value)}
                >
                    <Select.Option value={1}>Mã giảm giá</Select.Option>
                    <Select.Option value={2}>Ưu đãi vận chuyển</Select.Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5}>
                <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    onChange={(date: any, dateStrings: string[]) => {
                        setDateRange({
                            fromDate: dateStrings[0]?.split("-").reverse().join("-"),
                            toDate: dateStrings[1]?.split("-").reverse().join("-"),
                        });
                    }}
                    placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                    format={"DD-MM-YYYY"}
                    disabledDate={disabledDate}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={4}>
                <Select
                    style={{ width: "100%" }}
                    placeholder="Trạng thái hoạt động"
                    allowClear
                    onChange={(value: any) => setStatus(value)}
                >
                    <Select.Option value={1}>Đang hoạt động</Select.Option>
                    <Select.Option value={0}>Ngừng hoạt động</Select.Option>
                </Select>
            </Col>
        </Row>
    );
};

export default memo(Filter);
