import moment from "moment";

export const ValidationPattern = {
    pass: /^[a-zA-Z0-9!@#$%^&*]{6,}$/,
    phone: /^[0][0-9]{9,10}$/,
    number: /^[0-9]+$/,
    name: /^[\S][\s\S]{0,50}$/,
    cateName: /^[\s\S]{0,50}$/,
    username:
        /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆ fFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTu UùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ0-9]{0,50}$/,
    codePro: /^[a-zA-Z0-9]{0,25}$/,
    namePro: /^[\s\S]{0,150}$/,
    classifyName:
        /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆ fFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTu UùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ0-9]{0,50}$/,
    price: /^[0-9]+$/,
    email: /^[0-9]*@[a-z]*\.[a-z]{0,3}$/,
};

export const ValidationMes = {
    pass: "Mật khẩu tối thiểu có ít nhất 6 ký tự.",
    phone: "Số điện thoại không hợp lệ.",
    required: "Đây là trường bắt buộc.",
    passNotMatch: "Mật khẩu không khớp.",
    username: "Tên người dùng không quá 50 kí tự và không bao gồm kí tự đặc biệt.",
    cateName: "Tên danh mục không quá 50 kí tự.",
    email: "Định dạng email chưa hợp lệ.",
    number: "Định dạng số không hợp lệ",
    code: "Mã sản phẩm ít nhất 1 ký tự bao gồm kí tự chữ và số không quá 25 kí tự.",
    productName: "Tên sản phẩm không quá 150 kí tự và không được bỏ trống.",
};

let placeholder: [string, string] = ["Từ ngày", "Tới ngày"];

export const Config = {
    limit: 12,
    formatDate: "DD/MM/YYYY",
    formatTime: "HH:mm DD/MM/YYYY",
    layoutForm: { labelCol: { span: 8 }, wrapperCol: { span: 16 } },
    sizeImg: 3,
    sizeVideo: 60,
    placeholderDatePicker: placeholder,
};

export const getStartIndex = (page: number = 1): number => {
    return page * Config.limit - Config.limit;
};

export function disabledDate(current: any) {
    return current && current > moment().endOf("day");
}

export const Navigation = (id?: number, arg?: any) => {
    return {
        addProduct: `/home/products/add-product`,
        orderProductDetail: `/home/orders/detail/${id}`,
        chat: `/home/chat`,
        orderProduct: `/home/orders?page=1&search=&status=&start-date=&end-date=`,
        listLivestream: `/home/live-stream`,
    };
};
