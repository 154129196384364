import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

interface ISingleMenu {
    key: string;
    value: string;
    path: string;
    icon?: React.ReactNode;
}

interface ISubMenu {
    key: string;
    value: string;
    icon?: React.ReactNode;
    listChild: ISingleMenu[];
}

interface IGroupItem {
    key: string;
    title: string;
    listChild: ISingleMenu[];
}

interface IGroupMenu {
    key: string;
    value: string;
    icon?: React.ReactNode;
    listChild: IGroupItem[];
}

interface IDataMenu {
    type: "SINGLE_MENU" | "SUB_MENU" | "GROUP_MENU";
    item: ISingleMenu | ISubMenu | IGroupMenu;
}

export const pathMenu = {
    home: "/home",
    voucher: "/home/voucher",
    withdraw_detail: "/home/withdraw_detail",
    news: "/home/news",
    livestream_emulator: "/home/livestream_emulator",
    sale_wallet: "/home/sale_wallet",
    add_edit_news: "/home/add_edit_news",
    add_order: "/home/order/add_order",
    flash_sale_product: "/home/flash_sale_product",
    add_edit_flash_sale_product: "/home/add_edit_flash_sale_product",
    sale_staff: "/home/sale_staff",
    add_sale_staff: "/home/add_sale_staff",
    config_commission: "/home/config/commission",
    add_update_voucher: "/home/voucher/add_update",
    productCate: "/home/product-product-category?page=1&search=&status=2&start-date=&end-date=",
    product: "/home/products?page=1&search=&status=&categoryId",
    customer: "/home/customers?page=1",
    order: "/home/orders",
    liveStream: "/home/live-stream",
    chat: "/home/chat",
    notification: "/home/notification",
    reportSale: "/home/report-sale?page=1",
    reportLiveStream: "/home/report-stream",
    account: "/home/account?page=1&phone=ALL&status=2",
};

const MenuComponent: React.FC<any> = (props: any) => {
    let dataMenu: IDataMenu[] = [
        {
            type: "SINGLE_MENU",
            item: {
                key: "001",
                path: pathMenu.home,
                value: "Tổng Quan",
                icon: <i className="far fa-tachometer-alt" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "002",
                path: pathMenu.productCate,
                value: `Danh mục sản phẩm`,
                icon: <i className="fas fa-bars" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "004",
                path: pathMenu.product,
                value: "Sản phẩm",
                icon: <i className="fas fa-box-open" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "005",
                path: pathMenu.customer,
                value: "Khách hàng",
                icon: <i className="fas fa-users" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "024",
                path: pathMenu.sale_staff,
                value: "Nhân viên sale",
                icon: <i className="fas fa-user-nurse" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "025",
                path: pathMenu.flash_sale_product,
                value: "Flash sale",
                icon: <i className="fas fa-gifts" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "006",
                path: pathMenu.order,
                value: "Đơn hàng",
                icon: <i className="fas fa-cart-arrow-down" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "020",
                path: pathMenu.voucher,
                value: "Voucher",
                icon: <i className="fas fa-money-bill" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "007",
                path: pathMenu.liveStream,
                value: "Live stream",
                icon: <i className="fas fa-video" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "008",
                path: pathMenu.chat,
                value: "Chat",
                icon: <i className="fas fa-comments" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "009",
                path: pathMenu.notification,
                value: "Thêm thông báo",
                icon: <i className="fas fa-bell" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "058",
                path: pathMenu.news,
                value: "Tin tức, bài viết",
                icon: <i className="fas fa-newspaper" />,
            } as ISingleMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "096",
                path: pathMenu.sale_wallet,
                value: "Thu nhập",
                icon: <i className="fas fa-wallet" />,
            } as ISingleMenu,
        },
        {
            type: "SUB_MENU",
            item: {
                key: "010",
                value: "Báo cáo",
                icon: <i className="fas fa-chart-area" />,
                listChild: [
                    {
                        key: "010_01",
                        path: pathMenu.reportSale,
                        value: "Báo cáo bán hàng",
                    },
                    {
                        key: "010_02",
                        path: pathMenu.reportLiveStream,
                        value: "Báo cáo live stream",
                    },
                ],
            } as ISubMenu,
        },
        {
            type: "SUB_MENU",
            item: {
                key: "021",
                value: "Cấu hình",
                icon: <i className="fas fa-pen" />,
                listChild: [
                    {
                        key: "021_01",
                        path: pathMenu.config_commission,
                        value: "Hoa hồng - Tích điểm",
                    },
                ],
            } as ISubMenu,
        },
        {
            type: "SINGLE_MENU",
            item: {
                key: "011",
                path: pathMenu.account,
                value: "Tài khoản",
                icon: <i className="fas fa-user-circle" />,
            } as ISingleMenu,
        },
    ];

    let role: number = useAppSelector((state) => state.login.role);

    if (role === 4) {
        dataMenu = [
            {
                type: "SINGLE_MENU",
                item: {
                    key: "004",
                    path: pathMenu.customer,
                    value: "Khách hàng",
                    icon: <i className="fas fa-box-open" />,
                } as ISingleMenu,
            },
            {
                type: "SINGLE_MENU",
                item: {
                    key: "006",
                    path: pathMenu.order,
                    value: "Đơn hàng",
                    icon: <i className="fas fa-cart-arrow-down" />,
                } as ISingleMenu,
            },
        ];
    }

    const openKey = (dataMenu: IDataMenu[]): { openKey: string; key: string } => {
        const { pathname } = window.location;
        let result: { openKey: string; key: string } = { openKey: "", key: "" };

        dataMenu.forEach((value) => {
            const itemSingle = value.item as ISingleMenu;
            const itemSub = value.item as ISubMenu;
            const itemGroup = value.item as IGroupMenu;

            const getResult = (item: ISingleMenu): boolean => {
                if (item.path.split("?")[0] === pathname) {
                    result.key = item.key;
                    return true;
                }
                return false;
            };

            if (value.type === "SINGLE_MENU") {
                if (getResult(itemSingle)) result.openKey = itemSingle.key;
            } else if (value.type === "SUB_MENU") {
                itemSub.listChild.forEach((value1) => {
                    if (getResult(value1)) result.openKey = itemSub.key;
                });
            } else {
                itemGroup.listChild.forEach((value1) => {
                    let check: boolean = false;
                    value1.listChild.forEach((value2) => {
                        if (getResult(value2)) check = true;
                    });
                    if (check) result.openKey = itemGroup.key;
                });
            }
        });
        return result;
    };

    const renderMenuItem = (value: IDataMenu): React.ReactNode => {
        const itemSingle: ISingleMenu = value.item as ISingleMenu;
        const itemSubMenu: ISubMenu = value.item as ISubMenu;
        const itemGroup: IGroupMenu = value.item as IGroupMenu;

        const renderItemSingleMenu = (item: ISingleMenu): React.ReactNode => {
            return (
                <Menu.Item key={item.key} icon={item.icon}>
                    <Link to={item.path}>{item.value}</Link>
                </Menu.Item>
            );
        };

        if (value.type === "SINGLE_MENU") return renderItemSingleMenu(itemSingle);
        else if (value.type === "SUB_MENU")
            return (
                <Menu.SubMenu key={itemSubMenu.key} icon={itemSubMenu.icon} title={itemSubMenu.value}>
                    {itemSubMenu.listChild.map((value1) => renderItemSingleMenu(value1))}
                </Menu.SubMenu>
            );
        else
            return (
                <Menu.SubMenu key={itemGroup.key} icon={itemGroup.icon} title={itemGroup.value}>
                    {itemGroup.listChild.map((value1) => {
                        return (
                            <Menu.ItemGroup key={value1.key} title={value1.title}>
                                {value1.listChild.map((value2) => renderItemSingleMenu(value2))}
                            </Menu.ItemGroup>
                        );
                    })}
                </Menu.SubMenu>
            );
    };

    return (
        <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[openKey(dataMenu).key]}
            defaultOpenKeys={[openKey(dataMenu).openKey]}
        >
            {dataMenu.map((value) => renderMenuItem(value))}
        </Menu>
    );
};

export default MenuComponent;
