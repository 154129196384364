import React, { useEffect, useState } from "react";
import "./ProductCategory.scss";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { DatePicker, Input, Modal, Pagination, Select } from "antd";
import { FormAddCate, FormUpdateCate } from "./component/Form";
import TableProductCate from "./component/TableProductCate";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    changeStatusCateAction,
    deleteCateAction,
    getListCateAction,
} from "../../app/slices/product-category/ProductCategory";
import { useLocation } from "react-router-dom";
import AppServices from "../../service/AppServices";
import { IDateTableProductCate } from "./interfaces";
import { Config, disabledDate } from "../../commons/validation/ValidationPattern";
import { Btn } from "../../commons/components/btn/Button";
import moment from "moment";

const { RangePicker } = DatePicker;

const ProductCategory: React.FC<any> = () => {
    const dispatch = useAppDispatch();
    const [dataUpdateCate, setDataUpdateCate] = useState<IDateTableProductCate | null>(null);
    const [openModalAddCate, setOpenModalAddCate] = useState<boolean>(false);
    const [openModalUpdateCate, setOpenModalUpdateCate] = useState<boolean>(false);

    const searchParams = new URLSearchParams(useLocation().search);
    const page: number = parseInt(searchParams.get("page") as string);

    const [search, setSearch] = useState<string>("");
    const [status, setStatus] = useState<number>(2);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const [currentPage, setCurrentPage] = useState<number>(page);
    const role: number = parseInt(localStorage.getItem("role") as string);

    // FUNCTION LOGIC
    const pushParams = (
        _page?: number,
        _search?: string | null,
        _status?: number | null,
        _startDate?: string,
        _endDate?: string
    ) => {
        const pagePush: number = _page ? _page : page;
        const searchPush: string | null = _search === undefined ? search : _search;
        const statusPush: number | null = _status !== undefined ? _status : status;
        const startDatePush: string = _startDate !== undefined ? _startDate : startDate;
        const endDatePush: string = _endDate !== undefined ? _endDate : endDate;
        AppServices.history!.push(
            `/home/product-product-category?page=${pagePush}&search=${searchPush}&status=${statusPush}&start-date=${startDatePush}&end-date=${endDatePush}`
        );
    };

    const onChangePagination = (value: number) => {
        setCurrentPage(value);
        pushParams(value, undefined, 2, undefined, undefined);
        AppServices.scrollToTop();
    };

    const onChangeStatusCate = (id: any, status: number, parentId?: any) => {
        dispatch(changeStatusCateAction({ id: id, currentStatus: status, parentId }));
    };

    const onUpdateCate = (data: IDateTableProductCate) => {
        setDataUpdateCate(data);
        setOpenModalUpdateCate(true);
    };

    const onDeleteCate = async (id: any) => {
        dispatch(deleteCateAction({ id: id }));
    };

    useEffect(() => {
        const getData = () => {
            dispatch(
                getListCateAction({
                    page: page,
                    status: status === 2 ? null : status,
                    search: search,
                    toDate: endDate,
                    fromDate: startDate,
                    parentId: "",
                })
            );
        };
        getData();
    }, [page, status, search, startDate, endDate]);
    // END FUNCTION

    // MODAl CATE
    const ModalAddCate: React.FC<any> = () => {
        return (
            <div>
                <Modal
                    title={"Thêm danh mục"}
                    visible={openModalAddCate}
                    onCancel={() => setOpenModalAddCate(false)}
                    footer={false}
                >
                    <FormAddCate
                        eventClose={() => {
                            setOpenModalAddCate(false);
                        }}
                    />
                </Modal>
            </div>
        );
    };

    const ModalUpdateCate: React.FC<{ data: IDateTableProductCate }> = ({ data }) => {
        return (
            <div>
                <Modal
                    title={"Sửa danh mục"}
                    visible={openModalUpdateCate}
                    onCancel={() => setOpenModalUpdateCate(false)}
                    footer={false}
                >
                    <FormUpdateCate
                        data={dataUpdateCate as IDateTableProductCate}
                        eventClose={() => {
                            setOpenModalUpdateCate(false);
                            dispatch(
                                getListCateAction({
                                    page: page,
                                    status: status === 2 ? null : status,
                                    search: search,
                                    toDate: startDate,
                                    fromDate: endDate,
                                    parentId: "",
                                })
                            );
                        }}
                    />
                </Modal>
            </div>
        );
    };
    // END MODAL CATE

    return (
        <div className={"product-category"}>
            {/*HEADER TITLE*/}
            <TitleComponent
                title={"Danh mục sản phẩm"}
                component={
                    role !== 4 && (
                        <div>
                            <Btn
                                value={"Thêm mới"}
                                onClick={() => setOpenModalAddCate(true)}
                                backGroundColor={"#109703"}
                            />
                        </div>
                    )
                }
            />
            {/*END HEADER TITLE*/}

            {/*FILTER*/}
            <div className={"product-category__filter"}>
                <Input
                    defaultValue={search}
                    placeholder={"Nhập tên danh mục"}
                    style={{ width: 300, marginRight: 16 }}
                    onChange={(event) => setSearch(event.target.value)}
                />
                <Select
                    defaultValue={status}
                    className={"product-category__filter-select"}
                    style={{ marginTop: 8, marginBottom: 8, width: 300 }}
                    onChange={(value) => setStatus(value)}
                >
                    <Select.Option value={2}>Tất cả</Select.Option>
                    <Select.Option value={0}>Ngưng hoạt động</Select.Option>
                    <Select.Option value={1}>Đang hoạt động</Select.Option>
                </Select>
                {/* <RangePicker
          format={[Config.formatDate, Config.formatDate]}
          style={{ width: 300 }}
          defaultValue={[startDate ? moment(startDate, Config.formatDate) : null, endDate ? moment(endDate, Config.formatDate) : null]}
          placeholder={["Từ ngày", "Đến ngày"]}
          inputReadOnly={true}
          onCalendarChange={(date, formatString) => {
            pushParams(
              1,
            );
            setCurrentPage(1);
            setStartDate(formatString[0]);
            setEndDate(formatString[1]);
          }}
          disabledDate={disabledDate}
        /> */}
            </div>
            {/*END FILTER*/}

            <TableProductCate
                eventUpdate={onUpdateCate}
                eventChangeStatus={onChangeStatusCate}
                eventDelete={onDeleteCate}
            />

            <div className={"product-category__wrapper-pagination"}>
                <Pagination
                    current={currentPage}
                    total={useAppSelector((state) => state.productCategory.total)}
                    showSizeChanger={false}
                    pageSize={Config.limit}
                    onChange={onChangePagination}
                />
            </div>

            <ModalAddCate />
            <ModalUpdateCate data={dataUpdateCate as IDateTableProductCate} />
        </div>
    );
};

export default ProductCategory;
