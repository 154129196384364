import { deleteRequest, getRequest, IApiResponse, postRequest, putRequest } from "../../service/Basevices";
import { IResGetDetailLivestreamAPI, IResGetListComment, IResGetListLivestream } from "./Interfaces";
import { Config } from "../../commons/validation/ValidationPattern";
import { IResBody } from "../interface";

export const getListLivestreamAPI = (page: number, search: string, startDate: string, endDate: string, limit?: number): Promise<IApiResponse<IResGetListLivestream>> => {
  let path: string = `/livestream/list_livestream?page=${page}&limit=${limit ? limit : Config.limit}`;
  search && (path = path + `&search=${search}`);
  startDate && (path = path + `&start_date=${startDate}`);
  endDate && (path = path + `&end_date=${endDate}`);
  return getRequest(path, true);
};

export const getDetailLivestreamAPI = (id: number): Promise<IApiResponse<IResGetDetailLivestreamAPI>> => {
  let path: string = `/livestream/${id}/detail`;
  return getRequest(path, true);
};

export const getListCommentAPI = (page: number, id: number, search: string, limit?: number): Promise<IApiResponse<IResGetListComment>> => {
  let path: string = `/comment/list-comment/${id}?page=${page}&limit=${limit ? limit : Config.limit}`;
  search && (path = path + `&search=${search}`);
  return getRequest(path, true);
};

export const deleteLivestreamAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  let path: string = `/livestream/${id}/delete`;
  return deleteRequest(path, true);
};

export const putEndLiveAPI = (id: number, broadcastId: string): Promise<IApiResponse<IResBody>> => {
  return postRequest(`/youtube_livestream/live_broadcast_stop`, true, {
    live_stream_id: id,
    broadcast_id: broadcastId,
  });
};

export const putChangeLinkYoutubeAPI = (livestreamId: number, redData: { broadcast_id: string }): Promise<IApiResponse<IResBody>> => {
  return putRequest(`/livestream/${livestreamId}/edit_link`, true, redData);
};