import React, { useEffect, useState } from "react";
import "./Product.scss";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { Button, Input, Modal, Pagination, Select, Table, Tag } from "antd";
import { IColumn } from "../../service/Interfaces";
import { Link, useLocation } from "react-router-dom";
import { Config, Navigation } from "../../commons/validation/ValidationPattern";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getDataExportAction, getListProAction, IDataSourcePro, IInitState } from "../../app/slices/product/Product";
import AppServices from "../../service/AppServices";
import { EmptyComponent, SelectCateComponent } from "../../commons/components/AppComponent";
import { openNotification } from "../../commons/components/notifycation/Notification";
import { numberWithCommas } from "../../service/Format";
import moment from "moment";
import ExportCsv from "../../commons/components/export-excel/ExportCSV";
import { deleteListProductAPI } from "../../api/product/Product";
import { exportExcel } from "./Services";
import { Notification, formatNumber } from "utils";

interface IExportProduct {
    STT: number;
    "Mã sản phẩm": string;
    "Tên sản phẩm": string;
    "Danh mục": string;
    "Trạng thái hàng": string;
    "Trạng thái": string;
    "Đã bán": number;
    "Doanh số": number;
}

const getDataExport = (data: IDataSourcePro[]): IExportProduct[] => {
    let rs: IExportProduct[] = [];
    data.forEach((value) =>
        rs.push({
            STT: value.STT,
            "Mã sản phẩm": value.code as string,
            "Tên sản phẩm": value.name as string,
            "Danh mục": (value.cate.cateParent as string) + value.cate.cateChild ? "/" + value.cate.cateChild : "",
            "Trạng thái hàng": value.statusPro ? "Còn hàng" : "Hết hàng",
            "Trạng thái": value.status ? "Đang hoạt động" : "Ngưng hoạt động",
            "Đã bán": value.sold ? value.sold : 0,
            "Doanh số": value.turnover ? value.turnover : 0,
        })
    );
    return rs;
};

const Product: React.FC<any> = () => {
    const dispatch = useAppDispatch();
    const state: IInitState = useAppSelector((state) => {
        return state.product;
    });
    const role: number = parseInt(localStorage.getItem("role") as string);

    const searchParams = new URLSearchParams(useLocation().search);
    const page: number = parseInt(searchParams.get("page") as string);
    const search: string = searchParams.get("search") as string;
    const status: number | undefined = searchParams.get("status")
        ? parseInt(searchParams.get("status") as string)
        : undefined;
    const categoryId: string | null = searchParams.get("categoryId");

    const [currentPage, setCurrentPage] = useState<number>(page);
    const [currentCateId, setCurrentCateId] = useState<string | null>(categoryId);
    const [defaultValueCateChild, setDefaultValueCateChild] = useState<string>("");
    const [type, setType] = useState<any>();
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [priceOrder, setPriceOrder] = React.useState<any>();
    const [soldOrder, setSoldOrder] = React.useState<any>();

    const [rowSelect, setRowSelect] = useState<IDataSourcePro[]>([]);

    const pushParams = (_page?: number, _search?: string, _status?: number, _categoryId?: string | null) => {
        if (page) {
            let path: string = `/home/products?page=${_page ? _page : page}`;
            path = path + `&search=${_search !== undefined ? _search : search}`;
            path = path + `&status=${_status !== undefined ? _status : status ? status : ""}`;
            path = path + `&categoryId=${_categoryId !== undefined ? _categoryId : categoryId}`;
            AppServices.history!.push(path);
        }
    };

    const onUpdatePro = () => {
        if (rowSelect.length !== 1) {
            openNotification("INFO", "Tích chọn một dòng để có thể sang trang cập nhật sản phẩm.");
        } else {
            AppServices.history!.push(`/home/products/update-product/${rowSelect[0].key}`);
        }
    };

    const onDeleteListProduct = async () => {
        setLoadingDelete(true);
        let listProductId: number[] = [];
        rowSelect.forEach((value) => listProductId.push(value.key));
        if (listProductId.length > 0) {
            let res = await deleteListProductAPI(listProductId);
            if (res.body.status === 1) {
                openNotification("SUCCESS", "Xóa thành công sản phẩm");
                setTimeout(() => {
                    dispatch(
                        getListProAction({
                            page: page,
                            status: status,
                            categoryId: currentCateId,
                            search: search,
                            type: type,
                            price_order: priceOrder,
                            sold_order: soldOrder,
                        })
                    );
                }, 200);
            } else {
                openNotification("ERROR", "Đã có lỗi vui lòng thực hiện lại.");
            }
        }
        setLoadingDelete(false);
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(
                getListProAction({
                    page: page,
                    status: status,
                    categoryId: currentCateId,
                    search: search,
                    type: type,

                    price_order: priceOrder,
                    sold_order: soldOrder,
                })
            );
        }, 200);
    }, [page, search, status, currentCateId, type, priceOrder, soldOrder]);

    useEffect(() => {
        return function () {
            localStorage.removeItem("nameCate");
        };
    }, []);

    useEffect(() => {
        setCurrentCateId(categoryId);
        setDefaultValueCateChild("");
    }, [categoryId]);

    const columns: any[] = [
        {
            title: "STT",
            key: "STT",
            dataIndex: "STT",
            width: 70,
            render: (value: any) => value,
        },
        {
            title: "Mã sản phẩm",
            key: "code",
            dataIndex: "code",
            width: 120,
            render: (value: any) => value,
        },
        {
            title: "Tên sản phẩm",
            key: "name",
            dataIndex: "name",
            render: (value: any) => value,
        },
        {
            title: "Giá bán (đ)",
            width: 150,
            dataIndex: "price",
            render: (_: any, record: any) => {
                if (record?.max_price !== record?.min_price) {
                    return `${formatNumber(record?.min_price)} - ${formatNumber(record?.max_price)}`;
                } else {
                    return `${formatNumber(record?.min_price)}`;
                }
            },
            sorter: (a: any, b: any) => a?.max_price - b?.max_price,
        },
        {
            title: "Danh mục",
            key: "cate",
            dataIndex: "cate",
            render: (value: any) => (
                <div>
                    <div>{value.cateParent}</div>
                    <div>{`${value.cateChild ? "/ " + value.cateChild : ""}`}</div>
                </div>
            ),
        },
        {
            title: "Trạng thái hàng",
            key: "statusPro",
            dataIndex: "statusPro",
            render: (value: any) => (
                <div>{value === 1 ? <Tag color={"green"}>Còn hàng</Tag> : <Tag color={"gray"}>Hết hàng</Tag>}</div>
            ),
        },
        {
            title: "Trạng thái sản phẩm",
            key: "statusProduct",
            dataIndex: "statusProduct",
            render: (value: any) => (
                <div>
                    {value === 0 && <Tag color={"blue"}>Mặcịnh</Tag>}
                    {value === 1 && <Tag color={"green"}>Bán chạy</Tag>}
                    {value === 3 && <Tag color={"red"}>Hot</Tag>}
                </div>
            ),
        },
        {
            title: "Trạng thái",
            key: "status",
            dataIndex: "status",
            align: "center",
            render: (value: any) => (
                <div style={{ fontSize: 24, cursor: "pointer", position: "relative", zIndex: 100 }}>
                    {value === 1 ? (
                        <i className="far fa-toggle-on" style={{ color: "#1890ff" }} />
                    ) : (
                        <i className="far fa-toggle-off" />
                    )}
                </div>
            ),
        },
        {
            title: "Số lượng",
            key: "quantity",
            dataIndex: "quantity",
            render: (value: any) => numberWithCommas(value),
        },
        {
            title: "Đã bán",
            key: "sold",
            dataIndex: "sold",
            render: (value: any) => numberWithCommas(value),
            sorter: (a: any, b: any) => a?.sold - b?.sold,
        },
        {
            title: "Doanh số",
            key: "turnover",
            dataIndex: "turnover",
            render: (value: any) => numberWithCommas(value, " đ"),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setRowSelect(selectedRows);
        },
    };

    const onExport = async () => {
        try {
            const payload = {
                search,
                status,
                type,
                category_id: currentCateId && currentCateId?.length > 0 ? currentCateId : undefined,
                price_order: priceOrder,
                sold_order: soldOrder,
            };
            const res = await exportExcel(payload);
            if (res?.status) {
                Notification("success", "Xuất excel thành công!");
                window.open(res?.data, "_blank");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={"product-page"}>
            <div className={"product-page__header"}>
                <TitleComponent
                    title={"Sản phẩm"}
                    component={
                        role !== 4 && (
                            <div>
                                <Button
                                    className={"mr-16"}
                                    type={"primary"}
                                    danger
                                    loading={loadingDelete}
                                    onClick={() => {
                                        const isHasFlashsaleProduct = rowSelect?.find(
                                            (item: any) => item?.isFlashSale === 1
                                        );
                                        Modal.confirm({
                                            title: isHasFlashsaleProduct
                                                ? "Trong danh sách sản phẩm có sản phẩm flash sale. Nếu bạn xoá sản phẩm này thì những sản phẩm flash sale liên quan sẽ bị xoá. Bạn có chắc chắn muốn xoá?"
                                                : "Bạn có muốn xóa sản phẩm này?",
                                            onOk: () => onDeleteListProduct(),
                                            okText: "Xoá",
                                            cancelText: "Huỷ",
                                            okButtonProps: {
                                                danger: true,
                                            },
                                        });
                                    }}
                                >
                                    Xóa
                                </Button>
                                <ExportCsv
                                    onClick={onExport}
                                    fileName={`Bao-cao-danh-sach-san-pham_${moment().format("DD-MM-YYYY")}`}
                                    sheets={{
                                        "Danh sach san pham": ExportCsv.getSheets(
                                            getDataExport(state.dataSourceExport)
                                        ),
                                    }}
                                    sheetName={["Danh sach san pham"]}
                                    loading={state.loadingExport}
                                    style={{ display: "inline-block" }}
                                >
                                    Export
                                </ExportCsv>
                                <Button type={"primary"} className={"btn btn-update"} onClick={onUpdatePro}>
                                    Sửa
                                </Button>
                                <Link to={Navigation().addProduct} style={{ display: "inline-block" }}>
                                    <Button className={"btn--green"}>Thêm</Button>
                                </Link>
                            </div>
                        )
                    }
                />
            </div>

            <div className={"product-page__filter flex-start"}>
                <Input
                    placeholder={"Tên hoặc mã sản phẩm"}
                    className={"search"}
                    onChange={(event) => pushParams(1, event.target.value)}
                    defaultValue={search as string}
                />
                <span className={"cate"}>
                    <SelectCateComponent
                        defaultValue={localStorage.getItem("nameCate")}
                        parentId={null}
                        logData={({ id, name }) => {
                            pushParams(1, undefined, undefined, id);
                            localStorage.setItem("nameCate", name ? name : "Tất cả");
                        }}
                        isDisplayAll={true}
                        style={{ width: 200 }}
                    />
                </span>
                {categoryId && (
                    <span className={"mr-16"}>
                        <SelectCateComponent
                            parentId={categoryId}
                            defaultValue={defaultValueCateChild}
                            placeholder={"DM sản phẩm con"}
                            logData={({ id, name }) => {
                                setCurrentCateId(id);
                                setDefaultValueCateChild(name);
                            }}
                            style={{ width: 200 }}
                        />
                    </span>
                )}

                <Select
                    className={"status mr-16"}
                    defaultValue={status === undefined ? undefined : status}
                    placeholder={"Trạng thái."}
                    style={{ marginRight: 16 }}
                    allowClear
                    onChange={(value) => pushParams(1, undefined, value)}
                >
                    <Select.Option value={2}>Tất cả</Select.Option>
                    <Select.Option value={0}>Ngừng hoạt động</Select.Option>
                    <Select.Option value={1}>Đang hoạt động</Select.Option>
                </Select>

                <Select
                    className={"status"}
                    placeholder={"Trạng thái sản phẩm."}
                    style={{ marginRight: 16 }}
                    onChange={(value) => {
                        setType(parseInt(value as string));
                        pushParams(1);
                        setCurrentPage(1);
                    }}
                    allowClear
                >
                    <Select.Option value={0}>Mặc định</Select.Option>
                    <Select.Option value={1}>Bán chạy</Select.Option>
                    <Select.Option value={3}>Hot</Select.Option>
                </Select>
                <Select
                    className={"status mr-16"}
                    placeholder={"Sắp xếp theo giá bán"}
                    style={{ marginRight: 16 }}
                    onChange={(value) => {
                        setPriceOrder(parseInt(value as string));
                        pushParams(1);
                        setCurrentPage(1);
                    }}
                    allowClear
                >
                    <Select.Option value={0}>Từ thấp đến cao</Select.Option>
                    <Select.Option value={1}>Từ cao đến thấp</Select.Option>
                </Select>
                <Select
                    className={"status mr-16"}
                    placeholder={"Sắp xếp theo số lượng bán"}
                    onChange={(value) => {
                        setSoldOrder(parseInt(value as string));
                        pushParams(1);
                        setCurrentPage(1);
                    }}
                    allowClear
                >
                    <Select.Option value={0}>Từ thấp đến cao</Select.Option>
                    <Select.Option value={1}>Từ cao đến thấp</Select.Option>
                </Select>
            </div>

            <div className={"product-page__table"}>
                <Table
                    columns={columns}
                    dataSource={state.dataSource}
                    loading={state.loadingGetListPro}
                    locale={{
                        emptyText: <EmptyComponent />,
                    }}
                    pagination={false}
                    bordered
                    rowSelection={{ ...rowSelection, checkStrictly: true }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () =>
                                AppServices.history!.push(
                                    `/home/products/product-detail/${record.key}?tab=1&pageCustomer=1&pageOrder=1`
                                ),
                        };
                    }}
                />

                <div className={"pagination"}>
                    <Pagination
                        current={currentPage}
                        showSizeChanger={false}
                        pageSize={Config.limit}
                        total={state.total}
                        onChange={(page) => {
                            setCurrentPage(page);
                            pushParams(page);
                            window.scroll({ top: 0, behavior: "smooth" });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Product;
