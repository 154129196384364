import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { LoginAPI, LogoutAPI, putChangePassAccAPI } from "../../../api/login/Login";

interface IInitState {
  user: any;
  loadingLogin: boolean;
  loadingChangePass: boolean;
  avatar: string | null;
  name: string;
  email: string;
  role: number;
}

const initialState: IInitState = {
  user: null,
  loadingLogin: false,
  loadingChangePass: false,
  avatar: null,
  email: "",
  name: "",
  role: localStorage.getItem("role") ? parseInt(localStorage.getItem("role") as string) : 4,
};

export const loginAction = createAsyncThunk(
  "login/loginAction",
  async (params: { pass: string; userName: string }, thunkAPI) => {
    const rs = await LoginAPI(params.pass, params.userName);
    if (rs.body.status === 1) {
      return rs.body.data;
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

export const logoutAction = createAsyncThunk("login/logoutAction", async (params, thunkAPI) => {
  const rs = await LogoutAPI();
  if (rs.body.status === 1) {
    return rs.body.message;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const changePassAction = createAsyncThunk(
  "login/changePassAction",
  async (params: { newPass: string }, thunkAPI) => {
    const rs = await putChangePassAccAPI(params.newPass);
    if (rs.body.status === 1) {
      return rs.body.message;
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    demoAction: (state) => {
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.loadingLogin = true;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.loadingLogin = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.loadingLogin = false;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.avatar = action.payload.profilePictureUrl;
        state.role = action.payload.role;
        localStorage.setItem("username", action.payload.name);
        localStorage.setItem("email", action.payload.email);
        localStorage.setItem("avatar", action.payload.profilePictureUrl as string);
        localStorage.setItem("role", action.payload.role.toString());
        openNotification("SUCCESS", "Đăng nhập thành công.");
        window.localStorage.setItem("token", action.payload.token);
        window.localStorage.setItem("uuid", action.payload.id.toString());
      })

      .addCase(logoutAction.rejected, (state, action) => {
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(logoutAction.fulfilled, (state, action) => {
        localStorage.removeItem("token");
        window.location.href = "/login";
        openNotification("SUCCESS", action.payload);
      })

      .addCase(changePassAction.pending, (state) => {
        state.loadingChangePass = true;
      })
      .addCase(changePassAction.rejected, (state, action) => {
        state.loadingChangePass = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(changePassAction.fulfilled, (state, action) => {
        state.loadingChangePass = false;
        openNotification("SUCCESS", action.payload);
        window.location.href = "/login";
      });
  },
});

export const { demoAction } = loginSlice.actions;
export default loginSlice.reducer;
