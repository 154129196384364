import { Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Spin, Table, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import { EmptyComponent } from "commons/components/AppComponent";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import moment from "moment/moment";
import React, { useState } from "react";
import { Notification, checkSameValue, momentToStringDate, scrollTo } from "utils";
import useDebounce from "utils/useDebounce";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    IDateSourceListOrder,
    IInitState,
    changeStatusOrderAction,
    deleteOrderAction,
    getListOrderProductAction,
} from "../../app/slices/order-product/OrderProduct";
import { Btn } from "../../commons/components/btn/Button";
import ExportCsv from "../../commons/components/export-excel/ExportCSV";
import { openNotification } from "../../commons/components/notifycation/Notification";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { Config, disabledDate } from "../../commons/validation/ValidationPattern";
import AppServices from "../../service/AppServices";
import { formatString, numberWithCommas } from "../../service/Format";
import "./OrderProduct.scss";
import BillModal from "./component/BillModal";
import { PrintSection } from "./component/PrintSection";
import { orderService } from "./service";

interface IDataExport {
    STT: number;
    "Mã đơn": string;
    "Khách hàng": string;
    "Số điện thoại": string;
    "Số SP": number;
    "Tổng tiền": number;
    "Trạng thái đơn hàng": string;
    "Ngày tạo": string;
}

const getDataExport = (data: IDateSourceListOrder[]): IDataExport[] => {
    let rs: IDataExport[] = [];
    data.forEach((value) => {
        let status: string = "";
        if (value.status === -1) {
            status = "Đơn hàng đã hủy";
        } else if (value.status === 0) {
            status = "Chờ xác nhận";
        } else if (value.status === 1) {
            status = "Đã xác nhận";
        }
        rs.push({
            STT: value.STT,
            "Mã đơn": value.code,
            "Khách hàng": value.customer.name,
            "Số điện thoại": value.customer.phone,
            "Số SP": value.countPro,
            "Tổng tiền": value.total,
            "Trạng thái đơn hàng": status,
            "Ngày tạo": value.date && moment(value.date).format(Config.formatDate),
        });
    });
    return rs;
};

const OrderProduct: React.FC<any> = () => {
    const dispatch = useAppDispatch();
    const state: IInitState = useAppSelector((state) => {
        return state.orderProduct;
    });
    const [rowSelect, setRowSelect] = useState<any>(null);

    const rowSelection = {
        selectedRowKeys: rowSelect?.map((item: any) => item?.id),
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setRowSelect(selectedRows);
        },
    };

    const searchParams = new URLSearchParams(window.location.search);
    const page: number = parseInt(searchParams.get("page") as string);
    const search: string = searchParams.get("search") as string;
    const status: any = searchParams.get("status");
    const startDate: string = searchParams.get("start-date") as string;
    const endDate: string = searchParams.get("end-date") as string;
    const [currentPage, setCurrentPage] = useState<number>(page);
    const [isBillModalVisible, setIsBillModalVisible] = React.useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [formNote] = useForm();

    const columns = [
        {
            title: "STT",
            key: "STT",
            dataIndex: "STT",
            render: (text: any, record: any, index: any) => (paging.current - 1) * currentPageSize + index + 1,
        },
        {
            title: "Mã đơn",
            key: "code",
            dataIndex: "code",
            render: (value: string) => value,
        },
        {
            title: "Khách hàng",
            key: "customer",
            dataIndex: "customer",
            render: (_: any, record?: any) => (
                <div>
                    <div className={"font-bold"} style={{ textTransform: "capitalize" }}>
                        {formatString(record?.customer_name)}
                    </div>
                    <div style={{ fontSize: 14, color: "gray" }}>({formatString(record?.customer_phone)})</div>
                </div>
            ),
        },
        {
            title: "Người nhận",
            key: "receiver",
            dataIndex: "receiver",
            render: (_: any, record?: any) => (
                <div>
                    <div className={"font-bold"} style={{ textTransform: "capitalize" }}>
                        {formatString(record?.receive_address?.name)}
                    </div>
                    <div style={{ fontSize: 14, color: "gray" }}>({formatString(record?.receive_address?.phone)})</div>
                </div>
            ),
        },
        {
            title: "Số SP",
            key: "countPro",
            dataIndex: "countPro",
            render: (_: any, record?: any) => record?.OrderItems?.length,
        },
        {
            title: "Tổng tiền",
            dataIndex: "total_price",
            render: (value: number) => numberWithCommas(value, "đ"),
        },
        {
            title: "Trạng thái đơn hàng",
            key: "status",
            dataIndex: "status",
            render: (value: number) => (
                <div>
                    {value === 0 && <Tag color={"orange"}>Chờ xác nhận</Tag>}
                    {value === 1 && <Tag color={"blue"}>Đã xác nhận</Tag>}
                    {value === 2 && <Tag color={"#7bd3de"}>Đang giao</Tag>}
                    {value === 3 && <Tag color={"#ef91b0"}>Đã giao</Tag>}
                    {value === -1 && <Tag color={"red"}>Đơn hàng đã hủy</Tag>}
                    {value === -2 && <Tag color={"#c7a3e6"}>Hoàn hàng</Tag>}
                </div>
            ),
        },
        {
            title: "Ngày tạo",
            dataIndex: "create_at",
            render: (value: any) => momentToStringDate(value, "dateTime"),
        },
        {
            title: "Trạng thái in đơn",
            dataIndex: "print_status",
            width: 150,
            render: (value: any, record: any) => {
                if (!value) {
                    return (
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#5192c7", width: "100%", border: "none" }}
                            onClick={() => onUpdatePrintStatus(1, record?.key)}
                        >
                            Chưa in đơn
                        </Button>
                    );
                }
                return (
                    <Button
                        type="primary"
                        style={{ backgroundColor: "#239b39", width: "100%", border: "none" }}
                        onClick={() => onUpdatePrintStatus(0, record?.key)}
                    >
                        Đã in đơn
                    </Button>
                );
            },
        },
        {
            title: "",
            dataIndex: "",
            width: 100,
            render: (value: any, record: any) => (
                <Button
                    type="primary"
                    style={{ backgroundColor: "#f093b7", width: "100%", border: "none" }}
                    onClick={(e: any) => AppServices.history!.push(`/home/orders/detail/${record.key}`)}
                >
                    Chi tiết
                </Button>
            ),
        },
    ];

    const pushParams = (_page?: number, _status?: number, _search?: string, _startDate?: string, _endDate?: string) => {
        let path: string = `/home/orders?page=${_page !== undefined ? _page : page}`;
        path = path + `&search=${_search !== undefined ? _search : search}`;
        path = path + `&status=${_status !== undefined ? _status : status}`;
        // path = path + `&status=${_status === undefined ? "" : _status}`;
        path = path + `&start-date=${_startDate !== undefined ? _startDate : startDate}`;
        path = path + `&end-date=${_endDate !== undefined ? _endDate : endDate}`;
        AppServices.history!.push(path);
    };

    const onChangeInput = (event: any) => {
        pushParams(1, undefined, event.target.value);
    };
    const onchangeSelect = (value: string | undefined) => {
        if (value) {
            pushParams(1, parseInt(value));
        } else {
            pushParams(1, undefined);
        }
    };
    const onChangeDate = (date: any, formatString: string[]) => {
        pushParams(1, undefined, undefined, formatString[0], formatString[1]);
    };

    const resetForm = () => {
        formNote.setFieldsValue({
            note: "",
        });
    };

    const deleteOrder = async () => {
        let reqData: { id: number[] } = { id: [] };
        if (rowSelect) {
            rowSelect.forEach((value: any) => {
                reqData.id.push(value.key);
            });
            const deleteOr = async () => {
                const rs = await dispatch(deleteOrderAction({ data: reqData }));
                if (rs.type === "orderProduct/deleteOrderAction/fulfilled") {
                    dispatch(
                        getListOrderProductAction({
                            status: status,
                            startDate: startDate,
                            endDate: endDate,
                            search: search,
                            page: page,
                        })
                    );
                }
            };
            Modal.confirm({
                title: "Bạn có muốn xóa đơn hàng này?",
                okText: "Xóa",
                cancelText: "Hủy",
                onOk: () => deleteOr(),
            });
        } else {
            openNotification("ERROR", "Vui lòng chọn đơn hàng để xóa.");
        }
    };

    const onChangeStatus = async (_status: -1 | 1, note?: string) => {
        let validate: boolean = true;
        let listIdOrderPending: number[] = [];

        if (rowSelect) {
            rowSelect.forEach((value: any) => {
                if (_status === -1) {
                    if (value.status === 0 || value.status === 1) {
                        listIdOrderPending.push(value.key);
                    } else {
                        validate = false;
                    }
                } else {
                    if (value.status === 0) {
                        listIdOrderPending.push(value.key);
                    } else {
                        validate = false;
                    }
                }
            });
        }

        if (validate && rowSelect) {
            let rs = await dispatch(
                changeStatusOrderAction({
                    id: listIdOrderPending,
                    status: _status,
                    note: note as string,
                })
            );
            if (rs.type === "orderProduct/changeStatusOrderAction/fulfilled") {
                resetForm();
                setIsOpenModal(false);
                onGetData();
                setRowSelect([]);
            }
        } else {
            _status === -1
                ? openNotification("ERROR", "Vui lòng chọn các đơn hàng chờ xác nhận và đã xác nhận.")
                : openNotification("ERROR", "Vui lòng chọn các đơn hàng chờ xác nhận");
        }
    };

    // Update

    const [data, setData] = React.useState<any[]>([]);
    const [currentPageSize, setCurrentPageSize] = React.useState<number>(100);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [searchKey, setSearchKey] = React.useState<string>("");
    const [orderStatus, setOrderStatus] = React.useState<string>();
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });
    const searchDebounce = useDebounce(searchKey, 300);
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const onUpdatePrintStatus = async (status: number, targetId?: number) => {
        try {
            const payload = {
                id: targetId ? [targetId] : rowSelection?.selectedRowKeys,
                status,
            };
            const res = await orderService.changePrintStatus(payload);
            if (res?.status) {
                setRowSelect([]);
                Notification("success", "Cập nhật trạng thái in đơn thành công!");
                onGetData();
            }
        } catch (error) {
            console.error();
        }
    };

    const onExportExcel = async () => {
        try {
            const payload = {
                search,
                status: orderStatus,
                start_date: dateRange.fromDate,
                end_date: dateRange.toDate,
            };
            const res = await orderService.exportExcel(payload);
            if (res?.status) {
                Notification("success", "Xuất excel thành công!");
                window.open(res?.data, "_blank");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetData = async () => {
        try {
            setLoading(true);
            const payload = {
                page: paging.current,
                limit: currentPageSize,
                search: searchDebounce,
                status: orderStatus,
                start_date: dateRange.fromDate,
                end_date: dateRange.toDate,
            };
            const res: any = await orderService.getOrders(payload);
            if (res?.status) {
                const newList = res?.data?.map((item: any) => ({ ...item, key: item?.id }));
                setData(newList);
                setPaging({
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                });
                setCurrentPageSize(res?.paging?.limit);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetData();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [searchDebounce, orderStatus, dateRange]);

    React.useEffect(() => {
        onGetData();
    }, [paging.current, currentPageSize]);

    return (
        <Spin spinning={loading}>
            <div className={"order-pro"}>
                <div className={"order-pro__header"}>
                    {isBillModalVisible && (
                        <BillModal visible={isBillModalVisible} setVisible={setIsBillModalVisible} orders={rowSelect} />
                    )}

                    <TitleComponent
                        title={"Đơn hàng"}
                        component={
                            <div className={"flex-center"}>
                                <Btn
                                    onClick={() => AppServices.history!.push(pathMenu.add_order)}
                                    value={"Thêm mới"}
                                    backGroundColor={"#009129"}
                                    style={{ width: 140, height: 35, marginRight: 8 }}
                                    loading={state.loadingChangeCancel}
                                />

                                {rowSelect?.length > 0 && (
                                    <>
                                        {checkSameValue(rowSelect, "print_status") ? (
                                            <Btn
                                                onClick={() => {
                                                    console.log(rowSelect?.[0]?.print_status);

                                                    if (!rowSelect?.[0]?.print_status) {
                                                        onUpdatePrintStatus(1);
                                                    } else {
                                                        onUpdatePrintStatus(0);
                                                    }
                                                }}
                                                value={"Chuyển trạng thái in đơn"}
                                                backGroundColor={"#faa7f7"}
                                                style={{ width: 220, height: 35, marginRight: 8 }}
                                            />
                                        ) : (
                                            <Popconfirm
                                                title={<p>Chọn loại trạng thái in đơn</p>}
                                                placement="bottomRight"
                                                onConfirm={() => onUpdatePrintStatus(1)}
                                                onCancel={() => onUpdatePrintStatus(0)}
                                                okText="Đã in đơn"
                                                cancelText="Chưa in đơn"
                                            >
                                                <Btn
                                                    onClick={() => {}}
                                                    value={"Chuyển trạng thái in đơn"}
                                                    backGroundColor={"#faa7f7"}
                                                    style={{ width: 220, height: 35, marginRight: 8 }}
                                                />
                                            </Popconfirm>
                                        )}

                                        <Btn
                                            onClick={() => onChangeStatus(1)}
                                            value={"Xác nhận"}
                                            backGroundColor={"#3965c5"}
                                            style={{ width: 140, height: 35, marginRight: 8 }}
                                            loading={state.loadingChangeConfirm}
                                        />
                                        <Btn
                                            onClick={() => setIsOpenModal(true)}
                                            value={"Từ chối"}
                                            backGroundColor={"#f98973"}
                                            style={{ width: 140, height: 35, marginRight: 8 }}
                                            loading={state.loadingChangeCancel}
                                            disable={rowSelect?.find((item: any) => item?.status !== 0)}
                                        />
                                        {/* <Btn
                                            onClick={() => setIsBillModalVisible(true)}
                                            value={"Print bill"}
                                            backGroundColor={"#2dad5e"}
                                            style={{ width: 140, height: 35, marginRight: 8 }}
                                            loading={state.loadingChangeCancel}
                                        /> */}
                                        <PrintSection
                                            buttonPrint={() => (
                                                <Btn
                                                    value={"In hoá đơn"}
                                                    backGroundColor={"#e39a1b"}
                                                    style={{ width: 140, height: 35, marginRight: 8 }}
                                                />
                                            )}
                                            orders={rowSelect?.filter((item: any) => item?.status === 1)}
                                            setLoading={setLoading}
                                        />
                                    </>
                                )}
                                <ExportCsv
                                    onClick={onExportExcel}
                                    fileName={`Bao-cao-danh-sach-don-hang_${moment().format("DD-MM-YYYY")}`}
                                    sheets={{
                                        "Danh sach don hang": ExportCsv.getSheets(
                                            getDataExport(state.dataSourceExport)
                                        ),
                                    }}
                                    sheetName={["Danh sach don hang"]}
                                    loading={state.loadingExport}
                                    style={{ width: 140, height: 35 }}
                                >
                                    Export
                                </ExportCsv>
                            </div>
                        }
                    />
                </div>

                <div className={"order-pro__list-order style-box mt-16"}>
                    <div className={"order-pro__list-order__filter"}>
                        <div className={"order-pro__list-order__filter__list-filter"}>
                            <Input
                                onChange={(e: any) => setSearchKey(e.target.value)}
                                placeholder={"Nhập mã đơn.Tên khách hàng.SDT khách hàng."}
                                className={"item search"}
                                defaultValue={search}
                                allowClear
                            />
                            <Select
                                // defaultValue={status !== 5 ? status.toString() : undefined}
                                placeholder={"TT đơn hàng"}
                                className={"item status"}
                                onChange={(value: any) => setOrderStatus(value)}
                                allowClear
                            >
                                <Select.Option value={"0"}>Chờ xác nhận</Select.Option>
                                <Select.Option value={"1"}>Đã xác nhận</Select.Option>
                                <Select.Option value={"2"}>Đang giao</Select.Option>
                                <Select.Option value={"3"}>Đã giao</Select.Option>
                                <Select.Option value={"-2"}>Hoàn hàng</Select.Option>
                                <Select.Option value={"-1"}>Đã hủy</Select.Option>
                            </Select>
                            <DatePicker.RangePicker
                                format={Config.formatDate}
                                className={"item date"}
                                onChange={(date: any, dateStrings: string[]) => {
                                    setDateRange({
                                        fromDate: dateStrings[0],
                                        toDate: dateStrings[1],
                                    });
                                }}
                                inputReadOnly={true}
                                disabledDate={disabledDate}
                                style={{ width: 300 }}
                                placeholder={Config.placeholderDatePicker}
                                // defaultValue={
                                //     endDate
                                //         ? [moment(startDate, Config.formatDate), moment(endDate, Config.formatDate)]
                                //         : null
                                // }
                            />
                        </div>
                        <div className={"order-pro__list-order__filter__result-filter"}>
                            <span className={"font-bold color-gray"}>Kết quả lọc: {paging.total} </span>
                        </div>
                    </div>

                    <div className={"order-pro__list-order__wrapper-table mt-16"}>
                        <Table
                            id="top-table"
                            bordered
                            columns={columns}
                            locale={{
                                emptyText: <EmptyComponent />,
                            }}
                            pagination={{
                                ...paging,
                                pageSize: currentPageSize,
                                onChange: (page) => {
                                    setPaging({ ...paging, current: page });
                                    setTimeout(() => {
                                        scrollTo("start");
                                    }, 500);
                                },
                                showQuickJumper: true,
                                showSizeChanger: true,
                                locale: { jump_to: "Đi đến", page: "trang" },
                                onShowSizeChange: (current, pageSize) => {
                                    setCurrentPageSize(pageSize);
                                },
                            }}
                            // onRow={(record, index) => {
                            //     return {
                            //         onClick: () => AppServices.history!.push(`/home/orders/detail/${record.key}`),
                            //     };
                            // }}
                            rowSelection={{
                                ...rowSelection,
                                checkStrictly: true,
                                // getCheckboxProps: (record) => ({
                                //     disabled: record.status !== 1,
                                // }),
                            }}
                            dataSource={data}
                            loading={state.loadingGetListOrder}
                        />
                    </div>
                </div>

                <Modal
                    title={"Hủy đơn hàng"}
                    visible={isOpenModal}
                    footer={false}
                    onCancel={() => {
                        resetForm();
                        setIsOpenModal(false);
                    }}
                >
                    <Form
                        onFinish={(data) => {
                            onChangeStatus(-1, data.note);
                        }}
                        form={formNote}
                    >
                        <Form.Item
                            label={"Lý do hủy đơn"}
                            name={"note"}
                            rules={[
                                { required: true, message: "Vui lòng nhập lý do hủy đơn." },
                                { pattern: /^[\s\S]{0,255}$/, message: "Lý do hủy đơn không quá 255 ký tự" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                            <Row>
                                <Col lg={12}>
                                    <Button
                                        className={"btn--red"}
                                        onClick={() => {
                                            setIsOpenModal(false);
                                            resetForm();
                                        }}
                                    >
                                        Hủy
                                    </Button>
                                </Col>
                                <Col lg={12}>
                                    <Button
                                        className={"btn--green"}
                                        loading={state.loadingChangeCancel}
                                        htmlType={"submit"}
                                    >
                                        Xác nhận
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </Spin>
    );
};

export default OrderProduct;
