import { deleteRequest, getRequest, IApiResponse, putRequest } from "../../service/Basevices";
import { IResBody } from "../interface";
import { IResGetListOrderProductAPI, IResGetOrderDetail } from "./Interfaces";
import { Config } from "../../commons/validation/ValidationPattern";

export const getListOrderProductAPI = (
    page: number,
    search: string,
    status: null | number,
    startDate: string,
    endDate: string,
    limit?: number
): Promise<IApiResponse<IResGetListOrderProductAPI>> => {
    let path: string = `/order/list?page=${page}&limit=${limit ? limit : Config.limit}`;
    if (search) path = path + `&search=${search.trim()}`;
    if (status !== null) path = path + `&status=${status}`;
    if (startDate) path = path + `&start_date=${startDate}`;
    if (endDate) path = path + `&end_date=${endDate}`;
    return getRequest(path, true);
};

export const getOrderDetailAPI = (id: number): Promise<IApiResponse<IResGetOrderDetail>> => {
    const path: string = `/order/${id}/detail`;
    return getRequest(path, true);
};

export const changeStatusOrderAPI = (id: number[], status: number, note?: string): Promise<IApiResponse<IResBody>> => {
    return putRequest(`/order/change-status`, true, { status: status, id: id, note: note ? note : null });
};

export const deleteOrderAPI = (data: { id: number[] }): Promise<IApiResponse<IResBody>> => {
    return deleteRequest(`/order/delete`, true, data);
};
