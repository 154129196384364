import * as React from "react";
import BillItem from "./BillItem";

export class ComponentToPrint extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = { checked: false };
    }

    canvasEl: any;

    handleCheckboxOnChange = () => this.setState({ checked: !this.state.checked });

    setRef = (ref: any) => (this.canvasEl = ref);

    render() {
        const { props } = this.props;

        return (
            <div
                className="container"
                style={
                    {
                        // margin: "0",
                        // padding: "0",
                        // display: "flex",
                        // flexDirection: "column",
                    }
                }
            >
                {props?.props?.map((item: any) => (
                    // <div key={item?.id} style={{ height: "100vh", padding: "3vh" }}>
                    <div
                        key={item?.id}
                        style={{ padding: "10px", pageBreakAfter: "always", pageBreakBefore: "always" }}
                    >
                        <BillItem item={item} />
                    </div>
                ))}
            </div>
        );
    }
}

export const FunctionalComponentToPrint = React.forwardRef((props: any, ref: any) => {
    // eslint-disable-line max-len
    return (
        <div className="container" style={{ margin: "0", padding: "0", height: 0, overflowX: "hidden" }}>
            <ComponentToPrint ref={ref} props={props} />
        </div>
    );
});
