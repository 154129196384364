import React, { useEffect, useState } from "react";
import "./Notification.scss";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { Button, Input, Pagination, Select, Table, Tag } from "antd";
import RangePickerComponent from "../../commons/components/range-picker/RangePickerComponent";
import { IColumn } from "../../service/Interfaces";
import { formatString } from "../../service/Format";
import moment from "moment";
import { Config, getStartIndex } from "../../commons/validation/ValidationPattern";
import AppServices from "../../service/AppServices";
import { EmptyComponent } from "../../commons/components/AppComponent";
import { getListPushNotificationAPI } from "../../api/notification/Notification";
import { IResPushNotification } from "../../api/notification/interface";
import { openNotification } from "../../commons/components/notifycation/Notification";

interface IDataSource {
  key: any;
  col1: number;
  col2: string;
  col3: string;
  col4: any;
  col5: string;
}

const Notification: React.FC<any> = () => {

  const columns: IColumn [] = [
    {
      title: "STT",
      key: "col1",
      dataIndex: "col1",
      render: (value: number) => value,
    },
    {
      title: "Tiêu đề",
      key: "col2",
      dataIndex: "col2",
      render: (value: string) => formatString(value),
    },
    {
      title: "Mô tả",
      key: "col3",
      dataIndex: "col3",
      render: (value: string) => formatString(value),
    },
    {
      title: "Loại thông báo",
      key: "col4",
      dataIndex: "col4",
      render: (value: number) =>
        <div>
          {value === 3 && <Tag color={"blue"}>Live stream</Tag>}
          {value === 4 && <Tag color={"green"}>Bài đăng sản phẩm</Tag>}
          {value === 5 && <Tag color={"orange"}>Khuyến mãi</Tag>}
        </div>,
    },
    {
      title: "Ngày tạo",
      key: "col5",
      dataIndex: "col5",
      render: (value: string) => value && moment(value).format(Config.formatTime),
    },
  ];

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<IDataSource []>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [typeNotification, setTypeNotification] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  const getListNotification = async () => {

    setLoading(true);
    const getDataSource = (data: IResPushNotification []): IDataSource [] => {
      let rs: IDataSource [] = [];
      data.forEach((value, index) => rs.push(
        {
          key: value.id,
          col1: index + 1 + getStartIndex(currentPage),
          col2: value.content as string,
          col3: value.description as string,
          col4: value.type,
          col5: value.createAt,
        },
      ));
      return rs;
    };

    const res = await getListPushNotificationAPI(currentPage, search, typeNotification, startDate, endDate);
    if (res.body.status === 1) {
      setDataSource(getDataSource(res.body.data));
      setTotal(res.body.paging.totalItemCount);
    } else {
      openNotification("ERROR", res.body.message);
    }
    setLoading(false);

  };

  useEffect(() => {
    getListNotification();
  }, [currentPage, startDate, endDate, typeNotification, search]);

  return (
    <div className={"notification-page"}>
      <TitleComponent title={"Thêm thông báo"}
                      component={
                        <Button className={"btn--green"}
                                onClick={() => AppServices.history!.push("/home/notification/add")}
                        >
                          Thêm mới
                        </Button>
                      }
      />

      <div className={"notification-page__filter style-box mt-16"}>
        <Input placeholder={"Nhập tiêu đề."}
               style={{ width: 300, marginRight: 16 }}
               onChange={event => {
                 setSearch(event.target.value);
                 setCurrentPage(1);
               }}
        />
        <Select style={{ width: 300, marginRight: 16 }}
                placeholder={"Chọn tên thông báo"}
                onChange={value => {
                  setTypeNotification(parseInt(value as string));
                  setCurrentPage(1);
                }}
        >
          <Select.Option value={"0"}>Tất cả</Select.Option>
          <Select.Option value={"3"}>Live stream</Select.Option>
          <Select.Option value={"4"}>Bài đăng sản phẩm</Select.Option>
          <Select.Option value={"5"}>Khuyến mãi</Select.Option>
        </Select>
        <RangePickerComponent onCalendarChange={(date, formatString1) => {
          setStartDate(formatString1[0]);
          setEndDate(formatString1[1]);
          setCurrentPage(1);
        }} />
      </div>

      <div className={"notification-page__table style-box mt-16"}>
        <Table
          columns={columns}
          dataSource={dataSource}
          bordered
          loading={loading}
          locale={{
            emptyText: <EmptyComponent />,
          }}
          pagination={false}
        />
        <div className={"flex-end mt-16"}>
          <Pagination
            current={currentPage}
            total={total}
            pageSize={Config.limit}
            showSizeChanger={false}
            onChange={page => setCurrentPage(page)}
          />
        </div>
      </div>

    </div>
  );
};

export default Notification;