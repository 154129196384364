import { IResGetListUser } from "../../../api/account/Interface";
import { IDataTableAccount } from "../../../modules/account/interfaces";
import { getStartIndex } from "../../../commons/validation/ValidationPattern";

export const convertToListAcc = (data: IResGetListUser, page: number): IDataTableAccount[] => {
  let result: IDataTableAccount[] = data.data.map((value, index) => {
    return {
      key: value.id,
      STT: index + 1 + getStartIndex(page),
      name: value.name,
      phone: value.phone,
      email: value.email,
      role: value.role,
      status: value.status,
      date: value.createAt,
      avatar: value.profilePictureUrl,
      avatarName: value.profilePictureName,
    };
  });
  return result;
};

export const deleteAcc = (id: any, listAcc: IDataTableAccount[]): IDataTableAccount[] => {
  return listAcc.filter((value) => {
    return value.key !== id;
  });
};

export const enableOrDisableAcc = (id: any, listAcc: IDataTableAccount[]) => {
  listAcc.forEach((value) => {
    if (value.key === id) {
      if (value.status === 0) {
        value.status = 1;
      } else {
        value.status = 0;
      }
      return false;
    }
  });
};

export const updateAcc = (
  id: any,
  email: string,
  name: string,
  status: number,
  profilePictureUrl: string | null,
  listAcc: IDataTableAccount[],
) => {
  listAcc.forEach((value) => {
    if (value.key === id) {
      value.email = email;
      value.name = name;
      value.status = status;
      value.avatar = profilePictureUrl;
    }
  });
};
