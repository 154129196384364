import { InputNumber } from "antd";
import { setProducts } from "app/slices/order-product/CreateOrder";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IProductQuantityInput {
    record: any;
}

const ProductQuantityInput = (props: IProductQuantityInput) => {
    const { record } = props;

    const dispatch = useDispatch();
    const createOrderData = useSelector((state: any) => state.createOrder.data);
    const targetItem = createOrderData?.find((item: any) => item?.id === record?.id);
    console.log("🚀 ~ file: ProductQuantityInput.tsx:16 ~ ProductQuantityInput ~ targetItem:", targetItem);

    const onChange = (value: any) => {
        const newData = createOrderData?.map((item: any) => {
            if (item?.id === record?.id) {
                const renderTotalPrice = () => {
                    // có sale
                    if (item?.quantity_sale_left > 0 && item?.sale_price > 0) {
                        // Nếu mua quá số lượng sale thì sẽ check xem nếu sản phẩm có giá sỉ và số lượng thoả mãn -> Giá sỉ, nếu không sẽ tính giá sỉ
                        if (value > item?.quantity_sale_left) {
                            // Thoả mãn sỉ
                            if (item?.wholesale_quantity && value >= item?.wholesale_quantity) {
                                return item?.wholesale_price * value;
                            }
                            return item?.price * value;
                        }
                        return item?.sale_price * value;
                    } else if (
                        item?.wholesale_price &&
                        item?.wholesale_quantity &&
                        item?.wholesale_price >= 0 &&
                        item?.wholesale_quantity >= 0
                    ) {
                        // Có set giá sỉ, giá lẻ
                        if (value >= item?.wholesale_quantity) {
                            return item?.wholesale_price * value;
                        } else {
                            return item?.price * value;
                        }
                    } else {
                        return item?.price * value;
                    }
                };

                return {
                    ...item,
                    quantity: value,
                    total_price: renderTotalPrice(),
                };
            }
            return item;
        });
        dispatch(setProducts(newData));
    };

    return (
        <InputNumber
            style={{ width: "100%" }}
            onChange={onChange}
            autoComplete="off"
            disabled={targetItem?.isHasAttr ? !targetItem?.attribute : !targetItem?.product}
            min={1}
            value={record?.quantity}
            placeholder="Nhập số lượng"
        />
    );
};

export default memo(ProductQuantityInput);
