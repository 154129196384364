import { Button, Form, Input, InputNumber, Modal, Row } from "antd";
import React from "react";
import { formatNumber } from "utils";

interface IPaymentModal {
    visible: boolean;
    loading: boolean;
    selectedRows: any[];
    onRequestWithdraw: (values: any) => Promise<void>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentModal = (props: IPaymentModal) => {
    const { visible, setVisible, onRequestWithdraw, loading, selectedRows } = props;
    const [form] = Form.useForm();
    const user = selectedRows?.[0];

    const onCancel = () => {
        setVisible(false);
    };

    const onFinish = (values: any) => onRequestWithdraw(values);

    return (
        <Modal centered width={500} title="Thanh toán hoa hồng" open={visible} onCancel={onCancel} footer={null}>
            {user?.user_bank?.length === 0 && (
                <p style={{ color: "red" }}>
                    * Nhân viên sale hiện tại chưa có tài khoản ngân hàng nên không thực hiện được chức năng này!
                </p>
            )}
            <Row justify={"space-between"}>
                <p>Tên sale</p>
                <b>{user?.name}</b>
            </Row>
            <Row justify={"space-between"}>
                <p>Số dư ví thu nhập</p>
                <b>{formatNumber(user?.Wallet?.commision || 0)} VND</b>
            </Row>
            <Row justify={"space-between"}>
                <p>Ngân hàng</p>
                <b>{user?.user_bank?.[0]?.bank?.name || "---"}</b>
            </Row>
            <Row justify={"space-between"}>
                <p>Số tài khoản</p>
                <b>{user?.user_bank?.[0]?.account_number || "---"}</b>
            </Row>
            <Row justify={"space-between"}>
                <p>Chủ tài khoản</p>
                <b>{user?.user_bank?.[0]?.cardholder_name || "---"}</b>
            </Row>
            <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
                style={{ marginTop: 20 }}
            >
                <Form.Item
                    name={"money"}
                    label="Số tiền thanh toán"
                    rules={[{ required: true, message: "Vui lòng nhập số tiền thanh toán!" }]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        min={1}
                        addonAfter={"VND"}
                        placeholder="Nhập số tiền thanh toán"
                        disabled={user?.user_bank?.length === 0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    />
                </Form.Item>
                <Form.Item name={"note"} label="Ghi chú">
                    <Input.TextArea
                        style={{ width: "100%" }}
                        placeholder="Nhập ghi chú"
                        allowClear
                        rows={5}
                        disabled={user?.user_bank?.length === 0}
                    />
                </Form.Item>
                <Row justify="end" style={{ marginTop: 20 }}>
                    <Button type="default" onClick={onCancel}>
                        Huỷ
                    </Button>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{ marginLeft: 10 }}
                            loading={loading}
                            disabled={user?.user_bank?.length === 0}
                        >
                            Xác nhận
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
};

export default PaymentModal;
