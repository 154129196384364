import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import "./login.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { loginAction } from "../../app/slices/login/Login";
import { ValidationPattern } from "../../commons/validation/ValidationPattern";

interface IProps {
    history: any;
}

const Login: React.FC<IProps> = ({ history }) => {
    const dispatch = useAppDispatch();
    let role: number = useAppSelector((state) => state.login.role);

    const onLogin = async (value: any) => {
        const rs = await dispatch(loginAction({ userName: value.login.account, pass: value.login.pass }));
        if (rs.type === "login/loginAction/fulfilled") {
            if (role === 4) {
                history.push("/home/customers?page=1");
            } else {
                history.push("/home");
            }
        }
    };

    return (
        <div
            className={"flex-center login"}
            style={
                {
                    // backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.32), rgba(117, 19, 93, 0.32)), url(./assets/img/backLogin.png), linear-gradient(red, yellow)`,
                }
            }
        >
            <div className={"login__wrapper-form"}>
                <Row gutter={25}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <div className={"flex-center wrapper-img"}>
                            <img
                                src={
                                    "https://images.unsplash.com/photo-1695653422902-1bea566871c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80"
                                }
                                style={{ height: 120, width: 120, borderRadius: 10000, objectFit: "cover" }}
                                alt={"img"}
                            />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <div>
                            <h1 className={"login__wrapper-form__name"}>Rẻ Tốt</h1>
                            <div className={"mt-16"}>
                                <Form name={"login"} onFinish={onLogin}>
                                    <Form.Item
                                        name={["login", "account"]}
                                        rules={[
                                            { pattern: ValidationPattern.phone, message: "Tài khoản không hợp lệ." },
                                            { required: true, message: "Vui lòng điền đầy đủ thông tin." },
                                        ]}
                                    >
                                        <Input placeholder={"Tài khoản"} prefix={<i className="far fa-user-alt" />} />
                                    </Form.Item>

                                    <Form.Item
                                        name={["login", "pass"]}
                                        rules={[
                                            { pattern: /^[\s\S]{6,}$/, message: "Mật khẩu không hợp lệ." },
                                            { required: true, message: "Vui lòng điền đầy đủ thông tin." },
                                        ]}
                                    >
                                        <Input.Password
                                            placeholder={"Mật khẩu"}
                                            prefix={<i className="fal fa-lock-alt" />}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type={"primary"}
                                            htmlType={"submit"}
                                            danger
                                            size={"middle"}
                                            loading={useAppSelector((state) => state.login.loadingLogin)}
                                        >
                                            Đăng nhập
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Login;
