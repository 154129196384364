import { Button, Col, Form, Input, Modal, Row, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { EmptyComponent } from "commons/components/AppComponent";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatNumber } from "utils";
import { firebaseConnect } from "../../../../FirebaseConnect";
import { IOrderItems } from "../../../../api/order-product/Interfaces";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setRoomAction } from "../../../../app/slices/chat/Chat";
import { deleteOrderAction } from "../../../../app/slices/order-product/OrderProduct";
import { changeStatusOrderAction, getOrderDetailAction } from "../../../../app/slices/order-product/OrderProductDetail";
import { Btn } from "../../../../commons/components/btn/Button";
import TitleComponent from "../../../../commons/components/title-component/TitleComponent";
import { Navigation } from "../../../../commons/validation/ValidationPattern";
import AppServices from "../../../../service/AppServices";
import { formatString, numberWithCommas } from "../../../../service/Format";
import { IColumn } from "../../../../service/Interfaces";
import "./OrderProductDetail.scss";

const getDataSource = (data: any[]) => {
    let rs: any[] = [];
    if (data) {
        console.log("🚀 ~ file: OrderProductDetail.tsx:25 ~ getDataSource ~ data:", data);
        data.forEach((value, index) =>
            rs.push({
                key: value.id,
                col1: index + 1,
                col2: value.product,
                product_code: value.productCode,
                weight: value.weight,
                col3: value.quantity,
                col4: value.product.price,
                col5: value.quantity * value.product.price,
            })
        );
    }
    return rs;
};

const OrderProductDetail: React.FC<any> = () => {
    const columns: any[] = [
        {
            title: "STT",
            key: "col1",
            dataIndex: "col1",
            width: 30,
            render: (value: number) => value,
        },
        {
            title: "Mã sản phẩm",
            key: "product_code",
            dataIndex: "product_code",
            width: 30,
            render: (value: number) => value,
        },
        {
            title: "Tên sản phẩm",
            key: "col2",
            dataIndex: "col2",
            render: (value: { [key: string]: string }) => (
                <div>
                    {value.name}{" "}
                    {value.productAttributeName1 && value.productAttributeName2 ? (
                        <span>
                            ({value.productAttributeName1} | {value.productAttributeName2})
                        </span>
                    ) : value.productAttributeName1 && !value.productAttributeName2 ? (
                        <span>({value.productAttributeName1})</span>
                    ) : !value.productAttributeName1 && value.productAttributeName2 ? (
                        <span>({value.productAttributeName2})</span>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
        {
            title: "Số lượng",
            key: "col3",
            dataIndex: "col3",
            width: 120,
            render: (value: number) => numberWithCommas(value),
        },
        {
            title: "Khối lượng",
            key: "weight",
            dataIndex: "weight",
            width: 120,
            render: (value: number) => numberWithCommas(value),
        },
        {
            title: "Giá bán",
            key: "col4",
            dataIndex: "col4",
            width: 120,
            render: (value: number) => value && numberWithCommas(value, "đ"),
        },
        {
            title: "Thành tiền",
            key: "col5",
            dataIndex: "col5",
            width: 120,
            render: (value: number) => (value ? numberWithCommas(value, "đ") : ""),
        },
    ];

    const dispatch = useAppDispatch();
    const state: any = useAppSelector((state) => {
        return state.orderProductDetail;
    });
    const params: any = useParams();

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [formNote] = useForm();
    const [deviceId, setDeviceId] = useState<string>("");
    const [lastMsgRoom, setLastMsgRoom] = useState<string>("");
    const [isAdmin, setIsAdmin] = useState<number>(0);

    const onChangeStatus = async (id: number, status: number, note?: string) => {
        let rs = await dispatch(changeStatusOrderAction({ id: id, status: status, note: note }));
        if (rs.type === "orderProductDetail/changeStatusOrderAction/fulfilled") {
            resetForm();
            setIsOpenModal(false);
        }
        dispatch(getOrderDetailAction({ id: params.id }));
    };

    const resetForm = () => {
        formNote.setFieldsValue({
            note: "",
        });
    };

    const getDeviceId = (userId: number) => {
        const refDeviceIdUser = firebaseConnect.database().ref(`listDeviceIdUser/${userId}`);
        refDeviceIdUser.on("value", (childSnapshot, prevChildKey) => {
            setDeviceId(childSnapshot.val() ? childSnapshot.val().deviceID : "");
        });
    };

    const getLastMsg = (userId: number) => {
        let refChatTable = firebaseConnect.database().ref(`chatTable/${userId}`);
        refChatTable.limitToLast(1).on("value", (childSnapshot, prevSnapshot) => {
            childSnapshot.forEach((a) => {
                if (a.val() && a.val().image) {
                    setLastMsgRoom("Hình ảnh");
                    if (a.val() && a.val().user._id === 0) {
                        setIsAdmin(1);
                    } else {
                        setIsAdmin(0);
                    }
                } else {
                    setLastMsgRoom(a.val().text ? a.val().text : "");
                }
            });
        });
    };

    const createRoom = (userId: number, deviceId: string, customerName: string, phone: string, avatar: string) => {
        const refChatTable = firebaseConnect.database().ref(`roomTable/${userId}`);

        refChatTable.on("value", (childSnapShot, prevSnapShot) => {
            console.log(childSnapShot.val());
            if (!childSnapShot.val()) {
                const refRoomTable = firebaseConnect.database().ref(`roomTable/${userId}`);

                refRoomTable
                    .set({
                        admin: { isReadMsg: 1 },
                        lastMsg: {
                            createdAt: new Date().toISOString(),
                            isAdmin: isAdmin,
                            msg: lastMsgRoom,
                        },
                        user: {
                            deviceID: deviceId,
                            id: userId,
                            isFocus: 0,
                            isOnline: 1,
                            isReadMsg: 0,
                            name: customerName,
                            phoneNumber: phone,
                        },
                    })
                    .then((value) => {
                        console.log("run");
                        dispatch(
                            setRoomAction({
                                room: {
                                    id: userId,
                                    name: customerName,
                                    phoneNumber: phone,
                                    avatar: avatar,
                                    isReadMsg: 0,
                                    isOnline: 0,
                                    lastMsg: { msg: "", createdAt: "" },
                                    deviceId: "",
                                    timeStamp: 0,
                                },
                            })
                        );
                        AppServices.history?.push(`/home/chat?room=${userId}`);
                    });
            } else {
                dispatch(
                    setRoomAction({
                        room: {
                            id: userId,
                            name: customerName,
                            phoneNumber: phone,
                            avatar: avatar,
                            isReadMsg: 0,
                            isOnline: 0,
                            lastMsg: { msg: "", createdAt: "" },
                            deviceId: "",
                            timeStamp: 0,
                        },
                    })
                );
                AppServices.history?.push(`/home/chat?room=${userId}`);
            }
        });
    };

    const deleteOrder = async () => {
        const deleteOr = async () => {
            const rs = await dispatch(deleteOrderAction({ data: { id: [params.id] } }));
            if (rs.type === "orderProduct/deleteOrderAction/fulfilled") {
                AppServices.history?.push(Navigation().orderProduct);
            }
        };
        Modal.confirm({
            title: "Bạn có muốn xóa đơn hàng này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk: () => deleteOr(),
        });
    };

    useEffect(() => {
        dispatch(getOrderDetailAction({ id: params.id }));
    }, [params.id]);

    useEffect(() => {
        state.userId && getDeviceId(state.userId);
        state.userId && getLastMsg(state.userId);
    }, [state.userId]);

    return (
        <div className={"order-detail"}>
            <div className={"order-detail__header"}>
                <TitleComponent
                    title={<div>{`Đơn hàng ${state.code}`}</div>}
                    isGoBack={true}
                    component={
                        <div style={{ display: "flex" }}>
                            {(state.status === 0 || state.status === 4) && (
                                <div className={"flex-center"}>
                                    {/* {
                    state.status === 0 &&
                    <Btn onClick={() => !state.loadingStatusNotContact && onChangeStatus(params.id, 4)} value={"Không liên lạc được với KH"}
                         className={"mr-16"} loading={state.loadingStatusNotContact} backGroundColor={"orange"} />
                  } */}

                                    <Btn
                                        onClick={() => setIsOpenModal(true)}
                                        value={"Từ chối"}
                                        backGroundColor={"#ec7459"}
                                        className={"mr-16"}
                                        loading={state.loadingStatusCancel}
                                    />
                                    <Btn
                                        onClick={() => onChangeStatus(params.id, 1)}
                                        value={"Xác nhận"}
                                        className={"mr-16"}
                                        loading={state.loadingStatusConfirm}
                                    />
                                </div>
                            )}
                            {state.status === 1 && (
                                <Btn
                                    onClick={() => setIsOpenModal(true)}
                                    value={"Từ chối"}
                                    backGroundColor={"#ec7459"}
                                    className={"mr-16"}
                                    loading={state.loadingStatusCancel}
                                />
                            )}
                            {/* <Btn
                                onClick={() => deleteOrder()}
                                value={"Xóa"}
                                backGroundColor={"red"}
                                className={"mr-16"}
                            /> */}
                            <Btn
                                onClick={() =>
                                    createRoom(
                                        state.userId,
                                        deviceId,
                                        state.customerName,
                                        state.customerPhone,
                                        state.avatar
                                    )
                                }
                                value={"Nhắn tin"}
                                backGroundColor={"orange"}
                                icon={<i className="fal fa-comments" style={{ marginRight: 4 }} />}
                                className={"mr-16"}
                            />
                        </div>
                    }
                />
            </div>
            <div className={"order-detail__info mt-16"}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={7}>
                        <div className={"item order-detail__info__customer style-box"}>
                            <div className={"title font-bold"}>Thông tin khách hàng</div>
                            <div className={"info mt-16"}>
                                <div className={"info-item"}>
                                    <i className="fas fa-user" />
                                    <div style={{ textTransform: "capitalize" }}>
                                        {formatString(state.customerName)}
                                    </div>
                                </div>
                                <div className={"info-item"}>
                                    <i className="fas fa-phone-alt" />
                                    <div>{formatString(state.customerPhone)}</div>
                                </div>
                                <div className={"info-item"}>
                                    <i className="fas fa-map-marker-alt" />
                                    <div>{formatString(state.customerProvinceName)}</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={7}>
                        <div className={"item order-detail__info__customer style-box"}>
                            <div className={"title font-bold"}>Thông tin nhận hàng</div>
                            <div className={"info mt-16"}>
                                <div className={"info-item"}>
                                    <i className="fas fa-user" />
                                    <div style={{ textTransform: "capitalize" }}>
                                        {formatString(state.receiveAddress.name)}
                                    </div>
                                </div>
                                <div className={"info-item"}>
                                    <i className="fas fa-phone-alt" />
                                    <div>{formatString(state.receiveAddress.phone)}</div>
                                </div>
                                <div className={"info-item"} style={{ alignItems: "stretch" }}>
                                    <i className="fas fa-map-marker-alt" style={{ marginTop: 4 }} />
                                    <div>
                                        {(state.receiveAddress.address ? state.receiveAddress.address : "") +
                                            " - " +
                                            (state.receiveAddress.wardName ? state.receiveAddress.wardName : "") +
                                            " - " +
                                            (state.receiveAddress.districtName
                                                ? state.receiveAddress.districtName
                                                : "") +
                                            " - " +
                                            (state.receiveAddress.provinceName
                                                ? state.receiveAddress.provinceName
                                                : "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <div className={"item order-detail__info__order-pro style-box"}>
                            <Row className={"title font-bold"} justify={"space-between"}>
                                <span>Thông tin đơn hàng</span>
                                <span className={"ml-24"}>
                                    <span style={{ color: "red" }}>{state.status === -1 && "Hủy"}</span>
                                    <span style={{ color: "orangered" }}>{state.status === 0 && "Chờ xác nhận"}</span>
                                    <span style={{ color: "#959ee6" }}>{state.status === 1 && "Đã xác nhận"}</span>
                                    <span style={{ color: "orangered" }}>{state.status === 2 && "Đang giao"}</span>
                                    <span style={{ color: "#5eb0c6" }}>{state.status === 3 && "Đã giao"}</span>
                                    <span style={{ color: "blue" }}>{state.status === 5 && "Hoàn hàng"}</span>
                                </span>
                            </Row>
                            <div className={"info mt-16"}>
                                <div className={"info-item"}>
                                    <div className={"title-info-item"}>Mã đơn hàng</div>
                                    <div className={"font-bold code"}>{state.code}</div>
                                </div>

                                <div className={"info-item"}>
                                    <div className={"title-info-item"}>Tổng tiền</div>
                                    <div className={"font-bold code"}>{formatNumber(state.totalMoney)} đ</div>
                                </div>
                                <div className={"info-item"}>
                                    <div className={"title-info-item"}>Phí vận chuyển</div>
                                    <div className={"font-bold code"}>{formatNumber(state.deliveryFee)} đ</div>
                                </div>
                                {state.point > 0 && (
                                    <div className={"info-item"}>
                                        <div className={"title-info-item"}>Điểm sử dụng</div>
                                        <div className={"font-bold code"}>- {formatNumber(state.point)} đ</div>
                                    </div>
                                )}
                                {state.discount > 0 && (
                                    <div className={"info-item"}>
                                        <div className={"title-info-item"}>Voucher giảm giá</div>
                                        <div className={"font-bold code"}>- {formatNumber(state.discount)} đ</div>
                                    </div>
                                )}
                                {state.discountShip > 0 && (
                                    <div className={"info-item"}>
                                        <div className={"title-info-item"}>Voucher phí vận chuyển</div>
                                        <div className={"font-bold code"}>- {formatNumber(state.discountShip)} đ</div>
                                    </div>
                                )}

                                <div className={"info-item"}>
                                    <div className={"title-info-item"}>Thành tiền</div>
                                    <div className={"font-bold color-gray total"}>
                                        {numberWithCommas(state.totalPrice, "đ")}
                                    </div>
                                </div>

                                {state.note && (
                                    <div className={"info-item note-item"}>
                                        <div className={"title-info-item"}>
                                            <div style={{ width: 200 }}>Ghi chú</div>
                                        </div>
                                        <div className={"color-gray note"}>{state.note}</div>
                                    </div>
                                )}
                                {state.cancelReason && state.status === -1 && (
                                    <div className={"info-item note-item"}>
                                        <div className={"title-info-item"}>
                                            <div style={{ width: 200 }}>Lý do huỷ hàng</div>
                                        </div>
                                        <div className={"color-gray note"}>{state.cancelReason}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={"order-detail__list-pro-order mt-16"}>
                <Row gutter={16}>
                    <Col md={24} lg={14}>
                        <div className={"wrapper-table style-box"}>
                            <div className={"title"}>Danh sách sản phẩm</div>
                            <Table
                                columns={columns}
                                locale={{
                                    emptyText: <EmptyComponent />,
                                }}
                                bordered
                                dataSource={getDataSource(state.orderItems)}
                                loading={state.loadingGetDetailOrder}
                            />
                        </div>
                    </Col>
                    <Col md={24} lg={10}>
                        <div className={"wrapper-history style-box"}>
                            <div className={"title"}>Lịch sử đặt hàng</div>
                            <div className={"ccccc"}>
                                {state.orderHistories.map((value: any) => {
                                    return (
                                        <Row justify={"space-between"}>
                                            <div className={"title-history"}>
                                                {value.status === 0 && "Thời gian đặt hàng"}
                                                {value.status === -1 && "Hủy đặt hàng"}
                                                {value.status === 1 && "Nhà cung cấp xác nhận"}
                                                {value.status === 2 && "Đang giao"}
                                                {value.status === 3 && "Đã giao"}
                                                {value.status === -2 && "Hoàn hàng"}
                                                {value.status === 4 && "Không liên lạc được với KH"}
                                            </div>
                                            <div>
                                                {value.userRole === 3 ? "Khách hàng:" : "Admin: "} {value.updateByUser}
                                            </div>
                                            <div className={"value"}>
                                                {moment(value.updateAt).format("HH:mm DD/MM/YYYY")}
                                            </div>
                                        </Row>
                                    );
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal
                title={"Hủy đơn hàng"}
                visible={isOpenModal}
                footer={false}
                onCancel={() => {
                    resetForm();
                    setIsOpenModal(false);
                }}
            >
                <Form
                    onFinish={(data) => {
                        onChangeStatus(params.id, -1, data.note);
                    }}
                    form={formNote}
                >
                    <Form.Item
                        label={"Lý do hủy đơn"}
                        name={"note"}
                        rules={[
                            { required: true, message: "Vui lòng nhập lý do hủy đơn." },
                            { pattern: /^[\s\S]{0,255}$/, message: "Lý do hủy đơn không quá 255 ký tự" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                        <Row gutter={[16, 16]}>
                            <Col lg={11}>
                                <Button
                                    className={"btn--red"}
                                    onClick={() => {
                                        setIsOpenModal(false);
                                        resetForm();
                                    }}
                                >
                                    Hủy
                                </Button>
                            </Col>
                            <Col lg={11}>
                                <Button
                                    className={"btn--green"}
                                    loading={state.loadingStatusCancel}
                                    htmlType={"submit"}
                                >
                                    Xác nhận
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default OrderProductDetail;
