import AxiosClient from "api/axios/AxiosClient";

export const configService = {
    getConfigs: () => {
        return AxiosClient.get("configuration");
    },
    addUpdateConfig: (payload: any) => {
        return AxiosClient.post("configuration", payload);
    },
    addYoutubeConfig: () => {
        return AxiosClient.get("youtube_livestream/auth");
    },
    getYoutubeConfig: () => {
        return AxiosClient.get("youtube_livestream/youtube_oauth");
    },
};
