import React, { useEffect, useState } from "react";
import { Col, DatePicker, Row } from "antd";
import Statistical from "./components/Statistical";
import OrderComponent from "./components/OrderComponent";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import LiveStreamComponent from "./components/LiveStreamComponent";
import AppTitle from "../../commons/components/title-component/AppTitle";
import { numberWithCommas } from "../../service/Format";
import LineChart, { IChartLine, ILineConfig } from "../../commons/components/chart/LineChart";
import "./Dashboard.scss";
import { Config, disabledDate } from "../../commons/validation/ValidationPattern";
import { IResChartData, IResDashboard } from "../../api/dashboard/Interface";
import { getDataDashboardAPI } from "../../api/dashboard/Dashboard";
import LoadingComponent from "../../commons/components/loading/LoadingComponent";
import { Moment } from "../../service/Moment";
import { openNotification } from "../../commons/components/notifycation/Notification";

const { RangePicker } = DatePicker;

const Dashboard: React.FC<any> = () => {
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [state, setState] = useState<any>();
    const [dataChartOrder, setDataChartOrder] = useState<IChartLine | undefined>(undefined);
    const [dataChartLivestream, setDataCharLivestream] = useState<IChartLine | undefined>(undefined);

    const getData = async (startDate: string, endDate: string) => {
        const getDataChartOrder = (data: IResChartData[]): IChartLine => {
            let rs: IChartLine = {
                labels: [],
                datasets: [],
            };
            let labels: string[] = [];

            let waitConfirmOrder: number[] = []; // Chờ xác nhận
            let confirmedOrder: number[] = []; // Đã xác nhận
            let deliveringOrder: number[] = []; // Đã xác nhận
            let deliveredOrder: number[] = []; // Đã xác nhận
            let returnOrder: number[] = []; // Hoàn hàng
            let dataCancelOrder: number[] = []; // Huỷ

            data.forEach((value) => {
                labels.push(value.time);

                waitConfirmOrder.push(value?.value[0]);
                confirmedOrder.push(value?.value[1]);
                deliveringOrder.push(value?.value[2]);
                deliveredOrder.push(value?.value[3]);
                dataCancelOrder.push(value?.value[3]);
                returnOrder.push(value?.value[5]);
                // dataCancelOrder.push(value?.value[1]);
            });

            let datasetsWaitConfirmOrder: ILineConfig = {
                label: "Chờ xác nhận",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "purple",
                borderColor: "purple",
                borderWidth: 2,
                data: waitConfirmOrder,
            };

            let datasetsConfirmedOrder: ILineConfig = {
                label: "Đã xác nhận",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "#8de9cd",
                borderColor: "#8de9cd",
                borderWidth: 2,
                data: confirmedOrder,
            };

            let datasetsReturnOrder: ILineConfig = {
                label: "Hoàn hàng",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "orange",
                borderColor: "orange",
                borderWidth: 2,
                data: returnOrder,
            };

            let datasetsDeliveredOrder: ILineConfig = {
                label: "Đã giao",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "#d27b7b",
                borderColor: "#d27b7b",
                borderWidth: 2,
                data: deliveredOrder,
            };

            let datasetsDeliveringOrder: ILineConfig = {
                label: "Đang giao",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "blue",
                borderColor: "blue",
                borderWidth: 2,
                data: deliveringOrder,
            };

            let datasetsCancelOrder: ILineConfig = {
                label: "Hủy",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "red",
                borderColor: "red",
                borderWidth: 2,
                data: dataCancelOrder,
            };

            rs.labels = labels;
            rs.datasets.push(datasetsWaitConfirmOrder);
            rs.datasets.push(datasetsConfirmedOrder);
            rs.datasets.push(datasetsDeliveringOrder);
            rs.datasets.push(datasetsDeliveredOrder);
            rs.datasets.push(datasetsCancelOrder);
            rs.datasets.push(datasetsReturnOrder);

            return rs;
        };

        const getDataChartLivestream = (data: IResChartData[]): IChartLine => {
            let rs: IChartLine = {
                labels: [],
                datasets: [],
            };

            let labels: string[] = [];
            let dataViewStream: number[] = [];
            let dataAverageTimeStream: number[] = [];
            let dataCommentLivestream: number[] = [];
            let dataLikeLivestream: number[] = [];

            data.forEach((value) => {
                labels.push(value.time);
                dataViewStream.push(value?.value[0]);
                dataAverageTimeStream.push(value?.value[1]);
                dataCommentLivestream.push(value?.value[2]);
                dataLikeLivestream.push(value?.value[3]);
            });

            let datasetsViewStream: ILineConfig = {
                label: "Lượt xem",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "orange",
                borderColor: "orange",
                borderWidth: 2,
                data: dataViewStream,
            };

            let datasetsAverageTimeStream: ILineConfig = {
                label: "Thời gian xem trung bình",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "purple",
                borderColor: "purple",
                borderWidth: 2,
                data: dataAverageTimeStream,
            };

            let datasetsCommentLivestream: ILineConfig = {
                label: "Lượt bình luận",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "blue",
                borderColor: "blue",
                borderWidth: 2,
                data: dataCommentLivestream,
            };

            let datasetsLikeLivestream: ILineConfig = {
                label: "Lượt thích",
                fill: false,
                lineTension: 0.5,
                backgroundColor: "red",
                borderColor: "red",
                borderWidth: 2,
                data: dataLikeLivestream,
            };

            rs.labels = labels;
            rs.datasets.push(datasetsViewStream);
            rs.datasets.push(datasetsAverageTimeStream);
            rs.datasets.push(datasetsCommentLivestream);
            rs.datasets.push(datasetsLikeLivestream);

            return rs;
        };

        let rs = await getDataDashboardAPI(startDate, endDate);
        if (rs.body.status === 1) {
            setState(rs.body.data);
            setDataChartOrder(getDataChartOrder(rs.body.data.orderChartData));
            setDataCharLivestream(getDataChartLivestream(rs.body.data.livestreamChartData));
        } else {
            openNotification("ERROR", rs.body.message);
        }
    };

    useEffect(() => {
        getData(startDate, endDate);
    }, [startDate, endDate]);

    return (
        <div>
            {state ? (
                <div className={"dashboard"}>
                    <TitleComponent
                        title={"Tổng quan"}
                        component={
                            <RangePicker
                                placeholder={Config.placeholderDatePicker}
                                format={[Config.formatDate, Config.formatDate]}
                                disabledDate={disabledDate}
                                inputReadOnly={true}
                                onCalendarChange={(date: any, formatString: string[]) => {
                                    setStartDate(formatString[0]);
                                    setEndDate(formatString[1]);
                                }}
                            />
                        }
                    />

                    <div className={"dashboard__statistical"}>
                        <div className={"dashboard__statistical__item"}>
                            <Statistical
                                title={"Doanh thu"}
                                value={state.totalRevenue}
                                color={"red"}
                                background={<i style={{ width: 12, height: 12 }} className="fal fa-money-check-alt" />}
                                currency={"đ"}
                            />
                        </div>

                        <div className={"dashboard__statistical__item"}>
                            <Statistical
                                title={"Đơn hàng"}
                                value={state.totalOrder}
                                color={"orange"}
                                background={<i className="fal fa-cart-plus" />}
                            />
                        </div>

                        <div className={"dashboard__statistical__item"}>
                            <Statistical
                                title={"Khách hàng"}
                                value={state.totalCustomer}
                                color={"blue"}
                                background={<i className="fal fa-users" />}
                            />
                        </div>

                        <div className={"dashboard__statistical__item"}>
                            <Statistical
                                title={"Live stream"}
                                value={state.totalLivestream}
                                color={"green"}
                                background={<i className="fal fa-video" />}
                            />
                        </div>

                        <div className={"dashboard__statistical__item"}>
                            <Statistical
                                title={"Sản phẩm"}
                                value={state.totalProduct}
                                color={"black"}
                                background={<i className="fal fa-box-open" />}
                            />
                        </div>
                        <div className={"dashboard__statistical__item"}>
                            <Statistical
                                title={"Số khách hàng online"}
                                value={state.totalOnlineCustomer}
                                color={"black"}
                                background={<i className="fas fa-blog" />}
                            />
                        </div>
                    </div>

                    <Row gutter={8} style={{ marginTop: 16 }}>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <OrderComponent
                                dataValue={{
                                    completed: state.totalDoneOrder,
                                    pending: state.totalWaitConfirmationOrder,
                                    confirmed: state.totalConfirmOrder,
                                    delivering: state.totalDeliveringOrder,
                                    delivered: state.totalDeliveredOrder,
                                    returned: state.totalReturnOrder,
                                    cancel: state.totalCancelOrder,
                                }}
                                dataChart={dataChartOrder}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8}>
                            <LiveStreamComponent data={state.listLivestream} />
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginTop: 16, backgroundColor: "white", padding: 16, borderRadius: 4 }}>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={6}
                            style={{ borderRight: "2px solid rgb(240, 240, 245)", padding: "16px 32px 16px 16px" }}
                        >
                            <AppTitle title={"Tổng hợp live stream"} />
                            <div className={"mt-16"}>
                                <div className={"flex-between color-gray"} style={{ marginBottom: 8 }}>
                                    <div>Lượt xem</div>
                                    <div>{numberWithCommas(state.totalView)}</div>
                                </div>
                                <div className={"flex-between color-gray"} style={{ marginBottom: 8 }}>
                                    <div>Thời gian xem trung bình</div>
                                    <div>{Moment.msToHMS(state.avgViewTime)}</div>
                                </div>
                                <div className={"flex-between color-gray"} style={{ marginBottom: 8 }}>
                                    <div>Lượt bình luận</div>
                                    <div>{numberWithCommas(state.totalComment)}</div>
                                </div>
                                <div className={"flex-between color-gray"} style={{ marginBottom: 8 }}>
                                    <div>Lượt thích</div>
                                    <div>{numberWithCommas(state.totalLike)}</div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={18}>
                            <AppTitle title={"Biểu đồ số liệu"} />
                            <div className={"mt-16"}>
                                <LineChart data={dataChartLivestream} height={90} />
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className={"style-box"} style={{ height: "calc(100vh - 60px)" }}>
                    <LoadingComponent />
                </div>
            )}
        </div>
    );
};
export default Dashboard;
