import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { changeStatusOrderAPI, deleteOrderAPI, getListOrderProductAPI } from "../../../api/order-product/OrderProduct";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { IItemOrder } from "../../../api/order-product/Interfaces";
import { getStartIndex } from "../../../commons/validation/ValidationPattern";

export interface IDateSourceListOrder {
    key: any;
    STT: number;
    code: string;
    customer: { name: string; phone: string };
    receiver: { name: string; phone: string };
    countPro: number;
    total: number;
    status: number;
    date: string;
}

export interface IInitState {
    dataSource: IDateSourceListOrder[];
    loadingGetListOrder: boolean;
    total: number;
    loadingChangeCancel: boolean;
    loadingChangeConfirm: boolean;
    dataSourceExport: IDateSourceListOrder[];
    loadingExport: boolean;
    loadingDeleteOrder: boolean;
}

const getDataSource = (data: any[], page?: number): IDateSourceListOrder[] => {
    let rs: IDateSourceListOrder[] = [];
    data.forEach((value, index) => {
        rs.push({
            ...value,
            key: value.id,
            STT: index + 1 + getStartIndex(page),
            code: value.code,
            customer: { name: value.customerName, phone: value.customerPhone },
            receiver: { name: value.receiveAddress.name, phone: value.receiveAddress.phone },
            countPro: value.countItem,
            total: value.totalPrice,
            status: value.status,
            date: value.createAt,
        });
    });
    return rs;
};

const initialState: IInitState = {
    dataSource: [],
    loadingGetListOrder: false,
    total: 0,
    loadingChangeCancel: false,
    loadingChangeConfirm: false,
    loadingExport: false,
    dataSourceExport: [],
    loadingDeleteOrder: false,
};

export const getListOrderProductAction = createAsyncThunk(
    "orderProduct/getListOrderProductAction",
    async (arg: { page: number; search: any; status: any; startDate: string; endDate: string }, thunkAPI) => {
        const rs = await getListOrderProductAPI(arg.page, arg.search, arg.status, arg.startDate, arg.endDate);
        if (rs.body.status === 1) {
            return rs.body;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

export const changeStatusOrderAction = createAsyncThunk(
    "orderProduct/changeStatusOrderAction",
    async (arg: { id: number[]; status: number; note: string }, thunkAPI) => {
        const rs = await changeStatusOrderAPI(arg.id, arg.status, arg.note);
        if (rs.body.status === 1) {
            return { data: rs.body, status: arg.status };
        } else {
            return new Promise((resolve, reject) => reject({ status: arg.status, message: rs.body.message }));
        }
    }
);

export const getDataSourceExportAction = createAsyncThunk(
    "orderProduct/getDataSourceExportAction",
    async (arg, thunkAPI) => {
        const rs = await getListOrderProductAPI(1, "", null, "", "", 12);
        if (rs.body.status === 1) {
            return rs.body;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

export const deleteOrderAction = createAsyncThunk(
    "orderProduct/deleteOrderAction",
    async (arg: { data: { id: number[] } }, thunkAPI) => {
        const res = await deleteOrderAPI(arg.data);
        if (res.body.status === 1) {
            return res.body;
        } else {
            return new Promise((resolve, reject) => reject(res.body.message));
        }
    }
);

const orderProductSlice = createSlice({
    name: "orderProduct",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListOrderProductAction.pending, (state) => {
                state.loadingGetListOrder = true;
            })
            .addCase(getListOrderProductAction.rejected, (state, action) => {
                state.loadingGetListOrder = false;
                openNotification("ERROR", action.error.message as string);
            })
            .addCase(getListOrderProductAction.fulfilled, (state, action) => {
                state.loadingGetListOrder = false;
                state.dataSource = getDataSource(action.payload.data, action.meta.arg.page);
                state.total = action.payload.paging.totalItemCount;
            })

            .addCase(changeStatusOrderAction.pending, (state, action) => {
                let status = action.meta.arg.status;
                if (status === -1) {
                    state.loadingChangeCancel = true;
                } else if (status === 1) {
                    state.loadingChangeConfirm = true;
                }
            })
            .addCase(changeStatusOrderAction.rejected, (state, action) => {
                openNotification("ERROR", "Có lỗi xảy ra thay đổi trạng thái đơn hàng thất bại.");
            })
            .addCase(changeStatusOrderAction.fulfilled, (state, action) => {
                if (action.payload.status === -1) {
                    state.loadingChangeCancel = false;
                } else if (action.payload.status === 1) {
                    state.loadingChangeConfirm = false;
                }
                openNotification("SUCCESS", "Thay đổi trạng thái đơn hàng thành công.");
            })

            .addCase(getDataSourceExportAction.pending, (state) => {
                state.loadingExport = true;
            })
            .addCase(getDataSourceExportAction.rejected, (state, action) => {
                state.loadingExport = false;
                openNotification("ERROR", "Đã có lỗi xảy ra không lấy được dữ liệu xuất excel.");
            })
            .addCase(getDataSourceExportAction.fulfilled, (state, action) => {
                state.dataSourceExport = getDataSource(action.payload.data);
                state.loadingExport = false;
            })

            .addCase(deleteOrderAction.pending, (state) => {
                state.loadingDeleteOrder = true;
            })
            .addCase(deleteOrderAction.rejected, (state, action) => {
                state.loadingDeleteOrder = false;
                openNotification("ERROR", action.error.message as string);
            })
            .addCase(deleteOrderAction.fulfilled, (state, action) => {
                state.loadingDeleteOrder = false;
                openNotification("SUCCESS", "Xóa đơn hàng thành công.");
            });
    },
});

export default orderProductSlice.reducer;
