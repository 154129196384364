import { PageHeader, Row, Spin, Switch, Table } from "antd";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import { Btn } from "commons/components/btn/Button";
import Container from "commons/components/container";
import React from "react";
import AppServices from "service/AppServices";
import useDebounce from "utils/useDebounce";
import Filter from "../component/Filter";
import IconAntd from "commons/components/icon/IconAntd";
import CustomPopConfirm from "commons/components/pop-confirm";
import { Notification, formatNumber, momentToStringDate, scrollTo } from "utils";
import { saleService } from "../service";

const SaleStaffPage = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);
    const [search, setSearch] = React.useState<string>("");
    const [status, setStatus] = React.useState<number>();
    const [commissionSortType, setCommissionSortType] = React.useState<number>();
    const [customerSortType, setCustomerSortType] = React.useState<number>();
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });
    const searchDebounce = useDebounce(search, 300);
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Tên sale</b>,
            dataIndex: "name",
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: "phone",
        },
        {
            title: <b>Hoa hồng</b>,
            dataIndex: "reward",
            render: (value: number) => formatNumber(value || 0),
            sorter: (a: any, b: any) => a?.reward - b?.reward,
        },
        {
            title: <b>Số KH giới thiệu</b>,
            dataIndex: "count_referral",
            render: (value: number) => formatNumber(value || 0),
            sorter: (a: any, b: any) => a?.count_referral - b?.count_referral,
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: "create_at",
            render: (value: string) => momentToStringDate(value),
        },
        {
            title: <b>Trạng thái</b>,
            dataIndex: "status",
            render: (value: number, record: any) => (
                <Switch
                    checked={value === 1}
                    onChange={() => onChangeSaleStatus(record?.id, value === 1 ? false : true)}
                />
            ),
        },
        {
            title: <b>Thao tác</b>,
            width: 100,
            dataIndex: "",
            render: (_: any, record: any) => {
                return (
                    <>
                        <a onClick={() => AppServices.history!.push(pathMenu.add_sale_staff, { saleId: record?.id })}>
                            <IconAntd style={{ margin: "0 10px" }} icon="EditOutlined" size={"18px"} />
                        </a>
                        <CustomPopConfirm
                            title="Bạn có chắc chắn muốn xoá nhân viên sale này?"
                            onConfirm={() => onDeleteSale(record?.id)}
                        />
                    </>
                );
            },
        },
    ];

    const onDeleteSale = async (id: number) => {
        try {
            setLoading(true);
            const res: any = await saleService.deleteSale(id);
            if (res?.status) {
                Notification("success", "Xoá nhân viên sale thành công!");
                onGetSaleList();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onGetSaleList = async () => {
        try {
            setLoading(true);
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce,
                status,
                referral_order: customerSortType,
                commission_order: commissionSortType,
                start_date: dateRange.fromDate,
                end_date: dateRange.toDate,
            };
            const res: any = await saleService.getSaleStaffs(payload);
            if (res?.status) {
                setData(res?.data);
                setPaging({
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onChangeSaleStatus = async (id: number, value: any) => {
        try {
            setLoading(true);
            const payload = {
                is_active: value,
            };
            const res: any = await saleService.changeSaleStatus(id, payload);
            if (res?.status) {
                Notification("success", "Cập nhật trạng thái nhân viên sale thành công!");
                onGetSaleList();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onExportExcel = async () => {
        try {
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce,
                status,
                start_date: dateRange.fromDate,
                end_date: dateRange.toDate,
                referral_order: customerSortType,
                commission_order: commissionSortType,
            };
            const res = await saleService.exportExcel(payload);
            if (res?.status) {
                Notification("success", "Xuất file excel thành công!");
                window.open(res?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetSaleList();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [searchDebounce, status, dateRange, customerSortType, commissionSortType]);

    React.useEffect(() => {
        onGetSaleList();
    }, [paging.current]);

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        title="Danh sách nhân viên sale"
                        extra={[
                            <Row justify="space-between" style={{ width: 250 }}>
                                <Btn value={"Xuất excel"} onClick={onExportExcel} backGroundColor={"#e78310"} />
                                <Btn
                                    value={"Thêm mới"}
                                    onClick={() => AppServices.history!.push(pathMenu.add_sale_staff)}
                                    backGroundColor={"#109703"}
                                />
                            </Row>,
                        ]}
                    />
                }
                filterComponent={
                    <Filter
                        setCommissionSortType={setCommissionSortType}
                        setCustomerSortType={setCustomerSortType}
                        search={search}
                        setSearch={setSearch}
                        setStatus={setStatus}
                        setDateRange={setDateRange}
                    />
                }
                contentComponent={[
                    <>
                        <Row>
                            <p>
                                Kết quả lọc: <b>{paging.total}</b>
                            </p>
                        </Row>
                        <Table
                            id="top-table"
                            style={{ width: "100%" }}
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: "Chưa có bản ghi nào!",
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: (page) => {
                                    setPaging({ ...paging, current: page });
                                    setTimeout(() => {
                                        scrollTo("start");
                                    }, 500);
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    );
};

export default SaleStaffPage;
