import { notification } from "antd";
import moment from "moment";

export const toNonAccentVietnamese = (str: string = "") => {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
};

export function checkSameValue(array: any[], key: string) {
    console.log("🚀 ~ checkSameValue ~ array:", array);
    const firstValue = array[0][key];

    return array.every((obj) => (obj[key] && firstValue) || (!obj[key] && !firstValue));
}

export const EMAIL_REGEX =
    /^(([^<>()[\]\.,;!_:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const EMAIL_REGEX_2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const scrollTo = (position: "start" | "end") => {
    const element: any = document.getElementById("top-table");
    element.scrollIntoView({ block: position });
};
const REG_PHONE = /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8})$/;
const MOBI = /((^(\+84|84|0|0084){1})(3)(2|3|4|5|6|7|8|9))+([0-9]{7})$/;
const VINA = /((^(\+84|84|0|0084){1})(8)(2|3|4|5|6|8|9))+([0-9]{7})$/;
const VIETTEL = /((^(\+84|84|0|0084){1})(9)(3|4|1|6|7|8|9|0))+([0-9]{7})$/;
const SEVEN = /((^(\+84|84|0|0084){1})(7)(0|6|7|8|9))+([0-9]{7})$/;
const FIVE = /((^(\+84|84|0|0084){1})(5)(9))+([0-9]{7})$/;

export { MOBI, VINA, VIETTEL, SEVEN, FIVE };

export const momentToStringDate = (date: string | Date, type = "date") => {
    switch (type) {
        case "date":
            return date ? moment(date).utc().format("DD-MM-YYYY") : "";
        case "dateTime":
            return moment(date).format("HH:mm DD-MM-YYYY");
        case "time":
            return moment(date).format("HH:mm");
        default:
            return "";
    }
};

export function uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

type NotificationType = "success" | "info" | "warning" | "error";

export const Notification = (status: NotificationType, msg: any, icon?: any, duration?: number, onClick?: any) => {
    if (status !== "error") {
        notification[status]({
            message: "Thông báo",
            description: msg,
            icon,
            duration,
            onClick: () => onClick(),
        });
    } else {
        notification[status]({
            message: "Thông báo",
            description: msg,
        });
    }
};

export const STAFF_CODE = /^[a-zA-Z]+$/;

export function formatNumber(num: any) {
    if (num === null || num === undefined || num === 0 || Number.isNaN(parseFloat(num))) return 0;
    num = Math.round(num * 100) / 100;
    var result = num.toString().replace(/,/g, "");
    return result.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}
