import { Button, PageHeader, Row } from "antd";
import React from "react";
import Container from "./Container";
import ToolSpamCommentChild from "../component/ToolSpamCommentChild";
import { toolSpamLivestreamService } from "../service";
import AppServices from "service/AppServices";
import { pathMenu } from "commons/components/Menu/MenuComponent";

const LivestreamEmulatorPage = () => {
    const params = new URLSearchParams(window.location.search);
    const countUserParam: number = Number(params.get("count-user") as string) || 5;
    const timeIntervalComment: number = Number(params.get("time-interval-comment") as string) || -1;
    const timeIntervalReaction: number = Number(params.get("time-interval-reaction") as string) || -1;
    const liveStreamId: number = Number(params.get("livestream-id") as string) || -1;
    const [currentActiveUsers, setCurrentActiveUsers] = React.useState<number>(0);

    let rs: any[] = [];
    for (let i = 0; i < countUserParam; i++) {
        rs.push(i);
    }

    return (
        <Container
            header={
                <PageHeader
                    title="Giả lập livestream1"
                    extra={
                        <Button type="primary" onClick={() => AppServices.history!.push("/login")}>
                            Đăng nhập
                        </Button>
                    }
                />
            }
            contentComponent={[
                <div>
                    {rs?.length > 0 ? (
                        <div>
                            <Row justify="space-between">
                                <p>
                                    timeIntervalComment: <b>{timeIntervalComment}</b>
                                </p>
                                <p>
                                    {" "}
                                    timeIntervalReaction: <b>{timeIntervalReaction}</b>
                                </p>
                                <p>
                                    {" "}
                                    liveStreamId: <b>{liveStreamId}</b>
                                </p>
                            </Row>
                            {rs.map((value, index) => (
                                <ToolSpamCommentChild
                                    index={index}
                                    key={index}
                                    isReaction={true}
                                    setCurrentActiveUsers={setCurrentActiveUsers}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>Chưa có livestream đang diễn ra!</p>
                    )}
                </div>,
            ]}
        />
    );
};

export default LivestreamEmulatorPage;
