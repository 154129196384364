import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import { Config, ValidationMes, ValidationPattern } from "../../../commons/validation/ValidationPattern";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  createCateAction, getListCateAction, getListCateChildAction,
  updateCateAction,
} from "../../../app/slices/product-category/ProductCategory";
import { IDateTableCateChild, IDateTableProductCate } from "../interfaces";
import UploadListFileComponent from "../../product/page/add-update/component/UploadListFileComponent";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { useForm } from "antd/es/form/Form";

interface IFormAdd {
  eventClose: () => any;
}

interface IFormAddChild extends IFormAdd {
  data: IDateTableProductCate;
}

interface IFormUpdate {
  data: IDateTableProductCate;
  eventClose: () => any;
}

interface IFormUpdateChild {
  data: IDateTableCateChild;
  eventClose: () => any;
  nameParent: string;
}

const { layoutForm } = Config;

export const FormAddCate: React.FC<IFormAdd> = ({ eventClose }) => {

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const dispatch = useAppDispatch();

  const [url, setUrl] = useState<string>("");

  const onFinish = async (value: any) => {
    const { nameCate, index } = value.cate;
    if (nameCate.trim()) {
      if (url) {
        if (nameCate.trim()) {
          if (Number.isInteger(index)) {
            const rs = await dispatch(createCateAction({
              name: nameCate.trim(),
              iconUrl: url,
              displayOrder: index,
              parentId: null,
            }));
            if (rs.type === "productCate/createCate/fulfilled") {
              eventClose();
            }
          } else {
            openNotification("ERROR", "STT hiển thị là 1 số nguyên dương.");
          }
        } else {
          alert();
        }
      } else {
        openNotification("ERROR", "Vui lòng nhập ảnh.");
      }
    } else {
      openNotification("ERROR", "Tên danh mục không hợp lệ.");
    }
  };

  return (
    <Form {...layout} name={"addAndEditProductCate"} onFinish={onFinish}>
      <Form.Item
        name={["cate", "nameCate"]}
        label={"Tên danh mục"}
        rules={[
          { required: true, message: "Vui lòng nhập tên danh mục" },
          { pattern: ValidationPattern.cateName, message: ValidationMes.cateName },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["cate", "index"]}
        label={"STT hiển thị"}
        rules={[
          { required: true, message: "STT hiển thị không được bỏ trống." },
          { type: "number", min: 1, message: "STT hiển thị là 1 số nguyên dương" },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name={["cate", "icon"]}
        label={<div><span style={{ color: "red" }}>*</span> Icon danh mục</div>}
      >
        <UploadListFileComponent type={"picture-card"} limit={1} name={"image"}
                                 logData={data => {
                                   if (data[0] && data[0].status === "done") {
                                     setUrl(data[0].response.data.filename);
                                   } else if (data.length === 0) {
                                     setUrl("");
                                   }
                                 }}
                                 path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                 size={Config.sizeImg}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Row gutter={16}>
          <Col lg={12}>
            <Button onClick={() => eventClose()}
                    className={"btn--red mr-16"}
            >
              Hủy
            </Button>
          </Col>
          <Col lg={12}>
            <Button
              htmlType={"submit"}
              loading={useAppSelector(state => state.productCategory.loadingAddOrUpdateCate)}
              className={"btn--green"}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export const FormUpdateCate: React.FC<IFormUpdate> = ({ data, eventClose }) => {

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [url, setUrl] = useState<string>(data.iconName);

  const onFinish = async (value: any) => {
    const { nameCate, index } = value;
    if (nameCate.trim) {
      if (url) {
        if (Number.isInteger(index)) {
          let rs = await dispatch(updateCateAction({
            id: data.key,
            name: nameCate.trim(),
            iconUrl: url,
            displayOrder: index,
          }));
          if (rs.type === "productCate/updateCateAction/fulfilled") {
            eventClose();
          }
        } else {
          openNotification("ERROR", "STT hiển thị là 1 số nguyên dương.");
        }

      } else {
        openNotification("ERROR", "Vui lòng nhập ảnh.");
      }
    } else {
      openNotification("ERROR", "Tên danh mục không hợp lệ.");
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      nameCate: data.nameCate,
      index: data.index,
      icon: url,
    });
  }, []);

  return (
    <Form {...layout} form={form} name={"addAndEditProductCate"} onFinish={onFinish}>
      <Form.Item
        name={"nameCate"}
        label={"Tên danh mục"}
        rules={[
          { required: true, message: ValidationMes.required },
          { pattern: ValidationPattern.cateName, message: ValidationMes.cateName },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"index"}
        label={"STT hiển thị"}
        rules={[
          { required: true, message: "STT hiển thị không được bỏ trống" },
          { type: "number", min: 1, message: "STT hiển thị là 1 số nguyên dương" },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name={"icon"}
        label={<div><span style={{ color: "red" }}>*</span> Icon danh mục</div>}
      >
        <UploadListFileComponent type={"picture-card"} limit={1} name={"image"}
                                 logData={data => {
                                   if (data[0] && data[0].status === "done") {
                                     setUrl(data[0].response.data.filename);
                                   } else if (data.length === 0) {
                                     setUrl("");
                                   }
                                 }}
                                 path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                 size={Config.sizeImg}
                                 defaultData={url ? [
                                   {
                                     uid: 0,
                                     status: "done",
                                     name: data.iconName,
                                     url: data.icon,
                                   },
                                 ] : []}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Row gutter={16}>
          <Col lg={12}>
            <Button onClick={() => eventClose()}
                    className={"btn--red mr-16"}
            >
              Hủy
            </Button

            >
          </Col>
          <Col lg={12}>
            <Button htmlType={"submit"}
                    loading={useAppSelector(state => state.productCategory.loadingAddOrUpdateCate)}
                    className={"btn--green"}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export const FormAddCateChild: React.FC<IFormAddChild> = ({ eventClose, data }) => {

  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [url, setUrl] = useState<string>("");

  const resetForm = () => {
    form.setFieldsValue({
      cate: {
        name: "",
        index: null,
      },
    });
    setUrl("");
  };

  const onFinish = async (value: any) => {
    const { name, index } = value.cate;
    if (name.trim()) {
      if (Number.isInteger(index)) {
        const parentId = data.key;
        const rs = await dispatch(createCateAction({
          name: name.trim(),
          displayOrder: index,
          iconUrl: url,
          parentId: parentId,
        }));
        if (rs.type === "productCate/createCate/fulfilled") {
          eventClose();
          dispatch(getListCateAction({
            page: 1,
            status: null,
            toDate: null,
            search: null,
            fromDate: null,
            parentId: null,
          }));
          dispatch(getListCateChildAction({
            page: 1,
            status: null,
            toDate: null,
            search: null,
            fromDate: null,
            parentId: data.key,
          }));
          resetForm();
          setUrl("");
        }
      } else {
        openNotification("ERROR", "STT hiển thị là 1 số nguyên dương.");
      }
    } else {
      openNotification("ERROR", "Tên danh mục không hợp lệ.");
    }
  };

  useEffect(() => {
    resetForm();
  }, []);

  return (
    <Form {...layoutForm}
          name={"addAndEditProductCate"}
          onFinish={onFinish} form={form}
    >
      <Form.Item label={"Danh mục cha"}>
        {data.nameCate}
      </Form.Item>

      <Form.Item
        label={"Tên danh mục con"}
        name={["cate", "name"]}
        rules={[
          { required: true, message: "Vui lòng nhập tên danh mục con." },
          {
            pattern: ValidationPattern.cateName,
            message: ValidationMes.cateName,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={"STT hiển thị"}
        name={["cate", "index"]}
        rules={[
          { required: true, message: "STT hiển thị không được bỏ trống." },
          { type: "number", min: 1, message: "STT hiển thị là 1 số nguyên dương" },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={"Icon danh mục"}
        name={["cate", "icon"]}
      >
        <UploadListFileComponent type={"picture-card"} limit={1} name={"image"}
                                 logData={data => {
                                   if (data[0] && data[0].status === "done") {
                                     setUrl(data[0].response.data.filename);
                                   } else if (data.length === 0) {
                                     setUrl("");
                                   }
                                 }}
                                 path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                 size={Config.sizeImg}
                                 defaultData={url ? [
                                   {
                                     uid: 0,
                                     status: "done",
                                     name: data.iconName,
                                     url: data.icon,
                                   },
                                 ] : []}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layoutForm.wrapperCol, offset: 4 }}>
        <Row gutter={16}>
          <Col lg={12}>
            <Button onClick={() => {
              eventClose();
              resetForm();
            }}
                    className={"btn--red mr-16"}
            >
              Hủy
            </Button>
          </Col>
          <Col lg={12}>
            <Button htmlType={"submit"}
                    className={"btn--green"}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export const FormUpdateCateChild: React.FC<IFormUpdateChild> = ({ eventClose, data, nameParent }) => {

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [url, setUrl] = useState<string>(data.iconName);

  const onFinish = async (value: any) => {
    const { name, index } = value;
    if (Number.isInteger(index)) {
      if (name.trim()) {
        const rs = await dispatch(updateCateAction({
          id: data.key,
          name: name.trim(),
          iconUrl: url,
          displayOrder: index,
        }));
        if (rs.type === "productCate/updateCateAction/fulfilled") {
          eventClose();
        }
      } else {
        openNotification("ERROR", "Tên danh mục không hợp lệ.");
      }
    } else {
      openNotification("ERROR", "STT hiển thị là 1 số nguyên dương.");
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data.nameCate,
      index: data.index,
      icon: data.icon,
    });
  }, [data]);

  return (
    <Form {...layoutForm} form={form} name={"addAndEditProductCate"} onFinish={onFinish}>
      <Form.Item label={"Danh mục cha"}>
        <b>{nameParent}</b>
      </Form.Item>

      <Form.Item
        label={"Tên danh mục con"}
        name={"name"}
        rules={[
          { required: true, message: "Vui lòng nhập tên danh mục con." },
          {
            pattern: ValidationPattern.cateName,
            message: ValidationMes.cateName,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={"STT hiển thị"}
        name={"index"}
        rules={[
          { required: true, message: "STT hiển thị không được bỏ trống." },
          { type: "number", min: 1, message: "STT hiển thị là 1 số nguyên dương" },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={"Icon danh mục"}
        name={"icon"}
      >
        <UploadListFileComponent type={"picture-card"} limit={1} name={"image"}
                                 logData={data => {
                                   if (data[0] && data[0].status === "done") {
                                     setUrl(data[0].response.data.filename);
                                   } else if (data.length === 0) {
                                     setUrl("");
                                   }
                                 }}
                                 path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                 size={Config.sizeImg}
                                 defaultData={url ? [
                                   {
                                     uid: 0,
                                     status: "done",
                                     name: data.iconName,
                                     url: data.icon,
                                   },
                                 ] : []}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layoutForm.wrapperCol, offset: 4 }}>
        <Row gutter={16}>
          <Col lg={12}>
            <Button onClick={() => eventClose()}
                    className={"btn--red mr-16"}
            >
              Hủy
            </Button>
          </Col>
          <Col lg={12}>
            <Button htmlType={"submit"}
                    className={"btn--green"}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

