import { Select } from "antd";
import { setProducts } from "app/slices/order-product/CreateOrder";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderService } from "../service";
import useDebounce from "utils/useDebounce";

interface IProductSelect {
    record: any;
}

const ProductSelect = (props: IProductSelect) => {
    const { record } = props;

    const dispatch = useDispatch();
    const createOrderData = useSelector((state: any) => state.createOrder.data);
    const targetItem = createOrderData?.find((item: any) => item?.id === record?.id);
    console.log("🚀 ~ file: ProductSelect.tsx:18 ~ ProductSelect ~ targetItem:", targetItem);

    const [productList, setProductList] = React.useState<any[]>([]);
    const [search, setSearch] = React.useState<string>("");
    const searchDebounce = useDebounce(search, 300);

    const onChange = async (value: any) => {
        const newData = await Promise.all(
            createOrderData?.map(async (item: any) => {
                if (item?.id === record?.id) {
                    const res = await orderService.getProductDetail(value);

                    if (res?.status) {
                        // Kiểm tra xem sản phẩm đã chọn có thuộc tính hay không
                        //  - Không có thuộc tính

                        const firstItem = res?.data?.[0];

                        // Lấy giá sale nếu: flash_sale_price > 0 và flash_sale_item_remain > 0

                        if (!firstItem?.custom_attribute_option_id_1 && !firstItem?.custom_attribute_option_id_2) {
                            return {
                                ...item,
                                product_price_id: firstItem?.id,
                                attribute: undefined,
                                product: value,
                                quantity: 1,
                                isHasAttr: false,
                                price: firstItem?.price,
                                sale_price: +firstItem?.flash_sale_price,
                                quantity_sale_left: firstItem?.flash_sale_item_remain,
                                total_price:
                                    firstItem?.flash_sale_item_remain > 0
                                        ? +firstItem?.flash_sale_price * item?.quantity
                                        : firstItem?.price * item?.quantity,
                                weight: firstItem?.weight || "---",
                                wholesale_price: firstItem?.wholesale_price,
                                wholesale_quantity: firstItem?.wholesale_quantity,
                            };
                        } else {
                            return {
                                ...item,
                                product: value,
                                quantity: 1,
                                isHasAttr: true,
                                attribute: undefined,
                            };
                        }
                    }
                }
                return item;
            })
        );
        dispatch(setProducts(newData));
    };

    const onGetProducts = async () => {
        try {
            const payload = {
                page: 1,
                limit: 100,
                status: 1,
                search: searchDebounce,
                category_id: targetItem?.child_category ? targetItem?.child_category : targetItem?.parent_category,
            };
            const res: any = await orderService.getProducts(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }));
                // Kiểm tra: nếu SP không có phân loại và đã được chọn trước đó -> Ẩn sản phẩm đó đi

                setProductList(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (targetItem?.parent_category) onGetProducts();
    }, [targetItem?.parent_category, targetItem?.child_category, searchDebounce]);

    return (
        <Select
            onChange={onChange}
            showSearch
            filterOption={false}
            showArrow
            value={targetItem?.product}
            searchValue={search}
            onSearch={(value: string) => setSearch(value)}
            placeholder="Chọn sản phẩm"
            style={{ width: 170 }}
            options={productList}
            disabled={!targetItem?.parent_category}
        />
    );
};
export default memo(ProductSelect);
