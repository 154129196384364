import React, { useEffect, useState } from "react";
import "./Customer.scss";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { Btn } from "../../commons/components/btn/Button";
import { Col, DatePicker, Input, Modal, Pagination, Row, Table, Tabs, Tag } from "antd";
import { Config, disabledDate, Navigation } from "../../commons/validation/ValidationPattern";
import { IColumn } from "../../service/Interfaces";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    getDataExportAction,
    getListCustomerAction,
    IDatasourceCustomer,
    IInitState,
} from "../../app/slices/customer/Customer";
import AppServices from "../../service/AppServices";
import { EmptyComponent, SelectProvince } from "../../commons/components/AppComponent";
import { formatString, numberWithCommas } from "../../service/Format";
import moment from "moment";
import { openNotification } from "../../commons/components/notifycation/Notification";
import { IDetailCustomer, IResDetailOrder } from "../../api/customer/Interface";
import { deleteCustomerAPI, getInfoCustomerAPI, getListDetailOrderAPI } from "../../api/customer/Customer";
import LoadingComponent from "../../commons/components/loading/LoadingComponent";
import ExportCsv from "../../commons/components/export-excel/ExportCSV";
import { resetPassAPI } from "../../api/account/Account";
import { changeStatusAccAction } from "../../app/slices/account/Account";
import { firebaseConnect } from "../../FirebaseConnect";
import { setRoomAction } from "../../app/slices/chat/Chat";
import RewardPoint from "./component/RewardPoint";
import Cart from "./component/Cart";
import { formatNumber } from "utils";

interface IDataSourceBoughtHistory {
    key: number;
    col1: number;
    col2: string;
    col3: { name: string; phone: string };
    col4: number;
    col5: number;
    col6: number;
    col7: string;
}

interface IDataExport {
    STT: number;
    "Tên khách hàng": string;
    "Số điện thoại": string;
    "Tỉnh thành phố": string;
    "Doanh số trên đơn hàng": number;
    "Doanh số thực tế": number;
    "Ngày tạo": string;
}

const getDataExport = (data: IDatasourceCustomer[]): IDataExport[] => {
    let rs: IDataExport[] = [];
    data.forEach((value) =>
        rs.push({
            STT: value.col1,
            "Tên khách hàng": value.col2,
            "Số điện thoại": value.col3,
            "Tỉnh thành phố": value.col4,
            "Doanh số trên đơn hàng": value.col5,
            "Doanh số thực tế": value.col6,
            "Ngày tạo": moment(value.col7).format(Config.formatDate),
        })
    );
    return rs;
};

const ExpandTable: React.FC<{ data: IDatasourceCustomer; logger?: () => any }> = ({ data, logger }) => {
    const columns = [
        {
            // title: "STT",
            // key: "col1",
            // dataIndex: "col1",
            // width: 80,
            // render: (value) => value,
            title: "STT",
            width: 70,
            render: (text: any, record: any, index: any) => (page - 1) * 12 + index + 1,
        },
        {
            title: "Mã đơn",
            key: "col2",
            dataIndex: "col2",
            render: (value: any) => value,
        },
        {
            title: "Khách hàng",
            key: "col3",
            dataIndex: "col3",
            render: (value: { name: string; phone: string }) => (
                <div>
                    <div>{value.name}</div>
                    <div>({value.phone})</div>
                </div>
            ),
        },
        {
            title: "Số sản phẩm",
            key: "col4",
            dataIndex: "col4",
            render: (value: any) => value,
        },
        {
            title: "Tổng tiền",
            key: "col5",
            dataIndex: "col5",
            render: (value: any) => value && numberWithCommas(value, "đ"),
        },
        {
            title: "Trạng thái đơn hàng",
            key: "col6",
            dataIndex: "col6",
            render: (value: any) => (
                <div>
                    {value === -1 && <Tag color={"red"}>Hủy</Tag>}
                    {value === 0 && <Tag color={"orange"}>Chờ xác nhận</Tag>}
                    {value === 1 && <Tag color={"blue"}>Đã xác nhận</Tag>}
                    {value === 2 && <Tag color={"#fec9c9"}>Đang giao</Tag>}
                    {value === 3 && <Tag color={"#e18ee1"}>Đã giao</Tag>}
                    {value === -2 && <Tag color={"green"}>Hoàn hàng</Tag>}
                </div>
            ),
        },
        {
            title: "Ngày tạo",
            key: "col7",
            dataIndex: "col7",
            render: (value: any) => moment(value).format(Config.formatDate),
        },
    ];

    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [tab, setTab] = useState<number>(1);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [loadingResetPass, setLoadingResetPass] = useState<boolean>(false);
    const [detailCustomer, setDetailCustomer] = useState<IDetailCustomer>();
    const [dataSource, setDataSource] = useState<IDataSourceBoughtHistory[]>([]);
    const [loadingChangeStatus, setLoadingChangeStatus] = useState<boolean>(false);

    const [lastMsgRoom, setLastMsgRoom] = useState<string>("");
    const [isAdmin, setIsAdmin] = useState<number>(0);
    const [deviceId, setDeviceId] = useState<string>("");

    const getDetailCustomer = async () => {
        const rs = await getInfoCustomerAPI(data.key);
        if (rs.body.status === 1) {
            setDetailCustomer(rs.body.data);
        } else {
            openNotification("ERROR", rs.body.message);
        }
    };

    const getDetailOrder = async () => {
        const getDataSource = (data: any) => {
            let rs: any = [];
            data.forEach((value: any, index: any) =>
                rs.push({
                    key: value.id,
                    col1: ++index,
                    col2: value.code,
                    col3: { name: value.customerName, phone: value.customerPhone },
                    col4: value.productCount,
                    col5: value.totalPrice,
                    col6: value.status,
                    col7: value.createAt,
                })
            );
            return rs;
        };

        setLoading(true);
        const rs = await getListDetailOrderAPI(data.key, page, startDate, endDate);
        if (rs.body.status === 1) {
            setLoading(false);
            setDataSource(getDataSource(rs.body.data));
            setTotal(rs.body.paging.totalItemCount);
        } else {
            setLoading(false);
            openNotification("ERROR", rs.body.message);
        }
    };

    const onDeleteCustomer = async () => {
        setLoadingDelete(true);
        const rs = await deleteCustomerAPI(data.key);
        if (rs.body.status === 1) {
            setLoadingDelete(false);
            openNotification("SUCCESS", rs.body.message);
            logger && logger();
        } else {
            setLoadingDelete(false);
            openNotification("ERROR", rs.body.message);
        }
    };

    const onResetPass = async () => {
        setLoadingResetPass(true);
        let res = await resetPassAPI(data.key);
        if (res.body.status === 1) {
            openNotification("SUCCESS", "Reset mật khẩu khách hàng thành công.");
        } else {
            openNotification("ERROR", res.body.message);
        }
        setLoadingResetPass(false);
    };

    const onChangeStatus = async () => {
        setLoadingChangeStatus(true);
        const rs = await dispatch(changeStatusAccAction({ id: data.key, status: data.status ? 0 : 1 }));
        if (rs.type === "account/changeStatusAccAction/fulfilled") {
            getDetailCustomer();
            logger && logger();
        }
        setLoadingChangeStatus(false);
    };

    const createRoom = async (
        userId: number,
        deviceId: string,
        customerName: string,
        phone: string,
        avatar: string
    ) => {
        const refChatTable = firebaseConnect.database().ref(`roomTable/${userId}`);
        refChatTable.on("value", (childSnapShot, prevChildKey) => {
            if (!childSnapShot.val()) {
                const refRoomTable = firebaseConnect.database().ref(`roomTable/${userId}`);

                refRoomTable
                    .set({
                        admin: { isReadMsg: 1 },
                        lastMsg: {
                            createdAt: new Date().toISOString(),
                            isAdmin: isAdmin,
                            msg: lastMsgRoom,
                        },
                        user: {
                            deviceID: deviceId,
                            id: userId,
                            isFocus: 0,
                            isOnline: 1,
                            isReadMsg: 0,
                            name: customerName,
                            phoneNumber: phone,
                        },
                    })
                    .then((value) => {
                        dispatch(
                            setRoomAction({
                                room: {
                                    id: userId,
                                    name: customerName,
                                    phoneNumber: phone,
                                    avatar: avatar,
                                    isReadMsg: 0,
                                    isOnline: 0,
                                    lastMsg: { msg: "", createdAt: "" },
                                    deviceId: "",
                                    timeStamp: 0,
                                },
                            })
                        );
                        AppServices.history?.push(`/home/chat?room=${userId}`);
                    });
            } else {
                dispatch(
                    setRoomAction({
                        room: {
                            id: userId,
                            name: customerName,
                            phoneNumber: phone,
                            avatar: avatar,
                            isReadMsg: 0,
                            isOnline: 0,
                            lastMsg: { msg: "", createdAt: "" },
                            deviceId: "",
                            timeStamp: 0,
                        },
                    })
                );
                AppServices.history?.push(`/home/chat?room=${userId}`);
            }
        });
    };

    const getLastMsg = (userId: number) => {
        let refChatTable = firebaseConnect.database().ref(`chatTable/${userId}`);
        refChatTable.limitToLast(1).on("value", (childSnapshot, prevSnapshot) => {
            childSnapshot.forEach((a) => {
                if (a.val() && a.val().image) {
                    setLastMsgRoom("Hình ảnh");
                    if (a.val() && a.val().user._id === 0) {
                        setIsAdmin(1);
                    } else {
                        setIsAdmin(0);
                    }
                } else {
                    setLastMsgRoom(a.val().text ? a.val().text : "");
                }
            });
        });
    };

    const getDeviceId = (userId: number) => {
        const refDeviceIdUser = firebaseConnect.database().ref(`listDeviceIdUser/${userId}`);
        refDeviceIdUser.on("value", (childSnapshot, prevChildKey) => {
            setDeviceId(childSnapshot.val() ? childSnapshot.val().deviceID : "");
        });
    };

    useEffect(() => {
        setTimeout(() => {
            getDetailCustomer();
        }, 200);
        getDeviceId(data.key);
        getLastMsg(data.key);
    }, []);

    useEffect(() => {
        if (tab === 2) {
            setTimeout(() => {
                getDetailOrder();
            }, 200);
        }
    }, [tab, startDate, endDate, page]);

    return (
        <div className={"style-box"}>
            <Tabs defaultActiveKey="1" onChange={(tab) => setTab(parseInt(tab))}>
                <Tabs.TabPane tab="Thông tin chung" key="1">
                    {detailCustomer ? (
                        <div>
                            <Row gutter={16}>
                                <Col md={24} lg={8}>
                                    <div className={"title mb-16 font-bold"}>Thông tin khách hàng</div>
                                    <div className={"wrapper-list-info-user ml-16"}>
                                        <div className={"wrapper-info-user"}>
                                            <i className="fas fa-user" />
                                            <div>{formatString(detailCustomer.name)}</div>
                                        </div>
                                        <div className={"wrapper-info-user"}>
                                            <i className="fas fa-phone-alt" />
                                            <div>{formatString(detailCustomer.phone)}</div>
                                        </div>
                                        <div className={"wrapper-info-user"}>
                                            <i className="fas fa-map-marker-alt" />
                                            <div>{formatString(detailCustomer.provinceName)}</div>
                                        </div>
                                        <div className={"wrapper-info-user"}>
                                            <i className="fas fa-birthday-cake" />
                                            <div>
                                                {detailCustomer.dateOfBirth
                                                    ? moment(detailCustomer.dateOfBirth).format(Config.formatDate)
                                                    : "_ _ _"}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={24} lg={16}>
                                    <div className={"title mb-16 font-bold"}>Tổng quan bán hàng</div>
                                    <div className={"dashboard-customer"}>
                                        <div className={"dashboard-customer-row"}>
                                            <Row gutter={16}>
                                                <Col md={24} lg={12}>
                                                    <div className={"dashboard-customer-item"} style={{ color: "red" }}>
                                                        <div className={"dashboard-customer-value"}>
                                                            {numberWithCommas(detailCustomer.revenueComplete)}
                                                        </div>
                                                        <div className={"dashboard-customer-help"}>
                                                            Doanh số trên đơn
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={24} lg={12}>
                                                    <div className={"dashboard-customer-item"}>
                                                        <div
                                                            className={"dashboard-customer-value"}
                                                            style={{ color: "green" }}
                                                        >
                                                            {numberWithCommas(detailCustomer.revenueReality)}
                                                        </div>
                                                        <div className={"dashboard-customer-help"}>
                                                            Doanh số đơn chưa Hoàn thành
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className={"dashboard-customer-row"}>
                                            <Row gutter={16}>
                                                <Col md={24} lg={12}>
                                                    <div className={"dashboard-customer-item"}>
                                                        <div
                                                            className={"dashboard-customer-value"}
                                                            style={{ color: "blue" }}
                                                        >
                                                            {numberWithCommas(detailCustomer.productBoughtCount)}
                                                        </div>
                                                        <div className={"dashboard-customer-help"}>
                                                            Tổng sản phẩm đã mua
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={24} lg={12}>
                                                    <div className={"dashboard-customer-item"}>
                                                        <div
                                                            className={"dashboard-customer-value"}
                                                            style={{ color: "blue" }}
                                                        >
                                                            {numberWithCommas(detailCustomer.orderCount)}
                                                        </div>
                                                        <div className={"dashboard-customer-help"}>Tổng đơn hàng</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div
                                className={"flex-center"}
                                style={{ borderTop: "1px solid #d9d9d9", padding: "15px 0", marginTop: 8 }}
                            >
                                <Btn
                                    onClick={() =>
                                        createRoom(
                                            data.key,
                                            deviceId,
                                            data.col2,
                                            data.col3,
                                            detailCustomer?.profile_picture_url
                                        )
                                    }
                                    value={"Nhắn tin"}
                                    backGroundColor={"orange"}
                                    icon={<i className="fal fa-comments" style={{ marginRight: 4 }} />}
                                    className={"mr-16"}
                                />

                                <Btn
                                    value={"Xóa"}
                                    backGroundColor={"rgba(255, 255, 255, 0)"}
                                    color={"red"}
                                    className={"ml-16"}
                                    icon={<i className="fas fa-trash-alt" />}
                                    style={{
                                        width: 150,
                                    }}
                                    loading={loadingDelete}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: "Bạn có muốn xóa khách hàng không?",
                                            onOk: () => onDeleteCustomer(),
                                        });
                                    }}
                                />

                                <Btn
                                    value={data.status ? "Đang hoạt động" : "Ngưng hoạt động"}
                                    backGroundColor={"rgba(255, 255, 255, 0)"}
                                    color={data.status ? "#1890ff" : "gray"}
                                    className={"ml-16"}
                                    icon={
                                        data.status ? (
                                            <i
                                                className="fas fa-toggle-on"
                                                style={{ color: "#1890ff", fontSize: 20 }}
                                            />
                                        ) : (
                                            <i className="fas fa-toggle-off" style={{ color: "gray", fontSize: 20 }} />
                                        )
                                    }
                                    style={{
                                        width: 250,
                                        borderLeft: "1px solid #d9d9d9",
                                    }}
                                    loading={loadingChangeStatus}
                                    onClick={() => onChangeStatus()}
                                />

                                <Btn
                                    value={"Reset mật khẩu"}
                                    backGroundColor={"rgba(255, 255, 255, 0)"}
                                    color={"orange"}
                                    className={"ml-16"}
                                    icon={<i className="fas fa-trash-alt" />}
                                    style={{
                                        width: 200,
                                        borderLeft: "1px solid #d9d9d9",
                                        borderRadius: 0,
                                    }}
                                    loading={loadingResetPass}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: "Bạn có muốn reset mật khẩu khách hàng không?",
                                            onOk: () => onResetPass(),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <LoadingComponent />
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Thông tin mua hàng" key="2">
                    <div className={"bought-history"}>
                        <div className={"filter flex-between"}>
                            <div className={"title font-bold"}>Lịch sử mua hàng</div>
                            <div className={"flex-center"}>
                                <DatePicker.RangePicker
                                    format={[Config.formatDate, Config.formatDate]}
                                    inputReadOnly={true}
                                    placeholder={Config.placeholderDatePicker}
                                    onCalendarChange={(date, formatString) => {
                                        setStartDate(formatString[0]);
                                        setEndDate(formatString[1]);
                                    }}
                                    disabledDate={disabledDate}
                                />
                            </div>
                        </div>
                        <div className={"wrapper-table mt-16"}>
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                bordered
                                loading={loading}
                                pagination={false}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: () =>
                                            AppServices.history!.push(Navigation(record.key).orderProductDetail),
                                    };
                                }}
                                locale={{ emptyText: <EmptyComponent /> }}
                            />
                        </div>
                        <div className={"wrapper-pagination flex-end mt-16"}>
                            <Pagination
                                current={page}
                                total={total}
                                pageSize={Config.limit}
                                onChange={(page1) => setPage(page1)}
                            />
                        </div>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Điểm thưởng" key="3">
                    <RewardPoint customerId={data.key} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Giỏ hàng" key="4">
                    <Cart customerId={data.key} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

const Customer = () => {
    const columns: IColumn[] = [
        {
            title: "STT",
            key: "col1",
            dataIndex: "col1",
            width: 50,
            render: (value) => value,
        },
        {
            title: "Tên khách hàng",
            key: "col2",
            dataIndex: "col2",
            render: (value) => value,
        },
        {
            title: "Số điện thoại",
            key: "col3",
            dataIndex: "col3",
            render: (value) => value,
        },
        {
            title: "Tỉnh thành phố",
            key: "col4",
            dataIndex: "col4",
            render: (value) => formatString(value),
        },
        {
            title: "Doanh số trên đơn hàng",
            key: "col5",
            dataIndex: "col6",
            render: (value) => numberWithCommas(value, "đ"),
        },
        {
            title: "Doanh số thực tế",
            key: "col6",
            dataIndex: "col5",

            render: (value) => numberWithCommas(value, "đ"),
        },
        {
            title: "Tổng điểm tích luỹ",
            key: "total_point",
            dataIndex: "total_point",

            render: (value) => numberWithCommas(value),
        },
        {
            title: "Điểm đã sử dụng",
            key: "current_point",
            dataIndex: "current_point",

            render: (value, record) => numberWithCommas(+record?.total_point - +record?.current_point),
        },
        {
            title: "Điểm chưa sử dụng",
            key: "current_point",
            dataIndex: "current_point",

            render: (value, record) => formatNumber(value),
        },
        {
            title: "Trạng thái",
            key: "status",
            dataIndex: "status",
            render: (value) => (
                <div>
                    {value ? <Tag color={"green"}>Đang hoạt động</Tag> : <Tag color={"gray"}>Ngưng hoạt động</Tag>}
                </div>
            ),
        },

        {
            title: "Ngày tạo",
            key: "col7",
            dataIndex: "col7",
            render: (value) => moment(value).format(Config.formatDate),
        },
    ];

    const dispatch = useAppDispatch();
    let state: IInitState = useAppSelector((state) => state.customer);

    const searchParams = new URLSearchParams(window.location.search);
    const page: number = parseInt(searchParams.get("page") as string);

    const pushParams = (page: number) => {
        AppServices.history!.push(`/home/customers?page=${page}`);
    };

    const [currentPage, setCurrentPage] = useState<number>(page);
    const [search, setSearch] = useState<string>("");
    const [location, setLocation] = useState<number>(0);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const onExport = async (fn: Function) => {
        let rs = await dispatch(
            getDataExportAction({
                endDate: endDate,
                startDate: startDate,
                search: search,
                provinceId: location,
            })
        );
        if (rs.type === "customer/getDataExportAction/fulfilled") {
            fn();
        }
    };

    const getData = () => {
        dispatch(
            getListCustomerAction({
                page: currentPage,
                endDate: endDate,
                startDate: startDate,
                search: search,
                provinceId: location,
            })
        );
    };

    useEffect(() => {
        getData();
    }, [currentPage, search, location, startDate, endDate]);

    useEffect(() => {
        pushParams(1);
        setCurrentPage(1);
    }, [search, location, startDate, endDate]);

    return (
        <div className={"customer"}>
            <div className={"customer__header"}>
                <TitleComponent
                    title={"Danh sách khách hàng"}
                    component={
                        <div className={"flex-start"}>
                            <ExportCsv
                                onClick={(fn) => onExport(fn)}
                                fileName={`Bao-cao-danh-sach-khach-hang_${moment().format("DD-MM-YYYY")}`}
                                sheets={{
                                    "Danh sach khach hang": ExportCsv.getSheets(
                                        getDataExport(state.dataSourceExportCustomer)
                                    ),
                                }}
                                sheetName={["Danh sach khach hang"]}
                                loading={state.loadingExport}
                            >
                                Export
                            </ExportCsv>
                        </div>
                    }
                />
            </div>
            <div className={"customer__wrapper-table style-box mt-16"}>
                <div className={"customer__wrapper-table__filter"}>
                    <div className={"customer__wrapper-table__filter__list-filter"}>
                        <Input
                            placeholder={"Tên, số điện thoại"}
                            style={{ width: 300, marginRight: 8 }}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                        <div
                            style={{
                                display: "inline-block",
                                width: 300,
                                margin: "8px 0",
                                marginRight: 8,
                            }}
                        >
                            <SelectProvince placeholder={"Tỉnh/ Thành phố"} logger={(data) => setLocation(data.id)} />
                        </div>
                        <DatePicker.RangePicker
                            format={[Config.formatDate, Config.formatDate]}
                            style={{ width: 300 }}
                            inputReadOnly={true}
                            disabledDate={disabledDate}
                            placeholder={Config.placeholderDatePicker}
                            onCalendarChange={(value: any, formatSting: string[]) => {
                                setStartDate(formatSting[0]);
                                setEndDate(formatSting[1]);
                            }}
                        />
                    </div>
                    <div className={"customer__wrapper-table__filter__total"} style={{ fontWeight: "bold" }}>
                        Kết quả lọc: {state.totalCustomer}
                    </div>
                </div>

                <div className={"customer__wrapper-table__table mt-16"}>
                    <Table
                        columns={columns}
                        bordered
                        dataSource={state.datasourceCustomer}
                        locale={{ emptyText: <EmptyComponent /> }}
                        pagination={false}
                        expandable={{
                            expandedRowRender: (record) => <ExpandTable data={record} logger={() => getData()} />,
                            expandRowByClick: true,
                        }}
                        loading={state.loadingCustomer}
                    />
                </div>
            </div>
            <div className={"customer__pagination style-box flex-end"}>
                <Pagination
                    total={state.totalCustomer}
                    current={currentPage}
                    pageSize={Config.limit}
                    showSizeChanger={false}
                    onChange={(_page) => {
                        setCurrentPage(_page);
                        AppServices.history!.push(`/home/customers?page=${_page}`);
                        window.scroll({ top: 0, behavior: "smooth" });
                    }}
                />
            </div>
        </div>
    );
};

export default Customer;
