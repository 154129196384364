import React, { useEffect, useState } from "react";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { DatePicker, Input, Pagination, Table } from "antd";
import { Config, disabledDate, getStartIndex } from "../../commons/validation/ValidationPattern";
import moment from "moment";
import { IColumn } from "../../service/Interfaces";
import { EmptyComponent } from "../../commons/components/AppComponent";
import AppServices from "../../service/AppServices";
import { getListReportLivestreamAPI } from "../../api/report/Report";
import { IResReportLivestream } from "../../api/report/Interface";
import { numberWithCommas } from "../../service/Format";
import ExportCsv from "../../commons/components/export-excel/ExportCSV";
import { Moment } from "../../service/Moment";

interface IDataSourceReportLive {
  key: any;
  col1: number;
  col2: string;
  col3: string;
  col4: string;
  col5: string;
  col6: number;
  col7: number;
  col8: number;
}

interface IDataExport {
  "STT": number;
  "Mô tả live": string;
  "Thời gian bắt đầu": string;
  "Thời gian kết thúc": string;
  "Thời lượng": string;
  "Lượt thích": number;
  "Lượt bình luận": number;
  "Lượt xem": number
}

const getDataExport = (data: IDataSourceReportLive []): IDataExport [] => {
  let rs: IDataExport [] = [];
  data.forEach(value => rs.push({
    "STT": value.col1,
    "Mô tả live": value.col2,
    "Thời gian bắt đầu": value.col3 ? moment(value.col3).format(Config.formatTime) : "",
    "Thời gian kết thúc": value.col4 ? moment(value.col4).format(Config.formatTime) : "",
    "Thời lượng": Moment.msToHMS(parseInt(value.col5)),
    "Lượt thích": value.col6,
    "Lượt bình luận": value.col7,
    "Lượt xem": value.col8,
  }));
  return rs;
};

const ReportLiveStream: React.FC<any> = () => {

  const [datasource, setDatasource] = useState<IDataSourceReportLive []>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [startDate, setStarDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [datasourceExport, setDatasourceExport] = useState<IDataSourceReportLive []>([]);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const getDatasource = (data: IResReportLivestream[]): IDataSourceReportLive [] => {
    let rs: IDataSourceReportLive [] = [];
    data.forEach((value, index) => rs.push({
      key: value.id,
      col1: index + 1 + getStartIndex(currentPage),
      col2: value.title,
      col3: value.startTime,
      col4: value.endTime,
      col5: value.totalTime ? value.totalTime.toString() : "0",
      col6: value.countLiked,
      col7: value.countComment,
      col8: value.countViewed,
    }));
    return rs;
  };

  const getData = async (isExport?: boolean) => {
    isExport ? setLoadingExport(true) : setLoading(true);
    const res = await getListReportLivestreamAPI(isExport ? 1 : currentPage, search, startDate, endDate, isExport ? 1000000 : undefined);
    if (res.body.status === 1) {
      if (isExport) {
        setDatasourceExport(getDatasource(res.body.data));
      } else {
        setDatasource(getDatasource(res.body.data));
        setTotal(res.body.paging.totalItemCount);
      }
    }
    isExport ? setLoadingExport(false) : setLoading(false);
  };

  const onExport = async (fn: Function) => {
    await getData(true);
    fn();
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    });
  }, [currentPage, search, startDate, endDate]);

  const columns: IColumn [] = [
    {
      title: "STT",
      key: "col1",
      dataIndex: "col1",
      render: (value: number) => value,
    },
    {
      title: "Mô tả live",
      key: "col2",
      dataIndex: "col2",
      render: (value: string) => value,
    },
    {
      title: "Thời gian bắt đầu",
      key: "col3",
      dataIndex: "col3",
      render: (value: string) => value ? moment(value).format(Config.formatTime) : "___",
    },
    {
      title: "Thời gian kết thúc",
      key: "col4",
      dataIndex: "col4",
      render: (value: string) => value ? moment(value).format(Config.formatTime) : "___",
    },
    {
      title: "Thời lượng",
      key: "col5",
      dataIndex: "col5",
      render: (value: string) => Moment.msToHMS(parseInt(value)),
    },
    {
      title: "Lượt thích",
      key: "col6",
      dataIndex: "col6",
      render: (value: number) => numberWithCommas(value),
    },
    {
      title: "Lượt bình luận",
      key: "col7",
      dataIndex: "col7",
      render: (value: number) => numberWithCommas(value),
    },
    {
      title: "Lượt xem",
      key: "col8",
      dataIndex: "col8",
      render: (value: number) => numberWithCommas(value),
    },
  ];

  console.log(datasource);

  return (
    <div className={"report-livestream"}>

      <div className={"report-livestream__header"}>
        <TitleComponent title={"Báo cáo livestream"} component={<div>
          <ExportCsv fileName={`Bao-cao-livestream_${moment().format("DD-MM-YYYY")}`}
                     sheets={{ "Bao-cao-livestream": ExportCsv.getSheets(getDataExport(datasourceExport)) }}
                     sheetName={["Bao-cao-livestream"]}
                     loading={loadingExport}
                     onClick={fn => onExport(fn)}
          >
            Export
          </ExportCsv>
        </div>}
        />
      </div>

      <div className={"report-livestream__table style-box mt-16"}>

        <div className={"report-livestream__table__filter flex-between"}>

          <div>
            <Input placeholder={"Mô tả live"}
                   style={{ width: 300, marginRight: 16 }}
                   onChange={event => {
                     setSearch(event.target.value);
                     setCurrentPage(1);
                   }}
            />
            <DatePicker.RangePicker
              className={"item"}
              placeholder={Config.placeholderDatePicker}
              format={[Config.formatDate, Config.formatDate]}
              disabledDate={disabledDate}
              inputReadOnly={true}
              onCalendarChange={(date: any, formatString: [string, string]) => {
                setStarDate(formatString[0]);
                setEndDate(formatString[1]);
                setCurrentPage(1);
              }}
            />
          </div>
          <div style={{ fontWeight: "bold", color: "gray" }}>Kết quả lọc {total}</div>

        </div>
        <div className={"report-livestream__table__wrapper-table mt-16"}>
          <Table
            columns={columns}
            pagination={false}
            dataSource={datasource}
            loading={loading}
            locale={{
              emptyText: <EmptyComponent />,
            }}
            bordered
            onRow={(record, rowIndex) => {
              return {
                onClick: () => AppServices.history!.push(`/home/live-stream/${record.key}`),
              };
            }}
          />

          <div className={"flex-end mt-16"}>
            <Pagination
              current={currentPage}
              pageSize={Config.limit}
              showSizeChanger={false}
              total={total}
              onChange={page => setCurrentPage(page)}
            />
          </div>

        </div>

      </div>

    </div>
  );
};

export default ReportLiveStream;
