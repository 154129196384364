import React, { useEffect, useState } from "react";
import { Col, DatePicker, Image, Input, Pagination, Row } from "antd";
import { Config, disabledDate } from "../../commons/validation/ValidationPattern";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { IResLivestream } from "../../api/dashboard/Interface";
import { getListLivestreamAPI } from "../../api/livestream/Livestream";
import "./Livestream.scss";
import moment from "moment";
import { formatString } from "../../service/Format";
import AppServices from "../../service/AppServices";
import LoadingComponent from "../../commons/components/loading/LoadingComponent";
import { EmptyComponent } from "../../commons/components/AppComponent";
import { openNotification } from "../../commons/components/notifycation/Notification";

const LiveStream: React.FC<any> = () => {

  const [search, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [listLivestream, setListLivestream] = useState<IResLivestream []>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    let rs = await getListLivestreamAPI(currentPage, search.trim(), startDate, endDate, 16);
    if (rs.body.status === 1) {
      setListLivestream(rs.body.data);
      setTotal(rs.body.paging.totalItemCount);
    } else {
      openNotification("ERROR", rs.body.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 200);
  }, [currentPage, search, startDate, endDate]);

  return (
    <div className={"live-stream-page"}>

      <div className={"live-stream-page__header"}>
        <TitleComponent title={"Danh sách livestream"}
                        component={<div></div>} />
      </div>

      <div className={"live-stream-page__list-live style-box mt-16"}>

        <div className={"live-stream-page__list-live__filter flex-between"}>
          <div>
            <Input placeholder={"Mô tả live stream"} style={{ width: 300, marginRight: 16 }}
                   onChange={event => {
                     setSearch(event.target.value);
                     setCurrentPage(1);
                   }}
            />
            <DatePicker.RangePicker placeholder={Config.placeholderDatePicker}
                                    format={[Config.formatDate, Config.formatDate]}
                                    disabledDate={disabledDate}
                                    inputReadOnly={true}
                                    style={{ width: 300 }}
                                    onCalendarChange={(date: any, formatString: string[]) => {
                                      setStartDate(formatString[0]);
                                      setEndDate(formatString[1]);
                                    }}
            />
          </div>
          <div className={"total"}>Kết quả lọc {total}</div>
        </div>

        <div className={"live-stream-page__list-live__stream mt-24"}>
          {
            !loading ?
              (
                listLivestream.length > 0 ?
                  <div className={"wrapper-list"}>
                    <Row gutter={16} className={"mt-16 mb-16"}>
                      {
                        listLivestream.map(value =>
                          <Col xs={24} sm={24} md={12} lg={6} key={value.id} style={{ position: "relative" }}>
                            <div className={"item"}
                                 onClick={() => {
                                   AppServices.history!.push(`/home/live-stream/${value.id}`);
                                 }}
                            >
                              <Image src={value.thumbnailUrl} style={{ width: 120, height: 120 }} />
                              <div className={"content"}>
                                <div className={"title"}>{formatString(value.title)}</div>
                                <div className={"time"}>{moment(value.startTime).format(Config.formatTime)}</div>
                              </div>
                            </div>
                            {value.isLive === 1 && <div style={{
                              backgroundColor: "red",
                              color: "white",
                              position: "absolute",
                              top: 4,
                              right: 11,
                              padding: 4,
                              borderRadius: 3,
                            }}>
                                 Live
                            </div>}
                          </Col>,
                        )
                      }
                    </Row>
                  </div> : <EmptyComponent />
              )
              : <div style={{ height: "calc(100vh - 350px)" }}><LoadingComponent /></div>
          }

        </div>

        <div className={"live-stream-page__pagination flex-end"}>
          <Pagination
            current={currentPage}
            total={total}
            pageSize={16}
            showSizeChanger={false}
            onChange={page => setCurrentPage(page)}
          />
        </div>

      </div>

    </div>
  );

};

export default LiveStream;
