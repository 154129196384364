import { Col, DatePicker, Input, Row, Select } from "antd";
import React, { memo } from "react";
import Icon from "@ant-design/icons";
import { toNonAccentVietnamese } from "utils";
import moment from "moment";

interface IFilter {
    search: string;
    setDateRange: React.Dispatch<any>;
    setCommissionSortType: React.Dispatch<React.SetStateAction<number | undefined>>;
    setCustomerSortType: React.Dispatch<React.SetStateAction<number | undefined>>;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    setStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const Filter = (props: IFilter) => {
    const { search, setSearch, setDateRange, setStatus, setCommissionSortType, setCustomerSortType } = props;

    const disabledDate = (current: any) => {
        return current && current > moment().endOf("day");
    };
    return (
        <Row gutter={[18, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5} span={5}>
                <Input.Search
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Tên hoặc số điện thoại sale"
                    addonAfter={<Icon type="close-circle-o" rev={undefined} />}
                    value={search}
                    onChange={(e: any) => {
                        setSearch(e.target.value);
                    }}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4} xxl={4}>
                <Select
                    style={{ width: "100%" }}
                    placeholder="Trạng thái hoạt động"
                    allowClear
                    onChange={(value: any) => setStatus(value)}
                >
                    <Select.Option value={1}>Đang hoạt động</Select.Option>
                    <Select.Option value={0}>Ngừng hoạt động</Select.Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    onChange={(date: any, dateStrings: string[]) => {
                        setDateRange({
                            fromDate: dateStrings[0]?.split("-").join("-"),
                            toDate: dateStrings[1]?.split("-").join("-"),
                        });
                    }}
                    placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                    format={"DD-MM-YYYY"}
                    disabledDate={disabledDate}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4} xxl={4}>
                <Select
                    style={{ width: "100%" }}
                    placeholder="Sắp xếp theo hoa hồng"
                    allowClear
                    onChange={(value: any) => setCommissionSortType(value)}
                >
                    <Select.Option value={1}>Từ cao đến thấp</Select.Option>
                    <Select.Option value={0}>Từ thấp đến cao</Select.Option>
                </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={5} xl={5} xxl={5}>
                <Select
                    style={{ width: "100%" }}
                    placeholder="Sắp xếp theo số khách hàng giới thiệu"
                    allowClear
                    onChange={(value: any) => setCustomerSortType(value)}
                >
                    <Select.Option value={1}>Từ cao đến thấp</Select.Option>
                    <Select.Option value={0}>Từ thấp đến cao</Select.Option>
                </Select>
            </Col>
        </Row>
    );
};

export default memo(Filter);
