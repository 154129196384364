import { Popconfirm } from "antd";
import React from "react";
import styled from "styled-components";
import IconAntd from "../icon/IconAntd";

interface ICustomPopConfirm {
    onConfirm: any;
    title: string;
}

const CustomPopConfirm = (props: ICustomPopConfirm) => {
    const { onConfirm, title } = props;
    return (
        <Container
            placement="right"
            title={title}
            onConfirm={onConfirm}
            okText="Xoá"
            cancelText="Đóng"
            okButtonProps={{
                type: "default",
                danger: true,
            }}
            style={{ background: "red" }}
        >
            <a style={{ color: "red", marginLeft: 10 }} href="#">
                <IconAntd icon="DeleteOutlined" size={"18px"} />
            </a>
        </Container>
    );
};

const Container = styled(Popconfirm)`
    .ant-popover {
        .ant-popover-content {
            padding: 12px;

            .ant-popover-inner {
                padding: 10px;
            }
        }
    }
`;

export default CustomPopConfirm;
