import AxiosClient from "api/axios/AxiosClient";

export const saleService = {
    exportExcel: (payload: any) => {
        return AxiosClient.get("sales/export-excel", { params: payload });
    },
    getSaleStaffs: (payload: any) => {
        return AxiosClient.get("sales", { params: payload });
    },
    getSaleId: (id: number) => {
        return AxiosClient.get(`sales/${id}`);
    },
    changeSaleStatus: (id: number, payload: any) => {
        return AxiosClient.patch(`sales/${id}/is-active`, payload);
    },
    updateSale: (id: number, payload: any) => {
        return AxiosClient.patch(`sales/${id}`, payload);
    },
    addSale: (payload: any) => {
        return AxiosClient.post(`sales`, payload);
    },
    deleteSale: (id: number) => {
        return AxiosClient.delete(`sales/${id}/delete`);
    },
    getProvinces: () => {
        return AxiosClient.get(`address/province`);
    },
    getDistricts: (payload: any) => {
        return AxiosClient.get(`address/district`, { params: payload });
    },
    getWards: (payload: any) => {
        return AxiosClient.get(`address/ward`, { params: payload });
    },
    getBanks: (payload: any) => {
        return AxiosClient.get(`banks`, { params: payload });
    },
    getCustomersOfSale: (id: number, payload: any) => {
        return AxiosClient.get(`sales/${id}/customers`, { params: payload });
    },
    getWalletOfSale: (id: number, payload: any) => {
        return AxiosClient.get(`sales/${id}/wallet_history`, { params: payload });
    },
};
