import { Button, Checkbox, Form, Input, Modal, Row, Select, Spin } from "antd";
import { saleService } from "modules/sale-staff/service";
import React, { memo } from "react";
import { orderService } from "../service";
import { FIVE, MOBI, SEVEN, VIETTEL, VINA, toNonAccentVietnamese, Notification } from "utils";

interface IAddEditAddressModal {
    visible: boolean;
    selectedCusId: any;
    onGetAddresses: (isSetDefaultAddress?: boolean) => Promise<void>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddEditAddressModal = (props: IAddEditAddressModal) => {
    const { visible, setVisible, selectedCusId, onGetAddresses } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [provinces, setProvinces] = React.useState<any[]>([]);
    const [districts, setDistricts] = React.useState<any[]>([]);
    const [wards, setWards] = React.useState<any[]>([]);
    const [provinceId, setProvinceId] = React.useState<any>();
    const [districtId, setDistrictId] = React.useState<any>();
    const [isDefault, setIsDefault] = React.useState<boolean>(false);

    const onCancel = () => {
        setVisible(false);
    };

    const onGetProvinces = async () => {
        try {
            const res: any = await saleService.getProvinces();
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }));
                setProvinces(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetDistricts = async () => {
        try {
            const payload = {
                province_id: form.getFieldValue("province"),
            };
            const res: any = await saleService.getDistricts(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }));
                setDistricts(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetWards = async () => {
        try {
            const payload = {
                district_id: form.getFieldValue("district"),
            };
            const res: any = await saleService.getWards(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }));
                setWards(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            const payload = {
                name: values.name,
                phone: values.phone,
                province_id: values.province,
                district_id: values.district,
                ward_id: values.ward,
                address: values.address,
                is_default: isDefault ? 1 : 0,
            };

            const res = await orderService.addNewAddress(selectedCusId, payload);
            if (res?.status) {
                Notification("success", "Thêm mới địa chỉ thành công!");
                form.resetFields();
                setVisible(false);
                onGetAddresses(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        onGetProvinces();
    }, []);

    React.useEffect(() => {
        if (provinceId) {
            onGetDistricts();
        }
    }, [provinceId]);

    React.useEffect(() => {
        if (districtId) {
            onGetWards();
        }
    }, [districtId]);

    return (
        <Modal width={700} title="Thêm mới địa chỉ" visible={visible} onCancel={onCancel} footer={null} centered>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name={"name"}
                        label="Tên người nhận"
                        rules={[{ required: true, message: "Vui lòng nhập tên người nhận!" }]}
                    >
                        <Input allowClear placeholder="Nhập tên người nhận" />
                    </Form.Item>
                    <Form.Item
                        name={"phone"}
                        label="Số điện thoại"
                        rules={[
                            { required: true, message: "Vui lòng nhập số điện thoại!" },
                            {
                                message: "Số điện thoại không hợp lệ!",
                                validator: (_, value) => {
                                    if (!value?.toString()?.trim()) return Promise.resolve();
                                    if (
                                        (!value?.toString()?.match(SEVEN) &&
                                            !value?.toString()?.match(MOBI) &&
                                            !value?.toString()?.match(VIETTEL) &&
                                            !value?.toString()?.match(VINA) &&
                                            !value?.toString()?.match(FIVE)) ||
                                        isNaN(Number(value))
                                    ) {
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input allowClear placeholder="Nhập số điện thoại người nhận" />
                    </Form.Item>
                    <Form.Item
                        name={"province"}
                        label="Tỉnh/thành phố"
                        rules={[{ required: true, message: "Vui lòng chọn tỉnh, thành phố!" }]}
                    >
                        <Select
                            options={provinces}
                            allowClear
                            showArrow
                            placeholder="Chọn tỉnh/thành phố"
                            showSearch
                            onChange={(value: any) => {
                                setProvinceId(value);
                                setDistrictId(undefined);
                                form.setFieldsValue({
                                    district: undefined,
                                    ward: undefined,
                                });
                            }}
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase();
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase();
                                return nonAccent.includes(nonAccentInput);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"district"}
                        label="Quận/huyện"
                        rules={[{ required: true, message: "Vui lòng chọn quận, huyện!" }]}
                    >
                        <Select
                            options={districts}
                            allowClear
                            showArrow
                            placeholder="Chọn quận/huyện"
                            showSearch
                            disabled={!provinceId}
                            onChange={(value: any) => {
                                setDistrictId(value);
                                form.setFieldsValue({
                                    ward: undefined,
                                });
                            }}
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase();
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase();
                                return nonAccent.includes(nonAccentInput);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"ward"}
                        label="Phường/xã"
                        rules={[{ required: true, message: "Vui lòng chọn phường, xã!" }]}
                    >
                        <Select
                            options={wards}
                            allowClear
                            showArrow
                            placeholder="Chọn phường/xã"
                            showSearch
                            disabled={!districtId}
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase();
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase();
                                return nonAccent.includes(nonAccentInput);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"address"}
                        label="Địa chỉ cụ thể"
                        rules={[{ required: true, message: "Vui lòng nhập địa chỉ cụ thể!" }]}
                    >
                        <Input allowClear placeholder="Nhập địa chỉ cụ thể" />
                    </Form.Item>
                    <Checkbox onChange={(e: any) => setIsDefault(e.target.checked)}>Đặt làm địa chỉ mặc định</Checkbox>
                    <Row justify="end" style={{ marginTop: 20, borderTop: "1px solid #efeeee", paddingTop: 20 }}>
                        <Button type="default" onClick={onCancel}>
                            Huỷ
                        </Button>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                type="primary"
                                style={{ background: "green", marginLeft: 10 }}
                                loading={loading}
                            >
                                Lưu
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};

export default memo(AddEditAddressModal);
