import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { Config, ValidationMes, ValidationPattern } from "../../../commons/validation/ValidationPattern";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changePassAction } from "../../../app/slices/login/Login";

const FormChangePass: React.FC<{ eventCancel: () => any }> = ({ eventCancel }) => {
  const dispatch = useAppDispatch();
  const onFinish = (value: any) => {
    if (value.pass.newPass === value.pass.confirmPass) {
      dispatch(changePassAction({ newPass: value.pass.confirmPass }));
    } else {
      openNotification("ERROR", ValidationMes.passNotMatch);
    }
  };
  return (
    <div>
      <Form {...Config.layoutForm} name={"formForgotPass"} onFinish={onFinish}>

        <Form.Item
          label={"Mật khẩu mới"}
          name={["pass", "newPass"]}
          rules={[
            { pattern: ValidationPattern.pass, message: ValidationMes.pass },
            { required: true, message: "Vui lòng nhập mật khẩu mới." },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={"Xác nhận mật khẩu."}
          name={["pass", "confirmPass"]}
          rules={[
            { pattern: ValidationPattern.pass, message: ValidationMes.pass },
            { required: true, message: "Vui lòng nhập xác nhận mật khẩu." },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ ...Config.layoutForm.wrapperCol, offset: 4 }}>
          <Row gutter={16}>
            <Col lg={12}>
              <Button className={"btn--red"} onClick={() => eventCancel()}>
                Hủy
              </Button>
            </Col>
            <Col lg={12}>
              <Button
                htmlType={"submit"}
                className={"btn--green"}
                loading={useAppSelector((state) => state.login.loadingChangePass)}
              >
                Lưu
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormChangePass;
