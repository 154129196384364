import React, { useEffect } from "react";
import "./AddAndUpdateProduct.scss";
import TitleComponent from "../../../../commons/components/title-component/TitleComponent";
import { Button } from "antd";
import InfoPublicComponent from "./component/InfoPublicComponent";
import InfoSaleComponent from "./component/InfoSaleComponent";
import InfoImageComponent from "./component/InfoImageComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    genIndexTableAction,
    getDetailProductAction,
    IInitState,
    postProductAction,
    putProductAction,
    resetForm,
    validationFormAction,
} from "../../../../app/slices/add-update-product/AddAndUpdateProduct";
import { useParams } from "react-router-dom";
import AppServices from "../../../../service/AppServices";
import LoadingComponent from "../../../../commons/components/loading/LoadingComponent";

const AddAndUpdateProduct = () => {
    const dispatch = useAppDispatch();
    const state: IInitState = useAppSelector((state) => state.addAndUpdateProduct);
    let params: any = useParams();
    const pathname = window.location.pathname;
    const isAdd: boolean = pathname === "/home/products/add-product";

    const onPost = async () => {
        dispatch(validationFormAction({}));
        await dispatch(postProductAction());
    };

    const onCancel = async () => {
        AppServices.history!.push("/home/products?page=1&search=&status=&categoryId");
    };

    const onPut = async () => {
        dispatch(validationFormAction({}));
        await dispatch(putProductAction({ id: params.id }));
    };

    const getDetailData = async () => {
        if (pathname !== "/home/products/add-product") {
            await dispatch(getDetailProductAction({ id: params.id }));
            dispatch(genIndexTableAction());
        }
    };

    useEffect(() => {
        dispatch(resetForm());
        getDetailData();
        return () => {
            dispatch(resetForm());
        };
    }, []);

    if (state.loadingGetDetailProduct) {
        return <LoadingComponent />;
    } else {
        return (
            <div className={"add-product"}>
                <div className={"add-product__header"}>
                    <TitleComponent
                        title={isAdd ? "Thêm sản phẩm" : "Sửa sản phẩm"}
                        isGoBack={true}
                        component={
                            <div className={"add-product__wrapper-list-btn"}>
                                <Button
                                    type={"primary"}
                                    danger
                                    className={"add-product__wrapper-list-btn__cancel"}
                                    onClick={onCancel}
                                >
                                    Hủy
                                </Button>
                                {pathname === "/home/products/add-product" ? (
                                    <Button
                                        className={"add-product__wrapper-list-btn__save"}
                                        onClick={onPost}
                                        loading={state.loadingPostPro}
                                    >
                                        Lưu
                                    </Button>
                                ) : (
                                    <Button
                                        className={"add-product__wrapper-list-btn__save"}
                                        onClick={onPut}
                                        loading={state.loadingUpdatePro}
                                    >
                                        Lưu
                                    </Button>
                                )}
                            </div>
                        }
                    />
                </div>
                <br />
                <InfoPublicComponent exportData={(data) => console.log(data)} />
                <br />
                <InfoSaleComponent />
                <br />

                <InfoImageComponent />
                <div className={"add-product__wrapper-list-btn"}>
                    <Button
                        type={"primary"}
                        danger
                        className={"add-product__wrapper-list-btn__cancel"}
                        onClick={onCancel}
                    >
                        Hủy
                    </Button>
                    {pathname === "/home/products/add-product" ? (
                        <Button
                            className={"add-product__wrapper-list-btn__save"}
                            onClick={() => onPost()}
                            loading={state.loadingPostPro}
                        >
                            Lưu
                        </Button>
                    ) : (
                        <Button
                            className={"add-product__wrapper-list-btn__save"}
                            onClick={() => onPut()}
                            loading={state.loadingUpdatePro}
                        >
                            Lưu
                        </Button>
                    )}
                </div>
            </div>
        );
    }
};

export default AddAndUpdateProduct;
