import { Col, DatePicker, Image, Input, Modal, Popconfirm, Row, Table, Tabs, Tag } from "antd";
import { AppBtn, Btn } from "../../../commons/components/btn/Button";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteCateAction, getListCateChildAction } from "../../../app/slices/product-category/ProductCategory";
import { FormAddCateChild, FormUpdateCateChild } from "./Form";
import { IColumn } from "../../../service/Interfaces";
import { IDateTableCateChild, IDateTableProductCate } from "../interfaces";
import { Config, disabledDate } from "../../../commons/validation/ValidationPattern";
import moment from "moment";
import { EmptyComponent } from "commons/components/AppComponent";

interface ICateChild {
  data: IDateTableProductCate;
  eventChangeStatus: (id: any, status: number, parentId?: any) => any;
  eventDeleteCate: (id: any) => any;
}

interface IExpandRowTable {
  data: IDateTableProductCate;
  eventChangeStatus: (id: any, status: number, parentId?: any) => any;
  eventDeleteCate: (id: any) => any;
  eventUpdateCate: (data: IDateTableProductCate) => any;
}

const CateChild: React.FC<ICateChild> =
  ({
     data,
     eventDeleteCate,
     eventChangeStatus,
   }) => {

    const dispatch = useAppDispatch();
    const [openModalAddCate, setOpenModalAddCate] = useState<boolean>(false);
    const [openModalUpdateCate, setOpenModalUpdate] = useState<boolean>(false);
    const [search, setSearch] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [defaultCateChildUpdate, setDefaultCateChildUpdate] = useState<IDateTableCateChild>({
      key: 0, STT: 0, icon: "", status: 0, nameCate: "", index: "0", date: "", iconName: "",
      feature: { key: 0, STT: 0, icon: "", status: 0, nameCate: "", index: "0", date: "", iconName: "" },
    });

    const columns: IColumn[] = [
      {
        title: "Tên danh mục",
        key: "nameCate",
        dataIndex: "nameCate",
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Trạng thái",
        key: "status",
        dataIndex: "status",
        render: (value) => <div>{value === 1 ? <Tag color={"green"}>Đang hoạt động</Tag> :
          <Tag>Ngưng hoạt động</Tag>}</div>,
      },
      {
        title: "Thứ tự hiển thị",
        key: "index",
        dataIndex: "index",
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Ngày tạo",
        key: "date",
        dataIndex: "date",
        render: (value) => <div>{moment(value).format("DD/MM/YYYY")}</div>,
      },
    ];

    const role: number = parseInt(localStorage.getItem("role") as string);

    if (role !== 4) {
      columns.push(
        {
          title: "Chức năng",
          key: "feature",
          dataIndex: "feature",
          render: (value) =>
            <div style={{ cursor: "pointer" }}>

          <span onClick={() => eventChangeStatus(value.key, value.status === 1 ? 0 : 1, data.key)}>
            {
              value.status === 1 ?
                <i className="fal fa-toggle-on" style={{ color: "#1890ff", fontSize: 16 }} />
                : <i className="fal fa-toggle-off" color={"gray"} style={{ fontSize: 16 }} />
            }
          </span>

              <i className="fad fa-pen-square"
                 style={{ margin: "0 10px", color: "orange", fontSize: 16 }}
                 onClick={() => {
                   setOpenModalUpdate(true);
                   setDefaultCateChildUpdate(value);
                 }}
              />

              <Popconfirm
                title={"Bạn có muốn xóa danh mục?"}
                placement={"top"}
                onConfirm={async () => {
                  const rs = await dispatch(deleteCateAction({ id: value.key }));
                  if (rs.type === "productCate/deleteCateAction/fulfilled") {
                    dispatch(getListCateChildAction({
                      page: 1,
                      status: null,
                      search: null,
                      toDate: "",
                      fromDate: "",
                      parentId: data.key,
                    }));
                  }
                }}
                okText={"Xóa"}
                cancelText={"Hủy"}
              >
                <i className="fal fa-trash-alt" style={{ color: "red", fontSize: 16 }} />
              </Popconfirm>

            </div>,
        },
      );
    }

    useEffect(() => {
      setTimeout(() => {
        const getListCate = (search: string | null, fromDate: string | null, toDate: string | null) => {
          dispatch(getListCateChildAction({
            page: 1,
            status: null,
            search: search,
            parentId: data.key,
            fromDate: fromDate,
            toDate: toDate,
          }));
        };
        getListCate(search, startDate, endDate);
      }, 200);
    }, [search, startDate, endDate]);

    return (
      <div>
        <div style={{ backgroundColor: "white", padding: 16, borderRadius: 4 }}
             className={"flex-between"}
        >
          <div>
            <Input.Search placeholder="Nhập tên danh mục con" style={{ width: 300, marginRight: 16, marginBottom: 8 }}
                          onChange={event => setSearch(event.target.value)}
            />
            <DatePicker.RangePicker style={{ width: 300 }}
                                    format={[Config.formatDate, Config.formatDate]}
                                    inputReadOnly={true}
                                    placeholder={Config.placeholderDatePicker}
                                    disabledDate={disabledDate}
                                    onCalendarChange={(value: any, formatString: string []) => {
                                      setStartDate(formatString.length > 0 ? formatString[0] : "");
                                      setEndDate(formatString.length > 0 ? formatString[1] : "");
                                    }}
            />
          </div>
          {
            role !== 4 &&
            <Btn value={"Thêm danh mục"}
                 onClick={() => setOpenModalAddCate(true)}
                 backGroundColor={"#109703"}
                 style={{ width: 200 }}
            />
          }
        </div>
        <div style={{ marginTop: 8 }}>
          <Table
            columns={columns}
            dataSource={useAppSelector(state => state.productCategory.listCateChild)}
            pagination={{ defaultPageSize: Config.limit, showSizeChanger: false }}
            bordered
            locale={{
              emptyText: <EmptyComponent />,
            }}
            loading={useAppSelector(state => state.productCategory.loadingListCateChild)}
          />
        </div>

        {
          openModalAddCate ?
            <Modal
              title={"Thêm danh mục con"}
              visible={openModalAddCate}
              onCancel={() => setOpenModalAddCate(false)}
              footer={false}
            >
              <FormAddCateChild eventClose={() => setOpenModalAddCate(false)} data={data} />
            </Modal> : null
        }

        <Modal
          title={"Sửa danh mục con"}
          visible={openModalUpdateCate}
          onCancel={() => setOpenModalUpdate(false)}
          footer={false}
        >
          <FormUpdateCateChild
            eventClose={() => {
              setOpenModalUpdate(false);
            }} data={defaultCateChildUpdate} nameParent={data.nameCate}
          />
        </Modal>

      </div>
    );
  };

const ExpandRowTable: React.FC<IExpandRowTable> = ({ data, eventChangeStatus, eventUpdateCate, eventDeleteCate }) => {

  const [tabs, setTabs] = useState<string>("1");
  const role: number = parseInt(localStorage.getItem("role") as string);

  return (
    <div className={"product-category__wrapper-table-cate__expand-table"}>
      <Tabs activeKey={tabs} onChange={value => setTabs(value)}>
        <Tabs.TabPane tab="Thông tin danh mục" key={"1"}>
          <div className={"product-category__wrapper-table-cate__expand-table__tabs-info-cate"}>
            <Row
              className={"product-category__wrapper-table-cate__expand-table__tabs-info-cate__info"}
              justify={"space-between"}
            >
              <Col xs={24} sm={24} md={24} lg={4}>
                <div className={"color-gray"}>Icon danh mục</div>
                {data.icon ? <Image src={data.icon} style={{ borderRadius: "50%", height: 40, width: 40 }} /> :
                  <i className="fas fa-icons" />}
              </Col>
              <Col xs={24} sm={24} md={24} lg={4}>
                <div className={"color-gray"}>Tên danh mục</div>
                <div className={"mt-16 font-bold"}>{data.nameCate}</div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={4}>
                <div className={"color-gray"}>Trạng thái</div>
                <div className={"mt-16 font-bold"}>{data.status === 0 ? <Tag color={"gray"}>Ngưng hoạt động</Tag> :
                  <Tag color={"green"}>Đang hoạt động</Tag>}</div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={4}>
                <div className={"color-gray"}>Thứ tự hiển thị</div>
                <div className={"mt-16 font-bold"}>{data.index}</div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={4}>
                <div className={"color-gray"}>Ngày tạo</div>
                <div className={"mt-16 font-bold"}>{moment(data.date).format(Config.formatDate)}</div>
              </Col>
            </Row>

            <div className={"product-category__wrapper-table-cate__expand-table__tabs-info-cate__wrapper-list-btn"}
                 style={role === 4 ? { display: "none" } : { display: "block" }}
            >
              <Row className={"mt-16"}>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <AppBtn
                    icon={
                      data.status === 0 ?
                        <i className="fal fa-toggle-off" style={{ fontSize: 20, marginTop: 3 }} /> :
                        <i className="fad fa-toggle-on" style={{ fontSize: 20, marginTop: 3 }} />

                    }
                    title={data.status === 0 ? "Ngừng hoạt động" : "Đang hoạt động"}
                    color={data.status === 1 ? "#1890ff" : "gray"}
                    event={() => {
                      eventChangeStatus(data.key, data.status === 1 ? 0 : 1);
                    }}
                    loading={useAppSelector(state => state.productCategory.loadingChangeStatus)}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <AppBtn
                    icon={<i className="fal fa-edit" />}
                    title={"Chỉnh sửa"}
                    color={"green"}
                    event={() => eventUpdateCate(data)}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <AppBtn
                    icon={<i className="fal fa-trash-alt" />}
                    title={"Xóa"}
                    color={"red"}
                    loading={useAppSelector(state => state.productCategory.loadingDeleteCate)}
                    event={() => {
                      Modal.confirm({
                        title: "Bạn có muốn xóa danh mục sản phẩm này không.",
                        onOk: () => eventDeleteCate(data.key),
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab={"Danh mục con"} key={"2"}>
          <CateChild data={data} eventChangeStatus={eventChangeStatus} eventDeleteCate={eventDeleteCate} />
        </Tabs.TabPane>
      </Tabs>

    </div>
  );
};

export default ExpandRowTable;
