import * as AntdIcons from "@ant-design/icons";

const IconAntd = ({
    spin = false,
    icon,
    props,
    size = "20px",
    style,
    marginLeft,
}: {
    icon: any;
    size?: string;
    props?: any;
    spin?: boolean;
    style?: any;
    marginLeft?: number;
}) => {
    //@ts-ignore
    const AntdIcon = AntdIcons[icon];

    return <AntdIcon spin={spin} style={{ fontSize: size, height: size, ...style }} {...props} />;
};

export default IconAntd;
