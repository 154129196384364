import React from "react";
import styled from "styled-components";
import { formatNumber } from "utils";

interface IProductTable {
    order: any;
}

const ProductTable = (props: IProductTable) => {
    const { order } = props;
    const newOrder = Array.from(Array(22).keys())?.map((item: any) => {
        if (order?.OrderItems?.[item]) return order?.OrderItems?.[item];
        return {};
    });
    console.log("🚀 ~ newOrder ~ newOrder:", newOrder);
    return (
        <Container>
            <div className="header_block">
                <div className="item">
                    <p>TT</p>
                </div>
                <div className="item">
                    <p>Sản phẩm</p>
                </div>
                <div className="item">
                    <p>Phân loại</p>
                </div>
                <div className="item">
                    <p>SL</p>
                </div>
                <div className="item">
                    <p>Giá</p>
                </div>
                <div className="item">
                    <p>Tổng tiền</p>
                </div>
            </div>
            {newOrder?.map((item: any, index: number) => (
                <div className="product_item_block">
                    <div className="item" style={{ borderRight: "1px dashed black" }}>
                        <p>{index + 1}</p>
                    </div>
                    <div className="item">
                        <p>
                            {item?.product_name?.length > 45
                                ? `${item?.product_name?.slice(0, 45)}...`
                                : item?.product_name}
                        </p>
                    </div>
                    <div className="item">
                        {item?.product_attribute_name_1 && item?.product_attribute_name_2 ? (
                            <p
                                style={{ color: "black" }}
                            >{`${item?.product_attribute_name_1} - ${item?.product_attribute_name_2}`}</p>
                        ) : item?.product_attribute_name_1 ? (
                            <p style={{ color: "black" }}>{item?.product_attribute_name_1}</p>
                        ) : (
                            <p>--</p>
                        )}
                    </div>
                    <div className="item">
                        <p style={{ color: item?.quantity ? "black" : "white" }}>{item?.quantity || "-"}</p>
                    </div>
                    <div className="item">
                        <p style={{ color: item?.price ? "black" : "white" }}>
                            {item?.price ? formatNumber(item?.price) : "-"}
                        </p>
                    </div>
                    <div className="item">
                        <p style={{ color: item?.price ? "black" : "white" }}>
                            {item?.price ? formatNumber(item?.quantity * item?.price) : "-"}
                        </p>
                    </div>
                </div>
            ))}
            <div className="footer_block">
                <div className="item" style={{ border: "none" }}></div>
                <div className="item">
                    <p style={{ textAlign: "right" }}>Phí vận chuyển VNĐ</p>
                </div>
                <div className="item">
                    <p style={{ color: "#fff" }}>1</p>
                </div>
                <div className="item">
                    <p style={{ color: "#fff" }}>d</p>
                </div>
                <div className="item">
                    <p>
                        <b>{formatNumber(+order?.total_price - +order?.total_money)}</b>
                    </p>
                </div>
            </div>
            <div className="footer_block">
                <div className="item" style={{ border: "none" }}></div>
                <div className="item">
                    <p style={{ textAlign: "right" }}>Tổng tiền VNĐ</p>
                </div>
                <div className="item">
                    <p>{order?.OrderItems?.length}</p>
                </div>
                <div className="item">
                    <p style={{ color: "#fff" }}>d</p>
                </div>
                <div className="item">
                    <p>
                        <b>{formatNumber(+order?.total_price)}</b>
                    </p>
                </div>
            </div>
            <div className="footer_block_two">
                <div className="item"></div>
                <div className="item">
                    <p style={{ fontSize: 9 }}>
                        Đã đặt cọc:<b> 0</b>
                    </p>
                    <p style={{ fontSize: 9 }}>
                        Còn lại thanh toán khi nhận: <b>{formatNumber(+order?.total_price)}</b>
                    </p>
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;

    p {
        font-size: 8px;
        font-family: "arial";
        margin: 0;
        line-height: 10px;
    }

    .header_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px dashed black;
        border-left: 0;
        border-right: 0;

        .item {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            :nth-child(1) {
                width: 16px;
                justify-content: center;
            }
            :nth-child(2) {
                width: 170px;
                border-left: 1px dashed black;
                justify-content: center;
            }
            :nth-child(3) {
                width: 70px;
                border-left: 1px dashed black;
                justify-content: center;
            }
            :nth-child(4) {
                width: 23px;
                border-left: 1px dashed black;
                border-right: 1px dashed black;
                justify-content: center;
            }
            :nth-child(5) {
                width: 45px;
                border-right: 1px dashed black;
                justify-content: center;
            }
            :nth-child(6) {
                width: 50px;
                justify-content: center;
            }

            p {
                font-weight: 600;
                margin: 0;
                text-align: right;
            }
        }
    }
    .product_item_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed black;
        border-left: 0;
        border-right: 0;

        .item {
            display: flex;
            justify-content: flex-end;

            :nth-child(1) {
                width: 17px;
                justify-content: center;
            }
            :nth-child(2) {
                width: 170px;
                justify-content: left;
                padding-left: 2px;
                border-color: black;
            }
            :nth-child(3) {
                width: 70px;
                border-left: 1px dashed black;
                justify-content: center;
            }
            :nth-child(4) {
                width: 23px;
                border-left: 1px dashed black;
                border-right: 1px dashed black;
                justify-content: center;
            }
            :nth-child(5) {
                width: 45px;
                border-right: 1px dashed black;
                justify-content: center;
            }
            :nth-child(6) {
                width: 50px;
                justify-content: center;
            }

            p {
                font-weight: 600;
                margin: 0;
                text-align: right;
            }
        }
    }

    .footer_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed black;

        .item {
            display: flex;
            justify-content: flex-end;

            :nth-child(1) {
                justify-content: flex-start;
                width: 17px;
                justify-content: center;
            }
            :nth-child(2) {
                width: 240px;
                padding-right: 6px;
            }

            :nth-child(3) {
                width: 23px;
                border-left: 1px dashed black;
                border-right: 1px dashed black;
                justify-content: center;
            }
            :nth-child(4) {
                width: 45px;
                border-right: 1px dashed black;
                justify-content: center;
            }
            :nth-child(5) {
                width: 50px;
                justify-content: center;
            }

            p {
                font-weight: 600;
                margin: 0;
                text-align: right;
            }
        }
    }
    .footer_block_two {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed black;

        .item {
            display: flex;

            :nth-child(1) {
                width: 17px;
                justify-content: center;
                border-right: 1px dashed black;
                justify-content: flex-end;
            }
            :nth-child(2) {
                justify-content: center;
                justify-content: space-between;
                flex: 1;
                padding: 0 20px;
            }

            p {
                margin: 0;
                text-align: right;
            }
        }
    }

    .product_item {
        .product_name {
            font-weight: 600;
            margin-bottom: 6px;
            line-height: 22px;
        }
        .product_stats {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px dashed black;

            .item {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                flex: 1;

                :nth-child(1) {
                    justify-content: center;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
`;

export default ProductTable;
