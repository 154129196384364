import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getListReportCustomerAPI, getListReportProductAPI } from "../../../api/report/Report";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { IResReportCustomer, IResReportProduct, ITotalData } from "../../../api/report/Interface";
import { getStartIndex } from "../../../commons/validation/ValidationPattern";

export interface IDataSourceReportProduct {
  key: number;
  col1: number;
  col2: string;
  col3: string;
  col4: number;
  col5: number;
  col6: number;
}

export interface IDataSourceReportCustomer {
  key: number;
  col1: number;
  col2: string;
  col3: string;
  col4: number;
  col5: number;
  col6: number;
}

export interface IInitState {
  dataSourceReportProduct: IDataSourceReportProduct [];
  loadingReportProduct: boolean;
  totalReportProduct: number;

  dataSourceReportProductExport: IDataSourceReportProduct [];
  loadingReportProductExport: boolean;

  dataSourceReportCustomer: IDataSourceReportCustomer [];
  loadingReportCustomer: boolean;
  totalReportCustomer: number;

  dataSourceReportCustomerExport: IDataSourceReportCustomer [];
  loadingReportCustomerExport: boolean;
}

const initialState: IInitState = {
  dataSourceReportProduct: [],
  loadingReportProduct: false,
  totalReportProduct: 0,

  dataSourceReportProductExport: [],
  loadingReportProductExport: false,

  dataSourceReportCustomer: [],
  loadingReportCustomer: false,
  totalReportCustomer: 0,

  dataSourceReportCustomerExport: [],
  loadingReportCustomerExport: false,
};

export const getListReportProductAction = createAsyncThunk("reportSale/getListReportProductAction", async (arg: { page: number, search: string, startDate: string, endDate: string, isExport?: boolean }, thunkAPI) => {
  const rs = await getListReportProductAPI(arg.page, arg.search, arg.startDate, arg.endDate, arg.isExport);
  if (rs.body.status === 1) {
    return rs.body;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const getListReportCustomerAction = createAsyncThunk("reportSale/getListReportCustomerAction", async (arg: { page: number, search: string, startDate: string, endDate: string, isExport?: boolean }, thunkAPI) => {
  const rs = await getListReportCustomerAPI(arg.page, arg.search, arg.startDate, arg.endDate, arg.isExport);
  if (rs.body.status === 1) {
    return rs.body;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

const ReportSale = createSlice({
  name: "reportSale",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListReportProductAction.pending, (state, action) => {
        action.meta.arg.isExport ? state.loadingReportProductExport = true : state.loadingReportProduct = true;
      })
      .addCase(getListReportProductAction.rejected, (state, action) => {
        action.meta.arg.isExport ? state.loadingReportProductExport = true : state.loadingReportProduct = true;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(getListReportProductAction.fulfilled, (state, action) => {

        action.meta.arg.isExport ? state.loadingReportProductExport = false : state.loadingReportProduct = false;

        const getDataSourceReportProduct = (data: IResReportProduct [], total: ITotalData): IDataSourceReportProduct [] => {
          let rs: IDataSourceReportProduct [] = [];
          rs.push({
            key: 0,
            col1: 0,
            col2: "",
            col3: "Tổng",
            col4: total.soldCountTotal,
            col5: total.orderCountTotal,
            col6: total.revenueTotal,
          });
          data.forEach((value, index) => rs.push({
            key: value.id,
            col1: index + 1 + getStartIndex(action.meta.arg.page),
            col2: value.name,
            col3: value.categoryName,
            col4: value.soldCount,
            col5: value.orderCount,
            col6: value.totalRevenue,
          }));
          return rs;
        };

        if (action.meta.arg.isExport) {
          state.dataSourceReportProductExport = getDataSourceReportProduct(action.payload.data, action.payload.totalData);
        } else {
          state.dataSourceReportProduct = getDataSourceReportProduct(action.payload.data, action.payload.totalData);
          state.totalReportProduct = action.payload.paging.totalItemCount;
        }

      })

      .addCase(getListReportCustomerAction.pending, (state, action) => {
        action.meta.arg.isExport ? state.loadingReportCustomerExport = true : state.loadingReportCustomer = true;
      })
      .addCase(getListReportCustomerAction.rejected, (state, action) => {
        action.meta.arg.isExport ? state.loadingReportCustomerExport = true : state.loadingReportCustomer = true;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(getListReportCustomerAction.fulfilled, (state, action) => {

        action.meta.arg.isExport ? state.loadingReportCustomerExport = false : state.loadingReportCustomer = false;

        const getDataSourceReportCustomer = (data: IResReportCustomer [], total: ITotalData): IDataSourceReportCustomer [] => {
          let rs: IDataSourceReportCustomer [] = [];

          rs.push({
            key: 0,
            col1: 0,
            col2: "",
            col3: "Tổng",
            col4: total.soldCountTotal,
            col5: total.orderCountTotal,
            col6: total.revenueTotal,
          });

          data.forEach((value, index) => rs.push({
            key: value.id,
            col1: index + 1 + getStartIndex(action.meta.arg.page),
            col2: value.name,
            col3: value.phone,
            col4: value.soldCount,
            col5: value.orderCount,
            col6: value.totalRevenue,
          }));
          return rs;
        };

        if (action.meta.arg.isExport) {
          state.dataSourceReportCustomerExport = getDataSourceReportCustomer(action.payload.data, action.payload.totalData);
        } else {
          state.totalReportCustomer = action.payload.paging.totalItemCount;
          state.dataSourceReportCustomer = getDataSourceReportCustomer(action.payload.data, action.payload.totalData);
        }

      });
  },
});

export default ReportSale.reducer;