import { Col, DatePicker, Row, Table } from "antd";
import React from "react";
import styled from "styled-components";
import { formatNumber, momentToStringDate, scrollTo } from "utils";
import { saleService } from "../service";

interface ISaleWallet {
    saleId: number;
}

const SaleWallet = (props: ISaleWallet) => {
    const { saleId } = props;
    const [data, setData] = React.useState<any[]>([]);
    const [commision, setCommission] = React.useState<number>(0);

    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: "",
        toDate: "",
    });
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Hoa hồng</b>,
            dataIndex: "value",
            render: (value: number, record: any) => {
                if (record?.type === 1) {
                    return `+ ${formatNumber(value || 0)}`;
                }
                return `- ${formatNumber(value || 0)}`;
            },
        },
        {
            title: <b>Nội dung</b>,
            dataIndex: "note",
        },
        {
            title: <b>Thời gian</b>,
            dataIndex: "create_at",
            render: (value: string) => momentToStringDate(value),
        },
    ];

    const onGetWalletOfSale = async () => {
        try {
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                from_date: dateRange.fromDate,
                to_date: dateRange.toDate,
            };
            const res: any = await saleService.getWalletOfSale(saleId, payload);
            if (res?.status) {
                setCommission(res?.data?.commision);
                setData(res?.data?.rows);
                setPaging({ ...paging, current: res?.paging?.page, total: res?.paging?.totalItemCount });
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetWalletOfSale();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [dateRange]);

    React.useEffect(() => {
        if (saleId) {
            onGetWalletOfSale();
        }
    }, [paging.current]);

    return (
        <Container>
            <Row justify={"space-between"} gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5} span={5}>
                    <p>
                        Hoa hồng hiện tại: <b style={{ color: "#e83131" }}>{formatNumber(commision)}</b>
                    </p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <DatePicker.RangePicker
                        style={{ width: "100%" }}
                        onChange={(date: any, dateStrings: string[]) => {
                            setDateRange({
                                fromDate: dateStrings[0]?.split("-").reverse().join("-"),
                                toDate: dateStrings[1]?.split("-").reverse().join("-"),
                            });
                        }}
                        placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                        format={"DD-MM-YYYY"}
                    />
                </Col>
            </Row>
            <br />
            <Table
                id="top-table"
                style={{ width: "100%" }}
                bordered
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: "Chưa có bản ghi nào!",
                }}
                pagination={{
                    ...paging,
                    showSizeChanger: false,
                    onChange: (page) => {
                        setPaging({ ...paging, current: page });
                        setTimeout(() => {
                            scrollTo("start");
                        }, 500);
                    },
                }}
            />
        </Container>
    );
};

const Container = styled.div``;

export default SaleWallet;
