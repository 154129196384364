import { deleteRequest, getRequest, IApiResponse, postRequest, putRequest } from "../../service/Basevices";
import { IResBody } from "../interface";
import { IResGetAccountDetailAPI, IResGetListUser, IResPostAccApi } from "./Interface";
import { Config } from "../../commons/validation/ValidationPattern";

export const getListAccAPI = (
    page: number,
    limit: number = Config.limit,
    status: number | null,
    search: string | null,
    role: number
): Promise<IApiResponse<IResGetListUser>> => {
    let path: string = `/users/list?page=${page}&limit=${limit}`;
    if (search !== "ALL") path = path + `&search=${search}`;
    if (status !== 2 && status !== null) path = path + `&status=${status}`;
    if (role !== 0) path = path + `&role=${role}`;
    return getRequest(path, true);
};

export const getAccountDetailAPI = (id: number): Promise<IApiResponse<IResGetAccountDetailAPI>> => {
    return getRequest(`/users/info`, true);
    // return getRequest(`/users/${id}`, true);
};

export const checkTokenAPI = (): Promise<IApiResponse<IResBody>> => {
    return getRequest(`/users/check-token`, true);
};

export const postAccAPI = (
    name: string,
    email: string,
    password: string,
    phone: string,
    profilePictureUrl: string | null,
    role: number
): Promise<IApiResponse<IResPostAccApi>> => {
    let data = {
        name: name.trim(),
        password: password.trim(),
        phone: phone.trim(),
        profile_picture_url: profilePictureUrl,
        role: role,
    };

    let emailObj = { email: email };

    email && (data = { ...data, ...emailObj });
    return postRequest("/users/register", true, data);
};

export const putChangeStatusAcc = (id: any, status: number): Promise<IApiResponse<IResBody>> => {
    return putRequest(`/users/${id}/change-status`, true, { status: status });
};

export const putResetPassAccAPI = (id: any): Promise<IApiResponse<IResBody>> => {
    return putRequest(`/users/${id}/reset-password`);
};

export const putUpdateAcc = (
    id: any,
    email: string,
    name: string,
    status: number,
    phone: string,
    profilePictureUrl: string | null,
    role: number
): Promise<IApiResponse<IResBody>> => {
    let data = {
        name: name.trim(),
        status: status,
        phone: phone.trim(),
        profile_picture_url: profilePictureUrl ? profilePictureUrl : "",
        role: role,
    };

    let emailOjb = { email: email };
    email && (data = { ...data, ...emailOjb });

    return putRequest(`/users/${id}/update`, true, data);
};

export const resetPassAPI = (id: number): Promise<IApiResponse<IResBody>> => {
    const path: string = `/users/${id}/reset-password`;
    return putRequest(path, true, { id: id });
};

export const deleteAccAPI = (id: any): Promise<IApiResponse<IResBody>> => {
    return deleteRequest(`/users/${id}/delete`);
};
