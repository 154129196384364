import { Button, Checkbox, Col, DatePicker, Form, InputNumber, PageHeader, Row, Select, Spin, Table } from "antd";
import Container from "commons/components/container";
import React from "react";
import { useLocation } from "react-router-dom";
import AppServices from "service/AppServices";
import styled from "styled-components";
import FlashSaleQuantityInput from "../component/FlashSaleQuantityInput";
import FlashSalePriceInput from "../component/FlashSalePriceInput";
import { flashsaleService } from "../service";
import { Notification, formatNumber, toNonAccentVietnamese } from "utils";
import useDebounce from "utils/useDebounce";
import { orderService } from "modules/order-product/service";
import { useDispatch, useSelector } from "react-redux";
import {
    changeDecreasePercent,
    resetPriceSale,
    setDiscountType,
    setFlashSaleProducts,
} from "app/slices/flash-sale/FlashSaleSlice";
import moment from "moment";

const AddEditFlashSale = () => {
    const location: any = useLocation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const productId = location?.state?.productId;
    const flashSaleProducts = useSelector((state: any) => state?.flashSale?.data);

    const [loading, setLoading] = React.useState<boolean>(false);

    // Phân loại cha
    const [parentCategories, setParentCategories] = React.useState<any[]>([]);
    const [selectParentCateId, setSelectedParentCateId] = React.useState<any>(null);

    // Phân loại con
    const [childCategories, setChilCategories] = React.useState<any[]>([]);
    const [selectChildCateId, setSelectedChildCateId] = React.useState<any>(null);

    // Hình thức giảm
    const [decreaseType, setDecreaseType] = React.useState<number>();
    const [decreaseValue, setDecreaseValue] = React.useState<number>();

    // Sản phẩm
    const [products, setProducts] = React.useState<any[]>([]);
    const [selectProductId, setSelectProductId] = React.useState<number>(-1);
    const [attrList, setAttrList] = React.useState<any[]>([]);

    const [searchProduct, setSearchProduct] = React.useState<string>();
    const searchDebounce = useDebounce(searchProduct, 300);

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            render: (_: any, __: any, index: number) => index + 1,
        },
        {
            title: <b>Thuộc tính</b>,
            dataIndex: "attr",
            render: (_: any, record: any) => {
                if (record?.product_attribute_name_1?.name) {
                    if (record?.product_attribute_name_2?.name)
                        return `${record?.product_attribute_name_1?.name} - ${record?.product_attribute_name_2?.name}`;
                    return record?.product_attribute_name_1?.name;
                } else return "Không có thuộc tính";
            },
        },
        {
            title: <b>Số lượng khuyến mãi</b>,
            dataIndex: "quantity",
            render: (_: any, record: any) => <FlashSaleQuantityInput record={record} productId={productId} />,
        },
        {
            title: <b>Giá niêm yết (VND)</b>,
            dataIndex: "price",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Giá khuyến mãi (VND)</b>,
            dataIndex: "sale_price",
            render: (_: any, record: any) => (
                <FlashSalePriceInput
                    decreaseType={decreaseType}
                    record={record}
                    discount={decreaseValue}
                    productId={productId}
                />
            ),
        },
        {
            title: <b></b>,
            dataIndex: "",
            render: (_: any, record: any) => <Checkbox>Ngừng hoạt động</Checkbox>,
        },
    ];

    const onGetCategories = async () => {
        try {
            const payload = {
                page: 1,
                limit: 100,
                status: 1,
                parent_id: selectParentCateId,
            };
            const res: any = await flashsaleService.getParentCategories(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }));
                if (selectParentCateId) {
                    setChilCategories(data);
                } else {
                    setParentCategories(data);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetFlashsaleDetail = async () => {
        try {
            setLoading(true);
            const res = await flashsaleService.getDetailFlashsale(productId);
            if (res?.status) {
                const result = res?.data;
                const parentCategory = result?.Product?.Category?.parent_category?.id;
                const childCategory = result?.Product?.Category?.id;

                form.setFieldsValue({
                    parent_cate: parentCategory ? parentCategory : childCategory,
                    child_cate: parentCategory ? childCategory : undefined,
                    product: result?.Product?.id,
                    decreaseType: result?.type_discount,
                    decreaseValue: result?.discount_percent,
                    buyMaxQuantity: result?.max_number_use,
                    date: [moment(result?.from_time), moment(result?.to_time)],
                });
                setSelectedParentCateId(parentCategory ? parentCategory : childCategory);
                setSelectedChildCateId(parentCategory ? undefined : childCategory);
                setSelectProductId(result?.Product?.id);
                setDecreaseType(result?.type_discount);
                setDecreaseValue(result?.discount_percent);
                const reformatedTableData = result?.FlashSaleItems?.map((item: any) => ({
                    ...item,
                    product_attribute_name_1: {
                        name: item?.attribute_name_1,
                    },
                    product_attribute_name_2: {
                        name: item?.attribute_name_2,
                    },
                    sale_price: item?.discount_price,
                    price: item?.product_price,
                    quantity_sale: item?.quantity,
                }));
                setAttrList(reformatedTableData);
                dispatch(setFlashSaleProducts(reformatedTableData));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onGetProducts = async () => {
        try {
            const payload = {
                page: 1,
                limit: 100,
                status: 1,
                search: searchDebounce,
                category_id: childCategories?.length === 0 ? selectParentCateId : selectChildCateId,
            };
            const res: any = await orderService.getProducts(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }));
                setProducts(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetProductDetail = async () => {
        try {
            const res = await orderService.getProductDetail(selectProductId);
            if (res?.status) {
                const data = res?.data?.map((item: any) => {
                    return {
                        ...item,
                        value: item?.id,
                        label: `${item?.product_attribute_name_1?.name} - ${item?.product_attribute_name_2?.name}`,
                        quantity_sale: 0,
                        price_sale: 0,
                    };
                });
                setAttrList(data);
                dispatch(setFlashSaleProducts(data));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            const payload = {
                product_id: values.product,
                type_discount: values.decreaseType,
                max_number_use: values.buyMaxQuantity,
                from_time: values.date?.[0].format("YYYY-MM-DD HH:mm:ss"),
                to_time: values.date?.[1].format("YYYY-MM-DD HH:mm:ss"),
                discount_percent: values.decreaseType === 1 ? undefined : values.decreaseValue,
                items: flashSaleProducts?.map((item: any) => ({
                    id: productId ? item?.id : undefined,
                    product_price_id: productId ? item?.product_price_id : item?.id,
                    quantity: item?.quantity_sale,
                    discount_price: values.decreaseType === 1 ? item?.price_sale || item?.sale_price : 0,
                })),
            };
            let res: any;
            if (productId) {
                res = await flashsaleService.updateFlashSale(productId, payload);
            } else {
                res = await flashsaleService.addFlashSale(payload);
            }
            if (res?.status) {
                Notification(
                    "success",
                    productId ? "Cập nhật sản phẩm flash sale thành công!" : "Thêm mới sản phẩm flash sale thành công!"
                );
                AppServices.history?.goBack();
                dispatch(setFlashSaleProducts([]));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (selectProductId > 0 && !productId) {
            onGetProductDetail();
        }
    }, [selectProductId]);

    React.useEffect(() => {
        onGetCategories();
    }, [selectParentCateId]);

    React.useEffect(() => {
        if (productId) onGetFlashsaleDetail();
    }, [productId]);

    React.useEffect(() => {
        if (selectParentCateId) {
            if (
                (childCategories?.length === 0 && selectParentCateId) ||
                (childCategories?.length > 0 && selectChildCateId)
            ) {
                onGetProducts();
            }
        }
    }, [selectParentCateId, selectChildCateId, childCategories, searchDebounce]);

    return (
        <Spin spinning={loading}>
            <Form form={form} labelCol={{ span: 7 }} wrapperCol={{ span: 16 }} onFinish={onFinish} autoComplete="off">
                <Container
                    header={
                        <PageHeader
                            style={{ borderRadius: 8 }}
                            title={productId ? "Chỉnh sửa sản phẩm khuyến mãi" : "Thêm mới sản phẩm khuyến mãi"}
                            onBack={() => AppServices.history?.goBack()}
                            extra={[
                                <Form.Item noStyle>
                                    <Button style={{ background: "green" }} htmlType="submit" type="primary">
                                        Lưu
                                    </Button>
                                </Form.Item>,
                            ]}
                        ></PageHeader>
                    }
                    contentComponent={[
                        <>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"parent_cate"}
                                        label="Danh mục cha"
                                        rules={[{ required: true, message: "Vui lòng chọn danh mục cha!" }]}
                                    >
                                        <Select
                                            showArrow
                                            placeholder="Chọn danh mục cha"
                                            options={parentCategories}
                                            showSearch
                                            disabled={productId}
                                            filterOption={(input, option: any) => {
                                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase();
                                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase();
                                                return nonAccent.includes(nonAccentInput);
                                            }}
                                            onChange={(value: any) => {
                                                setSelectedChildCateId(undefined);
                                                setSelectedParentCateId(value);
                                                setSelectProductId(-1);
                                                form.setFieldsValue({ child_cate: undefined, product: undefined });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={"child_cate"} label="Danh mục con">
                                        <Select
                                            showArrow
                                            placeholder="Chọn danh mục con"
                                            disabled={!selectParentCateId || productId}
                                            options={childCategories}
                                            onChange={(value: any) => {
                                                setSelectedChildCateId(value);
                                                setSelectProductId(-1);
                                                form.setFieldsValue({ product: undefined });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"product"}
                                        label="Sản phẩm"
                                        rules={[{ required: true, message: "Vui lòng chọn sản phẩm" }]}
                                    >
                                        <Select
                                            disabled={!selectParentCateId}
                                            onChange={(value: any) => setSelectProductId(value)}
                                            showArrow
                                            showSearch
                                            filterOption={false}
                                            searchValue={searchProduct}
                                            onSearch={(value: string) => setSearchProduct(value)}
                                            placeholder="Chọn sản phẩm"
                                            options={products}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"decreaseType"}
                                        label="Hình thức giảm"
                                        rules={[{ required: true, message: "Vui lòng chọn hình thức giảm!" }]}
                                    >
                                        <Select
                                            allowClear
                                            showArrow
                                            placeholder="Chọn hình thức giảm"
                                            onChange={(value: number) => {
                                                setDecreaseType(value);
                                                dispatch(resetPriceSale());
                                                dispatch(setDiscountType(value));
                                                setDecreaseValue(undefined);
                                                const newList = attrList?.map((item: any) => ({
                                                    ...item,
                                                    sale_price: 0,
                                                }));
                                                setAttrList(newList);
                                                form.setFieldsValue({ decreaseValue: undefined });
                                            }}
                                        >
                                            <Select.Option value={1}>Giảm theo tiền</Select.Option>
                                            <Select.Option value={2}>Giảm theo %</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"decreaseValue"}
                                        label="Mức giảm"
                                        rules={
                                            decreaseType === 1
                                                ? []
                                                : [{ required: true, message: "Vui lòng nhập mức giảm!" }]
                                        }
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            placeholder="Nhập mức giảm"
                                            min={1}
                                            max={decreaseType === 1 ? 10000000 : 100}
                                            style={{ width: "100%" }}
                                            disabled={decreaseType === 1 || !decreaseType}
                                            onChange={(value: any) => {
                                                setDecreaseValue(value);
                                                dispatch(changeDecreasePercent(value));
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"date"}
                                        label="Thời gian áp dụng"
                                        rules={[
                                            { required: true, message: "Vui lòng chọn thời gian áp dụng flash sale!" },
                                        ]}
                                    >
                                        <DatePicker.RangePicker
                                            style={{ width: "100%" }}
                                            onChange={(date: any) => {}}
                                            showTime
                                            placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                                            format={" HH:mm:ss DD-MM-YYYY"}
                                        />
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"buyMaxQuantity"}
                                        label={
                                            <div>
                                                <p style={{ margin: 0 }}>SL mua tối đa</p>
                                                <p style={{ fontSize: 10, margin: 0, color: "#747474" }}>
                                                    (Đối với từng phân loại sản phẩm)
                                                </p>
                                            </div>
                                        }
                                        rules={[{ required: true, message: "Vui lòng nhập số lượng mua tối đa!" }]}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            placeholder="Nhập số lượng mua tối đa"
                                            min={1}
                                            max={decreaseType === 1 ? 10000000 : 100}
                                            style={{ width: "100%" }}
                                            onChange={(value: any) => {}}
                                        />
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                        </>,
                        <>
                            <h3>Chi tiết khuyến mãi</h3>
                            {selectProductId > 0 && (
                                <Table
                                    id="top-table"
                                    style={{ width: "100%", marginTop: 20 }}
                                    bordered
                                    columns={columns}
                                    dataSource={attrList}
                                    scroll={{
                                        x: 800,
                                        scrollToFirstRowOnChange: true,
                                    }}
                                    locale={{
                                        emptyText: "Chưa có bản ghi nào!",
                                    }}
                                    pagination={false}
                                />
                            )}
                        </>,
                    ]}
                />
            </Form>
        </Spin>
    );
};
const CustomRow = styled(Row)`
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header_block {
        border-bottom: 1px solid #f0f0f0;
        width: 100%;
        height: 40px;

        .header {
            font-size: 16px;
            line-height: 30px;
            font-weight: 700;
        }
    }

    .text {
        line-height: 40px;
    }
`;

export default AddEditFlashSale;
