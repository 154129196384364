import { DatePicker, Row, Table } from "antd";
import React from "react";
import styled from "styled-components";
import { formatNumber, momentToStringDate } from "utils";
import { rewardPointService } from "../service";

interface IRewardPoint {
    customerId: number;
}

const RewardPoint = (props: IRewardPoint) => {
    const { customerId } = props;
    const [data, setData] = React.useState<any[]>([]);
    const [points, setPoints] = React.useState<number>();
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });
    const columns = [
        {
            title: "STT",
            dataIndex: "col1",
            width: 80,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: "Điểm",
            dataIndex: "value",
            render: (value: number, record: any) => {
                if (record?.type === 1) return `+${formatNumber(value)}`;
                return `-${formatNumber(value)}`;
            },
        },
        {
            title: "Nội dung",
            dataIndex: "note",
        },
        {
            title: "Thời gian",
            dataIndex: "create_at",
            render: (value: any) => (value ? momentToStringDate(value) : "---"),
        },
    ];

    const onGetData = async () => {
        try {
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                from_date: dateRange.fromDate,
                to_date: dateRange.toDate,
            };
            const res: any = await rewardPointService.getData(customerId, payload);
            if (res?.status) {
                setData(res?.data.rows);
                setPoints(res?.data?.commision);
                setPaging({
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetData();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [dateRange]);

    React.useEffect(() => {
        onGetData();
    }, [paging.current]);

    return (
        <Container>
            <Row justify={"space-between"} style={{ marginBottom: 20 }}>
                <p>
                    Tổng điểm: <span style={{ color: "red" }}>{formatNumber(points)} điểm</span>
                </p>
                <DatePicker.RangePicker
                    placeholder={["Từ ngày", "Đến ngày"]}
                    format={"DD-MM-YYYY"}
                    onChange={(date: any, dateStrings: string[]) => {
                        setDateRange({
                            fromDate: dateStrings[0]?.split("-").reverse().join("-"),
                            toDate: dateStrings[1]?.split("-").reverse().join("-"),
                        });
                    }}
                />
            </Row>
            <Table
                id="top-table"
                style={{ width: "100%" }}
                bordered
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: "Chưa có bản ghi nào!",
                }}
                pagination={{
                    ...paging,
                    showSizeChanger: false,
                    onChange: (page) => {
                        setPaging({ ...paging, current: page });
                    },
                }}
            />
        </Container>
    );
};

const Container = styled.div``;

export default RewardPoint;
