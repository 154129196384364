import { Button, Card, Col, Form, Input, PageHeader, Row, Select, Spin } from "antd";
import { addNewProduct, setProducts } from "app/slices/order-product/CreateOrder";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import { Btn } from "commons/components/btn/Button";
import Container from "commons/components/container";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppServices from "service/AppServices";
import styled from "styled-components";
import { Notification, uuid } from "utils";
import useDebounce from "utils/useDebounce";
import AddEditAddressModal from "../component/AddEditAddressModal";
import AddProductToOrder, { findDuplicateObjects } from "../component/AddProductToOrder";
import PaymentInfo from "../component/PaymentInfo";
import { orderService } from "../service";
import { voucherService } from "modules/voucher/service";
import AddCustomerModal from "../component/AddCustomerModal";

const AddOrder = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const createOrderData = useSelector((state: any) => state.createOrder.data);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [visibleCus, setVisibleCus] = React.useState<boolean>(false);

    // Thông tin khách hàng
    const [customers, setCustomers] = React.useState<any[]>([]);
    const [selectedCusId, setSelectedCusId] = React.useState<number>();
    const [searchCustomerKey, setSearchCustomerKey] = React.useState<string>("");
    const searchCustomerKeyDebounce = useDebounce(searchCustomerKey, 100);

    // Thông tin nhận hàng
    const [addresses, setAddresses] = React.useState<any[]>([]);
    const [selectedAddress, setSelectedAddress] = React.useState<number>();
    const [searchAddresssKey, setSearchAddressKey] = React.useState<string>("");
    const searchAddresssKeyDebounce = useDebounce(searchAddresssKey, 100);

    // Thông tin thanh toán
    const [vouchers, setVouchers] = React.useState<any[]>([]);
    const [transportFee, setTransportFee] = React.useState<number>();
    const [voucherOrderId, setVoucherOrderId] = React.useState<any>();
    const [voucherTransportId, setVoucherTransportId] = React.useState<any>();

    const onGoBack = () => {
        AppServices.history?.push(pathMenu.order);
        dispatch(setProducts([]));
    };

    const onAddNewProduct = () => {
        dispatch(
            addNewProduct({
                id: uuid(),
                parent_category: undefined,
                child_category: undefined,
                product: undefined,
                attribute: undefined,
                quantity: 1,
                price: 0,
                sale_price: 0,
                total_price: 0,
                weight: 0,
            })
        );
    };

    const onDeleteProduct = (productId: string) => {
        const newList = createOrderData?.filter((item: any) => item?.id !== productId);
        dispatch(setProducts(newList));
    };

    const onGetCustomers = async (isSetDefaultCustomer?: boolean) => {
        try {
            const payload = {
                page: 1,
                limit: 12,
                search: searchCustomerKeyDebounce,
                status: 1,
            };
            const res = await orderService.getCustomers(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({
                    ...item,
                    label: `${item?.name} - ${item?.phone}`,
                    value: item?.id,
                }));
                setCustomers(data);
                if (isSetDefaultCustomer) {
                    setSelectedCusId(data?.[0]?.value);
                    form.setFieldsValue({ customer: data?.[0]?.value, address: undefined });
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onGetVouchers = async () => {
        try {
            setLoading(true);
            const payload = {
                page: 1,
                limit: 1000,
                status: 1,
                user_id: selectedCusId,
            };
            const res: any = await voucherService.getVouchers(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({
                    ...item,
                    value: item?.id,
                    label: `${item?.code} - ${item?.name}`,
                }));
                setVouchers(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onGetTransportFee = async () => {
        try {
            const res = await orderService?.getTransferFee();
            if (res?.status) {
                setTransportFee(Number(res?.data?.delivery_fee));
            }
        } catch (error) {
            console.error();
        }
    };

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            const isInvalidProduct = createOrderData?.find((item: any) => !item?.product);
            if (isInvalidProduct) {
                Notification("error", "Vui lòng nhập đầy đủ thông tin sản phẩm!");
                return;
            }
            if (createOrderData?.length === 0) {
                Notification("error", "Vui lòng thêm sản phẩm cho đơn hàng!");
                return;
            }
            const duplicateItems = findDuplicateObjects(createOrderData);
            if (duplicateItems?.length > 0) {
                Notification("error", "Hiện tại, danh sách sản phẩm đang chứa các sản phẩm trùng nhau!");
                return;
            }

            const payload = {
                user_id: values.customer,
                address_receive_id: values.address,
                products: createOrderData?.map((item: any) => ({
                    product_price_id: item?.product_price_id,
                    quantity: item?.quantity,
                })),
                order_delivery_partner: 1,
                voucher_id: voucherOrderId ? voucherOrderId?.id : null,
                voucher_ship_id: voucherTransportId ? voucherTransportId?.id : null,
                note: values?.note,
            };
            const res = await orderService.createOrder(payload);
            if (res?.status) {
                Notification("success", "Thêm mới đơn hàng thành công!");
                onGoBack();
                dispatch(setProducts([]));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onCreateCustomer = async (values: any) => {
        try {
            setLoading(true);
            const payload = {
                full_name: values.name,
                phone_number: values.phone,
                province_id: values.province,
                district_id: values.district,
                ward_id: values.ward,
                address: values.address,
            };
            const res = await orderService.createCustomer(payload);
            if (res?.status) {
                setVisibleCus(false);
                Notification("success", "Thêm khách hàng mới thành công!");
                onGetCustomers(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onGetAddresses = React.useCallback(
        async (isSetDefaultAddress?: boolean) => {
            try {
                const payload = {
                    page: 1,
                    limit: 12,
                    search: searchCustomerKeyDebounce,
                    status: 1,
                };
                const res = await orderService.getAddresses(selectedCusId, payload);
                if (res?.status) {
                    const data = res?.data?.map((item: any) => ({
                        ...item,
                        label: `${item?.name} - ${item?.phone} - ${item?.address}, ${item?.ward_name}, ${item?.district_name}, ${item?.province_name}`,
                        value: item?.id,
                    }));
                    setAddresses(data);
                    if (isSetDefaultAddress) {
                        form.setFieldsValue({ address: data?.[0]?.value });
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        [searchCustomerKeyDebounce, selectedCusId]
    );

    React.useEffect(() => {
        form.setFieldsValue({
            payment: "COD",
            transport: "Best Express",
        });
        dispatch(setProducts([]));
        onGetTransportFee();
    }, []);

    React.useEffect(() => {
        if (selectedCusId) {
            onGetVouchers();
        }
    }, [selectedCusId]);

    React.useEffect(() => {
        onGetCustomers();
    }, [searchCustomerKeyDebounce]);

    React.useEffect(() => {
        if (selectedCusId) onGetAddresses();
    }, [searchAddresssKeyDebounce, selectedCusId]);

    return (
        <Spin spinning={loading}>
            {visible && (
                <AddEditAddressModal
                    onGetAddresses={onGetAddresses}
                    visible={visible}
                    setVisible={setVisible}
                    selectedCusId={selectedCusId}
                />
            )}
            {visibleCus && (
                <AddCustomerModal
                    loading={loading}
                    onCreateCustomer={onCreateCustomer}
                    visible={visibleCus}
                    setVisible={setVisibleCus}
                />
            )}
            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onFinish} autoComplete="off">
                <Container
                    header={
                        <PageHeader
                            title={"Thêm mới đơn hàng"}
                            onBack={onGoBack}
                            extra={[
                                <Form.Item noStyle>
                                    <Button
                                        style={{ background: "green" }}
                                        htmlType="submit"
                                        type="primary"
                                        loading={loading}
                                    >
                                        Lưu
                                    </Button>
                                </Form.Item>,
                            ]}
                        />
                    }
                    contentComponent={[
                        <>
                            <Card
                                title={<b>Thông tin khách hàng</b>}
                                extra={[
                                    <Button
                                        style={{ background: "green" }}
                                        type="primary"
                                        onClick={() => setVisibleCus(true)}
                                    >
                                        Thêm khách hàng
                                    </Button>,
                                ]}
                            >
                                <CustomRow gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"customer"}
                                            label="Khách hàng"
                                            rules={[{ required: true, message: "Vui lòng chọn khách hàng!" }]}
                                        >
                                            <Select
                                                showArrow
                                                placeholder="Chọn khách hàng"
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={false}
                                                options={customers}
                                                searchValue={searchCustomerKey}
                                                onSearch={(search: any) => setSearchCustomerKey(search)}
                                                onChange={(value: any) => {
                                                    setSelectedCusId(value);
                                                    form.setFieldsValue({ address: undefined });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={"payment"} label="Thanh toán">
                                            <Input onChange={(e: any) => {}} allowClear autoComplete="off" disabled />
                                        </Form.Item>
                                    </Col>
                                </CustomRow>
                                <CustomRow gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item name={"transport"} label="Vận chuyển">
                                            <Input onChange={(e: any) => {}} allowClear autoComplete="off" disabled />
                                        </Form.Item>
                                    </Col>
                                </CustomRow>
                            </Card>
                        </>,
                        <>
                            <Card
                                title={<b>Thông tin nhận hàng</b>}
                                extra={[
                                    <Button
                                        style={{ background: "green" }}
                                        type="primary"
                                        onClick={() => setVisible(true)}
                                    >
                                        Thêm địa chỉ
                                    </Button>,
                                ]}
                            >
                                <CustomRow gutter={[12, 12]}>
                                    <Col span={13}>
                                        <Form.Item
                                            name={"address"}
                                            label="Địa chỉ nhận hàng"
                                            rules={[{ required: true, message: "Vui lòng chọn địa chỉ nhận hàng!" }]}
                                        >
                                            <Select
                                                showArrow
                                                placeholder="Chọn địa chỉ nhận hàng"
                                                options={addresses}
                                                filterOption={false}
                                                showSearch
                                                disabled={!selectedCusId}
                                                optionFilterProp="children"
                                                searchValue={searchAddresssKey}
                                                onSearch={(search: any) => setSearchAddressKey(search)}
                                                onChange={(value: number) => setSelectedAddress(value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item name={"note"} label="Ghi chú">
                                            <Input.TextArea
                                                onChange={(e: any) => {}}
                                                allowClear
                                                autoComplete="off"
                                                placeholder="Nhập ghi chú"
                                                rows={5}
                                            />
                                        </Form.Item>
                                    </Col>
                                </CustomRow>
                            </Card>
                        </>,
                        <>
                            <Card
                                title={<b>Chi tiết đơn hàng</b>}
                                extra={[
                                    <Button onClick={onAddNewProduct} style={{ background: "green" }} type="primary">
                                        Thêm sản phẩm
                                    </Button>,
                                ]}
                            >
                                <AddProductToOrder onDeleteProduct={onDeleteProduct} />
                            </Card>
                        </>,
                        <>
                            <Card title={<b>Thông tin thanh toán</b>}>
                                <PaymentInfo
                                    vouchers={vouchers}
                                    voucherTransportId={voucherTransportId}
                                    voucherOrderId={voucherOrderId}
                                    transportFee={transportFee}
                                    setVoucherTransportId={setVoucherTransportId}
                                    setVoucherOrderId={setVoucherOrderId}
                                />
                            </Card>
                        </>,
                        <>
                            <Row justify="end">
                                <Btn onClick={onGoBack} value="Huỷ" backGroundColor="red" style={{ marginRight: 10 }} />
                                <Form.Item noStyle>
                                    <Button
                                        style={{ background: "green" }}
                                        htmlType="submit"
                                        type="primary"
                                        loading={loading}
                                    >
                                        Lưu
                                    </Button>
                                </Form.Item>
                            </Row>
                        </>,
                    ]}
                />
            </Form>
        </Spin>
    );
};

const CustomRow = styled(Row)`
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header_block {
        border-bottom: 1px solid #f0f0f0;
        width: 100%;
        height: 40px;

        .header {
            font-size: 16px;
            line-height: 30px;
            font-weight: 700;
        }
    }

    .text {
        line-height: 40px;
    }
`;

export default AddOrder;
