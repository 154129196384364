import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { IDateTableCateChild, IDateTableProductCate } from "../../../modules/product-category/interfaces";
import {
  deleteCateAPI,
  getListCateAPI,
  postCateAPI,
  putChangeStatusCateAPI, putUpdateCateAPI,
} from "../../../api/product-category/ProductCategory";
import {
  convertDataToListCate,
  convertDataToListCateChild,
  deleteCate,
  enableOrDisAbleCate,
  updateCateChild,
} from "./Function";

interface IInitState {
  listCate: IDateTableProductCate[];
  listCateChild: IDateTableCateChild[];
  total: number,
  loadingListCate: boolean;
  loadingAddOrUpdateCate: boolean;
  loadingChangeStatus: boolean;
  loadingDeleteCate: boolean;
  loadingListCateChild: boolean;
}

const initialState: IInitState = {
  listCate: [],
  listCateChild: [],
  total: 0,
  loadingListCate: false,
  loadingAddOrUpdateCate: false,
  loadingChangeStatus: false,
  loadingDeleteCate: false,
  loadingListCateChild: false,
};

export const getListCateAction = createAsyncThunk(
  "productCate/getListCate",
  async (
    params: { page: number; search: string | null; status: number | null; fromDate: string | null; toDate: string | null; parentId: any },
    thunkAPI,
  ) => {
    const rs = await getListCateAPI(
      params.page,
      params.search,
      params.status,
      params.fromDate,
      params.toDate,
      params.parentId,
    );
    if (rs.body.status === 1) {
      return rs.body;
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

export const getListCateChildAction = createAsyncThunk(
  "productCate/getListCateChildAction",
  async (
    params: { page: number; search: string | null; status: number | null; fromDate: string | null; toDate: string | null; parentId: any },
    thunkAPI,
  ) => {
    const rs = await getListCateAPI(
      params.page,
      params.search,
      params.status,
      params.fromDate,
      params.toDate,
      params.parentId,
    );
    if (rs.body.status === 1) {
      return rs.body;
    } else {
      return new Promise((resolve, reject) => reject(rs.body.message));
    }
  },
);

export const createCateAction = createAsyncThunk("productCate/createCate", async (params: { name: string, displayOrder: string, iconUrl: string, parentId: any }, thunkAPI) => {
  const rs = await postCateAPI(params.name, params.displayOrder, params.iconUrl, params.parentId);
  if (rs.body.status === 1) {
    thunkAPI.dispatch(getListCateAction({
      status: null,
      parentId: null,
      toDate: null,
      fromDate: null,
      search: null,
      page: 1,
    }));
    return { body: rs.body, thunkApi: thunkAPI };
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const changeStatusCateAction = createAsyncThunk("productCate/changeStatusCateAction", async (params: { id: any, currentStatus: number, parentId?: any }, thunkAPI) => {
  const rs = await putChangeStatusCateAPI(params.id, params.currentStatus);
  if (rs.body.status === 1) {
    return { message: rs.body.message, id: params.id, parentId: params.parentId };
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const deleteCateAction = createAsyncThunk("productCate/deleteCateAction", async (params: { id: any }, thunkAPI) => {
  const rs = await deleteCateAPI(params.id);
  if (rs.body.status === 1) {
    return { message: rs.body.message, id: params.id };
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const updateCateAction = createAsyncThunk("productCate/updateCateAction", async (params: { id: any, name: string, displayOrder: string, iconUrl: string }, thunkAPI) => {
  const rs = await putUpdateCateAPI(params.id, params.name, params.displayOrder, params.iconUrl);
  if (rs.body.status === 1) {
    return rs.body;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

const productCategory = createSlice({
  name: "productCate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListCateAction.pending, (state) => {
        state.loadingListCate = true;
      })
      .addCase(getListCateAction.rejected, (state, action) => {
        state.loadingListCate = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(getListCateAction.fulfilled, (state, action) => {
        state.loadingListCate = false;
        state.listCate = convertDataToListCate(action.payload.data, action.meta.arg.page);
        state.total = action.payload.paging.totalItemCount;
      })

      .addCase(createCateAction.pending, state => {
        state.loadingAddOrUpdateCate = true;
      })
      .addCase(createCateAction.rejected, (state, action) => {
        state.loadingAddOrUpdateCate = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(createCateAction.fulfilled, (state, action) => {
        state.loadingAddOrUpdateCate = false;
        openNotification("SUCCESS", action.payload.body.message);
      })

      .addCase(changeStatusCateAction.pending, state => {
        state.loadingChangeStatus = true;
      })
      .addCase(changeStatusCateAction.rejected, (state, action) => {
        state.loadingChangeStatus = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(changeStatusCateAction.fulfilled, (state, action) => {
        state.loadingChangeStatus = false;
        openNotification("SUCCESS", action.payload.message);
        if (action.payload.parentId === null || action.payload.parentId === undefined) {
          enableOrDisAbleCate(action.payload.id, state.listCate);
        } else {
          enableOrDisAbleCate(action.payload.id, state.listCateChild, true);
        }
      })

      .addCase(deleteCateAction.pending, state => {
        state.loadingDeleteCate = true;
      })
      .addCase(deleteCateAction.rejected, (state, action) => {
        state.loadingDeleteCate = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(deleteCateAction.fulfilled, (state, action) => {
        state.loadingDeleteCate = false;
        openNotification("SUCCESS", action.payload.message);
        state.listCate = deleteCate(action.payload.id, state.listCate);
      })

      .addCase(updateCateAction.pending, state => {
        state.loadingAddOrUpdateCate = true;
      })
      .addCase(updateCateAction.rejected, (state, action) => {
        state.loadingAddOrUpdateCate = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(updateCateAction.fulfilled, (state, action) => {
        state.loadingAddOrUpdateCate = false;
        openNotification("SUCCESS", action.payload.message);
        updateCateChild(action.payload.data, state.listCateChild);
      })

      .addCase(getListCateChildAction.pending, state => {
        state.loadingListCateChild = true;
      })
      .addCase(getListCateChildAction.rejected, (state, action) => {
        state.loadingListCateChild = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(getListCateChildAction.fulfilled, (state, action) => {
        state.loadingListCateChild = false;
        state.listCateChild = convertDataToListCateChild(action.payload.data);
      });
  },
});

export default productCategory.reducer;
