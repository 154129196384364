import { notification } from "antd";
import AppServices from "../../../service/AppServices";

export const openNotification = (type: "INFO" | "WARNING" | "ERROR" | "SUCCESS", mes: string, navigate?: string, onClick?: () => any, _notification?: string) => {
  if (type === "INFO") {
    notification.info({
      message: `Thông báo: ${_notification ? _notification : ""}`,
      description: mes,
      placement: "topRight",
      onClick: () => {
        navigate && AppServices.history!.push(navigate);
        onClick && onClick();
      },
    });
  } else if (type === "SUCCESS") {
    notification.success({
      message: `Thông báo thành công`,
      description: mes,
      placement: "topRight",
      onClick: () => {
        navigate && AppServices.history!.push(navigate);
        onClick && onClick();
      },
    });
  } else if (type === "ERROR") {
    notification.error({
      message: `Thông báo lỗi`,
      description: mes,
      placement: "topRight",
      onClick: () => {
        navigate && AppServices.history!.push(navigate);
        onClick && onClick();
      },
    });
  } else {
    notification.warning({
      message: `Cảnh báo`,
      description: mes,
      placement: "topRight",
      onClick: () => {
        navigate && AppServices.history!.push(navigate);
        onClick && onClick();
      },
    });
  }
};
