import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteProductAPI,
  getListCustomerAPI,
  getListOrderAPI,
  getProductDetailInfoAPI, putStatusProductAPI,
} from "../../../api/product/Product";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { convertResListCustomerToDataSource, getColumnPrice, getDataSourcePrice } from "../../../modules/product/Services";
import { IColumn } from "../../../service/Interfaces";
import { defaultColumnsDetailPrice } from "../../../modules/product/page/product-detail/ProductDetail";
import { IResProductMedia } from "../../../api/product/Interface";
import { getDataSourceOrder } from "./Function";

export interface IDataSourcePrice {
  key: any;
  STT: number;
  col2: string;
  col3: string;
  col4: number;
  col5: { id: any; isCheck: number }
}

export interface IDataSourceCustomer {
  key: any;
  STT: number;
  customerName: string;
  phoneNumber: string;
  quantity: string;
  revenue: string;
}

export interface IDataSourceOrder {
  key: any;
  STT: number;
  code: string;
  name: string;
  quantity: number;
  total: number;
}

export interface IInitState {
  code: string | null;
  name: string | null;
  cateName: string | null;
  description: string | null;
  statusStock: number | null;
  status: number | null;
  type: number;
  price: number | null;

  productMedia: IResProductMedia [];
  dataSourcePrices: IDataSourcePrice [];
  dataSourceCustomer: IDataSourceCustomer [];
  dataSourceOrder: IDataSourceOrder [];
  columnsDetailPrice: IColumn [];

  loadingGetProductDetailInfo: boolean;
  loadingDeleteProduct: boolean;
  loadingGetListCustomer: boolean;
  loadingGetListOrder: boolean;
  loadingPutStatus: boolean;

  totalPageCustomer: number;
  totalPageOrder: number;
}

const initialState: IInitState = {
  code: null,
  name: null,
  cateName: null,
  description: null,
  statusStock: null,
  status: null,
  type: 0,
  price: null,

  dataSourcePrices: [],
  dataSourceCustomer: [],
  dataSourceOrder: [],
  productMedia: [],
  columnsDetailPrice: [],

  loadingGetProductDetailInfo: false,
  loadingDeleteProduct: false,
  loadingGetListCustomer: false,
  loadingGetListOrder: false,
  loadingPutStatus: false,

  totalPageOrder: 0,
  totalPageCustomer: 0,
};

export const getProductDetailInfoAction = createAsyncThunk("productDetail/getProductDetailInfoAction", async (params: { id: any }, thunkAPI) => {
  const rs = await getProductDetailInfoAPI(params.id);
  if (rs.body.status === 1) {
    return rs.body;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const getListCustomerAction = createAsyncThunk("productDetail/getCustomerAction", async (params: { id: any, page: number }, thunkAPI) => {
  const rs = await getListCustomerAPI(params.id, params.page);
  if (rs.body.status === 1) {
    return rs.body;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const getListOrderAction = createAsyncThunk("productDetail/getListOrderAction", async (params: { id: number, page: number }, thunkAPI) => {
  const rs = await getListOrderAPI(params.id, params.page);
  if (rs.body.status === 1) {
    return rs.body;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const deleteProductAction = createAsyncThunk("productDetail/deleteProductAction", async (params: { id: any }, thunkAPI) => {
  const rs = await deleteProductAPI(params.id);
  if (rs.body.status === 1) {
    return rs.body.message;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

export const putStatusProductAction = createAsyncThunk("productDetail/putStatusProductAction", async (arg: { id: number }, thunkAPI) => {
  const store: any = thunkAPI.getState();
  const status = store.productDetail.status === 0 ? 1 : 0;
  const rs = await putStatusProductAPI(arg.id, status);
  if (rs.body.status === 1) {
    return rs.body.message;
  } else {
    return new Promise((resolve, reject) => reject(rs.body.message));
  }
});

const productDetailSlice = createSlice({
  name: "productDetail",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProductDetailInfoAction.pending, state => {
        state.loadingGetProductDetailInfo = true;
      })
      .addCase(getProductDetailInfoAction.rejected, (state, action) => {
        state.loadingGetProductDetailInfo = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(getProductDetailInfoAction.fulfilled, (state, action) => {
        state.loadingGetProductDetailInfo = false
        let {
          code,
          name,
          categoryName,
          status,
          statusStock,
          description,
          productMedia,
          productPrices,
          typeTag
        } = action.payload.data;
        state.code = code;
        state.name = name;
        state.description = description;
        state.statusStock = statusStock;
        state.status = status;
        state.type = typeTag
        state.cateName = categoryName;
        state.price = productPrices.length > 0 ? productPrices[0].price : null;
        state.productMedia = productMedia;
        state.dataSourcePrices = getDataSourcePrice(action.payload.data.productPrices);
        let columnsInsert: IColumn [] = getColumnPrice(action.payload.data.productCustomAttributes);
        let stt: IColumn [] = [
          {
            title: "STT",
            key: "STT",
            dataIndex: "STT",
            render: (value: any) => value,
          },
        ];

        state.columnsDetailPrice = stt.concat(columnsInsert, defaultColumnsDetailPrice);

      })

      .addCase(deleteProductAction.pending, state => {
        state.loadingDeleteProduct = true;
      })
      .addCase(deleteProductAction.rejected, (state, action) => {
        state.loadingDeleteProduct = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(deleteProductAction.fulfilled, (state, action) => {
        state.loadingDeleteProduct = false;
        openNotification("SUCCESS", action.payload);
      })

      .addCase(getListCustomerAction.pending, state => {
        state.loadingGetListCustomer = true;
      })
      .addCase(getListCustomerAction.rejected, (state, action) => {
        state.loadingGetListCustomer = false;
        openNotification("ERROR", action.error.message as string);
      })
      .addCase(getListCustomerAction.fulfilled, (state, action) => {
        state.loadingGetListCustomer = false;
        state.totalPageCustomer = action.payload.paging.totalItemCount;
        state.dataSourceCustomer = convertResListCustomerToDataSource(action.payload.data);
      })

      .addCase(getListOrderAction.pending, state => {
        state.loadingGetListOrder = true;
      })
      .addCase(getListOrderAction.rejected, (state, action) => {
        state.loadingGetListOrder = false;
        openNotification("ERROR", action.error as string);
      })
      .addCase(getListOrderAction.fulfilled, (state, action) => {
        state.loadingGetListOrder = false;
        state.dataSourceOrder = getDataSourceOrder(action.payload.data);
        state.totalPageOrder = action.payload.paging.totalItemCount;
      })

      .addCase(putStatusProductAction.pending, state => {
        state.loadingPutStatus = true;
      })
      .addCase(putStatusProductAction.rejected, (state, action) => {
        state.loadingPutStatus = false;
        openNotification("ERROR", action.payload as string);
      })
      .addCase(putStatusProductAction.fulfilled, (state, action) => {
        state.loadingPutStatus = false;
        openNotification("SUCCESS", "");
      });
  },
});

export default productDetailSlice.reducer;