import { deleteRequest, getRequest, IApiResponse } from "../../service/Basevices";
import { IResBody } from "../interface";
import {
  IResGetInfoCustomer,
  IResGetListCustomer,
  IResGetListDetailOrderAPI,
} from "./Interface";
import { Config } from "../../commons/validation/ValidationPattern";

export const getListCustomerAPI =
  (
    page: number,
    search: string,
    provinceId: number,
    startDate: string,
    endDate: string,
    limit?: number,
  ): Promise<IApiResponse<IResGetListCustomer>> => {
    let path: string = `/customer/list?page=${page}&limit=${limit ? limit : Config.limit}`;
    if (search) path = path + `&search=${search.trim()}`;
    if (provinceId) path = path + `&province_id=${provinceId}`;
    if (startDate) path = path + `&start_date=${startDate}`;
    if (endDate) path = path + `&end_date=${endDate}`;
    return getRequest(path, true);
  };

export const getListDetailOrderAPI =
  (
    id: number,
    page: number,
    startDate: string,
    endDate: string,
  ): Promise<IApiResponse<IResGetListDetailOrderAPI>> => {
    let path: string = `/customer/${id}/detail-order?page=${page}&limit=${Config.limit}`;
    if (startDate) path = path + `&start_date=${startDate}`;
    if (endDate) path = path + `&end_date=${endDate}`;
    return getRequest(path, true);
  };

export const getInfoCustomerAPI = (id: number): Promise<IApiResponse<IResGetInfoCustomer>> => {
  return getRequest(`/customer/${id}/detail`, true);
};

export const deleteCustomerAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return deleteRequest(`/customer/${id}/delete`, true);
};