import { Select } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderService } from "../service";
import { setProducts } from "app/slices/order-product/CreateOrder";

interface IAttributieSelect {
    record: any;
}

const AttributieSelect = (props: IAttributieSelect) => {
    const { record } = props;
    const dispatch = useDispatch();

    const createOrderData = useSelector((state: any) => state.createOrder.data);
    const targetItem = createOrderData?.find((item: any) => item?.id === record?.id);
    const [attrList, setAttrList] = React.useState<any[]>([]);

    const onChange = (value: any, selection: any) => {
        const newData = createOrderData?.map((item: any) => {
            const target = attrList?.find((cate: any) => value === cate?.id);
            if (item?.id === record?.id) {
                const renderTotalPrice = () => {
                    if (target?.flash_sale_item_remain > 0) {
                        return target?.flash_sale_price;
                    } else if (
                        target.wholesale_price &&
                        target?.wholesale_quantity &&
                        target.wholesale_price >= 0 &&
                        target?.wholesale_quantity >= 0 &&
                        target?.wholesale_quantity < 1
                    ) {
                        return target?.wholesale_price;
                    } else {
                        return target?.price;
                    }
                };

                return {
                    ...item,
                    quantity: 1,
                    product_price_id: selection?.id,
                    attribute: value,
                    price: target?.price,
                    sale_price: target?.flash_sale_item_remain > 0 ? +target?.flash_sale_price : 0,
                    quantity_sale_left: target?.flash_sale_item_remain,
                    total_price: renderTotalPrice(),
                    weight: target?.weight || "---",
                    wholesale_price: target?.wholesale_price,
                    wholesale_quantity: target?.wholesale_quantity,
                };
            }
            return item;
        });
        dispatch(setProducts(newData));
    };

    const onGetProductDetail = async () => {
        try {
            const res = await orderService.getProductDetail(targetItem?.product);
            if (res?.status) {
                const data = res?.data?.map((item: any) => {
                    const attr_1 = item?.product_attribute_name_1?.name;
                    const attr_2 = item?.product_attribute_name_2?.name;

                    if (attr_1 && attr_2) {
                        return {
                            ...item,
                            value: item?.id,
                            label: `${attr_1} - ${attr_2}`,
                        };
                    } else if (attr_1 && !attr_2) {
                        return {
                            ...item,
                            value: item?.id,
                            label: attr_1,
                        };
                    } else {
                        return {
                            ...item,
                            value: item?.id,
                        };
                    }
                });
                setAttrList(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (targetItem?.product) onGetProductDetail();
    }, [targetItem?.product]);

    return (
        <Select
            onChange={onChange}
            showSearch
            filterOption={false}
            showArrow
            value={targetItem?.attribute}
            placeholder="Chọn thuộc tính"
            style={{ width: "100%" }}
            options={targetItem?.isHasAttr ? attrList : []}
            disabled={!targetItem?.product}
        />
    );
};

export default memo(AttributieSelect);
