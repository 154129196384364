import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, PageHeader, Row, Select, Spin } from "antd";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import { Btn } from "commons/components/btn/Button";
import Container from "commons/components/container";
import UploadComponent from "commons/components/upload/UploadImage";
import React from "react";
import { useLocation } from "react-router-dom";
import AppServices from "service/AppServices";
import styled from "styled-components";
import { voucherService } from "../service";
import { Notification } from "utils";
import moment from "moment";

const AddEditVoucher = () => {
    const location: any = useLocation();
    const [form] = Form.useForm();
    const voucherId = location?.state?.voucherId;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [isSendNoti, setIsSendNoti] = React.useState<boolean>(false);
    const [fromDate, setFromDate] = React.useState<any>();
    const [toDate, setToDate] = React.useState<any>();
    const [imageUrl, setImageUrl] = React.useState<string>();
    const [decreaseType, setDecreaseType] = React.useState<number>(2);
    const [numOfUsed, setNumOfUsed] = React.useState<number>(0);

    const onGetVoucherDetail = async () => {
        try {
            setLoading(true);
            const res = await voucherService.getVoucherDetail(voucherId);
            if (res?.status) {
                form.setFieldsValue({
                    code: res?.data?.code,
                    name: res?.data?.name,
                    quantity: res?.data?.quantity,
                    minPriceToApply: res?.data?.order_min_price,
                    highestDecrease: res?.data?.max_discount,
                    fromDate: moment(res?.data?.from_time),
                    toDate: moment(res?.data?.to_time),
                    decreaseValue: res?.data?.discount,
                    type: res?.data?.type,
                    decreaseType: res?.data?.type_discount,
                });
                setNumOfUsed(res?.data?.num_of_used);
                setIsSendNoti(res?.data?.is_push_noti === 1);
                setImageUrl(res?.data?.image_url);
                setDecreaseType(res?.data?.type_discount);
                if (res?.data?.type_discount === 1) {
                    form.setFieldsValue({ highestDecrease: undefined });
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values: any) => {
        try {
            if (!imageUrl) {
                Notification("error", "Vui lòng tải ảnh voucher!");
                return;
            }

            setLoading(true);
            const payload = {
                code: values.code.trim(),
                name: values.name,
                type: values.type,
                type_discount: values.decreaseType,
                quantity: values.quantity,
                discount: values.decreaseValue,
                max_discount: values.highestDecrease,
                order_min_price: values.minPriceToApply,
                is_push_noti: isSendNoti ? 1 : 0,
                image_url: imageUrl,
                from_time: values.fromDate.format("YYYY-MM-DD HH:mm:ss"),
                to_time: values.toDate.format("YYYY-MM-DD HH:mm:ss"),
            };
            let res: any;
            if (voucherId) {
                res = await voucherService.updateVoucher(voucherId, payload);
            } else {
                res = await voucherService.addVoucher(payload);
            }
            if (res?.status) {
                Notification(
                    "success",
                    voucherId ? "Cập nhật thông tin voucher thành công!" : "Thêm mới voucher thành công"
                );
                AppServices.history?.goBack();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (voucherId) onGetVoucherDetail();
    }, [voucherId]);

    return (
        <Spin spinning={loading}>
            <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish} autoComplete="off">
                <Container
                    header={
                        <PageHeader
                            style={{ borderRadius: 8 }}
                            title={voucherId ? "Chỉnh sửa voucher" : "Thêm mới voucher"}
                            onBack={() => AppServices.history?.goBack()}
                            extra={[
                                <Form.Item noStyle>
                                    <Button style={{ background: "green" }} htmlType="submit" type="primary">
                                        Lưu
                                    </Button>
                                </Form.Item>,
                            ]}
                        ></PageHeader>
                    }
                    contentComponent={[
                        <>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"code"}
                                        label="Mã voucher"
                                        rules={[
                                            { required: true, message: "Vui lòng nhập mã voucher!" },
                                            {
                                                max: 255,
                                                type: "string",
                                                message: "Vui lòng không nhập quá 255 ký tự!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            onChange={(e: any) => {}}
                                            placeholder="Nhập mã voucher"
                                            allowClear
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"name"}
                                        label="Tên voucher"
                                        rules={[
                                            { required: true, message: "Vui lòng nhập tên voucher!" },
                                            {
                                                max: 255,
                                                type: "string",
                                                message: "Vui lòng không nhập quá 255 ký tự!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            onChange={(e: any) => {}}
                                            placeholder="Nhập tên voucher"
                                            allowClear
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"type"}
                                        label="Loại voucher"
                                        rules={[{ required: true, message: "Vui lòng chọn loại voucher!" }]}
                                    >
                                        <Select showArrow placeholder="Chọn loại voucher">
                                            <Select.Option value={1}>Mã giảm giá</Select.Option>
                                            <Select.Option value={2}>Ưu đãi vận chuyển</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"decreaseType"}
                                        label="Hình thức giảm"
                                        rules={[{ required: true, message: "Vui lòng chọn hình thức giảm!" }]}
                                    >
                                        <Select
                                            showArrow
                                            placeholder="Chọn hình thức giảm"
                                            onChange={(value: any) => {
                                                setDecreaseType(value);
                                                form.setFieldsValue({
                                                    decreaseValue: undefined,
                                                    highestDecrease: undefined,
                                                });
                                            }}
                                        >
                                            <Select.Option value={1}>Giảm theo tiền</Select.Option>
                                            <Select.Option value={2}>Giảm theo %</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"quantity"}
                                        label="Số lượng"
                                        rules={[{ required: true, message: "Vui lòng nhập số lượng voucher!" }]}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            placeholder="Nhập số lượng voucher"
                                            min={voucherId ? numOfUsed : 1}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"decreaseValue"}
                                        label="Mức giảm"
                                        rules={[{ required: true, message: "Vui lòng nhập mức giảm!" }]}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            placeholder="Nhập mức giảm"
                                            min={1}
                                            max={decreaseType === 2 ? 100 : undefined}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"minPriceToApply"}
                                        label="Đơn hàng tối thiểu"
                                        rules={[
                                            { required: true, message: "Vui lòng nhập giá trị đơn hàng tối thiểu!" },
                                        ]}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            placeholder="Nhập giá trị đơn hàng tối thiểu"
                                            min={0}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={"highestDecrease"} label="Mức giảm tối đa">
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            placeholder="Nhập mức giảm tối đa"
                                            min={1}
                                            style={{ width: "100%" }}
                                            disabled={decreaseType === 1}
                                        />
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"fromDate"}
                                        label="Thời gian bắt đầu"
                                        rules={[
                                            { required: true, message: "Vui lòng chọn thời gian bắt đầu voucher!" },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            onChange={(date: any) => {
                                                setFromDate(date);
                                                if (toDate && date && date.isAfter(toDate)) {
                                                    setToDate(null);
                                                }
                                            }}
                                            placeholder={"Thời gian bắt đầu"}
                                            showTime
                                            format={"HH:mm:ss DD-MM-YYYY"}
                                            disabledDate={(currentDate) =>
                                                toDate && currentDate && currentDate.isAfter(toDate)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"toDate"}
                                        label="Thời gian kết thúc"
                                        rules={[
                                            { required: true, message: "Vui lòng chọn thời gian kết thúc voucher!" },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            onChange={(date: any) => {
                                                setToDate(date);
                                                if (fromDate && date && date.isBefore(fromDate)) {
                                                    setFromDate(null);
                                                }
                                            }}
                                            showTime
                                            placeholder={"Thời gian kết thúc"}
                                            format={" HH:mm:ss DD-MM-YYYY"}
                                            disabledDate={(currentDate) =>
                                                fromDate && currentDate && currentDate.isBefore(fromDate)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </CustomRow>
                            <CustomRow>
                                <Col span={12}>
                                    <Form.Item
                                        name={"image"}
                                        label={
                                            <span>
                                                Ảnh voucher <span style={{ color: "red" }}>*</span>
                                            </span>
                                        }
                                    >
                                        <UploadComponent
                                            isUploadServerWhenUploading
                                            uploadType="single"
                                            maxLength={1}
                                            listType="picture-card"
                                            isShowFileList
                                            onSuccessUpload={(data: any) => {
                                                if (data) {
                                                    setImageUrl(data?.url);
                                                } else setImageUrl(undefined);
                                            }}
                                            initialFile={
                                                imageUrl && imageUrl?.length > 0
                                                    ? [
                                                          {
                                                              uid: "-1",
                                                              name: "avatar.png",
                                                              status: "done",
                                                              url: imageUrl,
                                                          },
                                                      ]
                                                    : []
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                {!voucherId && (
                                    <Col span={12}>
                                        <Form.Item name={"isSendNoti"} label="">
                                            <Checkbox
                                                checked={isSendNoti}
                                                onChange={(value: any) => setIsSendNoti(value.target.checked)}
                                            >
                                                Gửi thông báo tới khách hàng
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                )}
                            </CustomRow>
                        </>,
                    ]}
                />
            </Form>
        </Spin>
    );
};

const CustomRow = styled(Row)`
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header_block {
        border-bottom: 1px solid #f0f0f0;
        width: 100%;
        height: 40px;

        .header {
            font-size: 16px;
            line-height: 30px;
            font-weight: 700;
        }
    }

    .text {
        line-height: 40px;
    }
`;

export default AddEditVoucher;
