import { PageHeader, Row, Spin, Table } from "antd";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import { Btn } from "commons/components/btn/Button";
import Container from "commons/components/container";
import React from "react";
import AppServices from "service/AppServices";
import Filter from "../components/Filter";
import useDebounce from "utils/useDebounce";
import { Notification, formatNumber, momentToStringDate, scrollTo } from "utils";
import PaymentModal from "../components/PaymentModal";
import { walletService } from "../service";

const SaleWalletPage = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>("");
    const [listRowKeysSelected, setListRowKeysSelected] = React.useState<any[]>([]);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });
    const searchDebounce = useDebounce(search, 300);
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const rowSelection = {
        type: "radio" as const,
        selectedRowKeys: listRowKeysSelected,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedRows(selectedRows);
            setListRowKeysSelected(selectedRowKeys);
        },
    };

    const columns = [
        Table.SELECTION_COLUMN,
        {
            title: <b>STT</b>,
            width: 70,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Tên sale</b>,
            dataIndex: "name",
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: "phone",
        },
        {
            title: <b>Ví thu nhập</b>,
            dataIndex: "wallet",
            render: (_: any, record: any) => {
                if (record?.Wallet) {
                    return formatNumber(+record?.Wallet?.money_withdrawn + +record?.Wallet?.commision);
                }
                return "---";
            },
        },
        {
            title: <b>Đã rút</b>,
            dataIndex: "withdrawed",
            render: (_: any, record: any) => {
                if (record?.Wallet) {
                    return formatNumber(record?.Wallet?.money_withdrawn);
                }
                return "---";
            },
        },
        {
            title: <b>Còn lại</b>,
            dataIndex: "left",
            render: (_: any, record: any) => {
                if (record?.Wallet) {
                    return formatNumber(record?.Wallet?.commision);
                }
                return "---";
            },
        },
        {
            title: <b>Ngân hàng</b>,
            dataIndex: "bank",
            render: (_: any, record: any) => {
                if (record?.user_bank?.length > 0) {
                    return record?.user_bank?.[0]?.bank?.name;
                }
                return "---";
            },
        },
        {
            title: <b>Số tài khoản</b>,
            dataIndex: "bank_number",
            render: (_: any, record: any) => {
                if (record?.user_bank?.length > 0) {
                    return record?.user_bank?.[0]?.account_number;
                }
                return "---";
            },
        },
        {
            title: <b>Chủ sở hữu</b>,
            dataIndex: "bank_holder",
            render: (_: any, record: any) => {
                if (record?.user_bank?.length > 0) {
                    return record?.user_bank?.[0]?.cardholder_name;
                }
                return "---";
            },
        },
    ];

    const onClickPayButton = () => {
        if (listRowKeysSelected?.length === 0) {
            Notification("error", "Vui lòng chọn ít nhất một nhân viên sale!");
        } else {
            setVisible(true);
        }
    };

    const onGetListData = async () => {
        try {
            setLoading(true);
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce,
                start_date: dateRange.fromDate,
                end_date: dateRange.toDate,
            };
            const res: any = await walletService.getData(payload);
            if (res?.status) {
                const newData = res?.data?.map((item: any) => ({ ...item, key: item?.id }));
                setData(newData);
                setPaging({ ...paging, total: res?.paging?.totalItemCount, current: res?.paging?.page });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onExportExcel = async () => {
        try {
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce,
                start_date: dateRange.fromDate,
                end_date: dateRange.toDate,
            };
            const res = await walletService.exportExcel(payload);
            if (res?.status) {
                Notification("success", "Xuất file excel thành công!");
                window.open(res?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onRequestWithdraw = async (values: any) => {
        try {
            setLoading(true);
            const payload = {
                user_id: selectedRows?.[0]?.id,
                payment_amount: values.money,
                note: values.note,
            };
            const res = await walletService.requestWithdraw(payload);
            if (res?.status) {
                Notification("success", "Thanh toán hoa hồng thành công!");
                setVisible(false);
                onGetListData();
                setListRowKeysSelected([]);
                setSelectedRows([]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        onGetListData();
    }, [paging.current]);

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetListData();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [searchDebounce, dateRange]);

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        title="Ví thu nhập"
                        extra={[
                            <Row justify="space-between" style={{ width: 250 }}>
                                <Btn value={"Xuất excel"} onClick={onExportExcel} backGroundColor={"#e78310"} />
                                <Btn value={"Thanh toán"} onClick={onClickPayButton} backGroundColor={"#109703"} />
                            </Row>,
                        ]}
                    />
                }
                filterComponent={<Filter search={search} setSearch={setSearch} setDateRange={setDateRange} />}
                contentComponent={[
                    <>
                        <Row>
                            <p>
                                Kết quả lọc: <b>{paging.total}</b>
                            </p>
                        </Row>
                        {visible && (
                            <PaymentModal
                                loading={loading}
                                visible={visible}
                                selectedRows={selectedRows}
                                setVisible={setVisible}
                                onRequestWithdraw={onRequestWithdraw}
                            />
                        )}
                        <Table
                            id="top-table"
                            style={{ width: "100%" }}
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: "Chưa có bản ghi nào!",
                            }}
                            rowSelection={rowSelection}
                            onRow={(record) => {
                                return {
                                    onClick: () =>
                                        AppServices.history!.push(pathMenu.withdraw_detail, {
                                            id: record?.id,
                                        }),
                                };
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: (page) => {
                                    setPaging({ ...paging, current: page });
                                    setTimeout(() => {
                                        scrollTo("start");
                                    }, 500);
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    );
};

export default SaleWalletPage;
