import { Button, Card, Col, Form, Input, InputNumber, PageHeader, Row, Space, Spin, Table } from "antd";
import Container from "commons/components/container";
import React from "react";
import styled from "styled-components";
import { Notification } from "utils";
import { configService } from "../service";
import moment from "moment";

const CommissionConfigPage = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [contactList, setContactList] = React.useState<any>([]);
    const [hotline, setHotline] = React.useState<string>();
    const [zalo, setZalo] = React.useState<string>();
    const [telegram, setTelegram] = React.useState<string>();
    const [whatsapp, setWhatsApp] = React.useState<string>();
    const [youtubeAuth, setYoutubeAuth] = React.useState<any>();

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            dataIndex: "stt",
        },
        {
            title: <b>Kênh liên hệ</b>,
            dataIndex: "contactChannel",
        },
        {
            title: <b>Thông tin liên hệ</b>,
            dataIndex: "contactInfo",
            render: (value: string, record: any) => {
                const onChange = (e: any) => {
                    const value = e.target.value;
                    if (record?.contactChannel === "Hotline") {
                        setHotline(value);
                    } else if (record?.contactChannel === "Zalo") {
                        setZalo(value);
                    } else if (record?.contactChannel === "Telegram") {
                        setTelegram(value);
                    } else {
                        setWhatsApp(value);
                    }
                };
                return (
                    <Input
                        placeholder={"Nhập thông tin"}
                        onChange={onChange}
                        allowClear
                        value={
                            record?.contactChannel === "Hotline"
                                ? hotline
                                : record?.contactChannel === "Zalo"
                                ? zalo
                                : record?.contactChannel === "Telegram"
                                ? telegram
                                : whatsapp
                        }
                    />
                );
            },
        },
    ];

    const onGetConfigs = async () => {
        try {
            setLoading(true);
            const res = await configService.getConfigs();
            if (res?.status) {
                form.setFieldsValue({
                    timeUsingApp: +res?.data?.earn_point_time,
                    pointAdded: +res?.data?.earn_point_points,
                    timesAdded: +res?.data?.earn_point_per_day,
                    commission: +res?.data?.sale_commision_percent,
                    firstTimeUsingApp: +res?.data?.initial_use_app_point,
                    transportFee: +res?.data?.delivery_fee,
                    timeSendNotification: +res?.data?.cart_push_noti_time / (24 * 60 * 60),
                    rewardPoint: res?.data?.referral_customer_point,
                });

                setContactList([
                    {
                        stt: 1,
                        contactChannel: "Hotline",
                        contactInfo: res?.data?.hotline,
                    },
                    {
                        stt: 2,
                        contactChannel: "Zalo",
                        contactInfo: res?.data?.zalo,
                    },
                    {
                        stt: 3,
                        contactChannel: "Telegram",
                        contactInfo: res?.data?.telegram,
                    },
                    {
                        stt: 4,
                        contactChannel: "WhatsApp",
                        contactInfo: res?.data?.whatsapp,
                    },
                ]);
                setHotline(res?.data?.hotline);
                setZalo(res?.data?.zalo);
                setTelegram(res?.data?.telegram);
                setWhatsApp(res?.data?.whatsapp);
                // setIsShowOnApp(+res?.data?.earn_point_app_visible);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onAddUpdateConfig = async (values: any) => {
        try {
            setLoading(true);
            const payload = {
                earn_point_time: values.timeUsingApp,
                earn_point_points: values.pointAdded,
                earn_point_per_day: values.timesAdded,
                sale_commision_percent: values.commission,
                initial_use_app_point: values.firstTimeUsingApp,
                earn_point_app_visible: 1,
                hotline,
                zalo,
                telegram,
                whatsapp,
                delivery_fee: values?.transportFee,
                cart_push_noti_time: values?.timeSendNotification * 24 * 60 * 60,
                referral_customer_point: values?.rewardPoint,
            };
            const res = await configService.addUpdateConfig(payload);
            if (res?.status) {
                Notification("success", "Cập nhật cấu hình thành công!");
                onGetConfigs();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onGetYoutubeAuth = async () => {
        try {
            const res = await configService.getYoutubeConfig();
            if (res?.status) {
                setYoutubeAuth({
                    email: res?.data?.email,
                    created_at: res?.data?.create_at,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onSigninYoutube = async () => {
        try {
            window.open(`${process.env.REACT_APP_BASE_URL as string}/youtube_livestream/auth`);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        onGetConfigs();
        onGetYoutubeAuth();
    }, []);

    return (
        <Spin spinning={loading}>
            <Form form={form} onFinish={onAddUpdateConfig} autoComplete="off" layout="vertical">
                <Container
                    header={
                        <PageHeader
                            title="Cấu hình"
                            extra={[
                                <Form.Item noStyle>
                                    <Button htmlType="submit" type="primary" style={{ background: "green" }}>
                                        Lưu
                                    </Button>
                                </Form.Item>,
                            ]}
                        />
                    }
                    contentComponent={[
                        <>
                            <p style={{ paddingBottom: 10, fontWeight: 600 }}>1. Cấu hình điểm thưởng</p>

                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <CustomCard title={"Tích điểm sử dụng app"} style={{ height: "100%" }}>
                                        <Col span={24}>
                                            <Form.Item
                                                name={"timeUsingApp"}
                                                label="Thời gian sử dụng app"
                                                rules={[
                                                    { required: true, message: "Vui lòng nhập thời gian sử dụng app!" },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={1}
                                                    max={60}
                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {}}
                                                    placeholder="Thời gian sử dụng app"
                                                    autoComplete="off"
                                                    addonAfter="Phút"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={"pointAdded"}
                                                label="Số điểm"
                                                rules={[
                                                    { required: true, message: "Vui lòng nhập số điểm được cộng!" },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {}}
                                                    placeholder="Điểm được cộng"
                                                    autoComplete="off"
                                                    addonAfter="VND"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={"timesAdded"}
                                                label="Số lần/ngày"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Vui lòng nhập số lần được cộng điểm trên ngày!",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {}}
                                                    placeholder="Số lần cộng điểm"
                                                    autoComplete="off"
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={24}>
                                            <Form.Item name={"isShowOnApp"} label="">
                                                <Checkbox
                                                    onChange={(e: any) => {
                                                        setIsShowOnApp(e.target.checked ? 1 : 0);
                                                    }}
                                                    checked={isShowOnApp === 1}
                                                >
                                                    Hiển thị trên app
                                                </Checkbox>
                                            </Form.Item>
                                        </Col> */}
                                    </CustomCard>
                                </Col>
                                <Col span={12}>
                                    <CustomCard title="Hoa hồng cho sale" style={{ marginBottom: 20 }}>
                                        <Col span={24}>
                                            <Form.Item
                                                name={"commission"}
                                                label="Hoa hồng"
                                                rules={[{ required: true, message: "Vui lòng nhập hoa hồng!" }]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    addonAfter="%"
                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {}}
                                                    placeholder="Hoa hồng"
                                                    autoComplete="off"
                                                    formatter={(value) =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </CustomCard>
                                    <CustomCard title="Tích xu khi đăng ký thành công">
                                        <Col span={24}>
                                            <Form.Item
                                                name={"firstTimeUsingApp"}
                                                label="Lần đầu dùng app"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Vui lòng nhập xu khi đăng ký thành công!",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {}}
                                                    placeholder="Lần đầu sử dụng app"
                                                    addonAfter="Điểm"
                                                    autoComplete="off"
                                                    formatter={(value) =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </CustomCard>
                                </Col>
                            </Row>
                        </>,
                        <>
                            <p style={{ paddingBottom: 10, fontWeight: 600 }}>2. Cấu hình thông tin khác</p>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <CustomCard title="Phí vận chuyển">
                                        <Col span={24}>
                                            <Form.Item
                                                name={"transportFee"}
                                                label="Phí vận chuyển"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Vui lòng nhập ví vận chuyển!",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: "100%" }}
                                                    onChange={(e: any) => {}}
                                                    placeholder="Nhập phí vận chuyển"
                                                    autoComplete="off"
                                                    addonAfter="VNĐ"
                                                    formatter={(value) =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </CustomCard>
                                    <CustomCard title="Thời gian gửi thông báo giỏ hàng">
                                        <Col span={24}>
                                            <Form.Item
                                                name={"timeSendNotification"}
                                                label="Thời gian gửi thông báo"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Vui lòng nhập thời gian gửi thông báo!",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: "100%" }}
                                                    placeholder="Nhập ngày"
                                                    addonAfter="Ngày"
                                                    formatter={(value) =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </CustomCard>
                                    <CustomCard title="Cộng điểm khi khách hàng giới thiệu khách hàng">
                                        <Col span={24}>
                                            <Form.Item
                                                name={"rewardPoint"}
                                                label="Điểm thưởng khi khách hàng giới thiệu khách hàng"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Vui lòng nhập điểm thưởng khi khách hàng giới thiệu khách hàng!",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: "100%" }}
                                                    placeholder="Nhập điểm"
                                                    addonAfter="Điểm"
                                                    formatter={(value) =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </CustomCard>
                                </Col>
                                <Col span={12}>
                                    <CustomCard title="Thông tin liên hệ">
                                        <Table
                                            id="top-table"
                                            style={{ width: "100%" }}
                                            bordered
                                            columns={columns}
                                            dataSource={contactList}
                                            pagination={false}
                                        />
                                    </CustomCard>
                                    <CustomCard
                                        title="Cấu hình tài khoản live"
                                        style={{ marginTop: 20 }}
                                        extra={
                                            <Button type="primary" onClick={onSigninYoutube}>
                                                Đăng nhập
                                            </Button>
                                        }
                                    >
                                        <Row style={{ width: "100%" }}>
                                            <Col span={8}>
                                                <label style={{ fontWeight: "bold" }}>Email: </label>

                                                {youtubeAuth?.email ? youtubeAuth?.email : " ---"}
                                            </Col>

                                            {/* <Col span={8}>
                                                <label style={{ fontWeight: "bold" }}>Trạng thái: </label>{" "}
                                                {dataConfigLivestream.status
                                                        ? dataConfigLivestream?.status === STATUSLIVE.SUCCESS
                                                            ? 'Đã kết nối'
                                                            : 'Ngưng kết nối'
                                                        : ' ---'}
                                            </Col> */}
                                            <Col span={8}>
                                                <label style={{ fontWeight: "bold" }}>Ngày tạo: </label>
                                                {youtubeAuth?.created_at
                                                    ? moment(youtubeAuth?.created_at).format("DD/MM/YYYY")
                                                    : " ---"}
                                            </Col>
                                        </Row>
                                    </CustomCard>
                                </Col>
                            </Row>
                        </>,
                    ]}
                />
            </Form>
        </Spin>
    );
};

const CustomCard = styled(Card)`
    .ant-card-head {
        background-color: #f5f5f5;
    }
`;

export default CommissionConfigPage;
