import { Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { getAccountDetailAPI } from "../../../api/account/Account";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getListAccAction, updateAccAction } from "../../../app/slices/account/Account";
import { Config, ValidationMes, ValidationPattern } from "../../../commons/validation/ValidationPattern";
import UploadListFileComponent from "../../product/page/add-update/component/UploadListFileComponent";

interface IFormUpdate {
    eventCancel: () => any;
}

const FormUpdateAccount = ({ eventCancel }: any) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [url, setUrl] = useState<any>("");
    const [acc, setAcc] = useState<any>(null);

    const onFinish = async (data: any) => {
        let rs = await dispatch(
            updateAccAction({
                id: acc!.id,
                status: data.status,
                name: data.name,
                profilePictureUrl: url,
                email: data.email ? data.email : null,
                phone: acc!.phone,
                role: parseInt(data.role) ? parseInt(data.role) : acc!.role,
            })
        );
        localStorage.setItem("username", data?.name);
        localStorage.setItem("email", data?.email);
        localStorage.setItem("avatar", url);
        if (rs.type === "account/updateAccAction/fulfilled") {
            eventCancel();
            dispatch(getListAccAction({ page: 1, status: 2, search: "", role: 0 }));
        }
    };

    const getAcc = async () => {
        const id: number = parseInt(localStorage.getItem("uuid") as string);
        const res: any = await getAccountDetailAPI(id);
        if (res.body.status === 1) {
            setAcc(res.body.data);
            form.setFieldsValue({
                name: res.body.data.name,
                email: res.body.data.email,
                status: res.body.data.status,
                role: res.body.data.role === 4 ? "Nhân viên" : "Quản trị viên",
            });
            localStorage.setItem("username", res.body.data.name);
            localStorage.setItem("email", res.body.data.email);
            localStorage.setItem("avatar", res.body.data.fileName);
            setUrl(res.body.data?.profile_picture_url);
        }
    };

    useEffect(() => {
        getAcc();
    }, []);

    return (
        <Form {...Config.layoutForm} form={form} onFinish={onFinish}>
            <Form.Item label={"Số điện thoại"} style={{ marginBottom: 12 }}>
                {acc && acc.phone}
            </Form.Item>

            <Form.Item
                label={"Họ tên"}
                name={"name"}
                rules={[
                    { required: true, message: "Vui lòng nhập họ tên." },
                    { pattern: ValidationPattern.username, message: ValidationMes.username },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item label={"Email"} name={"email"} rules={[{ type: "email", message: ValidationMes.email }]}>
                <Input />
            </Form.Item>

            {acc && acc.role !== 4 && (
                <Form.Item name={["role"]} label={"Loại tài khoản"}>
                    <Select>
                        <Select.Option value={"2"}>Quản trị viên</Select.Option>
                        <Select.Option value={"4"}>Nhân viên</Select.Option>
                    </Select>
                </Form.Item>
            )}

            <Form.Item name={"status"} label={"Trạng thái"}>
                <Select defaultValue={acc ? acc.status : 0}>
                    <Select.Option value={1}>Đang hoạt động</Select.Option>
                    <Select.Option value={0}>Ngưng hoạt động.</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name={["user", "avatar"]} label={"Ảnh đại diên"}>
                <UploadListFileComponent
                    type={"picture-card"}
                    limit={1}
                    name={"image"}
                    logData={(data) => {
                        if (data[0] && data[0].status === "done") {
                            setUrl(data[0].response.data.filename);
                        } else if (data.length === 0) {
                            setUrl("");
                        }
                    }}
                    path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                    size={2}
                    defaultData={
                        url
                            ? [
                                  {
                                      uid: 0,
                                      name: acc ? (acc.fileName as string) : "",
                                      url: acc ? (acc.profilePictureUrl as string) : "",
                                      status: "done",
                                  },
                              ]
                            : []
                    }
                />
            </Form.Item>

            <Form.Item wrapperCol={{ ...Config.layoutForm.wrapperCol, offset: 4 }}>
                <Row gutter={16}>
                    <Col lg={12}>
                        <Button className={"btn--red"} onClick={() => eventCancel()}>
                            Hủy
                        </Button>
                    </Col>
                    <Col lg={12}>
                        <Button
                            htmlType={"submit"}
                            loading={useAppSelector((state) => state.account.loadingUpdateAcc)}
                            className={"btn--green"}
                        >
                            Lưu
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default FormUpdateAccount;
