import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getListCustomerAPI } from "../../../api/customer/Customer";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { IResCustomer } from "../../../api/customer/Interface";
import { getStartIndex } from "../../../commons/validation/ValidationPattern";

export interface IDatasourceCustomer {
    key: number;
    col1: number;
    col2: string;
    col3: string;
    col4: string;
    col5: number;
    col6: number;
    col7: string;
    status: number;
    total_point: number;
    current_point: number;
    record: IResCustomer;
}

export interface IInitState {
    datasourceCustomer: IDatasourceCustomer[];
    loadingCustomer: boolean;
    totalCustomer: number;
    dataSourceExportCustomer: IDatasourceCustomer[];
    loadingExport: boolean;
}

const initialState: IInitState = {
    datasourceCustomer: [],
    loadingCustomer: false,
    totalCustomer: 0,
    dataSourceExportCustomer: [],
    loadingExport: false,
};

const getDataSource = (data: IResCustomer[], page?: number): IDatasourceCustomer[] => {
    let rs: IDatasourceCustomer[] = [];
    data.forEach((value, index) =>
        rs.push({
            key: value.id,
            col1: ++index + getStartIndex(page),
            col2: value.name,
            col3: value.phone,
            col4: value.provinceName,
            col5: value.revenueComplete,
            col6: value.revenueReality,
            col7: value.createAt,
            status: value.status,
            total_point: value.totalPoint,
            current_point: value.currentPoint,
            record: value,
        })
    );
    return rs;
};

export const getListCustomerAction = createAsyncThunk(
    "customer/getListCustomerAction",
    async (arg: { page: number; search: string; provinceId: number; startDate: string; endDate: string }, thunkAPI) => {
        const rs = await getListCustomerAPI(arg.page, arg.search, arg.provinceId, arg.startDate, arg.endDate);
        if (rs.body.status === 1) {
            return rs.body;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

export const getDataExportAction = createAsyncThunk(
    "customer/getDataExportAction",
    async (arg: { search: string; provinceId: number; startDate: string; endDate: string }, thunkAPI) => {
        const rs = await getListCustomerAPI(1, arg.search, arg.provinceId, arg.startDate, arg.endDate, 1000);
        if (rs.body.status === 1) {
            return rs.body;
        } else {
            return new Promise((resolve, reject) => reject(rs.body.message));
        }
    }
);

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListCustomerAction.pending, (state) => {
                state.loadingCustomer = true;
            })
            .addCase(getListCustomerAction.rejected, (state, action) => {
                state.loadingCustomer = false;
                openNotification("ERROR", action.error.message as string);
            })
            .addCase(getListCustomerAction.fulfilled, (state, action) => {
                state.loadingCustomer = false;
                state.datasourceCustomer = [];
                state.datasourceCustomer = getDataSource(action.payload.data, action.meta.arg.page);
                state.totalCustomer = action.payload.paging.totalItemCount;
            })

            .addCase(getDataExportAction.pending, (state) => {
                state.loadingExport = true;
            })
            .addCase(getDataExportAction.rejected, (state, action) => {
                state.loadingExport = false;
                openNotification("ERROR", "Lấy dữ liệu xuất file gặp lỗi vui lòng thử lại.");
            })
            .addCase(getDataExportAction.fulfilled, (state, action) => {
                state.loadingExport = false;
                state.dataSourceExportCustomer = getDataSource(action.payload.data);
            });
    },
});

export default customerSlice.reducer;
