import React from "react";
import { numberWithCommas } from "../../../service/Format";
import { Col, Row } from "antd";
import styled from "styled-components";
import AppTitle from "../../../commons/components/title-component/AppTitle";
import LineChart, { IChartLine } from "../../../commons/components/chart/LineChart";

interface IOrderComponent {
    dataValue: any;
    dataChart: IChartLine | undefined;
}

const OrderComponent: React.FC<IOrderComponent> = ({ dataValue, dataChart }) => {
    const item = (
        name: string,
        value: number,
        color: string | "red" | "orange" | "blue" | "purple"
    ): React.ReactNode => {
        return (
            <div>
                <div style={{ textAlign: "center", marginBottom: 5 }}>{name}</div>
                <div
                    style={{
                        border: `1px solid ${color}`,
                        borderRadius: 4,
                        height: 40,
                        fontWeight: "bold",
                        fontSize: 22,
                        color: color,
                    }}
                    className={"flex-center"}
                >
                    {numberWithCommas(value)}
                </div>
            </div>
        );
    };
    return (
        <Style>
            <div className={"order-page"}>
                <AppTitle title={"Đơn hàng"} />
                <div className={"order-component__value"}>
                    <Row gutter={[16, 16]}>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            {item("Chờ xác nhận", dataValue.pending, "#93c8e4")}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            {item("Đã xác nhận", dataValue.confirmed, "purple")}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            {item("Đang giao", dataValue.delivering, "blue")}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            {item("Đã giao", dataValue.delivered, "blue")}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            {item("Hoàn hàng", dataValue.returned, "blue")}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            {item("Hủy/ Từ chối", dataValue.cancel, "red")}
                        </Col>
                    </Row>
                </div>
                <div className={"order-component__chart"}>
                    <LineChart data={dataChart} height={600} width={1000} />
                </div>
            </div>
        </Style>
    );
};

const Style = styled.div`
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    height: 900px;

    .order-component {
        &__title {
            font-weight: bold;
            font-size: 16px;
        }

        &__value {
            margin-top: 16px;
        }

        &__chart {
            margin-top: 20px;
            height: 415px;
        }
    }
`;

export default OrderComponent;
