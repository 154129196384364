import React, { Component } from "react";
// import "./App.scss";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Home from "./modules/home/Home";
import Login from "./modules/login/Login";
import AppServices from "./service/AppServices";
import Spam from "./modules/spam/Spam";
import LivestreamEmulatorPage from "modules/livestream-emulator/page";

class App extends Component<any, any> {
    constructor(props: any) {
        super(props);

        if (window.location.pathname === "/") {
            window.location.href = "/login";
        }
    }

    render() {
        return (
            <BrowserRouter ref={(instances: any) => (AppServices.history = instances.history)}>
                <Switch>
                    <Route path={"/home"} component={Home} />
                    <Route exact path={"/login"} component={Login} />
                    <Route exact path={"/emulator"} component={LivestreamEmulatorPage} />
                    <Route exact path={"/spam"} component={Spam} />
                    <Route>404 not found</Route>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
