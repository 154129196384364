import { deleteRequest, getRequest, IApiResponse, postRequest, putRequest } from "../../service/Basevices";
import { IResBody } from "../interface";
import { IResListCate, IResPostCate } from "./Interface";
import { Config } from "../../commons/validation/ValidationPattern";

export const getListCateAPI = (
  page: number,
  search: string | null,
  status: number | null,
  fromDate: string | null,
  toDate: string | null,
  parentId: any,
  limit: number = Config.limit,
): Promise<IApiResponse<IResListCate>> => {

  let path: string = `/category/list?page=${page}&limit=${limit}`;
  if (search) path = path + `&search=${search}`;
  if (typeof status === "number") path = path + `&status=${status}`;
  if (fromDate) path = path + `&start_date=${fromDate}`;
  if (toDate) path = path + `&end_date=${toDate}`;
  if (parentId) path = path + `&parent_id=${parentId}`;
  return getRequest(path, true);

};

export const postCateAPI =
  (
    name: string,
    displayOrder: string,
    iconUrl: string,
    parentId: any,
  ): Promise<IApiResponse<IResPostCate>> => {
    return postRequest(`/category/create`, true, {
      name: name,
      display_order: displayOrder,
      icon_url: iconUrl ? iconUrl : "",
      parent_id: parentId,
    });
  };

export const putChangeStatusCateAPI = (id: any, currentStatus: number): Promise<IApiResponse<IResBody>> => {
  return putRequest(`/category/${id}/change-status`, true, { status: currentStatus });
};

export const putUpdateCateAPI =
  (
    id: any,
    name: string,
    displayOrder: any,
    iconUrl: string,
  ): Promise<IApiResponse<IResPostCate>> => {
    return putRequest(`/category/${id}/update`, true, {
      name: name,
      display_order: displayOrder,
      icon_url: iconUrl ? iconUrl : "",
    });
  };

export const deleteCateAPI = (id: any): Promise<IApiResponse<IResBody>> => {
  return deleteRequest(`/category/${id}/delete`, true);
};
