import React, { useState } from "react";
import { Col, Modal, Row, Table, Tag } from "antd";
import { AppBtn } from "../../../commons/components/btn/Button";
import styled from "styled-components";
import { useAppSelector } from "../../../app/hooks";
import { IColumn } from "../../../service/Interfaces";
import { IDataTableAccount } from "../interfaces";
import moment from "moment";
import { Config } from "../../../commons/validation/ValidationPattern";
import { EmptyComponent } from "commons/components/AppComponent";

interface ITableComponent {
  eventEdit: (value: IDataTableAccount) => any;
  eventChangeStatus: (id: any, currentStatus: number) => any;
  eventResetPass: (id: any) => any;
  eventDelete: (id: any) => any;
}

const TableComponent: React.FC<ITableComponent> = ({ eventEdit, eventDelete, eventResetPass, eventChangeStatus }) => {
  const [expandRowKeys, setExpandRowKeys] = useState<any>([]);
  const loadingChangeStatus = useAppSelector((state) => state.account.loadingPutChangeStatus);
  const loadingResetPass = useAppSelector((state) => state.account.loadingResetPass);
  const loadingDelete = useAppSelector((state) => state.account.loadingDeleteAccount);
  const dataTable = useAppSelector((state) => state.account.listAcc);
  const loading = useAppSelector((state) => state.account.loadingListAcc);

  const columns: IColumn[] = [
    {
      title: "STT",
      key: "STT",
      dataIndex: "STT",
      width: 50,
      render: (index: number) => <div>{index}</div>,
    },
    {
      title: "Họ tên",
      key: "name",
      dataIndex: "name",
      render: (name: string) => <div>{name}</div>,
    },
    {
      title: "Số điện thoại",
      key: "phone",
      dataIndex: "phone",
      render: (phone: string) => <div>{phone}</div>,
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (mail: string) => <div>{mail}</div>,
    },
    {
      title: "Loại tài khoản",
      key: "role",
      dataIndex: "role",
      render: (role: number) => <div>
        {role === 4 && <Tag color={"green"}>Nhân viên</Tag>}
        {role !== 4 && <Tag color={"blue"}>Quản trị viên</Tag>}
      </div>,
    },
    {
      title: "Trạng thái",
      key: "status",
      dataIndex: "status",
      render: (status: any) => (
        <div>
          {status === 0 ? <Tag color={"gray"}>Ngừng hoạt động</Tag> : <Tag color={"green"}>Đang hoạt động</Tag>}
        </div>
      ),
    },
  ];

  const expand = (value: IDataTableAccount) => {
    return (
      <StyleExpand>
        <div className={"title"} style={{ fontSize: 15 }}>
          Thông tin tài khoản
        </div>
        <div style={{ backgroundColor: "white" }}>
          <div style={{ padding: "15px 100px", borderBottom: "1px solid rgb(240, 242, 245)" }}>
            <Row style={{ marginBottom: 10 }}>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item label"}>Tên người dùng:</div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item value"}>{value.name}</div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item label"}>Email:</div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item value"}>{value.email}</div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item label"}>Số điện thoại:</div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item value"}>{value.phone}</div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item label"}>Ngày tạo:</div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className={"item value"}>{moment(value.date).format(Config.formatDate)}</div>
              </Col>
            </Row>
          </div>

          <div style={{ padding: "15px 150px" }} className={"wrapper-list-btn"}>
            <Row gutter={[25, 25]}>
              <Col xs={12} sm={12} md={12} lg={6}>
                <AppBtn
                  icon={
                    value.status === 0 ? (
                      <i className="fal fa-toggle-off" style={{ fontSize: 20, marginTop: 3 }} />
                    ) : (
                      <div>
                        <i className="fad fa-toggle-on" style={{ fontSize: 20, marginTop: 3 }} />
                      </div>
                    )
                  }
                  title={value.status === 1 ? "Đang hoạt động" : "Ngừng hoạt động"}
                  color={value.status === 0 ? "gray" : "#1890ff"}
                  event={() => eventChangeStatus(value.key, value.status === 1 ? 0 : 1)}
                  loading={loadingChangeStatus}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <AppBtn
                  icon={<i className="far fa-sync-alt" />}
                  title={"Reset mật khẩu"}
                  color={"gray"}
                  event={() => {
                    Modal.confirm({
                      title: "Bạn có muốn reset mật khẩu tài khoản này không?",
                      onOk: () => eventResetPass(value.key),
                    });
                  }}
                  loading={loadingResetPass}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <AppBtn
                  icon={<i className="fal fa-edit" />}
                  title={"Chỉnh sửa"}
                  color={"green"}
                  event={() => eventEdit(value)}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <AppBtn
                  icon={<i className="fal fa-trash-alt" />}
                  title={"Xóa"}
                  color={"red"}
                  event={() => {
                    Modal.confirm({
                      title: "Bạn có muốn xóa tài khoản này không.",
                      onOk: () => eventDelete(value.key),
                    });
                  }}
                  loading={loadingDelete}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div></div>
      </StyleExpand>
    );
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataTable}
        locale={{
          emptyText: <EmptyComponent />,
        }}
        bordered
        expandable={{
          expandedRowRender: (user) => expand(user),
          expandRowByClick: true,
          expandedRowKeys: expandRowKeys,
          onExpandedRowsChange: (value) => {
            setExpandRowKeys(value.filter((index) => index !== expandRowKeys[0]));
          },
        }}
        loading={loading}
        pagination={false}
      />
    </div>
  );
};

const StyleExpand = styled.div`
  .item {
    display: inline-block;
  }

  .label {
    margin-right: 40px;
  }

  .value {
    font-weight: bold;
  }

  .btn {
    height: 50px;
    cursor: pointer;
  }

  .wrapper-list-btn .ant-col {
    border-right: 1px solid gray;
  }

  .wrapper-list-btn .ant-row .ant-col:last-child {
    border: none;
  }

  .title {
    margin-left: 10px;
    font-weight: bold;
  }
`;

export default TableComponent;
