import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

interface IAppBtn {
    icon: React.ReactNode;
    title: string;
    color: string | "red" | "green" | "blue" | "gray";
    event?: () => any;
    loading?: boolean;
}

interface IBtn {
    value: string;
    loading?: boolean;
    backGroundColor?: "red" | "#109703" | "gray" | "rgba(255, 255, 255, 0)" | string;
    color?: "white" | "gray" | "black" | "#1890ff" | string;
    style?: React.CSSProperties;
    className?: string;
    onClick?: () => any;
    disable?: boolean;
    icon?: React.ReactNode;
}

export const AppBtn: React.FC<IAppBtn> = ({ color, event, title, icon, loading }) => {
    return (
        <div
            className={"flex-center"}
            onClick={() => event && event()}
            style={{ color: color, cursor: "pointer", height: "100%", width: "100%" }}
        >
            <div>{icon}</div>
            <div style={{ marginLeft: 8, fontWeight: "bold" }}>{title}</div>
        </div>
    );
};

export const Btn: React.FC<IBtn> = ({
    value,
    disable,
    loading,
    onClick,
    className,
    style,
    icon,
    backGroundColor = "#359eff",
    color = "white",
}) => {
    const stylePrivate: React.CSSProperties = {
        backgroundColor: backGroundColor,
        color: color,
    };
    return (
        <StyleBtn
            style={{ ...style, ...stylePrivate, cursor: disable || loading ? "no-drop" : "pointer" }}
            className={className}
            onClick={() => {
                if (!loading && !disable) {
                    onClick && onClick();
                }
            }}
        >
            {loading && <i className="fal fa-spinner fa-pulse" />}
            {icon}
            <div className={"value"}>{value}</div>
        </StyleBtn>
    );
};

const StyleBtn = styled.div`
    padding: 5px 25px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
        margin-right: 8px;
    }
`;
