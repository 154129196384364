import { Card, Col, DatePicker, PageHeader, Row, Spin, Table } from "antd";
import Container from "commons/components/container";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import AppServices from "service/AppServices";
import { formatNumber, momentToStringDate, scrollTo } from "utils";
import { walletService } from "../service";

const WithdrawHistory = () => {
    const location: any = useLocation();
    const id = location?.state?.id;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>();
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });

    const disabledDate = (current: any) => {
        return current && current > moment().endOf("day");
    };

    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        Table.SELECTION_COLUMN,
        {
            title: <b>STT</b>,
            width: 70,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: "create_at",
            render: (value: string) => momentToStringDate(value),
        },
        {
            title: <b>Biến động</b>,
            dataIndex: "type",
            render: (_: any, record: any) => {
                if (record?.type === 1) return `+ ${formatNumber(record?.value)}`;
                return `- ${formatNumber(record?.value)}`;
            },
        },
        {
            title: <b>Loại giao dịch</b>,
            dataIndex: "mutuable_type",
            render: (_: any, record: any) => {
                switch (record?.mutable_type) {
                    case 5:
                        return "Thanh toán hoa hồng";
                    case 2:
                    case 3:
                        return "Cộng tiền hoa hồng đơn hàng";
                    default:
                        return "---";
                }
            },
        },
        {
            title: <b>Ghi chú</b>,
            dataIndex: "note",
        },
    ];

    const onGetDetail = async () => {
        try {
            setLoading(true);
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                start_date: dateRange.fromDate?.split("-").reverse().join("-"),
                end_date: dateRange.toDate?.split("-").reverse().join("-"),
            };
            const res: any = await walletService.getDetail(id, payload);
            if (res?.status) {
                setData(res?.data);
                setPaging({ ...paging, total: res?.paging?.totalItemCount, current: res?.paging?.page });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        onGetDetail();
    }, [id, dateRange, paging.current]);

    return (
        <Spin spinning={loading}>
            <Container
                header={<PageHeader title="Lịch sử thu nhập" onBack={() => AppServices.history?.goBack()} />}
                contentComponent={[
                    <Card title="Thông tin sale">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Row justify={"space-between"}>
                                    <p>
                                        Tên sale: <b>{data?.user?.name}</b>
                                    </p>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row justify={"space-between"}>
                                    <p>
                                        Số điện thoại: <b>{data?.user?.phone}</b>
                                    </p>
                                </Row>
                            </Col>

                            <Col span={8}>
                                <Row justify={"space-between"}>
                                    <p>
                                        Ví thu nhập:{" "}
                                        <b>
                                            {formatNumber(+data?.user?.money_withdrawn + +data?.user?.commision) || 0}
                                        </b>
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Row justify={"space-between"}>
                                    <p>
                                        Hoa hồng đã rút: <b>{formatNumber(data?.user?.money_withdrawn) || 0}</b>
                                    </p>
                                </Row>
                            </Col>

                            <Col span={8}>
                                <Row justify={"space-between"}>
                                    <p>
                                        Hoa hồng còn lại: <b>{formatNumber(data?.user?.commision) || 0}</b>
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                    </Card>,
                    <Card
                        title="Lịch sử giao dịch"
                        extra={
                            <DatePicker.RangePicker
                                placeholder={["Từ ngày", "Đến ngày"]}
                                allowClear
                                style={{ width: "100%" }}
                                onChange={(_: any, dateStrings: string[]) => {
                                    setDateRange({
                                        fromDate: dateStrings[0]?.split("-").reverse().join("-"),
                                        toDate: dateStrings[1]?.split("-").reverse().join("-"),
                                    });
                                }}
                                disabledDate={disabledDate}
                            />
                        }
                    >
                        <Table
                            id="top-table"
                            style={{ width: "100%" }}
                            bordered
                            columns={columns}
                            dataSource={data?.wallet_history}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: "Chưa có bản ghi nào!",
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: (page) => {
                                    setPaging({ ...paging, current: page });
                                    setTimeout(() => {
                                        scrollTo("start");
                                    }, 500);
                                },
                            }}
                        />
                    </Card>,
                ]}
            />
        </Spin>
    );
};

export default WithdrawHistory;
