import { PageHeader, Row, Spin, Switch, Table, Tag } from "antd";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import { Btn } from "commons/components/btn/Button";
import Container from "commons/components/container";
import IconAntd from "commons/components/icon/IconAntd";
import CustomPopConfirm from "commons/components/pop-confirm";
import React from "react";
import AppServices from "service/AppServices";
import { Notification, momentToStringDate, scrollTo } from "utils";
import useDebounce from "utils/useDebounce";
import Filter from "../component/Filter";
import { newsService } from "../service";

const NewsPage = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);
    const [search, setSearch] = React.useState<string>("");
    const [status, setStatus] = React.useState<number>();
    const [newsType, setNewsType] = React.useState<number>();
    const [saveStatus, setsSaveStatus] = React.useState<number>();
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });
    const searchDebounce = useDebounce(search, 300);
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Tiêu đề</b>,
            dataIndex: "title",
        },
        {
            title: <b>Loại tin tức</b>,
            dataIndex: "type",
            render: (value: number) => {
                if (value === 1) return <Tag color={"#108d33"}>Banner</Tag>;
                if (value === 3) return <Tag color={"#c68ec6"}>Tin tức</Tag>;
                return <Tag color={"#e89c02"}>Chính sách</Tag>;
            },
        },
        {
            title: <b>Trạng thái đăng bài</b>,
            dataIndex: "publish_status",
            render: (value: number) => {
                if (value === 1) return <Tag color={"#108d33"}>Đăng bài</Tag>;
                return <Tag color={"#e89c02"}>Lưu nháp</Tag>;
            },
        },
        {
            title: <b>Trạng thái</b>,
            dataIndex: "status",
            render: (value: number, record: any) => (
                <Switch checked={value === 1} onChange={() => onChangeNewsStatus(record?.id, value === 1 ? 0 : 1)} />
            ),
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: "create_at",
            render: (value: any) => (value ? momentToStringDate(value) : "---"),
        },

        {
            title: <b>Thao tác</b>,
            width: 100,
            dataIndex: "",
            render: (_: any, record: any) => {
                return (
                    <>
                        <a onClick={() => AppServices.history!.push(pathMenu.add_edit_news, { id: record?.id })}>
                            <IconAntd style={{ margin: "0 10px" }} icon="EditOutlined" size={"18px"} />
                        </a>
                        <CustomPopConfirm
                            title="Bạn có chắc chắn muốn xoá bài viết này?"
                            onConfirm={() => onDeleteNews(record?.id)}
                        />
                    </>
                );
            },
        },
    ];

    const onGetNews = async () => {
        try {
            setLoading(true);
            const payload = {
                search: searchDebounce,
                type: newsType,
                status,
                publish_status: saveStatus,
                from_date: dateRange.fromDate,
                to_date: dateRange.toDate,
                page: paging.current,
                limit: paging.pageSize,
            };
            const res: any = await newsService.getNews(payload);
            if (res?.status) {
                setData(res?.data);
                setPaging({
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onDeleteNews = async (id: number) => {
        try {
            setLoading(true);
            const res = await newsService.deleteNews(id);
            if (res?.status) {
                Notification("success", "Xoá bài viết thành công!");
                onGetNews();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onChangeNewsStatus = async (id: number, status: number) => {
        try {
            const payload = {
                status,
            };
            const res = await newsService.updateNewsStatus(id, payload);
            if (res?.status) {
                onGetNews();
                Notification("success", "Cập nhật trạng thái tin tức thành công!");
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetNews();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [searchDebounce, newsType, status, saveStatus, dateRange]);

    React.useEffect(() => {
        onGetNews();
    }, [paging.current]);

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        title="Quản lý tin tức"
                        extra={[
                            <Btn
                                value={"Thêm mới"}
                                onClick={() => AppServices.history!.push(pathMenu.add_edit_news)}
                                backGroundColor={"#109703"}
                            />,
                        ]}
                    />
                }
                filterComponent={
                    <Filter
                        setNewsType={setNewsType}
                        setsSaveStatus={setsSaveStatus}
                        search={search}
                        setSearch={setSearch}
                        setStatus={setStatus}
                        setDateRange={setDateRange}
                    />
                }
                contentComponent={[
                    <>
                        <Row>
                            <p>
                                Kết quả lọc: <b>{paging.total}</b>
                            </p>
                        </Row>
                        <Table
                            id="top-table"
                            style={{ width: "100%" }}
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: "Chưa có bản ghi nào!",
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: (page) => {
                                    setPaging({ ...paging, current: page });
                                    setTimeout(() => {
                                        scrollTo("start");
                                    }, 500);
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    );
};

export default NewsPage;
