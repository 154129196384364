import { InputNumber } from "antd";
import { changeWeight, changeWholesalePrice } from "app/slices/add-update-product/AddAndUpdateProduct";
import { useDispatch } from "react-redux";

interface IWholesalePriceInput {
    value: any;
}

const WholesalePriceInput = (props: IWholesalePriceInput) => {
    const { value } = props;
    const dispatch = useDispatch();

    return (
        <InputNumber
            placeholder={"Giá sỉ"}
            value={!value?.value ? "" : value?.value}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(text) => {
                dispatch(
                    changeWholesalePrice({
                        key: value.id,
                        price: text,
                    })
                );
            }}
            style={{ width: 150 }}
            onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
        />
    );
};

export default WholesalePriceInput;
