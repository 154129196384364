import React, { useState } from "react";
import { Col, Collapse, Form, Input, InputNumber, Row, Select } from "antd";
import { CollapseExpandIcon, SelectCateComponent } from "../../../../../commons/components/AppComponent";
import { IDataInfoPublic } from "../../../Interfaces";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    changeDataInfoPublicProAction,
    IInitState,
} from "../../../../../app/slices/add-update-product/AddAndUpdateProduct";
import EditorComponent from "./EditorComponent";
import { ValidationMes, ValidationPattern } from "../../../../../commons/validation/ValidationPattern";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";

const labelCol = { span: 8 };
const wrapperCol = { span: 16, offset: 1 };

interface IInfoPublicComponent {
    exportData: (data: IDataInfoPublic) => any;
    defaultData?: IDataInfoPublic | null;
}

const InfoPublicComponent: React.FC<IInfoPublicComponent> = ({ defaultData, exportData }) => {
    const dispatch = useAppDispatch();
    let {
        code,
        proName,
        cate,
        description,
        // validation,
        cateChild,
    } = useAppSelector((state) => state.addAndUpdateProduct);
    const [idCate, setIdCate] = useState<any>(cate.id);

    const editorRef: any = React.useRef<SunEditorCore>();
    let status = useAppSelector((state) => state.addAndUpdateProduct.status);
    let statusStock = useAppSelector((state) => state.addAndUpdateProduct.statusStock);
    let state: IInitState = useAppSelector((state) => state.addAndUpdateProduct);
    const isAdd: boolean = window.location.pathname === "/home/products/add-product";

    const onChangeCate = (cate: any) => {
        dispatch(
            changeDataInfoPublicProAction({ cate: { id: cate.id, name: cate.name }, cateChild: { id: 0, name: "" } })
        );
        setIdCate(cate.id);
    };

    const onChangeCateChild = (cate: any) => {
        dispatch(changeDataInfoPublicProAction({ cateChild: { id: cate.id, name: cate.name } }));
    };

    const onChangeCode = (code: string) => {
        dispatch(changeDataInfoPublicProAction({ code: code }));
    };

    const onChangeName = (name: string) => {
        dispatch(changeDataInfoPublicProAction({ proName: name }));
    };

    const onChangeDescription = (description: string) => {
        dispatch(changeDataInfoPublicProAction({ description: description }));
    };

    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editorRef.current = sunEditor;
    };

    return (
        <div className={"add-product__info-public style-box wrapper-collapse"}>
            <Collapse
                bordered={false}
                expandIconPosition={"right"}
                defaultActiveKey={["1"]}
                expandIcon={(panelProps) => (
                    <div>
                        <CollapseExpandIcon isActive={panelProps.isActive as boolean} />
                    </div>
                )}
            >
                <Collapse.Panel key={"1"} header={"Thông tin chung"}>
                    <div className={"add-product__info-public__wrapper-form style-box"}>
                        <Form>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item
                                        name={"code"}
                                        label={"Mã sản phẩm:"}
                                        labelCol={labelCol}
                                        wrapperCol={wrapperCol}
                                        // validateStatus={validation.code ? "success" : "error"}
                                        // help={validation.code ? undefined : ValidationMes.code}
                                        rules={[
                                            { required: true, message: "Không được bỏ trống mã sản phẩm." },
                                            { pattern: ValidationPattern.codePro, message: ValidationMes.code },
                                        ]}
                                    >
                                        {isAdd ? (
                                            <Input
                                                placeholder="Nhập mã sản phẩm"
                                                onChange={(event) => onChangeCode(event.target.value)}
                                                value={code}
                                            />
                                        ) : (
                                            <div>{code}</div>
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item
                                        name={"cate"}
                                        label={"Danh mục:"}
                                        labelCol={labelCol}
                                        wrapperCol={wrapperCol}
                                        // validateStatus={validation.cate ? "success" : "error"}
                                        // help={validation.cate ? undefined : ValidationMes.required}
                                        rules={[{ required: true, message: "không được bỏ trống danh mục sản phẩm." }]}
                                    >
                                        <SelectCateComponent
                                            parentId={null}
                                            logData={(cate) => onChangeCate(cate)}
                                            defaultValue={cate.name}
                                            placeholder={"Danh mục sản phẩm"}
                                            isDisplayAll={false}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item
                                        name={"namePro"}
                                        label={"Tên sản phẩm :"}
                                        labelCol={labelCol}
                                        wrapperCol={wrapperCol}
                                        // validateStatus={validation.namePro ? "success" : "error"}
                                        // help={validation.namePro ? undefined : ValidationMes.productName}
                                        rules={[
                                            {
                                                pattern: ValidationPattern.namePro,
                                                message: "Tên sản phẩm không quá 150 kí tự.",
                                            },
                                            { required: true, message: "Tên sản phẩm không được bỏ trống." },
                                        ]}
                                    >
                                        <Input
                                            value={proName}
                                            onChange={(event) => onChangeName(event.target.value)}
                                            placeholder="Nhập tên sản phẩm"
                                        />
                                        <div style={{ display: "none" }}>{proName}</div>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12}>
                                    {idCate ? (
                                        <div className={"wrapper-cate-child"}>
                                            <Form.Item
                                                wrapperCol={wrapperCol}
                                                labelCol={labelCol}
                                                label={"Danh mục con"}
                                            >
                                                <SelectCateComponent
                                                    parentId={idCate}
                                                    isAllowClear
                                                    logData={(cate) => onChangeCateChild(cate)}
                                                    countCate={(countCate) => {
                                                        if (countCate === 0) {
                                                            // onChangeCateChild({ id: null, name: "" });
                                                        }
                                                    }}
                                                    defaultValue={cateChild.name}
                                                    placeholder={"Danh mục sản phẩm con"}
                                                />
                                            </Form.Item>
                                        </div>
                                    ) : null}
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} style={{ marginBottom: 24 }}>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={8}>
                                            <div className={"font-bold color-gray"} style={{ paddingRight: 10 }}>
                                                Trạng thái sản phẩm:
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={16}>
                                            <div style={{ paddingLeft: "6%" }}>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    onChange={(value) =>
                                                        dispatch(
                                                            changeDataInfoPublicProAction({ statusProduct: value })
                                                        )
                                                    }
                                                    value={state.statusProduct.toString()}
                                                >
                                                    <Select.Option value={"0"}>Mặc định</Select.Option>
                                                    <Select.Option value={"1"}>Bán chạy</Select.Option>
                                                    <Select.Option value={"3"}>Hot</Select.Option>
                                                </Select>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                {!isAdd && (
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <Row>
                                            <div className={"font-bold color-gray"} style={{ paddingRight: 10 }}>
                                                Trạng thái hàng:{" "}
                                            </div>
                                            <p>{statusStock.toString() === "1" ? "Còn hàng" : "Hết hàng"}</p>
                                        </Row>
                                    </Col>
                                )}

                                {!isAdd && (
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={8}>
                                                <div className={"font-bold color-gray"} style={{ paddingRight: 10 }}>
                                                    Trạng thái hoạt đông:
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={16}>
                                                <div style={{ paddingLeft: "6%" }}>
                                                    <Select
                                                        onChange={(value) =>
                                                            dispatch(changeDataInfoPublicProAction({ status: value }))
                                                        }
                                                        value={status.toString()}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <Select.Option value={"0"}>Ngừng hoạt động</Select.Option>
                                                        <Select.Option value={"1"}>Đang hoạt động</Select.Option>
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>

                            <Row className={"wrapper-description mt-16"}>
                                <Form style={{ width: "100%" }}>
                                    <Form.Item
                                        name={"description"}
                                        label="Mô tả sản phẩm:"
                                        noStyle
                                        rules={[{ required: true, message: "Mô tả sản phẩm không được bỏ trống." }]}
                                    >
                                        {/* <EditorComponent
                                            logData={(data) => onChangeDescription(data)}
                                            defaultValue={description ? description : ""}
                                        /> */}
                                        <SunEditor
                                            setOptions={{
                                                buttonList: [
                                                    ["undo", "redo"],
                                                    [
                                                        "bold",
                                                        "underline",
                                                        "italic",
                                                        "strike",
                                                        "subscript",
                                                        "superscript",
                                                    ],
                                                    ["removeFormat"],
                                                    "/",
                                                    ["font", "fontSize", "formatBlock"],
                                                    ["paragraphStyle", "blockquote"],
                                                    ["fontColor", "hiliteColor", "textStyle"],
                                                    ["align", "list", "lineHeight"],
                                                    ["table", "link", "image", "video"],
                                                    ["fullScreen", "showBlocks", "codeView"],
                                                    ["preview", "print"],
                                                    ["save"],
                                                ],
                                            }}
                                            width="100%"
                                            height={"400px"}
                                            placeholder="Nhập nội dung tin tức..."
                                            onChange={(value: any) => onChangeDescription(value.trim())}
                                            setContents={description}
                                            getSunEditorInstance={getSunEditorInstance}
                                        />
                                    </Form.Item>
                                </Form>
                            </Row>
                        </Form>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default InfoPublicComponent;
