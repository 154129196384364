import { InputNumber } from "antd";
import { setFlashSaleProducts } from "app/slices/flash-sale/FlashSaleSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

interface IFlashSaleQuantityInput {
    record: any;
    productId: any;
}

const FlashSaleQuantityInput = (props: IFlashSaleQuantityInput) => {
    const { record, productId } = props;
    const dispatch = useDispatch();
    const [value, setValue] = React.useState<number>(0);
    const flashSaleProducts = useSelector((state: any) => state?.flashSale?.data);

    const onChange = (value: any) => {
        setValue(value);
        const newList = flashSaleProducts?.map((item: any) => {
            if (item?.id === record?.id) {
                return {
                    ...item,
                    quantity_sale: value,
                };
            }
            return item;
        });
        dispatch(setFlashSaleProducts(newList));
    };

    React.useEffect(() => {
        if (productId) {
            setValue(record?.quantity);
        }
    }, [record]);

    return (
        <InputNumber
            onChange={onChange}
            style={{ width: "100%" }}
            value={value}
            placeholder="Nhập số lượng khuyến mãi"
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
    );
};

export default FlashSaleQuantityInput;
