import React, { Component } from "react";
import { firebaseConnect } from "../../../FirebaseConnect";
import moment from "moment";
import { IRoom } from "../Chat";
import { Avatar, Divider, Image, Modal } from "antd";

interface IMsg {
    id: any;
    avatar: string;
    createdAt: string;
    text: string;
    image: string;
    user: {
        _id: number;
        avatar: string;
        name: string;
    };
}

interface IProps {
    room: IRoom;
    onGetNewListMsg?: (isLoadMore: boolean, isOutOfPage: boolean, keyMsgScroll?: any) => any;
    page: number;
}

interface IState {
    listChat: IMsg[];
}

class ListChatComponent extends Component<IProps, IState> {
    private limit: number = 20;

    constructor(props: IProps) {
        super(props);
        this.state = {
            listChat: [],
        };
        this.getListMsg = this.getListMsg.bind(this);
        this.deleteMsg = this.deleteMsg.bind(this);
    }

    public getListMsg = (isLoadMore?: boolean) => {
        let lengthPrevListChat: number = this.state.listChat.length;

        let refChatTable = firebaseConnect.database().ref(`chatTable/${this.props.room.id}`);

        refChatTable.limitToLast(this.limit * this.props.page).on("value", (childSnapshot, prevChildKey) => {
            if (parseInt(childSnapshot.key as string) === this.props.room.id) {
                let newListMsg: IMsg[] = [];

                childSnapshot.forEach((value) => {
                    newListMsg.push({
                        id: value.key,
                        text: value.val().text,
                        createdAt: value.val().createdAt,
                        avatar: value.val().user.avatar,
                        user: value.val().user,
                        image: value.val().image,
                    });
                });

                const isOutOfPage: boolean = newListMsg.length === this.state.listChat.length;

                this.setState({
                    listChat: newListMsg,
                });

                if (isLoadMore) {
                    setTimeout(() => {
                        this.props.onGetNewListMsg &&
                            this.props.onGetNewListMsg(
                                true,
                                isOutOfPage,
                                this.state.listChat.length > this.limit &&
                                    this.state.listChat[newListMsg.length - lengthPrevListChat].id
                            );
                    });
                } else {
                    setTimeout(() => {
                        this.props.onGetNewListMsg && this.props.onGetNewListMsg(false, false);
                    });
                }

                isLoadMore = false;
            }
        });
    };

    public onChangeLastMsg(roomId: number, msg: string) {
        const ref = firebaseConnect.database().ref(`roomTable/${roomId}/lastMsg`);
        ref.set({
            msg: msg,
            isAdmin: 1,
            createdAt: new Date().toISOString(),
        });
    }

    public deleteMsg(id: any) {
        let refChatTable = firebaseConnect.database().ref(`chatTable/${this.props.room.id}/${id}`);
        refChatTable.remove();
        setTimeout(() => {
            this.onChangeLastMsg(this.props.room.id, this.state.listChat[this.state.listChat.length - 1].text);
        });
    }

    componentDidMount() {
        this.getListMsg();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.room !== this.props.room) {
            this.getListMsg();
        }
        if (prevProps.page !== this.props.page && this.props.page !== 1) {
            this.getListMsg(true);
        }
    }

    render() {
        return (
            <div>
                {this.state.listChat.map((value: any, index: number) => {
                    let time: any =
                        index !== 0
                            ? moment
                                  .duration(
                                      moment(this.state.listChat[index].createdAt).diff(
                                          moment(this.state.listChat[index - 1].createdAt)
                                      )
                                  )
                                  .asMinutes()
                            : 0;
                    if ((time as number) > 15 || index === 0) {
                        return (
                            <div key={value.id} id={value.id}>
                                <Divider orientation={"center"} style={{ fontSize: 12, color: "gray" }}>
                                    {moment(value.createdAt).format("HH:mm DD/MM/YYYY")}
                                </Divider>
                                <div
                                    className={`${
                                        value.user._id !== 0
                                            ? "chat__windows__win-msg--wrapper-msg-left"
                                            : "chat__windows__win-msg--wrapper-msg-right"
                                    }`}
                                    key={value.id}
                                >
                                    <div
                                        className={value.image ? "msg" : "msg msg-before"}
                                        style={value.image ? { backgroundColor: "white", padding: "20px 0 0px 0" } : {}}
                                    >
                                        <div className={"text"}>
                                            {value.image ? (
                                                <Image
                                                    src={value.image}
                                                    style={
                                                        value.user._id === 0
                                                            ? { width: 400, height: 400, borderRadius: 6 }
                                                            : { width: 400, height: 400, borderRadius: 6 }
                                                    }
                                                />
                                            ) : (
                                                value.text
                                            )}
                                        </div>
                                    </div>
                                    <div className={"wrapper-avatar flex-start"} style={{ alignItems: "flex-end" }}>
                                        {value.user._id === 0 ? (
                                            <Avatar
                                                shape={"circle"}
                                                style={
                                                    value.role === 0 ? { marginRight: 4 } : { marginLeft: 4, order: 2 }
                                                }
                                                size={40}
                                                src={
                                                    "http://dev.tpmart.winds.vn/static/media/Logo%20TP_mart.01bff5d6.png"
                                                }
                                            />
                                        ) : this.props.room.avatar ? (
                                            <Avatar
                                                shape={"circle"}
                                                style={
                                                    value.role === 0 ? { marginRight: 4 } : { marginLeft: 4, order: 2 }
                                                }
                                                size={40}
                                                src={this.props.room.avatar}
                                            />
                                        ) : (
                                            <Avatar
                                                shape={"circle"}
                                                style={{
                                                    backgroundColor: "rgba(114, 105, 239, 0.25)",
                                                    color: "rgb(114, 105, 239)",
                                                }}
                                                size={40}
                                            >
                                                {this.props.room.name[0].toUpperCase()}
                                            </Avatar>
                                        )}
                                    </div>

                                    {value.user._id === 0 && (
                                        <div
                                            className={!value.image ? "time" : "time time-img"}
                                            onClick={() =>
                                                Modal.confirm({
                                                    title: "Bạn có muốn xóa tin nhắn này",
                                                    onOk: () => this.deleteMsg(value.id),
                                                    cancelText: "Hủy",
                                                    okText: "Xóa",
                                                })
                                            }
                                        >
                                            <div>
                                                <i
                                                    className="fal fa-trash"
                                                    style={{ marginRight: 1.5, cursor: "pointer", color: "red" }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className={!value.image ? "time" : "time time-img"}>
                                        <div>
                                            <i
                                                className="fal fa-clock"
                                                style={{ marginRight: 1.5, cursor: "pointer" }}
                                            />
                                            {moment(value.createdAt).format("HH:mm")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else
                        return (
                            <div
                                className={`${
                                    value.user._id !== 0
                                        ? "chat__windows__win-msg--wrapper-msg-left"
                                        : "chat__windows__win-msg--wrapper-msg-right"
                                }`}
                                key={value.id}
                                id={value.id}
                            >
                                <div
                                    className={value.image ? "msg" : "msg msg-before"}
                                    style={value.image ? { backgroundColor: "white", padding: "20px 0 0px 0" } : {}}
                                >
                                    <div className={"text"}>
                                        {value.image ? (
                                            <Image
                                                src={value.image}
                                                style={
                                                    value.user._id === 0
                                                        ? {
                                                              width: 400,
                                                              height: 400,
                                                              borderRadius: 8,
                                                              borderBottomRightRadius: 0,
                                                          }
                                                        : {
                                                              width: 400,
                                                              height: 400,
                                                              borderRadius: 6,
                                                              borderBottomLeftRadius: 0,
                                                          }
                                                }
                                            />
                                        ) : (
                                            value.text
                                        )}
                                    </div>
                                </div>
                                <div className={"wrapper-avatar flex-start"} style={{ alignItems: "flex-end" }}>
                                    {value.user._id === 0 ? (
                                        <Avatar
                                            shape={"circle"}
                                            style={value.role === 0 ? { marginRight: 4 } : { marginLeft: 4, order: 2 }}
                                            size={40}
                                            src={"http://dev.tpmart.winds.vn/static/media/Logo%20TP_mart.01bff5d6.png"}
                                        />
                                    ) : this.props.room.avatar ? (
                                        <Avatar
                                            shape={"circle"}
                                            style={value.role === 0 ? { marginRight: 4 } : { marginLeft: 4, order: 2 }}
                                            size={40}
                                            src={this.props.room.avatar}
                                        />
                                    ) : (
                                        <Avatar
                                            shape={"circle"}
                                            style={{
                                                backgroundColor: "rgba(114, 105, 239, 0.25)",
                                                color: "rgb(114, 105, 239)",
                                            }}
                                            size={40}
                                        >
                                            {this.props.room.name[0].toUpperCase()}
                                        </Avatar>
                                    )}
                                </div>

                                {value.user._id === 0 && (
                                    <div
                                        className={!value.image ? "time" : "time time-img"}
                                        onClick={() =>
                                            Modal.confirm({
                                                title: "Bạn có muốn xóa tin nhắn này",
                                                onOk: () => this.deleteMsg(value.id),
                                                cancelText: "Hủy",
                                                okText: "Xóa",
                                            })
                                        }
                                    >
                                        <div>
                                            <i
                                                className="fal fa-trash"
                                                style={{ marginRight: 1.5, cursor: "pointer", color: "red" }}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className={!value.image ? "time" : "time time-img"}>
                                    <div>
                                        <i className="fal fa-clock" style={{ marginRight: 1.5, cursor: "pointer" }} />
                                        {moment(value.createdAt).format("HH:mm")}
                                    </div>
                                </div>
                            </div>
                        );
                })}
            </div>
        );
    }
}

export default ListChatComponent;
