import React from "react";
import { Line } from "react-chartjs-2";
import { EmptyComponent } from "../AppComponent";

export interface ILineConfig {
    label: string;
    fill: boolean;
    lineTension: number;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    data: number[];
}

export interface IChartLine {
    labels: string[];
    datasets: ILineConfig[];
}

interface ILineChart {
    data: IChartLine | undefined;
    height?: number;
    width?: number;
}

const LineChart: React.FC<ILineChart> = ({ data, width, height }) => {
    return <div>{data ? <Line data={data} type={"line"} height={height} width={width} /> : <EmptyComponent />}</div>;
};
export default LineChart;
