import { createSlice } from "@reduxjs/toolkit";

const flashSaleSlice = createSlice({
    name: "flashSaleSlice",
    initialState: {
        data: [],
        decreaseType: null,
    },
    reducers: {
        setFlashSaleProducts: (state: any, action: any) => {
            state.data = action.payload;
        },
        setDiscountType: (state: any, action: any) => {
            state.decreaseType = action.payload;
        },
        resetPriceSale: (state: any) => {
            const newList = state.data?.map((item: any) => ({ ...item, price_sale: 0, sale_price: 0 }));
            state.data = newList;
        },
        changeDecreasePercent: (state: any, action: any) => {
            const newList = state.data?.map((item: any) => ({
                ...item,
                price_sale: item?.price - item?.price * 0.01 * action?.payload,
            }));
            state.data = newList;
        },
    },
});

const { reducer: flashSaleReducer, actions } = flashSaleSlice;
export const { setFlashSaleProducts, resetPriceSale, changeDecreasePercent, setDiscountType } = actions;
export default flashSaleReducer;
