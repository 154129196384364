import { createSlice } from "@reduxjs/toolkit";

const createOrderSlice = createSlice({
    name: "createOrderSlice",
    initialState: {
        data: [],
    },
    reducers: {
        setProducts: (state: any, action: any) => {
            state.data = action.payload;
        },
        addNewProduct: (state: any, action: any) => {
            state.data = [...state.data, action.payload];
        },
    },
});

const { reducer: createOrderReducer, actions } = createOrderSlice;
export const { setProducts, addNewProduct } = actions;
export default createOrderReducer;
