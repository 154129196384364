import React, { useEffect, useState } from "react";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { Button, Input, Modal, Pagination, Select } from "antd";
import { numberWithCommas } from "../../service/Format";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  deleteAccAction,
  getListAccAction,
  changeStatusAccAction,
  resetPassAccAction,
} from "../../app/slices/account/Account";
import { FormAddAcc, FormUpdateAcc } from "./component/Form";
import TableComponent from "./component/TableComponent";
import AppServices from "../../service/AppServices";
import { useLocation } from "react-router-dom";
import { IDataTableAccount } from "./interfaces";
import { Config } from "../../commons/validation/ValidationPattern";
import { usePrev } from "../../service/CustomHook";

const Account: React.FC<any> = () => {

  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [accountUpdate, setAccountUpdate] = useState<IDataTableAccount | null>(null);
  const [role, setRole] = useState<number>(0);

  const dispatch = useAppDispatch();
  const total = useAppSelector((state) => state.account.total);

  const searchParams = new URLSearchParams(useLocation().search);
  const page: number = parseInt(searchParams.get("page") as string);
  const phone: string = searchParams.get("phone") as string;
  const status: number | null = typeof searchParams.get("status") === "string" ? parseInt(searchParams.get("status") as string) : null;

  const [currentPage, setCurrentPage] = useState<number>(page);

  const pushParams = (_page?: number, _phone?: string, _status?: 0 | 1 | 2) => {
    AppServices.history!.push(
      `/home/account?page=${_page ? _page : page}&phone=${typeof _phone === "string" ? _phone : phone}&status=${
        _status === undefined ? status : _status
      }`,
    );
  };

  const onChangePagination = (value: any) => {
    pushParams(value);
    setCurrentPage(value);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onChangeStatusAccount = (id: any, status: number) => {
    dispatch(changeStatusAccAction({ id, status }));
  };
  const onResetPass = (id: any) => {
    dispatch(resetPassAccAction({ id }));
  };
  const onDelete = (id: any) => {
    dispatch(deleteAccAction({ id }));
  };
  const onEditAccount = (value: IDataTableAccount) => {
    setOpenModalEdit(true);
    setAccountUpdate(value);
  };

  useEffect(() => {
    const getData = async () => {
      dispatch(getListAccAction({
        page: page,
        status: status === 2 ? null : status,
        search: phone as string,
        role: role,
      }));
    };
    getData();
  }, [page, status, phone, role]);

  let prePage = usePrev(page);
  useEffect(() => {
    if (page !== prePage) {
      setCurrentPage(page);
    }
  });

  // MODAL
  const ModalAdd: React.FC<any> = () => {
    return (
      <Modal title={"Thêm tài khoản"} visible={openModalAdd} onCancel={() => setOpenModalAdd(false)} footer={null}>
        <FormAddAcc eventCancel={() => {
          setOpenModalAdd(false);
          dispatch(getListAccAction({ page: page, status: status, search: phone as string, role: role }));
        }} />
      </Modal>
    );
  };
  const ModalEdit: React.FC<{ account: IDataTableAccount | null }> = ({ account }) => {
    return (
      <div>
        <Modal title={"Sửa tài khoản"} visible={openModalEdit} onCancel={() => setOpenModalEdit(false)} footer={false}>
          <FormUpdateAcc eventCancel={() => setOpenModalEdit(false)} account={account} />
        </Modal>
      </div>
    );
  };
  // END MODAL

  // RENDER FILTER
  const renderFilter = (
    total: number,
    defaultPhone: string,
    defaultStatus: number | null,
    onChangeSelect: (value: any) => any,
    onchangePhone: (value: any) => any,
  ) => {
    return (
      <div className={"flex-between"} style={{ backgroundColor: "white", borderRadius: 4, marginTop: 16, padding: 16 }}>
        <div>
          <Input
            placeholder={"Tên hoặc số điện thoại"}
            style={{ width: 200 }}
            onChange={(event) => onchangePhone(event.target.value)}
            defaultValue={defaultPhone === "ALL" ? "" : defaultPhone}
          />

          <Select
            defaultValue={defaultStatus as number}
            style={{ width: 200, margin: "0px 24px" }}
            onChange={(value) => onChangeSelect(value)}
          >
            <Select.Option value={2}>Tất cả</Select.Option>
            <Select.Option value={0}>Ngưng hoạt động</Select.Option>
            <Select.Option value={1}>Hoạt động</Select.Option>
          </Select>

          <Select
            defaultValue={"0"}
            style={{ width: 200 }}
            onChange={(value) => {
              setCurrentPage(1);
              pushParams(1);
              setRole(parseInt(value));
            }}
          >
            <Select.Option value={"0"}>Tất cả</Select.Option>
            <Select.Option value={"4"}>Nhân viên</Select.Option>
            <Select.Option value={"2"}>Quản trị viên</Select.Option>
          </Select>
        </div>
        <div className={"color-gray font-bold"}>
          Kết quả lọc:
          <span
            className={"font-bold"}
            style={{
              fontSize: 15,
              marginLeft: 5,
            }}
          >
            {numberWithCommas(total)}
          </span>
        </div>
      </div>
    );
  };
  // END FILTER

  return (
    <div>
      <TitleComponent
        title={"Tài khoản"}
        component={
          <Button type={"primary"} onClick={() => setOpenModalAdd(true)}>
            Thêm mới
          </Button>
        }
      />

      {
        renderFilter(
          total,
          phone,
          status,
          (status: any) => pushParams(1, "", status),
          (phone: any) => pushParams(1, phone, 2),
        )
      }

      <div style={{ padding: 16, backgroundColor: "white", marginTop: 16, borderRadius: 4 }}>
        <TableComponent
          eventEdit={(value) => onEditAccount(value)}
          eventChangeStatus={(id: any, currentStatus: number) => {
            onChangeStatusAccount(id, currentStatus);
          }}
          eventDelete={(id: any) => onDelete(id)}
          eventResetPass={(id: any) => onResetPass(id)}
        />
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 24 }}>
          <Pagination
            current={currentPage}
            pageSize={Config.limit}
            showSizeChanger={false}
            total={total}
            onChange={onChangePagination}
          />
        </div>
      </div>

      <ModalEdit account={accountUpdate} />
      <ModalAdd />
    </div>
  );
};

export default Account;
