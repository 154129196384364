import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Upload } from "antd";
import { openNotification } from "../../../../../commons/components/notifycation/Notification";

export interface IFile {
    uid: any;
    name: string;
    status: "done" | "error";
    url?: string;
}

interface IUpload {
    type: "picture" | "text" | "picture-card" | undefined;
    logData: (data: any[]) => any;
    limit: number;
    name: string;
    path: string;
    defaultData?: IFile[];
    accept?: ".jpg, .png" | ".mp4";
    size: number;
    isMultiple?: boolean;
}

const UploadListFileComponent: React.FC<IUpload> = ({
    limit,
    accept,
    logData,
    defaultData,
    type,
    name,
    path,
    size,
    isMultiple = false,
}) => {
    const [fileList, setFileList] = useState<IFile[]>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>("");

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleChange = (value: any) => {
        value.fileList.forEach((file: any, index: number) => {
            if (!file.status) {
                // value.fileList[index].status = "error";
                value.fileList.splice(index, 1);
            }
            if (file.response && file.response.status === 0) {
                openNotification("ERROR", file.response.message);
            }
        });

        setFileList(value.fileList);
        logData(value.fileList);
    };

    const uploadButton = (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 100,
                    width: 100,
                    border: "1px dashed gray",
                    cursor: "pointer",
                    color: "gray",
                }}
            >
                <i className="fal fa-plus-circle" />
                <div className="ant-upload-text">{type === undefined ? "Tải video" : "Tải ảnh"}</div>
            </div>
        </div>
    );

    const beforeUploadFile = (file: any): any => {
        let fileSize: number = size;

        if (type === undefined) {
            const isMP4 = file.type === "video/mp4";
            const isLt30M = file.size / 1024 / 1024 > fileSize;
            let isDurationValid: boolean = true;

            let promise = new Promise((resolve) => {
                let vid = document.createElement("video");
                let fileURL = URL.createObjectURL(file);
                vid.src = fileURL;
                let duration: number = 0;
                vid.ondurationchange = function () {
                    duration = vid.duration;
                    isDurationValid = duration > 5 && duration < 600;
                    resolve(isMP4 && isDurationValid);
                    // resolve(!isLt30M && isMP4 && isDurationValid);
                };
            });

            promise.then((value) => {
                if (!isMP4) {
                    openNotification("ERROR", "Video không đúng định dạng.");
                } else if (!isDurationValid) {
                    openNotification("ERROR", "Thời gian của video tối thiểu là 5 giây và tối đa là 10 phút!");
                }

                // else if (isLt30M) {
                //     openNotification("ERROR", "Dung lượng video tối đa là 120 MB");
                // }
            });

            return promise;
        } else {
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                openNotification("ERROR", "ảnh không đúng định dạng.");
            }

            const isLt3M = file.size / 1024 / 1024 > fileSize;
            if (isLt3M) {
                openNotification("ERROR", "Dung lượng ảnh tối đa là 3 MB.");
            }

            return !isLt3M && isJpgOrPng;
        }
    };

    const handleOnPreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url ? file.url : file.preview);
        setPreviewVisible(true);
    };

    useEffect(() => {
        if (defaultData && defaultData.length > 0 && fileList.length === 0) {
            setFileList(defaultData ? defaultData : []);
        }
        if (defaultData && defaultData.length === 0) {
            setFileList(defaultData ? defaultData : []);
        }
    }, [defaultData]);

    return (
        <Container>
            <Upload
                accept={accept ? accept : ".png, .jpg"}
                action={path}
                multiple={isMultiple}
                name={name}
                listType={type}
                fileList={fileList}
                onPreview={(file) => handleOnPreview(file)}
                beforeUpload={(file) => {
                    return beforeUploadFile(file);
                }}
                onChange={(value) => {
                    handleChange(value);
                }}
            >
                {fileList.length >= limit ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </Container>
    );
};

const Container = styled.div`
    .ant-upload {
        border: none;
    }
`;

export default UploadListFileComponent;
