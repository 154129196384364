import React from "react";
import { numberWithCommas } from "../../../service/Format";
import styled from "styled-components";

interface IStatistical {
    color: string | "red" | "orange" | "blue" | "green" | "yellowgreen";
    title: string;
    value: number;
    background: React.ReactNode;
    currency?: string;
}

const Statistical: React.FC<IStatistical> = ({ color, title, background, value, currency }) => {
    return (
        <Style style={{ borderTop: `4px solid ${color}` }}>
            <div className={"statistical-title"} style={{ color: `${color}` }}>
                {" "}
                {title}
            </div>
            <div className={"statistical-value"}>{numberWithCommas(value, currency)}</div>
            {/* <div className={"statistical-background"}>{background}</div> */}
        </Style>
    );
};
const Style = styled.div`
    padding: 10px 24px;
    background-color: white;
    border-radius: 8px;
    height: 130px;
    position: relative;

    .statistical-title,
    .statistical-value {
        font-weight: bold;
    }

    .statistical-title {
        font-size: 16px;
    }

    .statistical-value {
        margin-top: 10px;
        position: absolute;
        top: 42%;
        left: 24px;
    }

    .statistical-background {
        i {
            font-size: 50px;
            position: absolute;
            bottom: 16px;
            right: 16px;
            color: grey;
            width: 12px,
            height: 12px;
        }
    }
`;

export default Statistical;
