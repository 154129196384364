import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import BillItem from "./BillItem";

interface IBillModal {
    orders: any[];
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const BillModal = (props: IBillModal) => {
    const { visible, orders, setVisible } = props;
    const [finalOrders, setFinalOrders] = React.useState<any[]>([]);

    const onCancel = () => setVisible(false);

    React.useEffect(() => {
        const filteredList = orders.filter((item: any) => item?.status === 1);
        setFinalOrders(filteredList);
    }, []);

    return (
        <CustomModal
            width={378}
            title="In hoá đơn"
            visible={visible}
            onCancel={onCancel}
            cancelText="Huỷ"
            okText="Xác nhận"
            centered
        >
            <div className="content_block">
                {finalOrders?.length === 0 && (
                    <p style={{ color: "red" }}>
                        * Vui lòng chọn những đơn hàng ở trạng thái <b> ĐÃ XÁC NHẬN</b>.
                    </p>
                )}
                {finalOrders?.map((item: any, index: number) => (
                    <BillItem key={index} item={item} />
                ))}
            </div>
        </CustomModal>
    );
};

const CustomModal = styled(Modal)`
    overflow: auto;
    height: 770px;

    .content_block {
        max-height: 600px;
        overflow: auto;
    }
`;

export default BillModal;
