import React, { useState } from "react";
import TitleComponent from "../../../commons/components/title-component/TitleComponent";
import { Button, Col, Input, Row, Select } from "antd";
import "./AddNotification.scss";
import UploadListFileComponent from "../../product/page/add-update/component/UploadListFileComponent";
import EditorComponent from "../../product/page/add-update/component/EditorComponent";
import TextArea from "antd/es/input/TextArea";
import { SelectCateComponent, SelectProductComponent } from "../../../commons/components/AppComponent";
import { openNotification } from "../../../commons/components/notifycation/Notification";
import { createNotificationAPI } from "../../../api/notification/Notification";
import {
  IReqNotificationDiscount,
  IReqNotificationLivestream,
  IReqNotificationProduct,
} from "../../../api/notification/interface";
import AppServices from "../../../service/AppServices";

const AddNotification: React.FC<any> = () => {

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [typeNotification, setTypeNotification] = useState<string>("3");

  const [imgDiscount, setImgDiscount] = useState<string>("");
  const [contentDiscount, setContentDiscount] = useState<string>("");
  const [loadingPost, setLoadingPost] = useState<boolean>(false);

  const [cateParentId, setCateParentId] = useState<number>(-1);
  const [cateChildId, setCateChildId] = useState<number>(-1);
  const [defaultCateChild, setDefaultCateChild] = useState<string>("");
  const [productId, setProductId] = useState<number>(-1);
  const [defaultProduct, setDefaultProduct] = useState<string>("");

  const onChangeTypeNotification = () => {
    setImgDiscount("");
    setContentDiscount("");
    setCateParentId(-1);
    setCateChildId(-1);
    setDefaultCateChild("");
    setProductId(-1);
    setDefaultProduct("");
  };

  const validateLivestream = (): boolean => {
    if (!title) {
      openNotification("ERROR", "Vui lòng nhập tên tiêu đề.");
      return false;
    }
    if (title.length > 250) {
      openNotification("ERROR", "Tiêu đề không quá 250 ký tự.");
      return false;
    }
    if (!description) {
      openNotification("ERROR", "Vui lòng nhập mô tả.");
      return false;
    }
    if (description.length > 250) {
      openNotification("ERROR", "Mô tả không quá 250 ký tự.");
      return false;
    }
    return true;
  };

  const validaProduct = (): boolean => {
    if (!validateLivestream()) return false;
    if (productId === -1) {
      openNotification("ERROR", "Vui lòng chọn sản phẩm.");
      return false;
    }
    return true;
  };

  const validateDiscount = (): boolean => {
    if (!validateLivestream()) return false;

    if (!imgDiscount) {
      openNotification("ERROR", "Vui lòng thêm ảnh.");
      return false;
    } else if (!contentDiscount || contentDiscount.trim() === "<p></p>") {openNotification("ERROR", "Vui lòng nhập nội dung thông báo.");
      return false;
    }

    return true;
  };

  const navigateListNotification = () => {
    AppServices.history!.push(`/home/notification`);
  };

  const postNotificationLivestream = async () => {
    setLoadingPost(true);
    if (validateLivestream()) {
      let data: IReqNotificationLivestream = {
        type: 3,
        description: description,
        title: title,
      };
      const res = await createNotificationAPI(data);
      if (res.body.status === 1) {
        openNotification("SUCCESS", "Thêm thông báo live stream thành công.");
        navigateListNotification();
      } else {
        openNotification("ERROR", "Đã có lỗi xảy ra vui lòng thử lại.");
      }
    }
    setLoadingPost(false);
  };

  const postNotificationProduct = async () => {

    if (validaProduct()) {

      setLoadingPost(true);
      let data: IReqNotificationProduct = {
        title: title,
        description: description,
        type: 4,
        product_id: productId.toString(),
      };

      const res = await createNotificationAPI(data);

      if (res.body.status === 1) {
        openNotification("SUCCESS", "Thêm bài đăng sản phẩm thành công");
        navigateListNotification();
      } else {
        openNotification("ERROR", "Đã có lỗi xảy ra vui lòng thêm lại.");
      }

      setLoadingPost(false);

    }
  };

  const postNotificationDiscount = async () => {
    if (validateDiscount()) {

      setLoadingPost(true);
      let data: IReqNotificationDiscount = {
        type: 5,
        title: title,
        description: description,
        content: contentDiscount,
        image_url: imgDiscount,
      };

      const res = await createNotificationAPI(data);

      if (res.body.status === 1) {
        openNotification("SUCCESS", "Thêm thông báo khuyến mãi thành công");
        navigateListNotification();
      } else {
        openNotification("ERROR", "Đã có lỗi xảy ra vui lòng thêm thông báo lại.");
      }

      setLoadingPost(false);

    }
  };

  const onPostNotification = () => {
    if (typeNotification === "3") {
      postNotificationLivestream();
    } else if (typeNotification === "4") {
      postNotificationProduct();
    } else if (typeNotification === "5") {
      postNotificationDiscount();
    }
  };

  return (
    <div className={"add-notification-page"}>
      <TitleComponent title={"Thêm thông báo"}
                      component=
                        {
                          <div className={"add-notification-page__wrapper-btn flex-end"}>
                            <Button className={"btn--red mr-16"} style={{ width: 120 }}
                                    onClick={() => navigateListNotification()}
                            >
                              Thoát
                            </Button>
                            <Button className={"btn--green"} style={{ width: 120 }}
                                    loading={loadingPost}
                                    onClick={() => onPostNotification()}
                            >
                              Lưu
                            </Button>
                          </div>
                        }
                      isGoBack={true}
      />

      <div className={"add-notification-page__form style-box mt-16"}>
        <Row gutter={16}>
          <Col lg={12}>

            <div className={"item"}>
              <div className={"label"}><span style={{ color: "red" }}>*</span>Tên tiêu đề</div>
              <div className={"input"}>
                <Input placeholder={"Nhập tên tiêu đề"} value={title}
                       onChange={event => setTitle(event.target.value)} />
              </div>
            </div>

            <div className={"item"}>
              <div className={"label"}><span style={{ color: "red" }}>*</span>Mô tả</div>
              <div className={"input"}>
                <TextArea
                  placeholder={"Mô tả."}
                  value={description}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={event => setDescription(event.target.value)}
                />
              </div>
            </div>

            {
              typeNotification === "4" &&
              <div>
                   <div className={"item"}>
                        <div className={"label"}><span style={{ color: "red" }}>*</span>Chọn sản phẩm</div>
                        <div className={"input"}>
                             <SelectCateComponent
                                  parentId={null}
                                  logData={cate => {
                                    setCateParentId(cate.id);
                                    setDefaultCateChild("");
                                    setCateChildId(-1);
                                    setDefaultProduct("");
                                    setProductId(-1);
                                  }}
                             />
                        </div>
                   </div>

                   <div className={"item"}>
                        <div className={"label"} />
                        <div className={"input"}>
                             <SelectCateComponent parentId={cateParentId}
                                                  defaultValue={defaultCateChild}
                                                  placeholder={"Danh mục con."}
                                                  logData={cate => {
                                                    setCateChildId(cate.id);
                                                    setDefaultCateChild(cate.name);
                                                    setProductId(-1);
                                                    setDefaultProduct("");
                                                  }}
                             />
                        </div>
                   </div>

                {
                  cateParentId !== -1 &&
                  <div className={"item"}>
                       <div className={"label"} />
                       <div className={"input"}>
                            <SelectProductComponent
                                 cateParentId={cateParentId}
                                 cateChildId={cateChildId}
                                 logger={product => {
                                   setProductId(product.id);
                                   setDefaultProduct(product.name);
                                 }}
                                 defaultValue={defaultProduct}
                            />
                       </div>
                  </div>
                }

              </div>
            }

            {
              typeNotification === "5" &&
              <div className={"item"}>
                   <div className={"label"}><span style={{ color: "red" }}>*</span>Ảnh</div>
                   <div className={"input"}>
                        <UploadListFileComponent type={"picture-card"} limit={1} name={"image"}
                                                 logData={data => {
                                                   if (data[0] && data[0].status === "done") {
                                                     setImgDiscount(data[0].response.data.filename);
                                                   } else if (data.length === 0) {
                                                     setImgDiscount("");
                                                   }
                                                 }}
                                                 path={`${process.env.REACT_APP_BASE_URL}/files/uploadFile/1`}
                                                 size={3}
                        />
                   </div>
              </div>
            }

          </Col>
          <Col lg={12}>
            <div className={"item"}>
              <div className={"label"}><span style={{ color: "red" }}>*</span>Loại thông báo</div>
              <div className={"input"}>
                <Select defaultValue={typeNotification} style={{ width: "100%" }}
                        onChange={value => {
                          setTypeNotification(value);
                          onChangeTypeNotification();
                        }}
                >
                  <Select.Option value={"3"}>Live stream</Select.Option>
                  <Select.Option value={"4"}>Bài đăng sản phẩm</Select.Option>
                  <Select.Option value={"5"}>Khuyến mãi</Select.Option>
                </Select>
              </div>
            </div>
          </Col>
        </Row>

        {
          typeNotification === "5" &&
          <div>
               <div style={{ color: "gray", fontWeight: "bold", marginBottom: 8 }}><span
                    style={{ color: "red" }}>*</span>Nội dung thông báo
               </div>
               <div>
                    <EditorComponent logData={data => setContentDiscount(data)} />
               </div>
          </div>
        }

      </div>

    </div>
  );
};

export default AddNotification;