import React, { useEffect, useState } from "react";
import TitleComponent from "../../commons/components/title-component/TitleComponent";
import { DatePicker, Input, Pagination, Select, Table } from "antd";
import { Config, disabledDate } from "../../commons/validation/ValidationPattern";
import "./ReportSale.scss";
import { IColumn } from "../../service/Interfaces";
import { formatString, numberWithCommas } from "../../service/Format";
import { EmptyComponent } from "../../commons/components/AppComponent";
import {
    getListReportCustomerAction,
    getListReportProductAction,
    IDataSourceReportCustomer,
    IDataSourceReportProduct,
    IInitState,
} from "../../app/slices/report/ReportSale";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import AppServices from "../../service/AppServices";
import moment from "moment";
import ExportCsv from "../../commons/components/export-excel/ExportCSV";

interface IDataExportProduct {
    STT: any;
    "Tên sản phẩm": string;
    "Nhóm sản phẩm": string;
    "Số lượng bán": number;
    "Số lượng đơn hàng": number;
    "Tổng tiền thực tế": number;
}

interface IDataExportCustomer {
    STT: any;
    "Tên khách hàng": string;
    "Số điện thoại": string;
    "Số lượng bán": number;
    "Số lượng đơn hàng": number;
    "Tổng tiền thực tế": number;
}

const getDataExportProduct = (data: IDataSourceReportProduct[]): IDataExportProduct[] => {
    let rs: IDataExportProduct[] = [];
    data.forEach((value) =>
        rs.push({
            STT: value.col1 !== 0 ? value.col1 : "",
            "Tên sản phẩm": value.col2,
            "Nhóm sản phẩm": value.col3,
            "Số lượng bán": value.col4,
            "Số lượng đơn hàng": value.col5,
            "Tổng tiền thực tế": value.col6,
        })
    );
    return rs;
};

const getDataExportCustomer = (data: IDataSourceReportCustomer[]): IDataExportCustomer[] => {
    let rs: IDataExportCustomer[] = [];
    data.forEach((value) =>
        rs.push({
            STT: value.col1 !== 0 ? value.col1 : "",
            "Tên khách hàng": value.col2,
            "Số điện thoại": value.col3,
            "Số lượng bán": value.col4,
            "Số lượng đơn hàng": value.col5,
            "Tổng tiền thực tế": value.col6,
        })
    );
    return rs;
};

const ReportSale: React.FC<any> = () => {
    const state: IInitState = useAppSelector((state) => state.reportSale);
    const dispatch = useAppDispatch();
    const params = new URLSearchParams(window.location.search);
    const page: number = parseInt(params.get("page") as string);

    const [currentPage, setCurrentPage] = useState<number>(page);
    const [search, setSearch] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [select, setSelect] = useState<string>("1");

    const pushParams = (page: number = 1) => {
        AppServices.history!.push(`/home/report-sale?page=${page}`);
        setCurrentPage(page);
    };

    const onSearch = (event: any) => {
        setSearch(event.target.value);
        pushParams();
    };

    const onChangeDate = (date: any, formatString: [string, string]) => {
        setStartDate(formatString[0]);
        setEndDate(formatString[1]);
        pushParams();
    };

    const onExportProduct = async (fn: Function) => {
        let rs = await dispatch(
            getListReportProductAction({
                page: 1,
                search: search,
                startDate: startDate,
                endDate: endDate,
                isExport: true,
            })
        );
        if (rs.type === "reportSale/getListReportProductAction/fulfilled") {
            fn();
        }
    };

    const onExportCustomer = async (fn: Function) => {
        let rs = await dispatch(
            getListReportCustomerAction({
                page: 1,
                search: search,
                startDate: startDate,
                endDate: endDate,
                isExport: true,
            })
        );
        if (rs.type === "reportSale/getListReportCustomerAction/fulfilled") {
            fn();
        }
    };

    useEffect(() => {
        if (select === "1") {
            dispatch(
                getListReportProductAction({
                    page: currentPage,
                    search: search,
                    startDate: startDate,
                    endDate: endDate,
                })
            );
        } else {
            dispatch(
                getListReportCustomerAction({
                    page: currentPage,
                    search: search,
                    startDate: startDate,
                    endDate: endDate,
                })
            );
        }
    }, [currentPage, search, startDate, endDate, select]);

    const columns: IColumn[] = [
        {
            title: "STT",
            key: "col1",
            dataIndex: "col1",
            render: (value: number) => (value !== 0 ? value : ""),
        },
        {
            title: select === "1" ? "Tên sản phẩm" : "Tên khách hàng",
            key: "col2",
            dataIndex: "col2",
            render: (value: string) => formatString(value, true),
        },
        {
            title: select === "1" ? "Nhóm sản phẩm" : "Số điện thoại",
            key: "col3",
            dataIndex: "col3",
            render: (value: string) => formatString(value),
        },
        {
            title: "Số lượng đã bán",
            key: "col4",
            dataIndex: "col4",
            render: (value: number) => numberWithCommas(value),
        },
        {
            title: "Số lượng đơn hàng",
            key: "col5",
            dataIndex: "col5",
            render: (value: number) => numberWithCommas(value),
        },
        {
            title: "Tổng tiền thực tế",
            key: "col6",
            dataIndex: "col6",
            render: (value: number) => numberWithCommas(value, "đ"),
        },
    ];
    return (
        <div className={"report-sale"}>
            <div className={"report-sale__header"}>
                <TitleComponent
                    title={"Báo cáo bán hàng"}
                    component={
                        <div>
                            {select === "2" ? (
                                <ExportCsv
                                    fileName={`Bao-cao-ban-hang/khach-hang_${moment().format("DD-MM-YYYY")}`}
                                    sheets={{
                                        "Bao-cao-ban-hang": ExportCsv.getSheets(
                                            getDataExportCustomer(state.dataSourceReportCustomerExport)
                                        ),
                                    }}
                                    sheetName={["Bao-cao-ban-hang"]}
                                    loading={state.loadingReportCustomerExport}
                                    onClick={(fn) => onExportCustomer(fn)}
                                >
                                    Export
                                </ExportCsv>
                            ) : (
                                <ExportCsv
                                    fileName={`Bao-cao-ban-hang/nhom-san-pham_${moment().format("DD-MM-YYYY")}`}
                                    sheets={{
                                        "Bao-cao-ban-hang": ExportCsv.getSheets(
                                            getDataExportProduct(state.dataSourceReportProductExport)
                                        ),
                                    }}
                                    sheetName={["Bao-cao-ban-hang"]}
                                    loading={state.loadingReportProductExport}
                                    onClick={(fn) => onExportProduct(fn)}
                                >
                                    Export
                                </ExportCsv>
                            )}
                        </div>
                    }
                />
            </div>
            <div className={"report-sale__wrapper-table style-box mt-16"}>
                <div className={"report-sale__wrapper-table__filter"}>
                    <div className={"list-filter"}>
                        <Select
                            className={"item"}
                            defaultValue={"1"}
                            onChange={(value) => {
                                pushParams(1);
                                setSelect(value);
                                setCurrentPage(1);
                                setSearch("");
                                setStartDate("");
                                setEndDate("");
                            }}
                        >
                            <Select.Option value={"1"}>Nhóm sản phẩm</Select.Option>
                            <Select.Option value={"2"}>Khách hàng</Select.Option>
                        </Select>
                        <Input
                            placeholder={select === "1" ? "Tên hoặc mã sản phẩm" : "Tên hoặc số điện thoại khách hàng"}
                            value={search}
                            className={"item"}
                            onChange={onSearch}
                        />
                        {/* <DatePicker.RangePicker
              className={"item"}
              placeholder={Config.placeholderDatePicker}
              format={[Config.formatDate, Config.formatDate]}
              disabledDate={disabledDate}
              inputReadOnly={true}
              onCalendarChange={onChangeDate}
              value={[startDate ? moment(startDate, Config.formatDate) : null, endDate ? moment(endDate, Config.formatDate) : null]}
            /> */}
                    </div>
                    <div className={"count-filter"}>
                        Kết quả lọc {select === "1" ? state.totalReportProduct : state.totalReportCustomer}.
                    </div>
                </div>
                <div className={"report-sale__wrapper-table__table mt-16"}>
                    <Table
                        columns={columns}
                        dataSource={select === "1" ? state.dataSourceReportProduct : state.dataSourceReportCustomer}
                        pagination={false}
                        bordered
                        loading={select === "1" ? state.loadingReportProduct : state.loadingReportCustomer}
                        locale={{ emptyText: <EmptyComponent /> }}
                    />
                </div>

                <div className={"report-sale__pagination mt-16 flex-end"}>
                    <Pagination
                        current={currentPage}
                        total={select === "1" ? state.totalReportProduct : state.totalReportCustomer}
                        showSizeChanger={false}
                        pageSize={Config.limit}
                        onChange={(page1) => pushParams(page1)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReportSale;
