export class Moment {
  static getDate(
    _date: number | string,
    _format: string | "dd/mm/yyyy" | "dd-mm-yyyy" | "D/M/yyyy",
    _use_utc_0: boolean = true,
  ): string {
    const date = new Date(_date);
    const D = date.getDate();
    const M = date.getMonth() + 1;
    const dd = D < 10 ? "0" + D : D;
    const mm = M < 10 ? "0" + M : M;
    const yyyy = date.getFullYear();

    let result: string = _format;
    result = result.replace("D", D.toString());
    result = result.replace("M", M.toString());
    result = result.replace("dd", dd.toString());
    result = result.replace("mm", mm.toString());
    result = result.replace("yyyy", yyyy.toString());

    return result;
  }

  static getDateIncrementOrDecrement(
    _type: "INCREMENT" | "DECREMENT",
    _date: string | number,
    _numDay: number,
  ): number {
    const date = new Date(_date);
    if (_type === "INCREMENT") {
      date.setDate(date.getDate() + _numDay);
    } else {
      date.setDate(date.getDate() - _numDay);
    }
    return date.getTime();
  }

  static msToHMS(ms: number): string {
    let seconds: number = ms / 1000;
    let hours: number = parseInt((seconds / 3600).toString());
    seconds = seconds % 3600;
    let minutes = parseInt((seconds / 60).toString());
    seconds = Math.round(seconds % 60);
    console.log(hours + ":" + minutes + ":" + seconds);
    return hours + ":" + minutes + ":" + seconds;
  }
}
