import { IItemProductCustomAttributes, IProductPrices } from "../../../api/product/Interface";
import { IColumn } from "../../../service/Interfaces";
import { ITypePro } from "../../../modules/product/Interfaces";

export const convertDataSourceToPost = (data: any[]): { tier_index: number[]; price: number; status: number }[] => {
    let rs: any = [];
    data.forEach((value) => {
        rs.push({
            id: value?.product_price_id,
            tier_index: value.index,
            price: value.col3.value,
            status: value.col4.value,
            stock: value.stock.value,
            weight: value.weight.value,
            wholesale_price: value.wholesale_price.value,
            sold_count: value.sold_count.value,
            wholesale_quantity: value.quantity_wholesale_price.value,
            allow_order_when_out_stock: value.allow_order_when_out_stock.value,
        });
    });
    return rs;
};

export const getColumnPrice = (data: IItemProductCustomAttributes[]): IColumn[] => {
    let rs: IColumn[] = [];
    if (data[0]) {
        rs.push({
            title: data[0].name,
            key: "col1",
            dataIndex: "col1",
            render: (value) => value,
        });
    }
    if (data[1]) {
        rs.push({
            title: data[1].name,
            key: "col2",
            dataIndex: "col2",
            render: (value) => value,
        });
    }
    return rs;
};

export const getDataSourcePrice = (data: any[]) => {
    console.log("🚀 ~ getDataSourcePrice ~ data:", data);
    let rs: any = [];
    data.forEach((value) => {
        // Có 2 phân loại
        if (value.customAttributeOptionId1Name && value.customAttributeOptionId2Name) {
            const id = `${value.customAttributeOptionId1}${value.customAttributeOptionId2}`;
            rs.push({
                product_price_id: value.id,
                key: id,
                col1: value.customAttributeOptionId1Name,
                col2: value.customAttributeOptionId2Name,
                col3: { id, value: value.price },
                col4: { id, value: value.status },
                stock: { id, value: value.stock },
                weight: { id, value: value.weight },
                sold_count: { id, value: value.soldCount },
                wholesale_price: { id, value: value.wholesalePrice },
                quantity_wholesale_price: { id, value: value.wholesaleQuantity },
                allow_order_when_out_stock: { id, value: value.allowOrderWhenOutStock },
            });
        } else {
            rs.push({
                product_price_id: value.id,
                key: value.id,
                col1: value.customAttributeOptionId1Name,
                col2: value.customAttributeOptionId2Name,
                col3: { id: value.id, value: value.price },
                col4: { id: value.id, value: value.status },
                stock: { id: value.id, value: value.stock },
                weight: { id: value.id, value: value.weight },
                sold_count: { id: value.id, value: value.soldCount },
                wholesale_price: { id: value.id, value: value.wholesalePrice },
                quantity_wholesale_price: { id: value.id, value: value.wholesaleQuantity },
                allow_order_when_out_stock: { id: value.id, value: value.allowOrderWhenOutStock },
            });
        }
    });
    return rs;
};

export const getProductCustomAttribute = (
    data: ITypePro[],
    columns: IColumn[]
): { name: string; option: string[] }[] => {
    let rs: { name: string; option: string[] }[] = [];
    let option1: string[] = [];
    let option2: string[] = [];
    let name1 = "";
    let name2 = "";

    data.forEach((value, index) => {
        if (index === 0) {
            value.typeItem.forEach((value1) => option1.push(value1.value as string));
            name1 = columns[0].title as string;
        } else if (index === 1) {
            value.typeItem.forEach((value1) => option2.push(value1.value as string));
            name2 = columns[1].title as string;
        }
    });

    if (data.length === 1) {
        rs = [{ name: name1, option: option1 }];
    } else {
        rs = [
            { name: name1, option: option1 },
            { name: name2, option: option2 },
        ];
    }
    return rs;
};
