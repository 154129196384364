import React, { useEffect, useRef, useState } from "react";
import TitleComponent from "../../../../commons/components/title-component/TitleComponent";
import { Checkbox, Col, Image, Modal, Pagination, Row, Spin, Table, Tag } from "antd";
import "./ProductDetail.scss";
import { IColumn } from "../../../../service/Interfaces";
import AppServices from "../../../../service/AppServices";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    deleteProductAction,
    getListCustomerAction,
    getListOrderAction,
    getProductDetailInfoAction,
    putStatusProductAction,
} from "../../../../app/slices/product-detail/ProductDetail";
import { Link, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { pathMenu } from "../../../../commons/components/Menu/MenuComponent";
import { Config } from "../../../../commons/validation/ValidationPattern";
import { EmptyComponent } from "commons/components/AppComponent";
import LoadingComponent from "../../../../commons/components/loading/LoadingComponent";
import { formatNumber } from "utils";

export const defaultColumnsDetailPrice: IColumn[] = [
    {
        title: "Giá",
        key: "col4",
        dataIndex: "col4",
        render: (value) => formatNumber(value),
    },
    {
        title: "Ngừng bán",
        key: "col5",
        dataIndex: "col5",
        render: (value) => (
            <div>
                <Checkbox checked={value && value.isCheck === 1 ? false : true} />
            </div>
        ),
    },
];

const columnSalePro: IColumn[] = [
    {
        title: "STT",
        key: "STT",
        dataIndex: "STT",
        render: (value) => value,
    },
    {
        title: "Tên khách hàng",
        key: "customerName",
        dataIndex: "customerName",
        render: (value) => value,
    },
    {
        title: "Số điện thoại",
        key: "phoneNumber",
        dataIndex: "phoneNumber",
        render: (value) => value,
    },
    {
        title: "Số lượng",
        key: "quantity",
        dataIndex: "quantity",
        render: (value) => value,
    },
    {
        title: "Doanh thu",
        key: "revenue",
        dataIndex: "revenue",
        render: (value) => formatNumber(value),
    },
];

const columnsOrder: IColumn[] = [
    {
        title: "Mã đơn",
        key: "code",
        dataIndex: "code",
        render: (value) => value,
    },
    {
        title: "Tên khách hàng",
        key: "name",
        dataIndex: "name",
        render: (value) => value,
    },
    {
        title: "Số lượng",
        key: "quantity",
        dataIndex: "quantity",
        render: (value) => value,
    },
    {
        title: "Tổng cộng",
        key: "total",
        dataIndex: "total",
        render: (value) => formatNumber(value),
    },
];

const TabOne: React.FC<any> = () => {
    const {
        code,
        name,
        cateName,
        description,
        statusStock,
        status,
        price,
        dataSourcePrices,
        productMedia,
        columnsDetailPrice,
        type,
    } = useAppSelector((state) => state.productDetail);

    const refDescription = useRef<HTMLDivElement>(null);
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isShowMore, setIsShowMore] = useState<boolean>(true);

    useEffect(() => {
        if (refDescription.current) {
            if (refDescription.current.offsetHeight > 30) {
                setIsShow(true);
            }
        }
    }, [description]);
    return (
        <div>
            <div className={"product-detail__info__tab-info-pro"}>
                <div className={"info-public style-box"}>
                    <div className={"font-bold mb-16 title-info"}>Thông tin chung</div>
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"}>
                            Mã sản phẩm
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"}>
                            {code}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"}>
                            Danh mục
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"}>
                            {cateName}
                        </Col>
                    </Row>
                    <Row className={"mt-16 mb-16"} gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"}>
                            Tên sản phẩm
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"}>
                            {name}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"}>
                            Trạng thái
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"}>
                            {status && status === 1 ? (
                                <Tag color={"green"}>Đang hoạt động</Tag>
                            ) : (
                                <Tag color={"gray"}>Ngưng hoạt động</Tag>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={8} style={{ marginTop: 30 }}>
                        <Col xs={24} sm={24} md={6} lg={6} className={"title"}>
                            Mô tả
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={18} className={"value load-more"}>
                            <div
                                className={"description"}
                                style={!isShowMore ? { height: "100%", marginTop: -20 } : {}}
                            >
                                <div ref={refDescription}>
                                    <div dangerouslySetInnerHTML={{ __html: description as string }} />
                                </div>
                            </div>
                            <small
                                onClick={() => setIsShowMore(!isShowMore)}
                                style={{
                                    color: "blue",
                                    display: isShow ? "block" : "none",
                                    cursor: "pointer",
                                    marginBottom: 50,
                                }}
                            >
                                {isShowMore ? "Xem thêm" : "Ẩn bớt"}
                            </small>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"}>
                            Trạng thái hàng
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"}>
                            {statusStock && statusStock === 1 ? (
                                <Tag color={"blue"}>Còn hàng</Tag>
                            ) : (
                                <Tag color={"gray"}>Hết hàng</Tag>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={8} style={{ marginTop: 8 }}>
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"} />
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"} />
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"}>
                            Trạng thái sản phẩm
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"}>
                            {type === 0 && <Tag color={"blue"}>Mặc định</Tag>}
                            {type === 1 && <Tag color={"green"}>Bán chạy</Tag>}
                            {type === 3 && <Tag color={"red"}>Hot</Tag>}
                        </Col>
                    </Row>
                    <Row
                        className={"mt-16"}
                        gutter={8}
                        style={{
                            display:
                                useAppSelector((state) => state.productDetail.dataSourcePrices.length) === 0
                                    ? "flex"
                                    : "none",
                        }}
                    >
                        <Col xs={24} sm={24} md={24} lg={6} className={"title"} />
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"} />
                        {useAppSelector((state) => state.productDetail.price) !== null && (
                            <Col xs={24} sm={24} md={24} lg={6} className={"title"}>
                                Giá bán
                            </Col>
                        )}
                        <Col xs={24} sm={24} md={24} lg={6} className={"value"}>
                            {price}
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={"product-detail__img style-box mt-16"}>
                <div className={"title"}>Thông tin hình ảnh</div>
                <div className={"wrapper-img"}>
                    <div className={"label mr-16"}>Hình ảnh sản phẩm</div>
                    <div>
                        {productMedia.map((value) => {
                            if (value.type === 1 && value.productCustomAttributeOptionId === null)
                                return (
                                    <div key={value.id} style={{ marginRight: 8, display: "inline-block" }}>
                                        <Image
                                            key={value.url}
                                            height={100}
                                            width={100}
                                            style={{ borderRadius: 6 }}
                                            src={value.url}
                                        />
                                    </div>
                                );
                            else return null;
                        })}
                    </div>
                </div>
                <div className={"wrapper-video"}>
                    <div className={"label"}>Video sản phẩm</div>
                    <div>
                        {productMedia.map((value) => {
                            if (value.type === 2 && value.url)
                                return (
                                    <video key={value.id} width="320" height="240" controls>
                                        <source src={value.url} type="video/mp4" />
                                    </video>
                                );
                            else return null;
                        })}
                    </div>
                </div>
                {useAppSelector((state) => state.productDetail.dataSourcePrices).length > 0 && (
                    <div className={"wrapper-type"}>
                        <div className={"label"}>Phân loại</div>
                        {productMedia.map((value) => {
                            if (value.type === 1 && value.productCustomAttributeOptionId !== null)
                                return (
                                    <div key={value.url} style={{ marginRight: 6 }}>
                                        <Image
                                            key={value.url}
                                            height={100}
                                            width={100}
                                            style={{ borderRadius: 6, marginRight: 8 }}
                                            src={value.url}
                                        />
                                        <div
                                            style={{
                                                fontSize: 13,
                                                color: "gray",
                                                textAlign: "center",
                                            }}
                                        >
                                            {value.customAttributeOptionName}
                                        </div>
                                    </div>
                                );
                            else return null;
                        })}
                    </div>
                )}
            </div>
            <div
                className={"product-detail__price style-box mt-16"}
                style={{
                    display:
                        useAppSelector((state) => state.productDetail.dataSourcePrices.length) > 0 ? "block" : "none",
                }}
            >
                <div className={"title"}>Thông tin giá bán</div>
                <div className={"wrapper-table"}>
                    <Table
                        columns={columnsDetailPrice}
                        dataSource={dataSourcePrices}
                        locale={{
                            emptyText: <EmptyComponent />,
                        }}
                        bordered
                    />
                </div>
            </div>
        </div>
    );
};

const TabTwo: React.FC<any> = () => {
    const dispatch = useAppDispatch();
    const searchParams = new URLSearchParams(window.location.search);
    const defaultTab: number = parseInt(searchParams.get("tab") as string);
    const pageCustomer: number = parseInt(searchParams.get("pageCustomer") as string);
    const pageOrder: number = parseInt(searchParams.get("pageOrder") as string);

    const pushParams = (_tab?: number, _pageCustomer?: number, _pageOrder?: number) => {
        let url: string = window.location.pathname;
        url = url + `?tab=${_tab !== undefined ? _tab : defaultTab}`;
        url = url + `&pageCustomer=${_pageCustomer !== undefined ? _pageCustomer : pageCustomer}`;
        url = url + `&pageOrder=${_pageOrder !== undefined ? _pageOrder : pageOrder}`;
        AppServices.history!.push(url);
    };

    const dataSourceCustomer = useAppSelector((state) => state.productDetail.dataSourceCustomer);
    const loadingTableCustomer = useAppSelector((state) => state.productDetail.loadingGetListCustomer);
    const dataSourceOrder = useAppSelector((state) => state.productDetail.dataSourceOrder);
    const loadingSourceOrder = useAppSelector((state) => state.productDetail.loadingGetListOrder);
    const params: any = useParams();

    const [currentPageCustomer, setCurrentPageCustomer] = useState<number>(pageCustomer);
    const [currentPageOrder, setCurrentPageOrder] = useState<number>(pageOrder);
    const { totalPageCustomer, totalPageOrder } = useAppSelector((state) => state.productDetail);

    const onChangePageCustomer = (value: any) => {
        setCurrentPageCustomer(value);
        pushParams(undefined, value, undefined);
    };

    const onChangePageOrder = (value: any) => {
        setCurrentPageOrder(value);
        pushParams(undefined, undefined, value);
    };

    useEffect(() => {
        dispatch(getListCustomerAction({ id: params.id, page: pageCustomer }));
    }, [pageCustomer]);

    useEffect(() => {
        dispatch(getListOrderAction({ id: params.id, page: pageOrder }));
    }, [pageOrder]);

    return (
        <div>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <div className={"style-box"}>
                        <div className={"title mb-16 font-bold"}>Khách hàng mua sản phẩm</div>
                        <Table
                            columns={columnSalePro}
                            dataSource={dataSourceCustomer}
                            locale={{
                                emptyText: <EmptyComponent />,
                            }}
                            loading={loadingTableCustomer}
                            pagination={false}
                        />
                        <div className={"mt-16 flex-end"}>
                            <Pagination
                                current={currentPageCustomer}
                                pageSize={Config.limit}
                                onChange={onChangePageCustomer}
                                total={totalPageCustomer}
                            />
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <div className={"style-box"}>
                        <div className={"title mb-16 font-bold"}>Danh sách đơn hàng</div>
                        <Table
                            columns={columnsOrder}
                            dataSource={dataSourceOrder}
                            loading={loadingSourceOrder}
                            locale={{
                                emptyText: <EmptyComponent />,
                            }}
                            pagination={false}
                        />
                        <div className={"mt-16 flex-end"}>
                            <Pagination
                                current={currentPageOrder}
                                pageSize={Config.limit}
                                total={totalPageOrder}
                                onChange={onChangePageOrder}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const ProductDetail: React.FC<any> = () => {
    const dispatch = useAppDispatch();
    const searchParams = new URLSearchParams(window.location.search);
    const defaultTab: number = parseInt(searchParams.get("tab") as string);
    const pageCustomer: number = parseInt(searchParams.get("pageCustomer") as string);
    const pageOrder: number = parseInt(searchParams.get("pageOrder") as string);

    const pushParams = (_tab?: number, _pageCustomer?: number, _pageOrder?: number) => {
        let url: string = window.location.pathname;
        url = url + `?tab=${_tab !== undefined ? _tab : defaultTab}`;
        url = url + `&pageCustomer=${_pageCustomer !== undefined ? _pageCustomer : pageCustomer}`;
        url = url + `&pageOrder=${_pageOrder !== undefined ? _pageOrder : pageOrder}`;
        AppServices.history!.push(url);
    };
    const [tab, setTab] = useState<number>(defaultTab);
    const params: any = useParams();

    useEffect(() => {
        dispatch(getProductDetailInfoAction({ id: params.id }));
        window.scroll({ top: 0, behavior: "smooth" });
    }, []);

    const onchangeTab = (tab: number) => {
        setTab(tab);
        pushParams(tab, undefined, undefined);
    };

    const onDeleteProduct = async () => {
        const rs = await dispatch(deleteProductAction({ id: params.id }));
        if (rs.type === "productDetail/deleteProductAction/fulfilled") {
            AppServices.history!.push(pathMenu.product);
        }
    };

    const onPutStatusProduct = async () => {
        await dispatch(putStatusProductAction({ id: params.id }));
        dispatch(getProductDetailInfoAction({ id: params.id }));
    };
    // const antIcon = <LoadingOutlined style={{ fontSize: 24, marginRight: 8, color: "white" }} spin />;

    const role: number = parseInt(localStorage.getItem("role") as string);

    return (
        <div className={"product-detail"}>
            <div
                className={"flex-center"}
                style={{
                    top: 100,
                    height: "calc(100vh - 150px)",
                    width: "100vw",
                    position: "fixed",
                    backgroundColor: "white",
                    zIndex: 1000,
                    display: useAppSelector((state) => state.productDetail.loadingGetProductDetailInfo)
                        ? "flex"
                        : "none",
                }}
            >
                <LoadingComponent />
            </div>
            <div className={"product-detail__header"}>
                <TitleComponent
                    title={"Chi tiết sản phẩm"}
                    isGoBack={true}
                    component={
                        <div
                            className={"product-detail__header__list-btn"}
                            style={role !== 4 ? { display: "block" } : { display: "none" }}
                        >
                            <button
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Bạn có muốn xóa sản phẩm này không?.",
                                        onOk: () => onDeleteProduct(),
                                    });
                                }}
                                className={"btn btn-delete"}
                            >
                                {/* {useAppSelector(state => state.productDetail.loadingDeleteProduct) &&
                <LoadingOutlined style={{ fontSize: 24, marginRight: 8 }} spin />} */}
                                Xóa sản phẩm
                            </button>
                            <Link to={`/home/products/update-product/${params.id}`}>
                                <button className={"btn btn-edit"}>Sửa</button>
                            </Link>
                            <button
                                className={"btn btn-status"}
                                onClick={onPutStatusProduct}
                                style={{
                                    backgroundColor:
                                        useAppSelector((state) => state.productDetail.status) === 0 ? "green" : "red",
                                }}
                            >
                                {useAppSelector((state) => state.productDetail.loadingPutStatus) && <Spin />}
                                {useAppSelector((state) => state.productDetail.status) === 0
                                    ? "Hoạt động"
                                    : "Ngừng hoạt động"}
                            </button>
                        </div>
                    }
                />
            </div>
            <div className={"product-detail__info"}>
                <div className={"product-detail__info__tab"}>
                    <div onClick={() => onchangeTab(1)} className={`tab tab-info-pro ${tab === 1 ? "active" : ""}`}>
                        Thông tin sản phẩm
                    </div>
                    <div onClick={() => onchangeTab(2)} className={`tab tab-info-sale ${tab === 2 ? "active" : ""}`}>
                        Thông tin bán hàng
                    </div>
                </div>
                {tab === 1 ? <TabOne /> : <TabTwo />}
            </div>
        </div>
    );
};

export default ProductDetail;
