import { Button, Card, Checkbox, Col, Form, Input, PageHeader, Row, Select, Spin } from "antd";
import Container from "commons/components/container";
import UploadComponent from "commons/components/upload/UploadImage";
import React from "react";
import { useLocation } from "react-router-dom";
import AppServices from "service/AppServices";
import styled from "styled-components";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { newsService } from "../service";
import { Notification } from "utils";
import SunEditorCore from "suneditor/src/lib/core";

const AddEditNews = () => {
    const location: any = useLocation();
    const newsId = location?.state?.id;
    const [form] = Form.useForm();
    const editorRef: any = React.useRef<SunEditorCore>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isSendNoti, setIsSendNoti] = React.useState<boolean>(false);
    const [imageUrl, setImageUrl] = React.useState<string>();
    const [content, setContent] = React.useState<string>();
    const [defaultSaveType, setDefaultSaveType] = React.useState<number>();

    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editorRef.current = sunEditor;
    };

    const onGetNewsDetail = async () => {
        try {
            setLoading(true);
            const res = await newsService.getNewsDetail(newsId);
            if (res?.status) {
                form.setFieldsValue({
                    title: res?.data?.title,
                    save_status: res?.data?.publish_status,
                    type: res?.data?.type,
                });
                setImageUrl(res?.data?.image_url);
                setIsSendNoti(res?.data?.is_push_noti === 1);
                setContent(res?.data?.content);
                setDefaultSaveType(res?.data?.publish_status);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values: any) => {
        try {
            if (!imageUrl) {
                Notification("error", "Vui lòng tải ảnh cho bài viết!");
                return;
            }
            if (!content || editorRef.current.getText().trim()?.length === 0) {
                Notification("error", "Vui lòng nhập nội dung cho bài viết!");
                return;
            }
            setLoading(true);
            const payload = {
                title: values.title,
                content,
                image_url: imageUrl,
                type: values.type,
                publish_status: values.save_status,
                status: 1,
                is_push_noti: isSendNoti ? 1 : 0,
            };
            let res: any;
            if (newsId) {
                res = await newsService.updateNews(newsId, payload);
            } else {
                res = await newsService.createNews(payload);
            }

            if (res?.status) {
                Notification("success", newsId ? "Chỉnh sửa bài viết thành công!" : "Thêm mới bài viết thành công!");
                AppServices.history?.goBack();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (newsId) onGetNewsDetail();
    }, [newsId]);

    return (
        <Spin spinning={loading}>
            <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} onFinish={onFinish} autoComplete="off">
                <Container
                    header={
                        <PageHeader
                            style={{ borderRadius: 8 }}
                            title={newsId ? "Chỉnh sửa bài viết" : "Thêm mới bài viết"}
                            onBack={() => AppServices.history?.goBack()}
                            extra={[
                                <Form.Item noStyle>
                                    <Button htmlType="submit" type="primary" style={{ backgroundColor: "#109703" }}>
                                        Lưu
                                    </Button>
                                </Form.Item>,
                            ]}
                        ></PageHeader>
                    }
                    contentComponent={[
                        <>
                            <Card title={<b>Thông tin tin tức</b>}>
                                <CustomRow gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"title"}
                                            label="Tiêu đề tin tức"
                                            rules={[
                                                { required: true, message: "Vui lòng nhập tiêu đề banner!" },
                                                {
                                                    max: 255,
                                                    type: "string",
                                                    message: "Vui lòng không nhập quá 255 ký tự!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                onChange={(e: any) => {}}
                                                placeholder="Nhập tiêu đề banner"
                                                allowClear
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"save_status"}
                                            label="Trạng thái"
                                            rules={[{ required: true, message: "Vui lòng chọn trạng thái lưu!" }]}
                                        >
                                            <Select
                                                allowClear
                                                showArrow
                                                placeholder="Chọn trạng thái lưu"
                                                showSearch
                                                disabled={defaultSaveType === 1}
                                            >
                                                <Select.Option value={0}>Lưu nháp</Select.Option>
                                                <Select.Option value={1}>Đăng bài</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </CustomRow>
                                <CustomRow gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"type"}
                                            label="Loại banner"
                                            rules={[{ required: true, message: "Vui lòng chọn loại banner" }]}
                                        >
                                            <Select allowClear showArrow placeholder="Chọn loại banner" showSearch>
                                                <Select.Option value={1}>Banner</Select.Option>
                                                <Select.Option value={2}>Chính sách</Select.Option>
                                                <Select.Option value={3}>Tin tức</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"image"}
                                            label={
                                                <span>
                                                    Ảnh banner <span style={{ color: "red" }}>*</span>
                                                </span>
                                            }
                                        >
                                            <UploadComponent
                                                isUploadServerWhenUploading
                                                uploadType="single"
                                                maxLength={1}
                                                listType="picture-card"
                                                isShowFileList
                                                onSuccessUpload={(data: any) => {
                                                    if (data) {
                                                        setImageUrl(data?.url);
                                                    } else setImageUrl(undefined);
                                                }}
                                                initialFile={
                                                    imageUrl && imageUrl?.length > 0
                                                        ? [
                                                              {
                                                                  uid: "-1",
                                                                  name: "avatar.png",
                                                                  status: "done",
                                                                  url: imageUrl,
                                                              },
                                                          ]
                                                        : []
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </CustomRow>
                                {!newsId ||
                                    (newsId && defaultSaveType !== 1 && (
                                        <CustomRow>
                                            <Checkbox
                                                checked={isSendNoti}
                                                onChange={(e: any) => setIsSendNoti(e.target.checked)}
                                            >
                                                Gửi thông báo cho khách hàng
                                            </Checkbox>
                                        </CustomRow>
                                    ))}
                            </Card>
                        </>,
                        <>
                            <Card title={<b>Nội dung tin tức</b>}>
                                <SunEditor
                                    setOptions={{
                                        buttonList: [
                                            ["undo", "redo"],
                                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                            ["removeFormat"],
                                            "/",
                                            ["font", "fontSize", "formatBlock"],
                                            ["paragraphStyle", "blockquote"],
                                            ["fontColor", "hiliteColor", "textStyle"],
                                            ["align", "list", "lineHeight"],
                                            ["table", "link", "image", "video"],
                                            ["fullScreen", "showBlocks", "codeView"],
                                            ["preview", "print"],
                                            ["save"],
                                        ],
                                    }}
                                    height={"700px"}
                                    placeholder="Nhập nội dung tin tức..."
                                    onChange={(value: any) => setContent(value.trim())}
                                    setContents={content}
                                    getSunEditorInstance={getSunEditorInstance}
                                />
                            </Card>
                        </>,
                    ]}
                />
            </Form>
        </Spin>
    );
};

const CustomRow = styled(Row)`
    margin-bottom: 10px;
`;

export default AddEditNews;
