import React from "react";
import { Switch, Route } from "react-router-dom";
import ProductCategory from "../product-category/ProductCategory";
import Product from "../product/Product";
import Customer from "../customer/Customer";
import OrderProduct from "../order-product/OrderProduct";
import LiveStream from "../live-stream/LiveStream";
import Chat from "../chat/Chat";
import ReportSale from "../report-sale/ReportSale";
import ReportLiveStream from "../report-live-stream/ReportLiveStream";
import Account from "../account/Account";
import Dashboard from "../dashboard/Dashboard";
import AddAndUpdateProduct from "../product/page/add-update/AddAndUpdateProduct";
import ProductDetail from "../product/page/product-detail/ProductDetail";
import OrderProductDetail from "../order-product/page/order-product-detail/OrderProductDetail";
import DetailLivestream from "../live-stream/page/DetailLivestream";
import { useAppSelector } from "../../app/hooks";
import Notification from "../notification/Notification";
import AddNotification from "../notification/page/AddNotification";
import VoucherPage from "modules/voucher/page";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import AddEditVoucher from "modules/voucher/page/AddEditVoucher";
import CommissionConfigPage from "modules/config/page/CommissionConfigPage";
import SaleStaffPage from "modules/sale-staff/page";
import AddEditSaleStaff from "modules/sale-staff/page/AddEditSaleStaff";
import FlashSleProductPage from "modules/flash-sale/page";
import AddEditFlashSale from "modules/flash-sale/page/AddEditFlashSale";
import AddOrder from "modules/order-product/page/AddOrder";
import NewsPage from "modules/news/page";
import AddEditNews from "modules/news/page/AddEditNews";
import SaleWalletPage from "modules/wallet/page";
import WithdrawHistory from "modules/wallet/page/WithdrawHistory";
import LivestreamEmulatorPage from "modules/livestream-emulator/page";

const HomeRouter: React.FC<any> = () => {
    let role: number = useAppSelector((state) => state.login.role);

    if (role === 4) {
        return (
            <Switch>
                {/* <Route exact path={"/home/product-product-category"} component={ProductCategory} />
                <Route exact path={"/home/products"} component={Product} />
                <Route exact path={"/home/products/add-product"} component={AddAndUpdateProduct} />
                <Route exact path={"/home/products/update-product/:id"} component={AddAndUpdateProduct} />
                <Route exact path={"/home/products/product-detail/:id"} component={ProductDetail} /> */}
                <Route exact path={pathMenu.add_order} component={AddOrder} />
                <Route exact path={"/home/orders"} component={OrderProduct} />
                <Route exact path={"/home/customers"} component={Customer} />
                <Route exact path={"/home/orders/detail/:id"} component={OrderProductDetail} />
                {/* <Route exact path={"/home/chat"} component={Chat} /> */}
            </Switch>
        );
    } else {
        return (
            <Switch>
                <Route exact path={"/home"} component={Dashboard} />
                <Route exact path={pathMenu.add_order} component={AddOrder} />
                <Route exact path={pathMenu.withdraw_detail} component={WithdrawHistory} />
                <Route exact path={pathMenu.sale_wallet} component={SaleWalletPage} />
                <Route exact path={pathMenu.add_edit_news} component={AddEditNews} />
                <Route exact path={pathMenu.news} component={NewsPage} />
                <Route exact path={pathMenu.add_edit_flash_sale_product} component={AddEditFlashSale} />
                <Route exact path={pathMenu.sale_staff} component={SaleStaffPage} />
                <Route exact path={pathMenu.flash_sale_product} component={FlashSleProductPage} />
                <Route exact path={pathMenu.add_sale_staff} component={AddEditSaleStaff} />
                <Route exact path={pathMenu.voucher} component={VoucherPage} />
                <Route exact path={pathMenu.add_update_voucher} component={AddEditVoucher} />
                <Route exact path={pathMenu.config_commission} component={CommissionConfigPage} />
                <Route exact path={"/home/product-product-category"} component={ProductCategory} />
                <Route exact path={"/home/products"} component={Product} />
                <Route exact path={"/home/products/add-product"} component={AddAndUpdateProduct} />
                <Route exact path={"/home/products/update-product/:id"} component={AddAndUpdateProduct} />
                <Route exact path={"/home/products/product-detail/:id"} component={ProductDetail} />
                <Route exact path={"/home/customers"} component={Customer} />
                <Route exact path={"/home/orders"} component={OrderProduct} />
                <Route exact path={"/home/orders/detail/:id"} component={OrderProductDetail} />
                <Route exact path={"/home/live-stream"} component={LiveStream} />
                <Route exact path={"/home/live-stream/:id"} component={DetailLivestream} />
                <Route exact path={"/home/chat"} component={Chat} />
                <Route exact path={"/home/notification"} component={Notification} />
                <Route exact path={"/home/notification/add"} component={AddNotification} />
                <Route exact path={"/home/report-sale"} component={ReportSale} />
                <Route exact path={"/home/report-stream"} component={ReportLiveStream} />
                <Route exact path={"/home/account"} component={Account} />
                <Route exact path={pathMenu.livestream_emulator} component={LivestreamEmulatorPage} />
            </Switch>
        );
    }
};

export default HomeRouter;
