import React from "react";
import io, { Socket } from "socket.io-client";
import { environment } from "../config";
import { Notification } from "utils";
import { toolSpamLivestreamService } from "../service";
import { useSelector } from "react-redux";
import { Row } from "antd";

interface IToolSpamCommentChild {
    index: number;
    isReaction: boolean;
    setCurrentActiveUsers: React.Dispatch<React.SetStateAction<number>>;
}

const DEFINE_STATUS = {
    DISCONNECT: 0,
    CONNECTED: 1,
};

const DEFINE_STATUS_HAS_ACCOUNT = {
    HAS_ACCOUNT: 1,
    NOT_HAS_ACCOUNT: 0,
};

const ToolSpamCommentChild = (props: IToolSpamCommentChild) => {
    const { index, setCurrentActiveUsers } = props;

    const params = new URLSearchParams(window.location.search);

    const timeIntervalComment: number = Number(params.get("time-interval-comment") as string) || 5000;
    const timeIntervalReaction: number = Number(params.get("time-interval-reaction") as string) || 5000;
    const liveStreamId: number = Number(params.get("livestream-id") as string) || 81;
    const socketRef = React.useRef<any>();
    const linkSocket: string = "https://api.retot.net";

    const [status, setStatus] = React.useState<number>();

    const onSpam = () => {
        socketRef.current = io(linkSocket, {});
        socketRef.current?.on("connect", () => {
            console.log("conected");
        });

        socketRef.current?.emit(
            "client-join-live",
            {
                live_stream_id: liveStreamId,
            },
            (response: any) => {
                if (response?.status === "ok") {
                    Notification("success", "Vào xem livestream thành công!");
                    setStatus(DEFINE_STATUS.CONNECTED);

                    // Khi vào xem livestream, thành công mới bắt đầu spam comment + reaction
                    let i = 0;
                    setInterval(() => {
                        i++;
                        const messageState: any = {
                            msg_id: +new Date(),
                            user_id: index,
                            content: "comment_" + i,
                            live_stream_id: liveStreamId,
                            role: 3,
                            name: "default_name" + i,
                            user_profile_url:
                                "https://plus.unsplash.com/premium_photo-1697139065201-50c05c556fd3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        };
                        socketRef.current?.emit("client-send-comment", messageState, (data: any) => {
                            console.log(`Client ${data?.name} comment: ${data?.content}`);
                        });
                    }, timeIntervalComment);

                    setInterval(() => {
                        const randomIntFromInterval = (min: number, max: number) => {
                            return Math.floor(Math.random() * (max - min + 1) + min);
                        };
                        let reactionPayload = {
                            value: randomIntFromInterval(1, 4),
                            user_id: index,
                            live_stream_id: liveStreamId,
                        };
                        socketRef.current?.emit("client-send-reaction", reactionPayload, (data: any) => {
                            console.log(`Client ${data?.user_id} drop reaction: ${data?.value}`);
                        });
                    }, timeIntervalReaction);
                } else {
                    Notification("error", "Vào xem livestream thất bại!");
                    setStatus(DEFINE_STATUS.DISCONNECT);
                }
            }
        );

        // }
        // }
    };

    React.useEffect(() => {
        onSpam();
    }, []);

    return (
        <div>
            <div style={{ fontWeight: "bold" }}>
                viewer_{index}
                {status ? (
                    <span style={{ color: "green" }}> - Connected</span>
                ) : (
                    <span style={{ color: "gray" }}> - Disconnet</span>
                )}
            </div>
        </div>
    );
};

export default ToolSpamCommentChild;
