const LocalStorage = {
    getToken: () => localStorage.getItem('token'),
    setToken: (token: string) => localStorage.setItem('token', token),
    removeToken: () => localStorage.removeItem('token'),

    setIsRememberAccount: (value: number) => localStorage.setItem('isRemember', value.toString()),
    getIsRememberAccount: () => localStorage.getItem('isRemember'),

    setUsername: (value: string) => localStorage.setItem('username', value),
    getUsername: () => localStorage.getItem('username'),
    removeUsername: () => localStorage.removeItem('username'),

    setPassword: (value: string) => localStorage.setItem('password', value),
    getPassword: () => localStorage.getItem('password'),
    removePassword: () => localStorage.removeItem('password'),
};

export default LocalStorage;
