import AxiosClient from "api/axios/AxiosClient";

export const newsService = {
    getNews: (payload: any) => {
        return AxiosClient.get("news", { params: payload });
    },
    getNewsDetail: (id: number) => {
        return AxiosClient.get(`news/${id}`);
    },
    createNews: (payload: any) => {
        return AxiosClient.post("news", payload);
    },
    updateNews: (id: number, payload: any) => {
        return AxiosClient.put(`news/${id}`, payload);
    },
    updateNewsStatus: (id: number, payload: any) => {
        return AxiosClient.put(`news/${id}/change-status`, payload);
    },
    deleteNews: (id: number) => {
        return AxiosClient.delete(`news/${id}`);
    },
};
