import React from "react";
import styled from "styled-components";
import AppServices from "../../../service/AppServices";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface ITitleComponent {
    title: string | React.ReactNode;
    component: React.ReactNode;
    isGoBack?: boolean;
}

const TitleComponent: React.FC<ITitleComponent> = ({ title, isGoBack, component }) => {
    return (
        <StyleTitleComponent>
            <div className={"title flex-start"}>
                {isGoBack && (
                    <ArrowLeftOutlined
                        className={"icon mr-16"}
                        onClick={() => AppServices.history!.goBack()}
                        rev={undefined}
                    />
                )}
                {title}
            </div>
            <div className={"item-page"}>{component}</div>
        </StyleTitleComponent>
    );
};

const StyleTitleComponent = styled.div`
    background-color: white;
    padding: 8px 16px;
    border-radius: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
        &:hover {
            color: #1890ff;
            transition: all 0.4s;
        }
    }

    .title {
        font-weight: bold;
        font-size: 18px;
    }
`;

export default TitleComponent;
