import AxiosClient from "api/axios/AxiosClient";

export const orderService = {
    getCustomers: (payload: any) => {
        return AxiosClient.get("customer/list", { params: payload });
    },
    getAddresses: (user_id: any, payload: any) => {
        return AxiosClient.get(`address/${user_id}/listAddress`, { params: payload });
    },
    addNewAddress: (user_id: any, payload: any) => {
        return AxiosClient.post(`address/${user_id}/address`, payload);
    },
    getProducts: (payload: any) => {
        return AxiosClient.get(`product/list`, { params: payload });
    },
    getProductDetail: (id: number) => {
        return AxiosClient.get(`product/${id}/product-price`);
    },
    changePrintStatus: (payload: any) => {
        return AxiosClient.put(`order/change-print-status`, payload);
    },
    createOrder: (payload: any) => {
        return AxiosClient.post(`order/create-web`, payload);
    },
    getTransferFee: () => {
        return AxiosClient.get(`configuration`);
    },
    createCustomer: (payload: any) => {
        return AxiosClient.post(`order/user`, payload);
    },
    exportExcel: (payload: any) => {
        return AxiosClient.get(`order/export_excel`, { params: payload });
    },
    getOrders: (payload: any) => {
        return AxiosClient.get(`order/list`, { params: payload });
    },
};
