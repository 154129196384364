import { Row } from "antd";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import styled from "styled-components";
import { formatNumber, momentToStringDate } from "utils";
//@ts-nocheck
import logo from "../../../assets/image/black_white_logo.jpg";
import ProductTable from "./ProductTable";

interface IBillItem {
    item: any;
}

const BillItem = (props: IBillItem) => {
    const { item } = props;

    const totalWeight = item?.OrderItems?.reduce((acc: number, item: any) => acc + item?.weight, 0);

    return (
        <Container>
            <div className="child_container">
                <div className="header_block">
                    <div className="shop_info_block">
                        <img src={logo} className="logo" />
                        <div className="shop_main_info_block">
                            <p>
                                <b>Nhâm Shop - Rẻ Tốt </b>(Quý khách vui lòng{" "}
                                <b>giữ lại số điện thoại khi cần bảo hành</b>)
                            </p>
                            <p>Bảo hành: 0966.721.706; 0965.734.066; 0963.398.766 - Mua lẻ: 036.905.6012</p>
                            <p>Liền kề 35 TT04 TN Linh Đàm, P.Hoàng Liệt, Q.Hoàng Mai, TP. Hà Nội</p>
                        </div>
                    </div>
                    <div className="qr_code_block">
                        {item?.best_express_code && <QRCode size={48} value={item?.best_express_code} />}
                        <div className="order_info_block">
                            <p>Ngày: {momentToStringDate(item?.create_at)}</p>
                            <p>Khối lượng Ship (g): {formatNumber(totalWeight)}</p>
                            <p style={{ fontSize: 9 }}>
                                <b>Tiền thu hộ: {formatNumber(item?.total_price)} VND</b>
                            </p>
                        </div>
                        {item?.best_express_code && (
                            <Row style={{ width: 130 }}>
                                <Barcode value={item?.best_express_code} height={50} fontSize={18} />
                            </Row>
                        )}
                    </div>
                    <div className="cus_info_block">
                        <div className="name_block">
                            <div className="left_col">
                                <p style={{ color: "black", fontWeight: 600 }}>
                                    Khách nhận: {item?.receive_address?.name}
                                </p>
                            </div>
                            <div className="right_col">
                                <p style={{ color: "black", fontWeight: 600 }}>
                                    Điện thoại: {item?.receive_address?.phone}
                                </p>
                            </div>
                        </div>
                        <p style={{ color: "black", fontWeight: 600, fontSize: 8 }} className="address">
                            Địa chỉ: {item?.receive_address?.address}{" "}
                        </p>
                    </div>
                </div>
                <ProductTable order={item} />
                <div className="footer_block">
                    <div className="left_col">
                        <div className="up_row">
                            <p style={{ margin: 0, lineHeight: "10px" }}>
                                Chỉ dẫn giao hàng: <b>Được đồng kiểm</b> Khách hàng không nghe máy, Từ chối nhận, Sai
                                mẫu tách đơn...Bất kỳ vấn đề gì SHIPPER PHẢI GỌI CHO SHOP <b>Không tự ý chuyển hoàn</b>
                            </p>
                        </div>
                        <div className="below_row">
                            <p style={{ margin: 0, lineHeight: "10px" }}>
                                <b>* ĐT/zalo shop:</b> 0966.721.706; 0965.734.066; 0963.398.766
                                <b>(Shop trực từ 7h-21h cả chủ nhật)</b>
                            </p>
                        </div>
                    </div>
                    <div className="right_col">
                        <p style={{ textAlign: "center", paddingTop: 6 }}>Ghi chú shipper - Hoặc khách ký nhận</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;

    .child_container {
        border: 1px solid #b3b3b3;
        padding: 8px;
        p {
            font-size: 8px;
            font-family: "arial";
            margin: 0;
            line-height: 10px;
        }

        .dash_line {
            width: 100%;
            height: 1px;
            border-top: 1px dashed #c4c4c4;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .header_block {
            .shop_info_block {
                display: flex;
                justify-content: space-between;

                .logo {
                    width: 40px;
                    height: 40px;
                }

                .shop_main_info_block {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: space-around;
                    border-bottom: 1px dashed black;
                }
            }

            .qr_code_block {
                margin-top: -10px;
                margin-bottom: -10px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .order_info_block {
                }
            }

            .cus_info_block {
                .address {
                    margin-top: 2px;

                    p {
                        color: black;
                    }
                }

                .name_block {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-top: 1px dashed black;
                    border-bottom: 1px dashed black;

                    .left_col {
                        flex: 3;
                        display: flex;
                        align-items: center;

                        p {
                            color: black;
                        }
                    }

                    .right_col {
                        flex: 2;
                        display: flex;
                        align-items: center;
                        border-left: 1px dashed black;
                        margin-left: 7px;
                        padding-left: 10px;

                        p {
                            color: black;
                        }
                    }
                }
            }
        }

        .footer_block {
            display: flex;
            p {
                margin: 0;
            }

            .left_col {
                flex: 1.1;
                border-right: 1px dashed gray;

                .up_row {
                    padding-top: 6px;
                    border-bottom: 1px dashed gray;
                }

                .below_row {
                    padding-top: 6px;
                }
            }

            .right_col {
                flex: 1;
            }
        }

        .customer_info_block {
            p {
                margin: 0;
                line-height: 24px;
                text-align: left;
            }
        }
    }
`;

export default BillItem;
