import * as React from "react";
import ReactToPrint from "react-to-print";
import { FunctionalComponentToPrint } from "./ComponentToPrint";

interface IPrintSection {
    orders: any[];
    buttonPrint: any;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PrintSection = (props: IPrintSection) => {
    const { orders, buttonPrint, setLoading } = props;
    const componentRef = React.useRef<any>(null);

    const onBeforeGetContentResolve = React.useRef<any>(null);

    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called");
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve: any) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    return (
        <div style={{ width: 150 }}>
            <ReactToPrint
                content={reactToPrintContent}
                documentTitle="AwesomeFileName"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={buttonPrint}
            />
            <FunctionalComponentToPrint ref={componentRef} props={orders} />
        </div>
    );
};
