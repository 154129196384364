import { getRequest, IApiResponse, postRequest, putRequest } from "../../service/Basevices";
import {
  IReqNotificationDiscount,
  IReqNotificationLivestream, IReqNotificationProduct,
  IResGetCountNotification,
  IResGetListNotification, IResListPushNotification,
} from "./interface";
import { IResBody } from "../interface";
import { Config } from "../../commons/validation/ValidationPattern";

export const getListNotificationAPI = (page: number): Promise<IApiResponse<IResGetListNotification>> => {
  let path: string = `/notification/list?page=${1}&limit=${page * Config.limit}`;
  return getRequest(path, true);
};

export const getCountNotificationAPI = (): Promise<IApiResponse<IResGetCountNotification>> => {
  let path: string = `/notification/count-noti-new`;
  return getRequest(path, true);
};

export const putNotificationAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  let path: string = `/notification/read-noti/${id}`;
  return putRequest(path, true);
};

export const createNotificationAPI = (data: IReqNotificationDiscount | IReqNotificationLivestream | IReqNotificationProduct): Promise<IApiResponse<IResBody>> => {
  let path: string = `/notification/create-noti`;
  return postRequest(path, true, data);
};

export const getListPushNotificationAPI = (page: number, search: string, type: number, startDate: string, endDate: string): Promise<IApiResponse<IResListPushNotification>> => {
  let path: string = `/notification/list-web?page=${page}&limit=${Config.limit}`;
  search && (path = path + `&search=${search}`);
  type && (path = path + `&type=${type}`);
  startDate && (path = path + `&start_date=${startDate}`);
  endDate && (path = path + `&end_date=${endDate}`);
  return getRequest(path, true);
};
