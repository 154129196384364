import { Col, Row, Select } from "antd";
import moment from "moment";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { formatNumber, toNonAccentVietnamese } from "utils";

interface IPaymentInfo {
    vouchers: any[];
    voucherOrderId: any;
    voucherTransportId: any;
    transportFee: any;
    setVoucherTransportId: React.Dispatch<any>;
    setVoucherOrderId: React.Dispatch<any>;
}

const PaymentInfo = (props: IPaymentInfo) => {
    const { vouchers, voucherOrderId, voucherTransportId, setVoucherTransportId, setVoucherOrderId, transportFee } =
        props;
    const createOrderData = useSelector((state: any) => state.createOrder.data);
    const isHasProduct = createOrderData?.find((item: any) => item?.product);

    const [voucherOrder, setVoucherOrder] = React.useState<any[]>([]);
    const [voucherTransport, setVoucherTransport] = React.useState<any[]>([]);

    //type_discount: 1 - tiền, 2 - vận chuyển

    const totalPrice = createOrderData?.reduce((currentValue: number, currentItem: any) => {
        // đang sale
        if (currentItem?.quantity_sale_left > 0) {
            // Mua trong số lượng sale
            if (currentItem?.quantity_sale_left >= currentItem?.quantity) {
                return currentItem?.sale_price * currentItem?.quantity + currentValue;
            } else if (currentItem?.wholesale_quantity && currentItem?.wholesale_quantity <= currentItem?.quantity) {
                // Quá sale, nhưng thoả mãn si

                return currentItem?.wholesale_price * currentItem?.quantity + currentValue;
            }
            return currentItem?.price * currentItem?.quantity + currentValue;
        } else if (currentItem?.wholesale_price && currentItem?.wholesale_quantity) {
            // Mua lớn hơn khoảng sỉ
            if (currentItem?.quantity >= currentItem?.wholesale_quantity) {
                return currentItem?.wholesale_price * currentItem?.quantity + currentValue;
            }
            return currentItem?.price * currentItem?.quantity + currentValue;
        } else {
            return currentItem?.price * currentItem?.quantity + currentValue;
        }
    }, 0);

    const onApplyVoucherOrder = () => {
        //  tiền
        if (voucherOrderId?.type_discount === 1) {
            if (totalPrice - voucherOrderId?.discount > 0) return voucherOrderId?.discount;
            return totalPrice;
        } else {
            const decreaseMoney = totalPrice * 0.01 * voucherOrderId?.discount;

            if (voucherOrderId?.max_discount) {
                if (decreaseMoney > voucherOrderId?.max_discount) return voucherOrderId?.max_discount;
                return decreaseMoney;
            } else {
                if (totalPrice - decreaseMoney > 0) return decreaseMoney;
                return totalPrice;
            }
        }
    };

    const onApplyVoucherTransport = () => {
        //  tiền
        if (voucherTransportId?.type_discount === 1) {
            if (transportFee > voucherTransportId?.discount) return voucherTransportId?.discount;
            return transportFee;
        } else {
            const decreaseMoney = transportFee * 0.01 * voucherTransportId?.discount;

            if (voucherTransportId?.max_discount) {
                if (decreaseMoney > voucherTransportId?.max_discount) return voucherTransportId?.max_discount;
                return decreaseMoney;
            } else {
                if (transportFee - decreaseMoney > 0) return decreaseMoney;
                return totalPrice;
            }
        }
    };

    const onCalculateFinalPrice = () => {
        let priceBefore = 0;
        if (voucherOrderId && voucherTransportId) {
            priceBefore = totalPrice - onApplyVoucherOrder() - onApplyVoucherTransport();
        } else if (voucherOrderId && !voucherTransportId) {
            priceBefore = totalPrice - onApplyVoucherOrder();
        } else if (!voucherOrderId && voucherTransportId) {
            priceBefore = totalPrice - onApplyVoucherTransport();
        } else {
            priceBefore = totalPrice;
        }

        if (transportFee) {
            return priceBefore + transportFee;
        }
        return priceBefore;
    };

    React.useEffect(() => {
        setVoucherOrderId(undefined);
        setVoucherTransportId(undefined);
        // Voucher phải thoả mãn 4 điều kiện: giá trị đơn hàng tối thiểu, ngày bắt đầu, ngày kết thúc, số lượng
        const validVouchers = vouchers?.filter(
            (item: any) =>
                totalPrice >= item?.order_min_price &&
                moment(item?.to_time)?.isAfter(moment()) &&
                moment(item?.from_time).isBefore(moment()) &&
                item?.quantity > 0
        );
        const voucherOrder = validVouchers?.filter((item: any) => item?.type === 1);
        const voucherTransport = validVouchers?.filter((item: any) => item?.type === 2);
        setVoucherOrder(voucherOrder);
        setVoucherTransport(voucherTransport);
    }, [createOrderData]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <CustomRow>
                    <p>Voucher giảm giá</p>
                    <Select
                        value={voucherOrderId?.id}
                        style={{ width: 550 }}
                        showArrow
                        placeholder="Chọn voucher giảm giá"
                        options={voucherOrder}
                        allowClear
                        showSearch
                        disabled={!isHasProduct}
                        onChange={(_: any, item: any) => setVoucherOrderId(item)}
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase();
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase();
                            return nonAccent.includes(nonAccentInput);
                        }}
                    />
                </CustomRow>
                <CustomRow>
                    <p>Voucher vận chuyển</p>
                    <Select
                        style={{ width: 550 }}
                        showArrow
                        value={voucherTransportId?.id}
                        placeholder="Chọn voucher vận chuyển"
                        options={voucherTransport}
                        allowClear
                        showSearch
                        disabled={!isHasProduct}
                        onChange={(_: any, item: any) => setVoucherTransportId(item)}
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase();
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase();
                            return nonAccent.includes(nonAccentInput);
                        }}
                    />
                </CustomRow>
            </Col>
            <Col span={4} />
            <Col span={8}>
                <CustomRow>
                    <p>Tổng tiền hàng</p>
                    <p>{formatNumber(totalPrice)} VND</p>
                </CustomRow>

                {transportFee && (
                    <CustomRow>
                        <p>Phí vận chuyển</p>
                        <p>{formatNumber(transportFee)} VND</p>
                    </CustomRow>
                )}
                {voucherOrderId && (
                    <CustomRow>
                        <p className="voucher_text">Tiền giảm mã voucher</p>
                        <p className="voucher_text">- {formatNumber(onApplyVoucherOrder())} VND</p>
                    </CustomRow>
                )}
                {voucherTransportId && (
                    <CustomRow>
                        <p className="voucher_text">Tiền giảm phí vận chuyển</p>
                        <p className="voucher_text">- {formatNumber(onApplyVoucherTransport())} VND</p>
                    </CustomRow>
                )}

                <CustomRow>
                    <p className="final_price_text">Thành tiền</p>
                    <p className="final_price_text">{formatNumber(onCalculateFinalPrice())} VND</p>
                </CustomRow>
            </Col>
        </Row>
    );
};

const CustomRow = styled(Row)`
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .voucher_text {
        color: orange;
    }

    .final_price_text {
        color: #1c9c09;
    }

    p {
        margin: 0;
    }
`;

export default memo(PaymentInfo);
