import { Select } from "antd";
import { setProducts } from "app/slices/order-product/CreateOrder";
import { flashsaleService } from "modules/flash-sale/service";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toNonAccentVietnamese } from "utils";

interface IParentCategorySelect {
    record: any;
}

const ParentCategorySelect = (props: IParentCategorySelect) => {
    const { record } = props;
    const dispatch = useDispatch();

    const createOrderData = useSelector((state: any) => state.createOrder.data);
    const targetItem = createOrderData?.find((item: any) => item?.id === record?.id);

    const [categories, setCategories] = React.useState<any[]>([]);

    const onGetParentCategories = async () => {
        try {
            const payload = {
                page: 1,
                limit: 100,
                status: 1,
                parent_id: null,
            };
            const res: any = await flashsaleService.getParentCategories(payload);
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }));
                setCategories(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onChange = (value: any) => {
        const newData = createOrderData?.map((item: any) => {
            if (item?.id === record?.id) {
                const targetItem = categories?.find((cate: any) => value === cate?.id);
                return {
                    ...item,
                    isHasChildCate: targetItem?.count_child > 0,
                    parent_category: value,
                    child_category: undefined,
                    product: undefined,
                    attribute: undefined,
                    quantity: 1,
                    price: 0,
                    sale_price: 0,
                    total_price: 0,
                    weight: 0,
                    product_price_id: undefined,
                };
            }
            return item;
        });
        dispatch(setProducts(newData));
    };

    React.useEffect(() => {
        onGetParentCategories();
    }, []);

    return (
        <Select
            style={{ width: "100%" }}
            showArrow
            allowClear
            showSearch
            value={targetItem?.parent_category}
            placeholder="Chọn danh mục cha"
            options={categories}
            filterOption={(input, option: any) => {
                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase();
                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase();
                return nonAccent.includes(nonAccentInput);
            }}
            onChange={onChange}
        />
    );
};

export default memo(ParentCategorySelect);
