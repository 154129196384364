import { PageHeader, Row, Spin, Table, Tag } from "antd";
import { pathMenu } from "commons/components/Menu/MenuComponent";
import { Btn } from "commons/components/btn/Button";
import Container from "commons/components/container";
import IconAntd from "commons/components/icon/IconAntd";
import CustomPopConfirm from "commons/components/pop-confirm";
import React from "react";
import AppServices from "service/AppServices";
import { Notification, formatNumber, momentToStringDate, scrollTo } from "utils";
import useDebounce from "utils/useDebounce";
import Filter from "../component/Filter";
import { flashsaleService } from "../service";

const FlashSleProductPage = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);
    const [status, setStatus] = React.useState<number>();
    const [cateId, setCateId] = React.useState<number>();
    const [search, setSearch] = React.useState<string>("");
    const [dateRange, setDateRange] = React.useState<any>({
        fromDate: undefined,
        toDate: undefined,
    });

    const searchDebounce = useDebounce(search, 300);
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    });

    const columns = [
        {
            title: <b>STT</b>,
            width: 50,
            render: (text: any, record: any, index: any) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Mã sản phẩm</b>,
            dataIndex: "code",
            render: (_: any, record: any) => record?.Product?.code,
        },
        {
            title: <b>Tên sản phẩm</b>,
            dataIndex: "name",
            render: (_: any, record: any) => record?.Product?.name,
        },

        {
            title: <b>Danh mục</b>,
            dataIndex: "category_name",
        },
        {
            title: <b>Loại giảm</b>,
            dataIndex: "type_discount",
            render: (value: number) => {
                if (value === 1) return <Tag color={"#108d33"}>Giảm theo tiền</Tag>;
                return <Tag color={"#dd7e52"}>Giảm theo %</Tag>;
            },
        },
        {
            title: <b>Số lượng</b>,
            dataIndex: "flash_sale_quantity",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Số lượng còn lại</b>,
            dataIndex: "flash_sale_remain",
            render: (value: any) => formatNumber(value),
        },
        {
            title: <b>Giá (VND)</b>,
            width: 150,
            dataIndex: "price",
            render: (_: any, record: any) => {
                if (record?.max_product_price !== record?.min_product_price)
                    return `${formatNumber(record?.min_product_price)} - ${formatNumber(record?.max_product_price)}`;
                return formatNumber(record?.min_product_price);
            },
        },
        {
            title: <b>Thời gian tạo</b>,
            dataIndex: "create_at",
            render: (value: any) => momentToStringDate(value),
        },
        {
            title: <b>Trạng thái</b>,
            dataIndex: "status",
            render: (value: number) => {
                if (value === 1) return <Tag color={"#108d33"}>Đang khuyến mãi</Tag>;
                return <Tag color={"#cc4100"}>Không khuyến mãi</Tag>;
            },
        },
        {
            title: <b>Thao tác</b>,
            width: 100,
            dataIndex: "",
            render: (_: any, record: any) => {
                return (
                    <>
                        <a
                            onClick={() =>
                                AppServices.history!.push(pathMenu.add_edit_flash_sale_product, {
                                    productId: record?.id,
                                })
                            }
                        >
                            <IconAntd style={{ margin: "0 10px" }} icon="EditOutlined" size={"18px"} />
                        </a>
                        <CustomPopConfirm
                            title="Bạn có chắc chắn muốn xoá sản phẩm flash sale này?"
                            onConfirm={() => onDeleteFlashSale(record?.id)}
                        />
                    </>
                );
            },
        },
    ];

    const onGetFlashsales = async () => {
        try {
            setLoading(true);
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce,
                status,
                from_date: dateRange.fromDate,
                to_date: dateRange.toDate,
                category_id: cateId,
            };
            const res: any = await flashsaleService.getFlashsales(payload);
            if (res?.status) {
                setData(res?.data);
                setPaging({
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onDeleteFlashSale = async (id: number) => {
        try {
            setLoading(true);
            const res = await flashsaleService?.deleteFlashSale(id);
            if (res?.status) {
                Notification("success", "Xoá sản phảm flash sale thành công!");
                onGetFlashsales();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (paging.current === 1) {
            onGetFlashsales();
        } else {
            setPaging({ ...paging, current: 1 });
        }
    }, [searchDebounce, status, dateRange, cateId]);

    React.useEffect(() => {
        onGetFlashsales();
    }, [paging.current]);

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        title="Sản phẩm khuyến mãi"
                        extra={[
                            <Btn
                                value={"Thêm mới"}
                                onClick={() => AppServices.history!.push(pathMenu.add_edit_flash_sale_product)}
                                backGroundColor={"#109703"}
                            />,
                        ]}
                    />
                }
                filterComponent={
                    <Filter
                        setDateRange={setDateRange}
                        setStatus={setStatus}
                        search={search}
                        setSearch={setSearch}
                        setCateId={setCateId}
                    />
                }
                contentComponent={[
                    <>
                        <Row>
                            <p>
                                Kết quả lọc: <b>{paging.total}</b>
                            </p>
                        </Row>
                        <Table
                            id="top-table"
                            style={{ width: "100%" }}
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 1300,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: "Chưa có bản ghi nào!",
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: (page) => {
                                    setPaging({ ...paging, current: page });
                                    setTimeout(() => {
                                        scrollTo("start");
                                    }, 500);
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    );
};

export default FlashSleProductPage;
