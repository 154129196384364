import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import loginReducer from "./slices/login/Login";
import accountReducer from "./slices/account/Account";
import productCategoryReducer from "./slices/product-category/ProductCategory";
import addAndUpdateProductReducer from "./slices/add-update-product/AddAndUpdateProduct";
import productReducer from "./slices/product/Product";
import productDetailReducer from "./slices/product-detail/ProductDetail";
import orderProductReducer from "../app/slices/order-product/OrderProduct";
import orderDetailReducer from "../app/slices/order-product/OrderProductDetail";
import customerReducer from "../app/slices/customer/Customer";
import reportSaleReducer from "../app/slices/report/ReportSale";
import chatReducer from "../app/slices/chat/Chat";
import createOrderReducer from "./slices/order-product/CreateOrder";
import flashSaleReducer from "./slices/flash-sale/FlashSaleSlice";

export const store = configureStore({
    reducer: {
        login: loginReducer,
        account: accountReducer,
        productCategory: productCategoryReducer,
        addAndUpdateProduct: addAndUpdateProductReducer,
        product: productReducer,
        productDetail: productDetailReducer,
        orderProduct: orderProductReducer,
        orderProductDetail: orderDetailReducer,
        customer: customerReducer,
        reportSale: reportSaleReducer,
        chat: chatReducer,
        createOrder: createOrderReducer,
        flashSale: flashSaleReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
