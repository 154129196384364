import React, { useEffect, useRef, useState } from "react";
import { Avatar, Badge, Layout, Modal, Popover, Row } from "antd";
import "./Home.scss";
import MenuComponent, { pathMenu } from "../../commons/components/Menu/MenuComponent";
import Images from "../../constance/Images";
import HomeRouter from "./HomeRouter";
import { useAppDispatch } from "../../app/hooks";
import { logoutAction } from "../../app/slices/login/Login";
import FormChangePass from "./component/FormChangePass";
import AppTitle from "../../commons/components/title-component/AppTitle";
import styled from "styled-components";
import { IResNotification } from "../../api/notification/interface";
import {
    getCountNotificationAPI,
    getListNotificationAPI,
    putNotificationAPI,
} from "../../api/notification/Notification";
import moment from "moment";
import { Config, Navigation } from "../../commons/validation/ValidationPattern";
import AppServices from "../../service/AppServices";
import { openNotification } from "../../commons/components/notifycation/Notification";
import { IRoom, isReadMsg } from "../chat/Chat";
import { firebaseConnect } from "../../FirebaseConnect";
import { setRoomAction } from "../../app/slices/chat/Chat";
import { EmptyComponent } from "../../commons/components/AppComponent";
import FormUpdateAccount from "./component/FormUpdateAccount";
import collapse_left from "../../assets/image/collapse_left.png";
import collapse_right from "../../assets/image/collapse_right.png";
import { Notification } from "utils";
import LivestreamEmulatorPage from "modules/livestream-emulator/page";

const io = require("socket.io-client");

const { Header, Sider, Content } = Layout;

const RenderMenuHeader = (
    toggle: any,
    collapsed: any,
    name: string | null,
    gmail: string | null,
    statusDisplay: boolean,
    img: string | null
): React.ReactNode => {
    console.log({ img });

    return (
        <div className="home__logo">
            <div className={"home__logo-avatar flex-center"}>
                <img src={img && img !== "undefined" ? img : Images.avatar} alt="avatar" />
            </div>
            <div className={"home__logo-info"} style={statusDisplay ? { display: "block" } : { display: "none" }}>
                <div className={"name"}>{name}</div>
                <div className={"gmail"}>{gmail !== "null" && gmail}</div>
            </div>
            <Row onClick={toggle} justify={"center"} style={{ marginTop: 20 }}>
                {collapsed ? (
                    <img src={collapse_right} style={{ width: 20, height: 20, cursor: "pointer" }} />
                ) : (
                    <img src={collapse_left} style={{ width: 20, height: 20, cursor: "pointer" }} />
                )}
            </Row>
        </div>
    );
};

const RenderItemHeader = (icon: React.ReactNode, count: number): React.ReactNode => {
    return (
        <div>
            <Badge count={count && count} size={"small"}>
                <Avatar shape={"circle"} size={"small"} icon={icon} />
            </Badge>
        </div>
    );
};

const RenderManageUser = (eventLogout: () => any, eventChangePass: () => any, eventUpdateAccount: () => any) => {
    return (
        <div className={"home__manage-user"}>
            <div className={"home__manage-user__item"} onClick={() => eventChangePass()}>
                Đổi mật khẩu
            </div>
            <div className={"home__manage-user__item"} onClick={() => eventUpdateAccount()}>
                Cập nhật tài khoản
            </div>
            <div className={"home__manage-user__item"} onClick={() => eventLogout()}>
                Đăng xuất
            </div>
        </div>
    );
};

const RenderManageNotify: React.FC<{
    onClosePopover: () => any;
    isCallAPI: boolean;
    logger: (countNotification: number, callAPISuc: boolean) => any;
}> = ({ onClosePopover, isCallAPI, logger }) => {
    const [listNotification, setListNotification] = useState<IResNotification[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);

    const listNotificationRef = useRef<HTMLDivElement>(null);

    const getData = async () => {
        setLoading(false);
        let res = await getListNotificationAPI(page);
        let count = await getCountNotificationAPI();
        if (res.body.status === 1) {
            setLoading(true);
            setListNotification(res.body.data);
            logger(count.body.data.count, true);
            setCount(count.body.data.count);
            if (res.body.data.length < Config.limit * page || res.body.data.length === 0) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getData();
    }, [page, isCallAPI]);

    const Style = styled.div`
        &:hover {
            background-color: #eeeeee;
            border-radius: 4px;
            cursor: pointer;
        }
    `;
    const item = (id: any, content: string, isRead: number, date: string, orderId: number): React.ReactNode => {
        const onReadNotification = async () => {
            AppServices.history!.push(Navigation(orderId).orderProductDetail);
            onClosePopover();
            await putNotificationAPI(id);
            await getData();
        };
        return (
            <Style
                className={"flex-start"}
                key={id}
                style={{ borderBottom: "1px solid #eeeeee", padding: "10px 6px" }}
                onClick={onReadNotification}
            >
                {isRead === 0 && <i className="fas fa-circle" style={{ color: "red" }} />}
                <i className="fas fa-bell ml-16 mr-16" style={{ fontSize: 24, color: "gray" }} />
                <div style={{ fontSize: 13, color: "gray" }}>
                    <div>{content}</div>
                    <div>{moment(date).format(Config.formatTime)}</div>
                </div>
            </Style>
        );
    };
    return (
        <div style={{ width: 400, padding: 8 }}>
            <div className={"flex-between mb-16"}>
                <AppTitle title={"Thông báo"} />
                <div style={{ fontSize: 13, color: "orange" }}>Chưa đọc {count} thông báo mới</div>
            </div>
            {
                <div
                    className={"home__wrapper-notification"}
                    ref={listNotificationRef}
                    onScroll={() => {
                        if (
                            listNotificationRef.current!.scrollTop + listNotificationRef.current!.clientHeight ===
                            listNotificationRef.current!.scrollHeight
                        ) {
                            let oldScrollTop: number = listNotificationRef.current!.scrollTop - 150;
                            if (loading) {
                                listNotificationRef.current!.scrollTop = oldScrollTop;
                                setPage(page + 1);
                            }
                        }
                    }}
                >
                    {listNotification.map((value) =>
                        item(value.id, value.content, value.isRead, value.createAt, value.orderId)
                    )}
                    {listNotification.length === 0 && <EmptyComponent description={"Không có thông báo mới nào"} />}
                    <div className={"flex-center"} style={{ padding: 15 }}>
                        {loading && <i className="fas fa-spinner fa-pulse" style={{ color: "#1890ff" }} />}
                    </div>
                </div>
            }
        </div>
    );
};
const RenderManageMessage: React.FC<{ listRoom: IRoom[]; onClickRoom: (room: IRoom) => any }> = ({
    listRoom,
    onClickRoom,
}) => {
    const Style = styled.div`
        width: 400px;
        padding: 4px;
        color: gray;

        .list-item {
            max-height: 500px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d9d9d9;
                border-radius: 8px;
            }

            &::-webkit-scrollbar-track {
                width: 10px;
                background-color: white;
            }

            .item {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                border-radius: 6.5px;
                border-bottom: 1px solid rgb(230, 235, 245);
                transition: all 0.25s ease;

                &:hover {
                    background-color: rgb(230, 235, 245);
                }

                i {
                    color: rgb(114, 105, 239);
                    margin-right: 4px;
                }

                .avatar {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(114, 105, 239, 0.25);
                    border-radius: 50%;
                    color: rgb(114, 105, 239);
                    text-transform: capitalize;
                    font-weight: bold;
                    font-size: 16px;
                    margin-right: 4px;
                }

                .content {
                    flex-grow: 1;

                    .name {
                        font-weight: bold;
                    }

                    .time {
                        font-size: 12px;
                    }

                    .msg {
                        font-size: 13px;
                    }
                }
            }
        }
    `;

    return (
        <Style>
            <AppTitle title={"Tin nhắn"} />
            {listRoom.length > 0 ? (
                <div className={"list-item mt-16"}>
                    {listRoom.map((room) => {
                        return (
                            <div className={"item"} key={room.id} onClick={() => onClickRoom(room)}>
                                <i className="fad fa-circle" />

                                {room.avatar ? (
                                    <Avatar shape={"circle"} style={{ marginRight: 4 }} size={40} src={room.avatar} />
                                ) : (
                                    <Avatar
                                        shape={"circle"}
                                        style={{
                                            marginRight: 4,
                                            color: "rgb(114, 105, 239)",
                                            backgroundColor: "rgba(114, 105, 239, 0.25)",
                                        }}
                                        size={40}
                                    >
                                        {room.name[0]}
                                    </Avatar>
                                )}

                                <div className={"content"}>
                                    <div className={"flex-between"}>
                                        <div className={"name"}>{room.name}</div>
                                        <div className={"time"}>
                                            {moment(room.lastMsg.createdAt).format(Config.formatTime)}
                                        </div>
                                    </div>
                                    <div className={"msg"}>{room.lastMsg.msg}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <EmptyComponent description={"Không có tin nhắn chưa đọc"} />
            )}
        </Style>
    );
};
const HeaderComponent: React.FC<{ toggle: () => any; collapsed: boolean }> = ({ toggle, collapsed }) => {
    const dispatch = useAppDispatch();
    const [openModalChangePass, setOpenModalChangePass] = useState<boolean>(false);
    const [openModalUpdateAcc, setOpenModalUpdateAcc] = useState<boolean>(false);

    const [openPopoverUser, setOpenPopoverUser] = useState<boolean>(false);
    const [openPopoverNotify, setOpenPopoverNotify] = useState<boolean>(false);
    const [openPopoverMessage, setOpenPopoverMessage] = useState<boolean>(false);

    const [countNotifyCation, setCountNotifyCation] = useState<number>(0);
    const [isCallNotification, setIsCallNotification] = useState<boolean>(false);

    const [listRoom, setListRoom] = useState<IRoom[]>([]);

    const actionLogout = () => {
        dispatch(logoutAction());
        setOpenPopoverUser(false);
    };

    const actionChangePass = () => {
        setOpenModalChangePass(true);
        setOpenPopoverUser(false);
    };

    const actionUpdateAccount = () => {
        setOpenModalUpdateAcc(true);
        setOpenPopoverUser(false);
    };

    const getCountNoty = async () => {
        let count = await getCountNotificationAPI();
        if (count.body.status === 1) {
            setCountNotifyCation(count.body.data.count);
        }
    };

    const getListRoom = () => {
        const refRoomTable = firebaseConnect.database().ref("roomTable/");
        refRoomTable
            .orderByChild("/admin/isReadMsg")
            .equalTo(0)
            .on("value", (childSnapshot) => {
                let newListRoom: IRoom[] = [];
                childSnapshot.forEach((value) => {
                    newListRoom.push({
                        id: parseInt(value.key as string),
                        name: value.val().user.name,
                        phoneNumber: value.val().user.phoneNumber,
                        avatar: value.val().user.avatar,
                        isReadMsg: value.val().user.isReadMsg,
                        lastMsg: value.val().lastMsg || { msg: "", createdAt: "" },
                        isOnline: value.val().user.isOnline,
                        deviceId: value.val().user.deviceID,
                        isFocus: value.val().user.isFocus,
                        timeStamp: moment(value.val().lastMsg.createdAt).unix(),
                    });
                });

                newListRoom.sort(function (a, b) {
                    return b.timeStamp - a.timeStamp;
                });

                setListRoom(newListRoom);
            });
    };

    const notifyMsg = () => {
        let isFirstLoad: boolean = true;
        const refRoomTable = firebaseConnect.database().ref("notification/0");
        refRoomTable.on("value", (childSnapshot) => {
            const params = new URLSearchParams(window.location.search);
            let roomId: number = parseInt(params.get("room") as string);
            if (!isFirstLoad) {
                if (childSnapshot.val().user.id !== roomId)
                    openNotification(
                        "INFO",
                        childSnapshot.val().user.name + ":" + childSnapshot.val().lastMsg.msg,
                        Navigation().chat,
                        () => {
                            dispatch(
                                setRoomAction({
                                    room: {
                                        id: childSnapshot.val().user.id,
                                        name: childSnapshot.val().user.name,
                                        phoneNumber: childSnapshot.val().user.phoneNumber,
                                        avatar: childSnapshot.val().user.avatar,
                                        isReadMsg: 0,
                                        isOnline: 0,
                                        lastMsg: { msg: "", createdAt: "" },
                                        deviceId: "",
                                        timeStamp: 0,
                                    },
                                })
                            );
                            AppServices.history?.push(`/home/chat?room=${childSnapshot.val().user.id}`);
                        },
                        "Tin nhắn mới"
                    );
            }
            isFirstLoad = false;
        });
    };

    // const socket = io(process.env.REACT_APP_SOCKET_URL);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_URL);
        socket.on("connect", () => {
            socket.on(`new-order`, (data: { id: number; msg: string; order_id: number }) => {
                openNotification(
                    "INFO",
                    data.msg,
                    Navigation(data.order_id).orderProductDetail,
                    async () => await putNotificationAPI(data.id)
                );
                setIsCallNotification(true);
                getCountNoty();
            });
        });
        setIsCallNotification(!isCallNotification);
        getListRoom();
        getCountNoty();
        notifyMsg();
    }, []);

    return (
        <div>
            <Header
                className="site-layout-background"
                style={{
                    padding: "2px 50px",
                    position: "fixed",
                    zIndex: 1,
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: 4,
                }}
            >
                <div>
                    <div className={"flex-between"}>
                        <div className={"flex-center"}>
                            <div className={"home__logo-name"}>Rẻ Tốt</div>
                        </div>
                        <div className={"flex-between home__wrapper-btn"} style={{ width: 120 }}>
                            <Popover
                                placement={"bottomLeft"}
                                trigger={"click"}
                                visible={openPopoverNotify}
                                style={{
                                    position: "fixed",
                                    top: 0,
                                }}
                                onVisibleChange={() => setOpenPopoverNotify(!openPopoverNotify)}
                                content={
                                    <div className={"home__notification"}>
                                        <RenderManageNotify
                                            onClosePopover={() => setOpenPopoverNotify(false)}
                                            logger={(countNotification, callAPISuc) => {
                                                setCountNotifyCation(countNotification);
                                                setIsCallNotification(false);
                                            }}
                                            isCallAPI={isCallNotification}
                                        />
                                    </div>
                                }
                            >
                                {RenderItemHeader(<i className="fad fa-bell" />, countNotifyCation)}
                            </Popover>

                            <Popover
                                placement={"bottomLeft"}
                                trigger={"click"}
                                visible={openPopoverMessage}
                                onVisibleChange={() => setOpenPopoverMessage(!openPopoverMessage)}
                                content={
                                    <div className={"home__msg-notification"}>
                                        <RenderManageMessage
                                            listRoom={listRoom}
                                            onClickRoom={(room) => {
                                                setOpenPopoverMessage(false);
                                                isReadMsg(room.id, true);
                                                AppServices.history?.push(`/home/chat?room=${room.id}`);
                                                dispatch(setRoomAction({ room: room }));
                                            }}
                                        />
                                    </div>
                                }
                            >
                                {RenderItemHeader(<i className="fal fa-comment-dots" />, listRoom.length)}
                            </Popover>

                            <Popover
                                placement={"bottom"}
                                title={false}
                                trigger={"click"}
                                visible={openPopoverUser}
                                onVisibleChange={() => setOpenPopoverUser(!openPopoverUser)}
                                content={
                                    <div className={"home__user-notification"}>
                                        {RenderManageUser(actionLogout, actionChangePass, actionUpdateAccount)}
                                    </div>
                                }
                            >
                                {RenderItemHeader(<i className="fal fa-user" />, 0)}
                            </Popover>
                        </div>
                    </div>
                </div>
            </Header>
            <Modal
                title={"Đổi mật khẩu"}
                visible={openModalChangePass}
                footer={false}
                onCancel={() => setOpenModalChangePass(false)}
            >
                <FormChangePass eventCancel={() => setOpenModalChangePass(false)} />
            </Modal>
            <Modal
                title={"Cập nhật tài khoản"}
                visible={openModalUpdateAcc}
                footer={false}
                onCancel={() => setOpenModalUpdateAcc(false)}
            >
                <FormUpdateAccount eventCancel={() => setOpenModalUpdateAcc(false)} />
            </Modal>
        </div>
    );
};

const Home: React.FC<any> = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const toggle = () => setCollapsed(!collapsed);
    const { pathname } = window.location;

    const username: string | null = localStorage.getItem("username");
    const email: string | null = localStorage.getItem("email");
    const avatar: string | null = localStorage.getItem("avatar");

    return (
        <div className={"home"}>
            <Layout>
                <HeaderComponent toggle={toggle} collapsed={collapsed} />
                <Sider trigger={null} collapsible collapsed={collapsed} style={{ marginTop: 69 }}>
                    {RenderMenuHeader(
                        toggle,
                        collapsed,
                        username,
                        email,
                        !collapsed,
                        avatar === "null" ? null : avatar
                    )}
                    <MenuComponent collapsed={collapsed} toggle={toggle} />
                </Sider>
                <Layout className="site-layout">
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: "0px 16px",
                            padding: "24px 0",
                            minHeight: "calc(100vh - 90px)",
                            backgroundColor: "rgb(240, 242, 245)",
                            marginTop: 60,
                        }}
                    >
                        {pathname.includes(pathMenu.livestream_emulator) ? <LivestreamEmulatorPage /> : <HomeRouter />}
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default Home;
